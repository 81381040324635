import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatRippleModule } from '@angular/material/core';

import { I18nVehiclePipesModule, IsVehicleCreatedFromReportPipe, VehicleCrmPipesModule, VehiclePipesModule } from '@caronsale/frontend-pipes';

import { BaseModule } from '@cosCoreComponentsGeneral/base.module';
import { ServiceBadgeComponent } from '@cos/partials/service-badge/service-badge.component';

import { VehicleReportTranslatedModalModule } from './vehicle-report-translated-modal/vehicle-report-translated-modal.module';

import { VehicleOriginReportButtonComponent } from './vehicle-origin-report-button.component';
import { EnzoComponentsModule } from '@caronsale/enzo-angular';

@NgModule({
  declarations: [VehicleOriginReportButtonComponent],
  imports: [
    CommonModule,
    BaseModule,
    EnzoComponentsModule,
    VehiclePipesModule,
    VehicleCrmPipesModule,
    I18nVehiclePipesModule,
    VehicleReportTranslatedModalModule,
    MatRippleModule,
    ServiceBadgeComponent,
  ],
  exports: [VehicleOriginReportButtonComponent],
  providers: [IsVehicleCreatedFromReportPipe],
})
export class VehicleOriginReportButtonModule {}
