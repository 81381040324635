import { IAuction, Validation } from '@caronsale/cos-models';
import { Injectable, OnDestroy } from '@angular/core';
import { ConfigService } from '@cosCoreServices/config/config.service';
import { Observable, Subject, takeUntil } from 'rxjs';
import { HeartbeatService } from '@caronsale/frontend-services';

@Injectable({
  providedIn: 'root',
})
export class AuctionService implements OnDestroy {
  protected readonly unsubscribe$: Subject<void> = new Subject<void>();

  // emits the seconds elapsed since last emission
  protected readonly heartbeatSecondsElapsed$: Observable<number>;

  public constructor(
    //
    private configService: ConfigService,
    heartbeatService: HeartbeatService,
  ) {
    this.heartbeatSecondsElapsed$ = heartbeatService.oneSecondInterval$.pipe(takeUntil(this.unsubscribe$));
  }

  public ngOnDestroy() {
    // in unit tests this can be triggered by the teardown option "destroyAfterEach"
    this.unsubscribe$.next();
  }

  public isAuctionRunning(auction: IAuction): boolean {
    return Validation.isAuctionRunning(auction);
  }

  public isInstantPurchaseActive(auction: IAuction): boolean {
    // TODO: Just check if remainingTimeForInstantPurchase is a number >= 0
    return auction.allowInstantPurchase && auction.remainingTimeForInstantPurchaseInSeconds > 0;
  }

  public isHotBidPhaseActive(auction: IAuction | number): boolean {
    const hotBidDurationInSeconds = Number(this.configService.getConfig().DEFAULT_AUCTION_HOT_BID_DURATION_SECONDS);

    if (typeof auction === 'number') {
      return auction <= hotBidDurationInSeconds;
    }
    return auction?.remainingTimeInSeconds <= hotBidDurationInSeconds && Validation.isAuctionRunning(auction) && !this.isInstantPurchaseActive(auction);
  }

  public remainingSecondsToHotBidPhasePercent(remainingSeconds: number): number | undefined {
    const hotBidDurationInSeconds = Number(this.configService.getConfig().DEFAULT_AUCTION_HOT_BID_DURATION_SECONDS);

    if (remainingSeconds > hotBidDurationInSeconds) {
      return undefined;
    }
    return (remainingSeconds * 100) / hotBidDurationInSeconds;
  }

  public countRemainingTimesDown<T extends IAuction>(auction: T, secondsElapsed: number): T {
    if (!this.isAuctionRunning(auction)) {
      return auction;
    }

    // if both are undefined, null or 0, there is nothing to do
    if (!auction.remainingTimeInSeconds && !auction.remainingTimeForInstantPurchaseInSeconds) {
      return auction;
    }

    // do not change undefined, null and 0
    const countDown = (val: number) => (val ? Math.max(val - secondsElapsed, 0) : val);

    return {
      ...auction,
      remainingTimeInSeconds: countDown(auction.remainingTimeInSeconds),
      remainingTimeForInstantPurchaseInSeconds: countDown(auction.remainingTimeForInstantPurchaseInSeconds),
    };
  }

  public preventJumpsInRemainingTimeOfAllAuctions<T extends IAuction>(previousList: T[], newList: T[]): T[] {
    if (!previousList) {
      return newList;
    }
    return newList.map(auction =>
      this.preventJumpsInRemainingTime(
        auction,
        previousList.find(prevAuction => prevAuction.uuid === auction.uuid),
      ),
    );
  }

  private preventJumpsInRemainingTime<T extends IAuction>(newValue: T, previousValue: T): T {
    // prevent the remainingTime to jump up by one of we have already counted it down.
    // if we count it down by one (because our heartbeat hits asynchronously to the server clock),
    // we refresh the auction and the response might still contain the higher number of seconds, we should not jump back up again
    if (newValue.remainingTimeInSeconds - previousValue?.remainingTimeInSeconds === 1) {
      newValue.remainingTimeInSeconds = previousValue.remainingTimeInSeconds; // keep our lower value
    }
    if (newValue.remainingTimeForInstantPurchaseInSeconds - previousValue?.remainingTimeForInstantPurchaseInSeconds === 1) {
      newValue.remainingTimeForInstantPurchaseInSeconds = previousValue.remainingTimeForInstantPurchaseInSeconds; // keep our lower value
    }
    return newValue;
  }
}
