<h2>{{ 'transport.info.title' | translate }}</h2>

<!-- Seller view -->
@if (data.userType === sellerUser) {
  <div class="info-table" *ngIf="data.userType === sellerUser">
    <h3>{{ 'transport.info.estimated-pickup' | translate }}:</h3>
    <h3>{{ composeDate(data.transportationTask.estimatedPickupDate) }} - {{ composeDate(data.transportationTask.estimatedDeliveryDate) }}</h3>

    <h3>{{ 'transport.info.company' | translate }}:</h3>
    <h3>{{ data.transporterCompanyName }}</h3>

    <h3>{{ 'transport.info.poa' | translate }}:</h3>
    @if (data.urlToSignedPowerOfAttorneyDocumentFile) {
      <a [href]="data.urlToSignedPowerOfAttorneyDocumentFile" target="_blank">
        <fa-icon icon="file-pdf" class="pdf-icon" size="2x"></fa-icon>
      </a>
    }
  </div>
}

<!-- Buyer view -->
@if (data.userType === buyerUser) {
  <div class="info-table">
    <h3>{{ 'transport.info.booked-at' | translate }}:</h3>
    <h3>{{ composeDate(data.transportationTask.bookedAt) }}</h3>

    <h3>{{ 'transport.info.estimated-pickup' | translate }}:</h3>
    <h3>{{ composeDate(data.transportationTask.estimatedPickupDate) }}</h3>

    <h3>{{ 'transport.info.estimated-delivery' | translate }}:</h3>
    <h3>{{ composeDate(data.transportationTask.estimatedDeliveryDate) }}</h3>

    <h3>{{ 'transport.info.status' | translate }}:</h3>
    <h3>{{ data.transportationTask | i18nTransportationState | translate }}</h3>
  </div>
}

<p [innerHtml]="'transport.info.support' | translate: { faqLink: constants.FAQ_URL, faqPhone: constants.FAQ_PHONE }"></p>
