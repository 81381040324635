<div *ngIf="galleryImages()?.[selectedIndex()] as selectedImage" class="selected-image">
  <img
    #selectedImageElement
    [ngSrc]="selectedImage.urlRef"
    priority
    fill
    ngSrcset="640w, 750w, 960w"
    sizes="(max-width: 960px) 100vw, (max-width: 1300px) 55vw, (max-width: 1450px) 50vw, (max-width: 1610px) 45vw, (max-width: 1830px) 40vw, 35vw" />
  <enzo-icon color="white" icon="arrow-left" class="arrows" (click)="decreaseSelectedIndex()"></enzo-icon>
  <enzo-icon color="white" icon="arrow-right" class="arrows" (click)="increaseSelectedIndex()"></enzo-icon>

  <div class="text-overlay-container">
    <enzo-text *ngFor="let overlayText of selectedImage.overlayText" kind="caption" color="white" testid="text-overlay" class="text-overlay">
      {{ overlayText }}
    </enzo-text>
  </div>

  <enzo-text kind="caption" color="white" class="count-overlay">{{ selectedIndex() + 1 }}/{{ galleryImages().length }}</enzo-text>
  <enzo-icon color="white" icon="fullscreen" (click)="openFullscreenGallery(selectedImageElement)"></enzo-icon>

  <ng-container [ngTemplateOutlet]="selectedImage.overlayTemplate"></ng-container>
</div>

<app-image-gallery-thumbnails [(selectedIndex)]="selectedIndex" [thumbnails]="galleryImages()"></app-image-gallery-thumbnails>

<enzo-text *ngIf="galleryImages()?.[selectedIndex()]?.imageDescription" class="image-description">
  {{ galleryImages()[selectedIndex()].imageDescription }}
</enzo-text>
