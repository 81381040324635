<enzo-icon
  icon="note"
  [color]="bright() ? 'black' : 'white'"
  [size]="iconSize()"
  [ngClass]="{ bright: bright(), highlight: highlight() }"
  [style.anchor-name]="anchorName()"
  (click)="toggleNotesPopover()"
  [matTooltip]="popoverState === 'closed' ? auctionNote()?.content : ''"
  matTooltipClass="multiline-tooltip" />

<div
  popover
  #notesPopover
  [style.position-anchor]="anchorName()"
  [style.--top-position]="getPopoverTopPosition()"
  [style.--left-position]="getPopoverLeftPosition()"
  [ngClass]="popoverOnTop() ? 'on-top' : 'on-bottom'"
  (toggle)="popoverState = $any($event).newState">
  <enzo-icon icon="close" size="small" (click)="notesPopover.hidePopover()" />
  <enzo-textfield
    #enzoInput
    [formControl]="notesControl"
    [label]="'auction.my-notes' | translate"
    [placeholder]="'general.type-your-text' | translate"
    type="multiline"
    [rows]="6"
    [maxLength]="500" />
</div>
