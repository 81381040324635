import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Injectable()
export class RecommendationIdService {
  private recommendationId?: string;

  public constructor(private activatedRoute: ActivatedRoute) {
    this.recommendationId = this.activatedRoute?.snapshot?.queryParams?.['recommendationId'];
  }

  public setRecommendationId(recommendationId: string): void {
    this.recommendationId = recommendationId;
  }

  public getRecommendationId(): Readonly<string> {
    return this.recommendationId;
  }

  public static attachRecommendationIdToUrl(url: string, recommendationId?: string): string {
    if (!recommendationId) {
      return url;
    }

    url += url.includes('?') ? '&' : '?';

    return url + 'recommendationId=' + recommendationId;
  }
}
