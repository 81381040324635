import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { createStore } from '@ngneat/elf';
import { getEntity, hasEntity, selectEntity, upsertEntities, withEntities } from '@ngneat/elf-entities';
import { persistState, sessionStorageStrategy } from '@ngneat/elf-persist-state';

import { IUserNote } from '@caronsale/cos-models';

@Injectable({ providedIn: 'root' })
export class NotesRepository {
  public store = createStore({ name: 'auctionNotes' }, withEntities<IUserNote, '_fk_uuid_annotated_item'>({ idKey: '_fk_uuid_annotated_item' }));
  public persist = persistState(this.store, { storage: sessionStorageStrategy });

  public getNote(id: string): IUserNote {
    return this.store.query(getEntity(id));
  }

  public selectNote(id: string): Observable<IUserNote> {
    return this.store.pipe(selectEntity(id));
  }

  public upsertNotes(notes: IUserNote | IUserNote[]): void {
    this.store.update(upsertEntities(notes));
  }

  public hasNote(id: string): boolean {
    return this.store.query(hasEntity(id));
  }

  public reset(): void {
    this.store.reset();
  }
}
