import { Component, Input } from '@angular/core';

import { IBuyerAuctionView } from '@caronsale/cos-models';

/**
 * Show the current auction status
 * For running auctions, it shows
 * - a title that tells if this is this is the starting price or the highest bid
 * - the price-target-info component that shows a red or green badge indicating if the minimum ask is reached.
 *   (its content tells the minimum ask if this is allowed for the auction)
 * - the current highest bid value. This value is animated if it is updated by a new bidding info.
 * - for regular taxation the amount of VAT as small text underneath the current highest bid
 * - the buyer-fee-info component that shwos an expandable statement of all additional fees and the total to be payed
 * For closed auctions, it just displays the status text
 */
@Component({
  selector: 'app-highest-bid-info',
  templateUrl: './highest-bid-info.component.html',
  styleUrls: ['./highest-bid-info.component.scss'],
})
export class HighestBidInfoComponent {
  @Input()
  public auction: IBuyerAuctionView;
}
