import { Injectable, NgZone, inject } from '@angular/core';
import PhotoSwipe, { SlideData } from 'photoswipe';
import * as PDFObject from 'pdfobject';

@Injectable({ providedIn: 'root' })
export class FullscreenGalleryService {
  private ngZone = inject(NgZone);

  public open(index: number, dataSource: SlideData[], imageTrigger?: HTMLImageElement): PhotoSwipe {
    dataSource = dataSource.map(data => ({ ...data, element: imageTrigger }));
    const photoSwipe = new PhotoSwipe({ index, dataSource });

    this.ngZone.runOutsideAngular(() => {
      // to support different aspect ratios this loads images in their original sizes.
      const images = dataSource.map(() => new Image());
      const setDataSource = photoSwipe.options.dataSource;

      photoSwipe.on('change', () => {
        const currentIndex = photoSwipe.currIndex;
        const indexesToLoad = [(currentIndex - 1 + dataSource.length) % dataSource.length, currentIndex, (currentIndex + 1) % dataSource.length];

        for (const idx of indexesToLoad) {
          const item = setDataSource[idx];
          const img = images[idx];
          if (!img.src) {
            img.onload = () => {
              item.width = img.naturalWidth;
              item.height = img.naturalHeight;
              photoSwipe.refreshSlideContent(idx);
            };
            img.src = item.src;
          }
        }
      });

      photoSwipe.init();

      // whenever overlayText is provided this creates the elements that go on top of the image containing said text.
      if (dataSource.some(data => data.overlayText)) {
        photoSwipe.ui.registerElement({
          name: 'custom-caption',
          appendTo: 'wrapper',
          html: dataSource[index].overlayText ? `<enzo-text>${dataSource[index].overlayText.join(', ')}</enzo-text>` : '',
          onInit: (el, pswp) =>
            pswp.on('change', () => {
              if (pswp.currSlide.data.overlayText) {
                el.innerHTML = `<enzo-text>${pswp.currSlide.data.overlayText.join(', ')}</enzo-text>`;
              } else {
                el.innerHTML = '';
              }
            }),
        });
      }
    });

    return photoSwipe;
  }

  public openPdfs(pdfUrls: string[], openAtPage = 0, pdfIndexToOpen = 0): PhotoSwipe {
    const photoSwipe = new PhotoSwipe({ index: pdfIndexToOpen, dataSource: pdfUrls.map(() => ({})) });

    this.ngZone.runOutsideAngular(() => {
      photoSwipe.init();
      photoSwipe.ui.registerElement({
        name: 'pdf-object',
        appendTo: 'wrapper',
        onInit: (el, pswp) => {
          // TODO: remove "any" type after types library is updated to the current pdfobject API version
          PDFObject.embed(pdfUrls[pdfIndexToOpen], el, { page: openAtPage as any });
          pswp.on('change', () => {
            PDFObject.embed(pdfUrls[pswp.currIndex], el);
          });
        },
      });
    });

    return photoSwipe;
  }
}
