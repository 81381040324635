import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BaseModule } from '@cosCoreComponentsGeneral/base.module';
import { AuctionPipesModule, GeneralPipesModule } from '@caronsale/frontend-pipes';
import { TaxationInfoModule } from '@cos/partials/taxation-info/taxation-info.module';
import { CrossBorderDiscountBadgeModule } from '../../cross-border-discount-badge/cross-border-discount-badge.module';
import { HighestBidValueModule } from '../../highest-bid-value/highest-bid-value.module';
import { VoucherSelectionModule } from '@cosBuyer/partials/voucher-selection/voucher-selection.module';
import { AuctionVatModule } from '../auction-vat/auction-vat.module';
import { PriceTargetInfoComponent } from '../../price-target-info/price-target-info.component';
import { HighestBidInfoComponent } from './highest-bid-info.component';
import { AuctionFeeDiscountComponent } from '@cosBuyer/partials/auction-fee-discount/auction-fee-discount.component';

@NgModule({
  declarations: [HighestBidInfoComponent],
  exports: [HighestBidInfoComponent],
  imports: [
    //
    AuctionPipesModule,
    AuctionVatModule,
    BaseModule,
    CommonModule,
    GeneralPipesModule,
    HighestBidValueModule,
    PriceTargetInfoComponent,
    TaxationInfoModule,
    VoucherSelectionModule,
    CrossBorderDiscountBadgeModule,
    AuctionFeeDiscountComponent,
  ],
})
export class HighestBidInfoModule {}
