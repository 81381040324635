<form [formGroup]="purchasePreferencesForm">
  <!-- Maker field -->
  <enzo-select
    formControlName="makes"
    [options]="makes$ | async"
    [label]="'purchase-preferences-modal.maker-label' | translate"
    [placeholder]="'purchase-preferences-modal.maker-placeholder' | translate"
    [allowMultiple]="true"
    [showClearIcon]="true"></enzo-select>

  @if (ranges(); as ranges) {
    <!-- Age range -->
    <div class="range-select-wrapper">
      <enzo-text kind="emphasis-label" color="grey-ultradark">{{ 'purchase-preferences-modal.car-age-range-label' | translate }}</enzo-text>
      <enzo-select
        formControlName="vehicleAgeFrom"
        [options]="ranges.years"
        [placeholder]="'purchase-preferences-modal.from' | translate"
        [allowMultiple]="false"
        [showClearIcon]="true"></enzo-select>

      <enzo-select
        formControlName="vehicleAgeTo"
        [options]="ranges.years"
        [placeholder]="'purchase-preferences-modal.to' | translate"
        [allowMultiple]="false"
        [showClearIcon]="true"></enzo-select>
    </div>

    <!-- Mileage range -->
    <div class="range-select-wrapper">
      <enzo-text kind="emphasis-label" color="grey-ultradark">{{ 'purchase-preferences-modal.mileage-range-label' | translate }}</enzo-text>

      <enzo-select
        formControlName="mileageFromKM"
        [options]="ranges.mileage"
        [placeholder]="'purchase-preferences-modal.from' | translate"
        [allowMultiple]="false"
        [showClearIcon]="true"></enzo-select>

      <enzo-select
        formControlName="mileageToKM"
        [options]="ranges.mileage"
        [placeholder]="'purchase-preferences-modal.to' | translate"
        [allowMultiple]="false"
        [showClearIcon]="true"></enzo-select>
    </div>

    <!-- Price range -->
    <div class="range-select-wrapper">
      <enzo-text kind="emphasis-label" color="grey-ultradark">{{ 'purchase-preferences-modal.price-range-label' | translate }}</enzo-text>
      <enzo-select
        formControlName="priceFrom"
        [options]="ranges.price"
        [placeholder]="'purchase-preferences-modal.from' | translate"
        [allowMultiple]="false"
        [showClearIcon]="true"></enzo-select>

      <enzo-select
        formControlName="priceTo"
        [options]="ranges.price"
        [placeholder]="'purchase-preferences-modal.to' | translate"
        [allowMultiple]="false"
        [showClearIcon]="true"></enzo-select>
    </div>

    <!-- Feul-type field -->
    <enzo-select
      formControlName="fuelTypes"
      [options]="ranges.fuelTypes"
      [label]="'purchase-preferences-modal.fuel-type-label' | translate"
      [placeholder]="'purchase-preferences-modal.maker-placeholder' | translate"
      [allowMultiple]="true"
      [showClearIcon]="true"></enzo-select>

    <!-- Distance to vehicle  -->
    <enzo-select
      formControlName="distanceRadiusKM"
      [label]="'purchase-preferences-modal.distance-to-vehicle-label' | translate"
      [options]="ranges.searchRadius"
      [placeholder]="'purchase-preferences-modal.maker-placeholder' | translate"
      [allowMultiple]="false"
      [showClearIcon]="true"></enzo-select>

    <!-- Vehicle location field (Country) -->
    <enzo-select
      formControlName="countries"
      [label]="'purchase-preferences-modal.vehicle-location-label' | translate"
      [options]="ranges.countries"
      [placeholder]="'purchase-preferences-modal.maker-placeholder' | translate"
      [allowMultiple]="true"
      [showClearIcon]="true"></enzo-select>

    <!-- Category field -->
    <enzo-select
      formControlName="vehicleCategories"
      [options]="ranges.vehicleCategory"
      [label]="'purchase-preferences-modal.category-label' | translate"
      [placeholder]="'purchase-preferences-modal.maker-placeholder' | translate"
      [allowMultiple]="true"
      [showClearIcon]="true"></enzo-select>
  }
</form>
