import { Injectable } from '@angular/core';
import {
  MatLegacySnackBar as MatSnackBar,
  MatLegacySnackBarConfig as MatSnackBarConfig,
  MatLegacySnackBarRef as MatSnackBarRef,
} from '@angular/material/legacy-snack-bar';

import { TranslateService } from '@ngx-translate/core';

import { Validation } from '@caronsale/cos-models';

const DEFAULT_SNACK_TIME = 3000;

@Injectable()
export class I18nSnackService {
  public constructor(
    private snackBar: MatSnackBar,
    private translateService: TranslateService,
  ) {}

  public open(messageKey: string, actionKey?: string, matConfig?: MatSnackBarConfig, translationParams?: { [key: string]: any }): MatSnackBarRef<any> {
    const validMatConfig = Validation.isUndefinedOrNull(matConfig) ? { duration: DEFAULT_SNACK_TIME } : matConfig;

    return this.snackBar.open(
      this.translateService.instant(messageKey, translationParams),
      actionKey ? this.translateService.instant(actionKey, translationParams) : '',
      validMatConfig,
    );
  }

  public openWithOk(messageKey: string, matConfig?: MatSnackBarConfig, translationParams?: { [key: string]: any }): MatSnackBarRef<any> {
    return this.open(messageKey, 'general.ok', matConfig, translationParams);
  }

  public showErrorFromBackend(error: any, defaultMsgKey: string) {
    const message = error?.error?.message || this.translateService.instant(defaultMsgKey);
    this.snackBar.open(message, null, { duration: 5000 });
  }
}
