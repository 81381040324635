import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { EPrebookedServiceBNPLOptionType, EPrebookedServiceType, IBuyerAuctionView, IPrebookedService, IVoucherAssignment } from '@caronsale/cos-models';
import { PrebookedServicesUtils } from '@caronsale/frontend-utils';
import { VoucherService } from '@cosBuyer/partials/services/voucher.service';
import { Subject, takeUntil } from 'rxjs';
import { ITaxationInfo } from '@cos/partials/taxation-info/taxation-info.component';
import { BuyNowPayLaterService } from '@cosCoreServices/buy-now-pay-later/buy-now-pay-later.service';
import { AuctionPriceCalculatorService } from '@cosCoreServices/auction-price-calculator/auction-price-calculator.service';

/**
 * Display an expandable / collapsible statement of the additional fees
 * Add the purchaseFee, the successFee and a mandatory or already booked transport fee to
 * the currentHighestBidValue and display the "total" to be payed.
 * For closed auctions in renegotiation, an "auctionPrice" can be given as the calculation base.
 */
@Component({
  selector: 'app-fee-info',
  templateUrl: './fee-info.component.html',
  styleUrls: ['./fee-info.component.scss'],
})
export class FeeInfoComponent implements OnInit, OnChanges, OnDestroy {
  @Input()
  public auction: IBuyerAuctionView;

  @Input()
  public currentPrebookedServices: IPrebookedService[];

  // optional, defaults to auction.currentHighestBidValueNet, enables to display also "lastOfferByX" values
  @Input()
  public highestBidOrNextBid: number;

  @Input()
  public showPrebookedServicesFees: boolean = true;

  @Input()
  public taxationInfo: ITaxationInfo;

  @Input()
  public isRenegotiationCard: boolean = false;

  @Output()
  public isExpanded = new EventEmitter<boolean>();

  public selectedVoucher: IVoucherAssignment;

  public feeCalculationVisible: boolean = false;
  public purchaseFee: number;
  public successFee: number;
  public transportationPrice: number;
  public cosCheckPlusGuaranteePrice: number;
  public extendedGuaranteePrice: number;
  public totalAmount: number;
  public crossBorderTaxFee: number = 0;
  public crossBorderTaxDiscount: number = 0;
  public applicableVoucherAmount: number = 0;
  public expandedState: boolean = false;

  private unsubscribe$: Subject<void> = new Subject<void>();

  public constructor(
    private auctionPriceCalculatorService: AuctionPriceCalculatorService,
    private voucherService: VoucherService,
    private buyNowPayLaterService: BuyNowPayLaterService,
  ) {}

  public ngOnInit(): void {
    this.voucherService
      .getSelectedVoucher()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(selectedVoucher => {
        this.selectedVoucher = selectedVoucher;
        this.calculateTotalAmount();
      });
  }

  public ngOnChanges() {
    this.calculateTotalAmount();
  }

  private calculateTotalAmount(): void {
    const auctionPricing = this.auctionPriceCalculatorService.getAuctionPrice(this.highestBidOrNextBid, this.auction, this.currentPrebookedServices, {
      selectedVoucher: this.selectedVoucher,
    });

    this.crossBorderTaxDiscount = auctionPricing.feeDetails.crossBorderTaxDiscount;
    this.crossBorderTaxFee = auctionPricing.feeDetails.crossBorderTaxFee;
    this.purchaseFee = this.auction.buyerPurchaseFee;
    this.successFee = this.auction.buyerSuccessFee;
    this.transportationPrice = auctionPricing.feeDetails.transportationPrice;
    this.applicableVoucherAmount = auctionPricing.voucherDiscount;
    this.cosCheckPlusGuaranteePrice = auctionPricing.feeDetails.cosCheckPlusGuaranteePrice;
    this.extendedGuaranteePrice = auctionPricing.feeDetails.extendedGuaranteePrice;

    this.totalAmount = auctionPricing.totalAmount + (this.getBuyNowPayLaterAmount() || 0);
  }

  public ngOnDestroy(): void {
    this.unsubscribe$.next();
  }

  public toggleFeeCalculationVisibile() {
    this.feeCalculationVisible = !this.feeCalculationVisible;
  }

  private getBuyNowPayLaterAmount() {
    const bnplService = PrebookedServicesUtils.getService(this.currentPrebookedServices, EPrebookedServiceType.BUY_NOW_PAY_LATER);
    const payIn60DaysOption = PrebookedServicesUtils.getOption(bnplService, EPrebookedServiceBNPLOptionType.PAY_IN_60_DAYS);

    if (!payIn60DaysOption?.isSelected) {
      return 0;
    }

    return this.buyNowPayLaterService.getBNPL60DaysPrice(this.highestBidOrNextBid, this.auction, this.currentPrebookedServices, this.selectedVoucher);
  }

  public expandedStateChange($event): void {
    this.isExpanded.emit($event);
  }
}
