<mat-dialog-content class="imprint-dialog-content">
  <h2 class="view-title">{{ 'legal.imprint.title' | translate }}</h2>

  <p>{{ 'legal.imprint.information-according-to-law' | translate }}</p>

  <p>
    <strong>{{ constants.COMPANY_NAME }}</strong> <br />
    {{ constants.COMPANY_ADDRESS_LINE_1 }} <br />
    {{ constants.COMPANY_ADDRESS_LINE_2 }}
  </p>

  <p>
    <strong>{{ 'legal.imprint.representing-directors' | translate }}:</strong> {{ constants.REPRESENTING_DIRECTORS }}
  </p>

  <p>{{ 'legal.imprint.phone-number' | translate }}: {{ constants.CONTACT_PHONE }}</p>

  <p>
    {{ 'legal.imprint.mail-address' | translate }}:
    <a target="_blank" class="imprint-link" href="mailto:{{ constants.INFO_EMAIL }}"> {{ constants.INFO_EMAIL }} </a>
  </p>

  <p>
    <strong>{{ 'legal.imprint.registration-court' | translate }}:</strong> {{ constants.REGISTRATION_COURT }}
  </p>

  <p>{{ 'legal.imprint.tax-id' | translate }}: {{ constants.TAX_ID }}</p>

  <p>{{ 'legal.imprint.tax-number' | translate }}: {{ constants.TAX_NUMBER }}</p>

  <p>
    <strong>{{ 'legal.imprint.dispute-settlement' | translate }}</strong>
  </p>

  <p>
    {{ 'legal.imprint.dispute-settlement-platform-info' | translate }}:
    <a target="_blank" class="imprint-link" href="{{ constants.EC_DISPUTE_SETTLEMENT_PLATFORM }}"> {{ constants.EC_DISPUTE_SETTLEMENT_PLATFORM }} </a>
  </p>

  <p>{{ 'legal.imprint.dispute-settlement-policy' | translate }}</p>

  <div class="imprint-dialog-button">
    <div class="row-spacer"></div>

    <button mat-button (click)="this.close()">{{ 'action.close' | translate }}</button>
  </div>
</mat-dialog-content>
