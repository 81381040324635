import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BaseModule } from '@cosCoreComponentsGeneral/base.module';

import { MobileBannerOverlayComponent } from './mobile-banner-overlay.component';

@NgModule({
  imports: [CommonModule, BaseModule],
  declarations: [MobileBannerOverlayComponent],
  exports: [MobileBannerOverlayComponent],
})
export class MobileBannerOverlayModule {}
