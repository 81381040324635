import { Component, Inject, OnDestroy, OnInit, Optional } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { IBuyerAuctionView, IBuyerUser, IPrebookedService } from '@caronsale/cos-models';
import { I18nInfoDialogComponent } from '@cosCoreComponentsGeneral/i18n/info-dialog/i18n-info-dialog.component';
import { Subject, debounceTime, filter, takeUntil, tap } from 'rxjs';
import { BuyerAuctionService } from '@cosCoreFeatures/auction-detail/common/auction-service/buyer-auction.service';
import { BiddingService } from '@cosBuyer/partials/services/bidding/bidding.service';
import { IBuyerAuctionViewState } from '@cosCoreFeatures/auction-detail/common/auction-service/buyer-auction-view-states';
import { GoogleAnalyticsService } from '@cosCoreServices/google-analytics/google-analytics.service';
import { ProductAnalyticsService } from '@cosCoreServices/product-analytics/product-analytics.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { RecommendationIdService } from '@cosBuyer/partials/services/recommendation-id.service';

export interface IMobileBidDialogData {
  buyerUser: IBuyerUser;
  auction: IBuyerAuctionView;
  currentPrebookedServices: IPrebookedService[];
}

@Component({
  selector: 'app-buyer-auction-detail-mobile-bid-dialog',
  templateUrl: './mobile-bid-dialog.component.html',
  styleUrls: ['./mobile-bid-dialog.component.scss'],
})
export class MobileBidDialogComponent implements OnInit, OnDestroy {
  public auction: IBuyerAuctionView;
  public newBidValue: number = null;

  // state (derived from auction)
  public isBiddingAgentVisible = true;
  public isBiddingAgentSet = false;
  public minimalBidIncrement: number;
  public maxBidValue: number;
  public minimalBidThatIsHigher: number;
  public bidValueChangedSubject: Subject<number> = new Subject<number>();

  private unsubscribe$ = new Subject<void>();

  public constructor(
    //
    private dialogRef: MatDialogRef<MobileBidDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: IMobileBidDialogData,
    private dialog: MatDialog,
    private biddingService: BiddingService,
    private buyerAuctionService: BuyerAuctionService,
    private googleAnalyticsService: GoogleAnalyticsService,
    private productAnalyticsService: ProductAnalyticsService,
    @Optional() private recommendationIdService?: RecommendationIdService,
  ) {
    this.bidValueChangedSubject
      .pipe(debounceTime(300), takeUntilDestroyed())
      .subscribe(value => this.biddingService.trackIfBidTooHigh(this.auction, value, 'Details view footer'));
  }

  public ngOnInit(): void {
    this.buyerAuctionService
      .getBuyerAuctionViewState$(this.data.auction.uuid)
      .pipe(
        filter((buyerAuctionViewState: IBuyerAuctionViewState) => Boolean(buyerAuctionViewState?.auction)),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((buyerAuctionViewState: IBuyerAuctionViewState) => {
        this.auction = buyerAuctionViewState.auction;
        this.isBiddingAgentVisible = !this.buyerAuctionService.isHotBidPhaseActive(this.auction);
        this.isBiddingAgentSet = this.biddingService.isBiddingAgentSet(this.auction);
        this.minimalBidIncrement = this.biddingService.getMinimalBidIncrement(this.auction);
        this.minimalBidThatIsHigher = this.biddingService.getMinimalBidThatIsHigher(this.auction);
        this.maxBidValue = this.biddingService.getHighestPossibleBid(this.auction);
      });
  }

  public ngOnDestroy(): void {
    this.unsubscribe$.next();
  }

  public isBidHighEnough(): boolean {
    return this.biddingService.isBidHighEnough(this.auction, this.newBidValue);
  }

  public isBidTooHigh(): boolean {
    return this.biddingService.isBidTooHigh(this.auction, this.newBidValue);
  }

  public isBidOutOfRange(): boolean {
    return !this.biddingService.isBidHighEnough(this.auction, this.newBidValue) || this.biddingService.isBidTooHigh(this.auction, this.newBidValue);
  }

  public onBidInputFocused(): void {
    if (!this.isBidHighEnough()) {
      this.newBidValue = null;
    }
  }

  public bidOnAuction() {
    this.googleAnalyticsService.trackBidByIncrementFromDetailViewFooterClick();
    this.biddingService
      .directlyBidOnAuction(
        this.data.buyerUser,
        this.auction,
        this.data.currentPrebookedServices,
        this.newBidValue,
        undefined,
        this.recommendationIdService?.getRecommendationId(),
      )
      .pipe(
        tap(() => this.dialogRef.close()),
        filter(Boolean),
      )
      .subscribe(() => {
        this.productAnalyticsService.trackEvent('bidPlaced', {
          'Auction end time': new Date(this.auction.endingTime).toISOString(),
          'Auction uuid': this.auction.uuid,
          'Bid timestamp': new Date().toISOString(),
          'Bid type': 'Bid by value',
          'Bid value': this.newBidValue,
          'Bid source': 'Details view footer',
        });
      });
  }

  public setBiddingAgent() {
    this.googleAnalyticsService.trackBiddingAgentFromDetailViewFooterClick();
    this.biddingService
      .setBiddingAgent(this.data.buyerUser, this.auction, this.data.currentPrebookedServices, this.newBidValue)
      .pipe(
        tap(() => this.dialogRef.close()),
        filter(Boolean),
      )
      .subscribe(() => {
        this.productAnalyticsService.trackEvent('bidPlaced', {
          'Auction end time': new Date(this.auction.endingTime).toISOString(),
          'Auction uuid': this.auction.uuid,
          'Bid timestamp': new Date().toISOString(),
          'Bid type': 'Bidding agent',
          'Bid value': this.newBidValue,
          'Bid source': 'Details view footer',
        });
      });
  }

  public removeBiddingAgent() {
    this.googleAnalyticsService.trackRemoveBiddingAgentFromDetailViewFooterClick();
    this.biddingService.removeBiddingAgent(this.auction.uuid);
    this.dialogRef.close();
  }

  public showBiddingAgentInfo(): void {
    I18nInfoDialogComponent.show(this.dialog, 'dialog.buyer.bidding-agent-info', '500px');
  }
}
