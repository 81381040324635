import { animate, AnimationTriggerMetadata, group, query, style, transition, trigger } from '@angular/animations';

export function slideLeftRight(timings: string = '.3s ease-in'): AnimationTriggerMetadata {
  return trigger('slideLeftAndRight', [
    transition(':increment', [
      query(':enter, :leave', style({ position: 'absolute', right: 0, left: 0 }), { optional: true }),
      group([
        query(':leave', [style({ transform: 'translateX(0%)' }), animate(timings, style({ transform: 'translateX(-105%)' }))], {
          optional: true,
        }),
        query(':enter', [style({ transform: 'translateX(105%)' }), animate(timings, style({ transform: 'translateX(0%)' }))], {
          optional: true,
        }),
      ]),
    ]),
    transition(':decrement', [
      query(':enter, :leave', style({ position: 'absolute', right: 0, left: 0 }), { optional: true }),
      group([
        query(':leave', [style({ transform: 'translateX(0%)' }), animate(timings, style({ transform: 'translateX(105%)' }))], {
          optional: true,
        }),
        query(':enter', [style({ transform: 'translateX(-105%)' }), animate(timings, style({ transform: 'translateX(0%)' }))], {
          optional: true,
        }),
      ]),
    ]),
  ]);
}
