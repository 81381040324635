<enzo-context [language]="translateService.currentLang" (enzoLanguageChange)="languageChange($event)"></enzo-context>

@if (!isUIProductionEnvironment() && !bannerIsHidden) {
  <div class="development-banner" data-cy="app-root_development-banner" (click)="bannerIsHidden = true">
    @if (uiEnvironmentLabel === serviceEnvironmentLabel) {
      <enzo-headline level="6" [balance]="false">{{ uiEnvironmentLabel | titlecase }}</enzo-headline>
    } @else {
      <enzo-text kind="label"> Frontend: {{ uiEnvironmentLabel | titlecase }}</enzo-text>
      <enzo-text kind="label"> Backend: {{ serviceEnvironmentLabel | titlecase }}</enzo-text>
    }
  </div>
}

@if (!hideMobileFullscreenOverlay) {
  <div class="mobile-overlay">
    <enzo-logo [options]="{ kind: 'primary', size: '120' }"></enzo-logo>

    <enzo-text align="center" balance>{{ 'mobile-overlay.upper-text' | translate }}</enzo-text>

    <br />

    <enzo-text align="center" balance>{{ 'mobile-overlay.lower-text' | translate }}</enzo-text>

    <enzo-headline level="5" align="center">{{ 'mobile-overlay.call-to-action' | translate }}</enzo-headline>

    <div class="stores">
      <a href="https://apps.apple.com/de/app/caronsale/id1455361415" target="_blank" rel="noopener"><img src="/assets/images/appstore.svg" /></a>

      <a
        href="https://play.google.com/store/apps/details?id=com.castletech.caronsale&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
        target="_blank"
        rel="noopener">
        <img src="/assets/images/playstore.svg" />
      </a>
    </div>
  </div>
}

<!-- when the mobile overlay is shown, we want to hide the routed content (siblings of router-outlet) via css without ng-deep, so we need to wrap it -->
<div class="routed-content">
  <router-outlet></router-outlet>
</div>
