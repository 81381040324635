import { NgModule, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { CosCoreClient } from '@cosCoreServices/core-client/cos-core-client.service';
import { EAuctionDirectUploadRejectionReason, FormatUtils } from '@caronsale/cos-models';
import { ProductAnalyticsService } from '@cosCoreServices/product-analytics/product-analytics.service';

@NgModule()
export class E2EModule {
  public constructor(router: Router, zone: NgZone, cosCoreClient: CosCoreClient, productAnalyticsService: ProductAnalyticsService) {
    if (window['Cypress']) {
      // needed in e2e tests to programmatically login and out of the app
      window['CosCoreClient'] = cosCoreClient;
      // needed in e2e tests to identify e2e sessions, otherwise they exceed the amplitude MTU quota
      window['ProductAnalyticsService'] = productAnalyticsService;

      // needed to be used by create-auction tests
      window['EAuctionDirectUploadRejectionReason'] = EAuctionDirectUploadRejectionReason;
      window['convertToExternalId'] = FormatUtils.convertToExternalId;

      // faster navigation in e2e tests since default cy.visit() command reloads the entire application
      // with this we can effectively skip as much as possible of the UI when testing specific scenarios
      window['navigateByUrl'] = (...params: Parameters<Router['navigateByUrl']>): Promise<boolean> => {
        return zone.run(() => router.navigateByUrl(...params));
      };
    }
  }
}
