import { OverlayRef } from '@angular/cdk/overlay';
import { TemplateRef, Type } from '@angular/core';
import { Subject } from 'rxjs';
import { ICosBottomOverlayCloseEvent } from './types';

// R = Response Data Type, T = Data passed to Modal Type
export class CosBottomOverlayRef<R = any, T = any> {
  public afterClosed$ = new Subject<ICosBottomOverlayCloseEvent<R>>();

  public constructor(
    public overlay: OverlayRef,
    public content: string | TemplateRef<any> | Type<any>,
    public data: T, // pass data to modal i.e. FormData
  ) {}

  public close(data?: R) {
    this._close('close', data);
  }

  private _close(type: 'backdrop-click' | 'close', data: R) {
    this.overlay.dispose();

    this.afterClosed$.next({
      type,
      data,
    });

    this.afterClosed$.complete();
  }
}
