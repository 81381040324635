import { ChangeDetectionStrategy, Component, computed, input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { EnzoComponentsModule } from '@caronsale/enzo-angular';
import { IVehicle } from '@caronsale/cos-vehicle-models';
import { ExpandablePanelModule } from '@cos/partials/expandable-panel/expandable-panel.module';

@Component({
  selector: 'app-vehicle-additional-info',
  standalone: true,
  imports: [EnzoComponentsModule, ExpandablePanelModule, TranslateModule],
  templateUrl: './vehicle-additional-info.component.html',
  styleUrls: ['./vehicle-additional-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VehicleAdditionalInfoComponent {
  public vehicle = input.required<IVehicle>();
  public additionalInfos = computed<string[]>(() => {
    if (this.vehicle().additionalInfo) {
      return this.vehicle()
        .additionalInfo.split(';')
        .map(item => item.trim());
    }
    return [];
  });
}
