import { ActivatedRouteSnapshot, CanActivateFn, createUrlTreeFromSnapshot } from '@angular/router';
import { inject } from '@angular/core';
import { FeatureFlagKeys, ProductAnalyticsService } from '../services/product-analytics/product-analytics.service';
import { of, switchMap } from 'rxjs';

export function featureEnabledGuard(flag: FeatureFlagKeys, fallbackroute: string = '/'): CanActivateFn {
  return (snapshot: ActivatedRouteSnapshot) => {
    return inject(ProductAnalyticsService)
      .isOn(flag)
      .pipe(
        switchMap(isEnabled => {
          if (isEnabled) {
            return of(true);
          }

          return of(createUrlTreeFromSnapshot(snapshot, [fallbackroute]));
        }),
      );
  };
}
