import { Pipe, PipeTransform } from '@angular/core';
import { I18nFormatUtils } from '@caronsale/cos-models';
import { EVehicleRimType } from '@caronsale/cos-vehicle-models';

@Pipe({
  standalone: true,
  name: 'i18nTireRimType',
})
export class I18nTireRimTypePipe implements PipeTransform {
  public transform(tireRimType: EVehicleRimType): string {
    return I18nFormatUtils.formatVehicleRimType(tireRimType);
  }
}
