import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, Input, OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ICosDropdownOption } from './types';
import { Observable, Subject, takeUntil } from 'rxjs';
import { CosAbstractControlValueAccessor } from '../cos-abstract-control-value-accessor';

export const COS_DROPDOWN_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => CosDropdownComponent),
  multi: true,
};

@Component({
  selector: 'app-dropdown',
  templateUrl: './cos-dropdown.component.html',
  styleUrls: ['./cos-dropdown.component.scss'],
  providers: [COS_DROPDOWN_VALUE_ACCESSOR],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CosDropdownComponent extends CosAbstractControlValueAccessor implements ControlValueAccessor, OnInit, OnDestroy {
  @Input()
  public options: ICosDropdownOption[];

  @Input()
  public reset$: Observable<void>;

  public dropdownVisible = false;
  public disabled: boolean;
  public selectedOption: ICosDropdownOption;

  private unsubscribe$: Subject<void> = new Subject<void>();

  public constructor(private changeDetectorRef: ChangeDetectorRef) {
    super();
  }

  public ngOnInit() {
    if (!Array.isArray(this.options)) {
      throw new Error('cos-dropdown requires options.');
    }
    this.reset();
    this.reset$?.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.reset());
  }

  public ngOnDestroy() {
    this.unsubscribe$.next();
  }

  private reset() {
    this.selectedOption = this.options[0];
    this.propagateWhenChanged(this.selectedOption.value);
    this.changeDetectorRef.markForCheck();
  }

  // "callbacks" from the ControlValueAccessor implementation in the base class
  protected controlValueAccessorWriteValue(value: any) {
    this.selectedOption = this.options.find(option => option.value === value);
    // did we find an option for this value?
    if (!this.selectedOption) {
      // no -> set the default option and inform the FormControl
      this.reset();
    } else {
      this.changeDetectorRef.markForCheck();
    }
  }

  protected controlValueAccessorSetDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled; // will prevent the button click handler by setting pointer-events: none
    this.dropdownVisible = false;
    this.changeDetectorRef.markForCheck();
  }

  /*
   * DOM Event handlers (change detection is triggered by angular)
   */
  public onBlur() {
    this.dropdownVisible = false;
  }

  // click event handler for the open-close button
  public toggleDropdown() {
    if (!this.disabled) {
      // in disabled state, click events are not generated from mouse clicks, but from unit tests
      this.dropdownVisible = !this.dropdownVisible;
    }
  }

  // click event handler for option. propagated up to the form control using the custom value accessor interface
  public selectOption(option: ICosDropdownOption) {
    this.selectedOption = option;
    this.dropdownVisible = false;

    // propagate value into form control using control value accessor interface
    this.propagateWhenChanged(this.selectedOption.value);
  }
}
