import { ActivatedRouteSnapshot, CanActivateFn, createUrlTreeFromSnapshot, UrlTree } from '@angular/router';
import { inject } from '@angular/core';
import { CosCoreClient } from '@cosCoreServices/core-client/cos-core-client.service';
import { EUserRole, Validation } from '@caronsale/cos-models';
import { CosBuyerClientService } from '@cosCoreServices/cos-salesman-client/cos-buyer-client.service';
import { map, Observable, of } from 'rxjs';

export function incompleteSignupGuard(): CanActivateFn {
  return (snapshot: ActivatedRouteSnapshot): Observable<boolean | UrlTree> => {
    const lastAuthenticationResult = inject(CosCoreClient).getLastAuthenticationResult();
    if (lastAuthenticationResult?.authenticated && lastAuthenticationResult?.userRole === EUserRole.BUYERS) {
      const cosBuyerClient = inject(CosBuyerClientService);

      // Fetch the latest profile from the backend. Avoid using the cached version because:
      // 1. The guard is only called once per navigated route (e.g. /salesman/*, /pickup/* etc)
      // 2. We need to make sure that if the profile got update, this guard always picks up the latest version
      return cosBuyerClient.getBuyerUserProfile().pipe(
        map(buyer => {
          if (!Validation.isUndefinedNullOrEmptyString(buyer.fullyRegisteredAt)) {
            return true;
          }

          // In case the buyer skipped the sign-up flow and didn't fill in all the fields
          // redirect him to the respective signup flow page
          const missingAddress = Validation.isInvalidOrEmptyString(buyer.addressLine);
          const missingCity = Validation.isInvalidOrEmptyString(buyer.city);
          const missingZipCode = Validation.isInvalidOrEmptyString(buyer.zipCode);
          const missingCountryCode = Validation.isInvalidOrEmptyString(buyer.countryCode);
          const missingCompanyDetails = [missingAddress, missingCity, missingZipCode, missingCountryCode].some(x => x);
          if (missingCompanyDetails) {
            return createUrlTreeFromSnapshot(snapshot, ['/signup/company-details']);
          }

          const missingFirstName = Validation.isInvalidOrEmptyString(buyer.firstName);
          const missingLastName = Validation.isInvalidOrEmptyString(buyer.lastName);
          const missingPhoneNumber = Validation.isInvalidPhoneNumber(buyer.phoneNumber);
          const missingPersonalDetails = [missingFirstName, missingLastName, missingPhoneNumber].some(x => x);
          if (missingPersonalDetails) {
            return createUrlTreeFromSnapshot(snapshot, ['/signup/personal-details']);
          }

          return true;
        }),
      );
    }

    return of(true);
  };
}
