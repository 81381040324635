import { Component, Inject, OnDestroy } from '@angular/core';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { EnzoComponentsModule } from '@caronsale/enzo-angular';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { Subject, takeUntil } from 'rxjs';
import { EEnzoDialogResult, EnzoDialogRef, EnzoDialogTranslationParams } from '@cosCoreComponents/modal-dialogs/enzo-dialog.service';
import {
  DEFAULT_DIALOG_TYPE,
  DEFAULT_DIALOG_VARIANT,
  EnzoDialogBase,
  EnzoDialogType,
  EnzoVariant,
  IEnzoDialogBaseParams,
} from '@cosCoreComponents/modal-dialogs/enzo-dialog-base';

export interface IEnzoModalMessageParams extends IEnzoDialogBaseParams {
  translationBaseKey: string;
  translationParams?: EnzoDialogTranslationParams;
  enzoDialogType: EnzoDialogType;
  enzoDialogVariant?: EnzoVariant;
}

@Component({
  templateUrl: './enzo-modal-message.component.html',
  styleUrls: ['./enzo-modal-message.component.scss'],
  standalone: true,
  imports: [CommonModule, EnzoComponentsModule, TranslateModule],
})
export class EnzoModalMessageComponent extends EnzoDialogBase implements OnDestroy {
  public hasMessage = false;
  public footerButtonBaseKey: string;

  private unsubscribe$: Subject<void> = new Subject<void>();

  public constructor(
    @Inject(DIALOG_DATA) public params: IEnzoModalMessageParams,
    private dialogRef: DialogRef<EEnzoDialogResult, IEnzoModalMessageParams>,
    translateService: TranslateService,
    private enzoDialogRef: EnzoDialogRef,
  ) {
    super();
    this.footerButtonBaseKey = params.translationBaseKey;
    this.enzoDialogType = params.enzoDialogType ?? DEFAULT_DIALOG_TYPE;
    this.enzoDialogVariant = params.enzoDialogVariant ?? DEFAULT_DIALOG_VARIANT;

    this.hasMessage =
      this.isTranslationKeyDefined(translateService, params.translationBaseKey + '.message') ||
      this.isTranslationKeyDefined(translateService, params.translationBaseKey + '.message1') ||
      !!params.translationParams?.messageTemplate;

    this.enzoDialogRef.footerButtonClick$.pipe(takeUntil(this.unsubscribe$)).subscribe(res => this.dialogRef.close(res));
  }

  public ngOnDestroy(): void {
    this.unsubscribe$.next();
  }
}
