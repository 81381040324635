import { Component, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { TranslateModule } from '@ngx-translate/core';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { Observable } from 'rxjs';

export interface IErrorDialogData {
  titleKey: string;
  messageKeyOrKeys: string | string[];
  params?: unknown;
}

@Component({
  selector: 'app-i18n-error-dialog',
  templateUrl: './i18n-error-dialog.component.html',
  styleUrls: ['./i18n-error-dialog.component.scss'],
  standalone: true,
  imports: [MatButtonModule, TranslateModule],
})
export class I18nErrorDialogComponent {
  public titleKey: string;
  public messageKey: string;
  public messageKeys: Array<string>;

  // show an error dialog, use same base key for title and message
  // public static show(dialog: MatDialog, titleKey: string, messageKey?: string | Array<string>, _width?: string): Observable<any>;
  public static show(
    dialog: MatDialog,
    titleKey: string,
    messageKeyOrKeys?: string | Array<string>,
    widthOrParams?: string | { [key: string]: any },
    _width?: string,
  ): Observable<any> {
    let params = {};
    let width: string;
    // params and width are both optional, so we have to find out what is actually given
    if (typeof widthOrParams === 'string') {
      width = widthOrParams;
    } else if (typeof widthOrParams === 'object') {
      width = _width; // might be undefined
      params = widthOrParams;
    }
    return dialog
      .open<I18nErrorDialogComponent, IErrorDialogData>(I18nErrorDialogComponent, {
        id: titleKey, // to be able to find and close it
        width,
        data: {
          titleKey,
          messageKeyOrKeys,
          params,
        },
      })
      .afterClosed();
  }

  public constructor(
    //
    public dialogRef: MatDialogRef<I18nErrorDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IErrorDialogData,
  ) {
    if (!data.messageKeyOrKeys) {
      // message is undefined, null or empty string => title is the base key
      this.titleKey = data.titleKey + '.title';
      this.messageKey = data.titleKey + '.message';
    } else if (typeof data.messageKeyOrKeys === 'string') {
      // one error
      this.titleKey = data.titleKey;
      this.messageKey = data.messageKeyOrKeys;
    } else {
      // array of error message keys
      this.titleKey = data.titleKey;
      this.messageKeys = data.messageKeyOrKeys;
    }
  }

  public close(): void {
    this.dialogRef.close();
  }
}
