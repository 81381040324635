import { animate, AnimationTransitionMetadata, AnimationTriggerMetadata, style, transition, trigger } from '@angular/animations';

export const FADE_OUT_TIMING = '0.2s ease-in';

export function fadeOutTransition(timings: string = FADE_OUT_TIMING): AnimationTransitionMetadata {
  return transition(':leave', [style({ opacity: 1 }), animate(timings, style({ opacity: 0 }))]);
}

export function fadeOut(timings: string = FADE_OUT_TIMING): AnimationTriggerMetadata {
  return trigger('fadeOut', [fadeOutTransition(timings)]);
}
