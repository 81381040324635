import { Injectable } from '@angular/core';
import { EnzoDialogService, EEnzoDialogResult } from '@cosCoreComponents/modal-dialogs/enzo-dialog.service';
import {
  IBuyNowPayLaterSessionWithBillingData,
  IBuyerAuctionView,
  IPrebookedService,
  IVoucherAssignment,
  EBuyNowPayLaterProvider,
  IBuyNowPayLaterOrder,
  IBuyNowPayLaterBalanceWithLabel,
  IBuyNowPayLaterAmount,
} from '@caronsale/cos-models';
import {
  BuyNowPayLaterDialogComponent,
  IBuyNowPayLaterOptionsDialogParams,
} from '@cosCoreFeatures/buyer/components/buy-now-pay-later-options-dialog/buy-now-pay-later-options-dialog.component';

import { AuctionPriceCalculatorService } from '@cosCoreServices/auction-price-calculator/auction-price-calculator.service';
import { CosBuyerClientService } from '@cosCoreServices/cos-salesman-client/cos-buyer-client.service';
import { Observable, map } from 'rxjs';
import { BillieBuyNowPayLaterService } from './providers/billie/billie-buy-now-pay-later.service';
import { BuyNowPayLaterProviderService } from './providers/buy-now-pay-later-provider-service.interface';

export const BNPL_RATE = 0.0289;

export interface IBuyNowPayLaterEstimate {
  feeAmount: number;
  totalAmount: number;
}

@Injectable()
export class BuyNowPayLaterService {
  public providerToService: {
    [key in EBuyNowPayLaterProvider]: BuyNowPayLaterProviderService;
  };

  public constructor(
    private auctionPriceCalculatorService: AuctionPriceCalculatorService,
    private cosBuyerClientService: CosBuyerClientService,
    private enzoDialogService: EnzoDialogService,
    billieBuyNowPayLaterService: BillieBuyNowPayLaterService,
  ) {
    this.providerToService = {
      [EBuyNowPayLaterProvider.BILLIE]: billieBuyNowPayLaterService,
    };
  }

  public createSessionWithBillingData(auctionUuid: string): Observable<IBuyNowPayLaterSessionWithBillingData> {
    return this.cosBuyerClientService.createBuyNowPayLaterSessionWithBillingData(auctionUuid);
  }

  public getBNPL60DaysPrice(bidValue: number, auction: IBuyerAuctionView, currentPrebookedServices: IPrebookedService[], selectedVoucher?: IVoucherAssignment) {
    const price = this.auctionPriceCalculatorService.getAuctionPrice(bidValue, auction, currentPrebookedServices, { selectedVoucher, includeFeesVAT: true });
    return price.totalAmount * BNPL_RATE;
  }

  public getBuyNowPayLaterFeeAmount(auctionUuid: string): Observable<IBuyNowPayLaterAmount> {
    return this.cosBuyerClientService.getBuyNowPayLaterFeeAmount(auctionUuid);
  }

  public performOrderCreationWithSession(auctionUuid: string, sessionData: IBuyNowPayLaterSessionWithBillingData): Observable<IBuyNowPayLaterOrder> {
    return this.providerToService[sessionData.provider.provider].performOrderCreationWithSession(auctionUuid, sessionData);
  }

  public performOrderCreationWithoutSession(auctionUuid: string): Observable<IBuyNowPayLaterOrder> {
    return this.cosBuyerClientService.createBuyNowPayLaterOrder(auctionUuid);
  }

  public openBuyNowPayLaterConfirmationDialog(auction: IBuyerAuctionView, feeAmount: number): Observable<EEnzoDialogResult> {
    const dialogOptions = {
      width: '450px',
      maxWidth: 'none',
      data: {
        auction,
        totalAmount: feeAmount,
      },
    };

    return this.enzoDialogService.openModal<EEnzoDialogResult, IBuyNowPayLaterOptionsDialogParams, BuyNowPayLaterDialogComponent>(
      BuyNowPayLaterDialogComponent,
      dialogOptions,
    );
  }

  public isEnabledForUser(): Observable<boolean> {
    return this.cosBuyerClientService.getBuyNowPayLaterStatus().pipe(map(status => status.enabled));
  }

  public getBuyerUsage(): Observable<IBuyNowPayLaterBalanceWithLabel> {
    return this.cosBuyerClientService.getBuyNowPayLaterBalance();
  }

  public getRunningAuctionPaymentAmount(auctionUuid: string): Observable<IBuyNowPayLaterEstimate> {
    return this.cosBuyerClientService.getRunningAuctionBNPLPaymentAmount(auctionUuid);
  }
}
