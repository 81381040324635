import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CosDropdownComponent } from './cos-dropdown.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [CosDropdownComponent],
  exports: [CosDropdownComponent],
  imports: [CommonModule, FontAwesomeModule, TranslateModule],
})
export class CosDropdownModule {}
