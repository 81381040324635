import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import {
  BuyerTransportationService,
  ESelfPickupAvailability,
  ETransportationAvailability,
  ITransportationViewModel,
} from '@cosCoreServices/buyer-transportation/buyer-transportation.service';
import { EPrebookedServiceTransportOptionType, IBuyerAuctionView, IPrebookedService, PrebookedServiceTransportOptionType } from '@caronsale/cos-models';
import { MatLegacyRadioButton as MatRadioButton } from '@angular/material/legacy-radio';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';
import { BuyerAuctionService } from '@cosCoreFeatures/auction-detail/common/auction-service/buyer-auction.service';

const TRANSPORT_NOT_OFFERED_REASON_KEYS = {
  [ETransportationAvailability.NOT_ALLOWED_FOR_REGION]: 'transport.not-available.not-allowed-for-region',
  [ETransportationAvailability.NOT_AVAILABLE]: 'prebooked-service.transport.not-available-text',
  [ETransportationAvailability.DISABLED_MANUALLY]: 'transport.not-available.pick-up-only',
  [ETransportationAvailability.BOOKING_NOT_POSSIBLE_ANY_MORE]: 'transport.not-available.day-after-released-booking-hint',
  [ETransportationAvailability.JOB_REJECTED]: 'transport.not-available.transport-not-available',
};

const TRANSPORT_OPTION_SELF_PICKUP = EPrebookedServiceTransportOptionType.SELF_PICKUP;
const TRANSPORT_OPTION_TRANSPORT = EPrebookedServiceTransportOptionType.STANDARD;

@Component({
  selector: 'app-prebooked-transport',
  templateUrl: './prebooked-transport.component.html',
  styleUrls: ['./prebooked-transport.component.scss'],
})
export class PrebookedTransportComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {
  @Input()
  public auction: IBuyerAuctionView;

  @Input()
  public currentPrebookedServices: IPrebookedService[];

  @Output()
  public transportSelectionChange: EventEmitter<PrebookedServiceTransportOptionType> = new EventEmitter<PrebookedServiceTransportOptionType>();

  @Input()
  public view: 'CONFIRMATION_MODAL' | 'DETAIL_PAGE' = 'DETAIL_PAGE';

  @ViewChild('selfPickupOption')
  public selfPickupRadioButton: MatRadioButton;

  @ViewChild('transportOption')
  public transportRadioButton: MatRadioButton;

  // export constants to be used in template
  public readonly TRANSPORT_OPTION_SELF_PICKUP = TRANSPORT_OPTION_SELF_PICKUP;
  public readonly TRANSPORT_OPTION_TRANSPORT = TRANSPORT_OPTION_TRANSPORT;

  public showSelectedTransportOptionOnly: boolean;
  public selfPickupAvailable: boolean;
  public transportAvailable: boolean;
  public transportPrice: number;
  public transportForm: UntypedFormGroup;
  public isHotBidPhaseActive = false;
  public estimatedDeliveryDays: { from: number; to: number };
  public transportNotOfferedInfoKey: string;
  public selfPickupNotOfferedInfo: boolean;
  public showPickupTimeSubtext = false;
  public isDutchToForeignAuction: boolean;

  private unsubscribe$ = new Subject<void>();

  public constructor(
    //
    private formBuilder: UntypedFormBuilder,
    private buyerTransportationService: BuyerTransportationService,
    private changeDetectorRef: ChangeDetectorRef,
    private buyerAuctionService: BuyerAuctionService,
  ) {
    this.transportForm = this.formBuilder.group({
      transport: TRANSPORT_OPTION_SELF_PICKUP,
    });
  }

  public ngOnInit(): void {
    this.showPickupTimeSubtext = this.auction.hasIncreasedPickupTime || this.auction.isCompoundPickup || this.auction.sellerAccount?.isCompoundEnabled;
    this.estimatedDeliveryDays = this.buyerTransportationService.getEstimatedDeliveryDays(this.auction);
    this.isDutchToForeignAuction = this.buyerTransportationService.isDutchToForeignAuction(this.auction);
    this.showSelectedTransportOptionOnly =
      this.view === 'CONFIRMATION_MODAL' &&
      this.isTransportEnforcedOrPrebookingPersisted(this.auction) &&
      // Check if user can unbook the transport service
      // Self-pickup is disabled when the ENV variable CHECKOUT_TRANSPORT_ALLOW_DESELECT is set
      !this.buyerTransportationService.isSelfPickupEnabled(this.auction.prebookedServices);
    this.transportForm.controls['transport'].valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(newTransportOptionValue => {
      this.transportSelectionChange.emit(newTransportOptionValue);
    });
  }

  public ngAfterViewInit() {
    this.ngOnChanges();
    this.changeDetectorRef.detectChanges();
  }

  public ngOnChanges(): void {
    this.isHotBidPhaseActive = this.buyerAuctionService.isHotBidPhaseActive(this.auction);

    const selfPickupAvailability: ESelfPickupAvailability = this.buyerTransportationService.getSelfPickupAvailability(
      this.auction,
      this.currentPrebookedServices,
    );
    const transportationViewModel: ITransportationViewModel = this.buyerTransportationService.getTransportationViewModel(
      this.auction,
      this.currentPrebookedServices,
    );
    this.requestTransportationPriceIfNotRequestedYet(transportationViewModel);
    this.transportPrice = transportationViewModel.transportationPrice;

    if (!this.selfPickupRadioButton || !this.transportRadioButton) {
      return; // wait for ngAfterViewInit
    }

    // as long as there is no transport pre-booked or enforced, self-pickup is selected by default
    if (selfPickupAvailability === ESelfPickupAvailability.SELECTED) {
      this.setSelfPickupSelected(transportationViewModel);
    } else {
      // transport is pre-booked or enforced.
      // (selfPickupAvailability is AVAILABLE, AVAILABLE_BUT_TRANSPORT_SELECTED or NOT_AVAILABLE_TRANSPORT_ENFORCED)
      this.setTransportSelected(selfPickupAvailability);
    }
  }

  // transport prebooking is only persisted if it is selected in the auction's prebookedServices. Do not look into currentPrebookedServices
  private isTransportEnforcedOrPrebookingPersisted(auction: IBuyerAuctionView): boolean {
    const transportationViewModel: ITransportationViewModel = this.buyerTransportationService.getTransportationViewModel(auction);
    return [
      ETransportationAvailability.ENFORCED_PRICE_IS_KNOWN,
      ETransportationAvailability.ENFORCED_PRICE_IS_REQUESTED,
      ETransportationAvailability.ENFORCED_PRICE_NOT_REQUESTED_YET,
      ETransportationAvailability.BOOKED_OR_PREBOOKED,
    ].includes(transportationViewModel.transportationAvailability);
  }

  private setTransportSelected(selfPickupAvailability: ESelfPickupAvailability) {
    this.transportRadioButton.disabled = false;
    this.transportAvailable = true;
    this.transportNotOfferedInfoKey = null;
    this.transportForm.setValue({ transport: TRANSPORT_OPTION_TRANSPORT }, { emitEvent: false });
    switch (selfPickupAvailability) {
      case ESelfPickupAvailability.AVAILABLE:
        this.selfPickupRadioButton.disabled = false;
        this.selfPickupAvailable = true;
        this.selfPickupNotOfferedInfo = false;
        break;
      case ESelfPickupAvailability.AVAILABLE_BUT_TRANSPORT_SELECTED: // grey info icon
        this.selfPickupRadioButton.disabled = true;
        this.selfPickupAvailable = true;
        this.selfPickupNotOfferedInfo = true;
        break;
      case ESelfPickupAvailability.NOT_AVAILABLE_TRANSPORT_ENFORCED: // red info icon
        this.selfPickupRadioButton.disabled = true;
        this.selfPickupAvailable = false; // triggers the 'not-available' css class which makes the info icon red
        this.selfPickupNotOfferedInfo = false;
        break;
      default:
        // cannot happen
        break;
    }
  }

  private setSelfPickupSelected(transportationViewModel: ITransportationViewModel) {
    this.selfPickupRadioButton.disabled = false;
    this.selfPickupNotOfferedInfo = false;
    this.selfPickupAvailable = true;
    this.transportForm.setValue({ transport: TRANSPORT_OPTION_SELF_PICKUP }, { emitEvent: false });
    // depending on the transportation availability you can switch to transport
    switch (transportationViewModel.transportationAvailability) {
      case ETransportationAvailability.NOT_ALLOWED_FOR_REGION:
      case ETransportationAvailability.NOT_AVAILABLE:
      case ETransportationAvailability.DISABLED_MANUALLY:
      case ETransportationAvailability.BOOKING_NOT_POSSIBLE_ANY_MORE:
      case ETransportationAvailability.JOB_REJECTED:
        this.transportRadioButton.disabled = true;
        this.transportAvailable = false;
        this.transportNotOfferedInfoKey = TRANSPORT_NOT_OFFERED_REASON_KEYS[transportationViewModel.transportationAvailability];
        break;
      default:
        this.transportRadioButton.disabled = false;
        this.transportAvailable = true;
        this.transportNotOfferedInfoKey = null;
        break;
    }
  }

  private requestTransportationPriceIfNotRequestedYet(transportationViewModel: ITransportationViewModel) {
    // ToDo: shall this be done automatically in getTransportationViewModel?
    if (
      (transportationViewModel.transportationAvailability === ETransportationAvailability.AVAILABLE_PRICE_NOT_REQUESTED_YET ||
        transportationViewModel.transportationAvailability === ETransportationAvailability.ENFORCED_PRICE_NOT_REQUESTED_YET) &&
      transportationViewModel.isPriceRequestPossible
    ) {
      this.buyerTransportationService.requestTransportationPrice(this.auction, this.unsubscribe$);
    }
  }

  public ngOnDestroy(): void {
    this.unsubscribe$.next();
  }
}
