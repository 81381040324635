import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';

import { BaseModule } from '@cosCoreComponentsGeneral/base.module';
import { StatusInfoComponent } from './status-info.component';
import { AuctionPipesModule, I18nAuctionPipesModule } from '@caronsale/frontend-pipes';
import { RatingScaleDisplayModule } from '@cos/partials/rating-scale-display/rating-scale-display.module';
import { HotBidProgressBarModule } from '@cos/partials/status-info/hot-bid-progress-bar/hot-bid-progress-bar.module';
import { EnzoComponentsModule } from '@caronsale/enzo-angular';
import { EndingDateComponent } from '@cos/partials/ending-date/ending-date.component';
import { SellerAuctionPaymentStateComponent } from '@cos/partials/seller-auction-payment-state/seller-auction-payment-state.component';

@NgModule({
  declarations: [StatusInfoComponent],
  exports: [StatusInfoComponent],
  imports: [
    AuctionPipesModule,
    CommonModule,
    BaseModule,
    EndingDateComponent,
    EnzoComponentsModule,
    HotBidProgressBarModule,
    I18nAuctionPipesModule,
    RatingScaleDisplayModule,
    SellerAuctionPaymentStateComponent,
    MatTooltipModule,
  ],
})
export class StatusInfoModule {}
