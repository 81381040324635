/*
 * implements the control value accessor interface for custom form controls
 */
export abstract class CosAbstractControlValueAccessor {
  // mirrors the value property of the FromControl instance.
  // initially undefined, since we really don't know it
  private formControlValue;

  // records if there are changes we could not propagate, because registerOnChange has not been called yet.
  private dirty = false;

  // @formatter:off   (auto-format generates an unnecessary semicolon which breaks the build)
  // will be overwritten by registerOnChange() from ControlValueAccessor
  private propagateChange = (_: any) => {
    this.dirty = true;
  };
  // @formatter:on

  // "interface" to the concrete class that extends us
  // the FormControl tells us the value to show in the view
  protected abstract controlValueAccessorWriteValue(value: any);

  // the FormControl wants to disable us
  protected abstract controlValueAccessorSetDisabledState(isDisabled: boolean);

  // We tell the FormControl changes coming from the UI
  protected propagateWhenChanged(newValue: any) {
    if (newValue !== this.formControlValue) {
      // propagate only when really something changed
      this.formControlValue = newValue;
      this.propagateChange(newValue);
    }
  }

  // ControlValueAccessor interface:
  public writeValue(value: any) {
    this.formControlValue = value;
    this.controlValueAccessorWriteValue(value);
  }

  public registerOnChange(fn: any) {
    this.propagateChange = fn;
    // were there value changes before we could propagate them?
    if (this.dirty) {
      this.dirty = false;
      // setTimeout prevents an "expression changed after checked" warning
      // In case there are more changes before the callback, the timer callback
      // would propagate the latest formControlValue again.
      setTimeout(() => this.propagateChange(this.formControlValue), 0);
    }
  }

  public registerOnTouched() {
    // ToDo
  }

  public setDisabledState(isDisabled: boolean) {
    this.controlValueAccessorSetDisabledState(isDisabled);
  }
}
