import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CosCoreClient } from '@cosCoreServices/core-client/cos-core-client.service';

export interface IDamageCatalogUrl {
  url: string;
}

// TODO: Move everything shooting against public endpoints to here
@Injectable()
export class CosPublicClientService {
  public constructor(private cosClient: CosCoreClient) {}

  public getDamageCatalogUrl(): Observable<IDamageCatalogUrl> {
    return this.cosClient.request('get', `/meta/catalog`);
  }
}
