import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GeneralPipesModule } from '@caronsale/frontend-pipes';
import { BaseModule } from '@cosCoreComponentsGeneral/base.module';
import { AddressSelectionComponent } from '@cosCoreComponents/address-selection/address-selection.component';

@NgModule({
  imports: [CommonModule, BaseModule, GeneralPipesModule],
  declarations: [AddressSelectionComponent],
  exports: [AddressSelectionComponent],
})
export class AddressSelectionModule {}
