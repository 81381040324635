import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { GeneralPipesModule } from '@caronsale/frontend-pipes';
import { BaseModule } from '@cosCoreComponentsGeneral/base.module';

import { VehicleReportTranslatedModalComponent } from './vehicle-report-translated-modal.component';

@NgModule({
  declarations: [VehicleReportTranslatedModalComponent],
  exports: [VehicleReportTranslatedModalComponent],
  imports: [CommonModule, BaseModule, GeneralPipesModule],
})
export class VehicleReportTranslatedModalModule {}
