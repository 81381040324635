import { Component, Inject } from '@angular/core';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { IGeneralBusinessTerms } from '@caronsale/cos-models';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-general-business-terms-modal',
  templateUrl: './general-business-terms-modal.component.html',
  standalone: true,
  imports: [TranslateModule, MatButtonModule, MatDialogModule],
})
export class GeneralBusinessTermsModalComponent {
  public constructor(@Inject(MAT_DIALOG_DATA) public generalBusinessTerms: IGeneralBusinessTerms) {}
}
