import { FormControl, Validators } from '@angular/forms';
import { BaseModule } from '@cosCoreComponents/general/base.module';
import { GeneralPipesModule, I18nGeneralPipesModule } from '@caronsale/frontend-pipes';
import { Component, OnInit } from '@angular/core';
import { IAccount, IFile, Validation } from '@caronsale/cos-models';
import { AccountDataService } from '@cosCoreServices/account-data/account-data.service';
import { CosBuyerClientService } from '@cosCoreServices/cos-salesman-client/cos-buyer-client.service';
import { I18nSnackService } from '@cosCoreServices/i18n-snack/i18n-snack.service';
import { Observable, map, switchMap, catchError, EMPTY, finalize } from 'rxjs';
import { Buffer } from 'buffer';
import { KYCDocumentType } from '@cosCoreServices/cos-salesman-client/types';
import { TranslateModule } from '@ngx-translate/core';
import { EnzoComponentsModule, EnzoValidation } from '@caronsale/enzo-angular';
import { CommonModule } from '@angular/common';
import { BrowserDetectionService } from '@cosCoreServices/browser-detection/browser-detection.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { IUploadProperties } from '@cosCoreComponents/general/signup/upload-documents/types';
const VAT_ID_PATTERN = /^[A-Z]{2}[A-Z0-9]{8,12}$/;

type layoutTypes = 'horizontal' | 'vertical';

@Component({
  selector: 'app-buyer-self-register-modal',
  templateUrl: './buyer-self-register-modal.component.html',
  styleUrls: ['./buyer-self-register-modal.component.scss'],
  imports: [BaseModule, CommonModule, EnzoComponentsModule, GeneralPipesModule, I18nGeneralPipesModule, TranslateModule],
  standalone: true,
})
export class BuyerSelfRegisterModalComponent implements OnInit {
  private fileUploadCommonProps: IUploadProperties = {
    url: null,
    givenFile: null,
    cancellable: false,
    fakeUploadDuration: 3000,
    mimeTypes: ['application/pdf', 'image/jpeg', 'image/png'],
    response: { received: false, success: false },
  };

  public firstIdDocumentLabel: string;
  public idDocumentFrontType: KYCDocumentType;
  public secondIdDocumentVisible: boolean;

  public selectedIdType: string = '1';
  public radioBtnLayout: layoutTypes = 'horizontal';

  public accountData: IAccount;
  public saveInProgress: boolean = false;

  public registrationUpload: IUploadProperties = { ...this.fileUploadCommonProps };
  public idFrontUpload: IUploadProperties = { ...this.fileUploadCommonProps };
  public idBackUpload: IUploadProperties = { ...this.fileUploadCommonProps };

  public disableButtons: boolean = false;

  public vatId = new FormControl('', {
    validators: [EnzoValidation.wrapValidator(Validators.pattern(VAT_ID_PATTERN), 'signup.invalid-vat-id')],
  });

  public constructor(
    private dialogRef: MatDialogRef<BuyerSelfRegisterModalComponent>,
    private cosBuyerSvc: CosBuyerClientService,
    private accountDataSvc: AccountDataService,
    private snack: I18nSnackService,
    private browserDetectionService: BrowserDetectionService,
  ) {
    this.browserDetectionService.isMobileOrTabletWidth$
      .pipe(takeUntilDestroyed())
      .subscribe(isMobileOrTablet => (this.radioBtnLayout = isMobileOrTablet ? 'vertical' : 'horizontal'));
  }

  public ngOnInit() {
    this.accountDataSvc.getAccountData().subscribe((accountData: IAccount) => {
      this.accountData = accountData;

      if (accountData.urlToBusinessRegistrationDocument) {
        this.setUploadProperty(this.registrationUpload, accountData.urlToBusinessRegistrationDocument);
      }

      if (accountData.ownerUrlToIdDocumentFront) {
        this.setUploadProperty(this.idFrontUpload, accountData.ownerUrlToIdDocumentFront);
      }

      if (accountData.ownerUrlToIdDocumentBack) {
        this.setUploadProperty(this.idBackUpload, accountData.ownerUrlToIdDocumentBack);
      }

      this.vatId.patchValue(accountData?.vatId);
    });

    this.updateIdDocumentLabels();
  }

  public onIdTypeChange(event: string) {
    this.selectedIdType = event;

    this.updateIdDocumentLabels();
  }

  public documentSelected(event: CustomEvent, documentType: KYCDocumentType, uploadElementProperties: IUploadProperties) {
    const selectedFile: File = event.detail;
    this.disableButtons = true;

    this.encodeFile(selectedFile)
      .pipe(
        switchMap((encodedFile: IFile) => this.cosBuyerSvc.uploadKYCDocument(encodedFile, this.accountData.uuid, documentType, 'self-register-modal')),
        finalize(() => (this.disableButtons = false)),
      )
      .subscribe({
        next: (file: IFile) => {
          uploadElementProperties.response = { received: true, success: true };
          uploadElementProperties.url = file.url;
        },
        error: err => {
          uploadElementProperties.response = { received: true, success: false, errorMessage: err.message };
        },
      });
  }

  public documentDeleted(uploadElementProperties: IUploadProperties) {
    this.cosBuyerSvc.deleteKYCDocument(uploadElementProperties.url).subscribe();
    uploadElementProperties.response = { received: false, success: false };
    uploadElementProperties.url = null;
  }

  public requestKYCCheck(): Observable<void> {
    return this.accountDataSvc.requestBuyerKYCCheckForAccount().pipe(
      map(() => {
        this.snack.open('account.buyer.kyc-check-requested', null, { duration: 5000 });
      }),
      catchError(error => {
        this.snack.open('self-registration.buyer.error-kyc-check-requested', null, { duration: 5000 }, { error: error.error.message });

        return EMPTY;
      }),
    );
  }

  public saveAccountData() {
    if (this.vatId.valid) {
      this.saveInProgress = true;
      const uploadedRegistration = !Validation.isInvalidOrEmptyString(this.registrationUpload.url);
      const uploadedIdFront = !Validation.isInvalidOrEmptyString(this.idFrontUpload.url);
      const uploadedIdBack = !Validation.isInvalidOrEmptyString(this.idBackUpload.url);

      const hasUploadedDocuments = uploadedRegistration && uploadedIdFront && uploadedIdBack;
      const hasAtLeastOneUploadedDocument = uploadedRegistration || uploadedIdFront || uploadedIdBack;

      this.updateAccountData()
        .pipe(
          switchMap(() => {
            if (hasAtLeastOneUploadedDocument) {
              return this.requestKYCCheck();
            }

            return EMPTY;
          }),
          catchError(error => {
            this.snack.open('self-registration.buyer.error-saving', null, null, { error: error.message });

            return EMPTY;
          }),

          finalize(() => {
            if (!hasUploadedDocuments) {
              this.snack.open('account.buyer.user-profile-updated', null, { duration: 5000 });
            }

            this.saveInProgress = false;
            this.dialogRef.close(true);
          }),
        )
        .subscribe();
    }
  }

  public closeModal() {
    this.dialogRef.close(false);
  }

  public hasUploadedBusinessRegistrationDocument(): boolean {
    return !!this.accountData?.urlToBusinessRegistrationDocument;
  }

  public hasUploadedIdDocument(): boolean {
    switch (this.selectedIdType) {
      case '1': // id front and back
        return !!this.accountData?.ownerUrlToIdDocumentFront && !!this.accountData?.ownerUrlToIdDocumentBack;
      case '2': // id front only
      case '3': // id front only
        return !!this.accountData?.ownerUrlToIdDocumentFront;
    }
    return false;
  }

  private encodeFile(file: File): Observable<IFile> {
    return new Observable<ArrayBuffer>(subscriber => {
      const reader = new FileReader();

      reader.readAsArrayBuffer(file);
      reader.onload = () => {
        subscriber.next(reader.result as ArrayBuffer);
        subscriber.complete();
      };
    }).pipe(
      map(
        readerResult =>
          ({
            mimeType: file.type,
            rawData: Buffer.from(readerResult).toString('base64'),
            encoding: null,
            url: null,
            name: file.name,
          }) as IFile,
      ),
    );
  }

  private updateIdDocumentLabels() {
    this.idDocumentFrontType = 'idDocumentFront';

    switch (this.selectedIdType) {
      case '1':
        this.firstIdDocumentLabel = 'signup.front-view';
        this.secondIdDocumentVisible = true;
        break;
      case '2':
        this.firstIdDocumentLabel = 'signup.passport-photo-section';
        this.secondIdDocumentVisible = false;
        break;
      case '3':
        this.firstIdDocumentLabel = 'signup.driving-license-photo-section';
        this.secondIdDocumentVisible = false;
        break;
    }
  }

  private updateAccountData(): Observable<void> {
    const accountDataToUpdate: Partial<IAccount> = {
      urlToBusinessRegistrationDocument: this.registrationUpload.url,
      ownerUrlToIdDocumentFront: this.idFrontUpload.url,
      ownerUrlToIdDocumentBack: this.idBackUpload.url,
      vatId: this.vatId.value,
    };

    return this.accountDataSvc.updateAccountData(accountDataToUpdate);
  }

  private setUploadProperty(uploadProperty: IUploadProperties, documentUrl: string) {
    uploadProperty.givenFile = new File(['fake file'], documentUrl, { type: 'image/jpeg' });

    uploadProperty.url = documentUrl;
  }
}
