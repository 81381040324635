<div class="card-description">
  <div class="card-header">
    <enzo-headline level="5" color="blackish">{{ conditionConfig().headerLabel | translate }}</enzo-headline>
    <enzo-icon icon="info-outline" size="small" color="purple" />
  </div>

  <enzo-banner [kind]="conditionConfig().kind" [icon]="conditionConfig().icon">{{ conditionConfig().text | translate }}</enzo-banner>
  <enzo-text kind="caption" color="blackish">{{ conditionConfig().description | translate }}</enzo-text>

  @if (auction().associatedVehicle.urlToAttachment1 || auction().associatedVehicle.origin === EVehicleCreationOrigin.SELF_REVIEWED) {
    <div class="report-document">
      <app-vehicle-origin-report-button [auction]="auction()" #reportButton />
      @if (auction().associatedVehicle.urlToAttachment1) {
        <enzo-ghost-button [label]="'general.view-condition-report' | translate" variant="highlight" (enzoClick)="reportButton.openReport()" />
      }
    </div>
  }
</div>

<div class="image-container">
  <img [ngSrc]="conditionConfig().imageUrl" width="265" height="265" />
</div>
