<!-- prettier-ignore -->
<ng-container *ngIf="(vm$ | async) as vm">
  <ng-container [ngSwitch]="vm.displayFormat">
    <enzo-text *ngSwitchCase="EEndingDateDisplayFormat.NOT_SLOTTED" kind="label" align="right" balance="true">
      <enzo-icon icon="auction-time" size="small" />
      {{ vm.endingAtDateKey | translate }}
      <b>
        {{ 'remaining-time.ending-date-time-after' | translate:{ time: (vm.expirationDate | date: 'HH:mm'), date:
        (vm.expirationDate | date: 'dd.MM') } }}
      </b>
    </enzo-text>

    <enzo-text *ngSwitchCase="EEndingDateDisplayFormat.TOMORROW" kind="label" align="right" balance="true">
      <enzo-icon icon="auction-time" size="small" />
      {{ vm.endingAtDateKey | translate }}
      <b> {{ 'remaining-time.ending-time' | translate:{ time: vm.expirationDate | date:
        'HH:mm' } }} </b>
    </enzo-text>

    <enzo-text *ngSwitchCase="EEndingDateDisplayFormat.ABSOLUTE_ENDING_DATE" kind="label" align="right" balance="true">
      <enzo-icon icon="auction-time" size="small" />
      {{ vm.endingAtDateKey | translate }}
      <b>
        {{ (vm.duration.days || vm.duration.months ? 'remaining-time.ending-date' : null) | translate:{ date:
        vm.expirationDate | date:'dd.MM' } }} {{
        'remaining-time.ending-time' | translate:{ time: vm.expirationDate | date: 'HH:mm' } }}
      </b>
    </enzo-text>

    <enzo-text *ngSwitchCase="EEndingDateDisplayFormat.REMAINING_MINUTES_SECONDS" kind="label" align="right" balance="true">
      <enzo-icon icon="auction-time" size="small" />
      {{ vm.endingWithinTimeSpanKey |translate }}
      <b>
        {{ (vm.duration.minutes ? 'remaining-time.duration-minutes' : null) | translate: { numMinutes:
        vm.duration.minutes } }} {{
        'remaining-time.duration-seconds' | translate: { numSeconds: vm.duration.seconds } }}
      </b>
    </enzo-text>

    <enzo-text *ngSwitchCase="EEndingDateDisplayFormat.HOT_BID_PHASE" kind="label" align="right" balance="true"
      testid="hotbid-text">
      <enzo-icon icon="warning" color="red" size="small" />
      {{ vm.endingWithinTimeSpanKey | translate }}
      <b class="hotbid-remaining-time">{{ 'remaining-time.duration-seconds' | translate: { numSeconds:
        vm.duration.seconds } }} </b>
    </enzo-text>

    <enzo-text *ngSwitchCase="EEndingDateDisplayFormat.ZERO" kind="emphasis-label">{{ showAfterOverKey | translate
      }}</enzo-text>
  </ng-container>
</ng-container>
