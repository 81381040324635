import { ErrorHandler, Injectable } from '@angular/core';
import { environment } from '@cosCoreEnvironments/environment';

@Injectable()
export class GlobalErrorHandlerService implements ErrorHandler {
  public handleError(error: any): void {
    const chunkFailedMessage = /Loading chunk \S+ failed/;

    if (chunkFailedMessage.test(error?.message)) {
      window.location.reload();
    }

    if (!environment.disableLogging) {
      console.error(error?.stack ? error.stack.toString() : error?.message ?? 'unknown error');
    }
  }
}
