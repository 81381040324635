import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-pickup-confirmation',
  templateUrl: './pickup-confirmation.component.html',
  styleUrls: ['./pickup-confirmation.component.scss'],
})
export class PickupConfirmationComponent {
  public uuid: string;
  public error: any;

  public constructor(private route: ActivatedRoute) {
    this.uuid = this.route.snapshot.queryParams.id;
  }

  public onError(error): void {
    this.error = error;
  }
  public resetErrorCode(): void {
    // show PIN input page
    this.error = null;
    this.uuid = null;
  }
}
