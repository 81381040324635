import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { IBuyerAuctionView, IPrebookedService, IVoucherAssignment, Validation } from '@caronsale/cos-models';
import { Subject, filter, takeUntil } from 'rxjs';
import { BuyerAuctionService } from '@cosCoreFeatures/auction-detail/common/auction-service/buyer-auction.service';
import { EBuyerAuctionViewVariant, IBuyerAuctionViewState } from '@cosCoreFeatures/auction-detail/common/auction-service/buyer-auction-view-states';
import { VoucherService } from '@cosBuyer/partials/services/voucher.service';
import { GoogleAnalyticsService } from '@cosCoreServices/google-analytics/google-analytics.service';

export type IBuyerAuctionDetailsModalData = {
  auctionUuid: string;
  hidePaymentInfo: boolean;
};

@Component({
  selector: 'app-buyer-auction-details-modal',
  templateUrl: './buyer-auction-details-modal.component.html',
  styleUrls: ['./buyer-auction-details-modal.component.scss'],
})
export class BuyerAuctionDetailsModalComponent implements OnInit, OnDestroy {
  public auction: IBuyerAuctionView;
  public currentPrebookedServices: IPrebookedService[];
  public availableVouchers: IVoucherAssignment[];
  public selectedVoucher: IVoucherAssignment;

  private unsubscribe$: Subject<void> = new Subject<void>();

  public constructor(
    @Inject(MAT_DIALOG_DATA) public data: IBuyerAuctionDetailsModalData,
    private buyerAuctionService: BuyerAuctionService,
    private voucherService: VoucherService,
    private googleAnalyticsService: GoogleAnalyticsService,
  ) {}

  public ngOnInit(): void {
    this.buyerAuctionService
      .getBuyerAuctionViewState$(this.data.auctionUuid, undefined, EBuyerAuctionViewVariant.DETAIL_VIEW)
      .pipe(
        filter((buyerAuctionViewState: IBuyerAuctionViewState) => !!buyerAuctionViewState?.auction),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((buyerAuctionViewState: IBuyerAuctionViewState) => {
        this.auction = buyerAuctionViewState.auction;
        this.currentPrebookedServices = buyerAuctionViewState.currentPrebookedServices;
        if (!Validation.isAuctionClosedSuccessfully(this.auction)) {
          this.voucherService
            .listAvailableVouchersForAuction(this.data.auctionUuid)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(vouchers => {
              this.availableVouchers = vouchers.availableVouchers;
              this.selectedVoucher = vouchers.selectedVoucher;
            });
        }
      });
  }

  public ngOnDestroy(): void {
    this.unsubscribe$.next();
  }

  public closeDialog() {
    this.googleAnalyticsService.trackCloseActionDetailModal(this.auction.uuid);
  }
}
