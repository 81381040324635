import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import {
  EAirCondition,
  ECoupling,
  EEuroNorm,
  EFuelType,
  EHeadlights,
  ELockingWheelNut,
  EParkingAssistance,
  ETransmissionType,
  EUpholsteryType,
  EVehicleCategory,
  EVehicleColor,
  EVehicleCommercialUsage,
  EVehicleDoors,
  EVehicleFullServiceHistoryType,
  EVehicleHeater,
  EVehiclePart,
  EVehicleServiceHistoryAvailability,
  IVehicle,
} from '@caronsale/cos-vehicle-models';
import { ECountryCode, IInternalAuctionView } from '@caronsale/cos-models';
import { CarOnSaleUtils, EUnknownValues } from '@cosCoreUtils/CarOnSaleUtils';

export type VehicleInfoFormValue = Pick<
  IVehicle,
  | 'vin'
  | 'licensePlate'
  | 'origin'
  | 'enginePowerInHp'
  | 'make'
  | 'model'
  | 'ez'
  | 'mileageInKm'
  | 'isOdometerInMiles'
  | 'commercialUsage'
  | 'category'
  | 'transmission'
  | 'bodyColorCode'
  | 'engineSizeInCcm'
  | 'fuelType'
  | 'upholstery'
  | 'doors'
  | 'numSeats'
  | 'lastHu'
  | 'huReportAvailable'
  | 'serviceHistoryAvailability'
  | 'fullServiceHistoryType'
  | 'lastServiceInspectionDate'
  | 'lastServiceInspectionMileage'
  | 'isCocDocumentAvailable'
  | 'hasMaintenanceBook'
  | 'isRegistrationDocumentAvailable'
  | 'isRegistrationDocumentPart2Available'
  | 'isDataExcerptAvailable'
  | 'countryOfLastRegistration'
  | 'numKeys'
  | 'numPreOwners'
  | 'euroNorm'
  | 'isReimportedVehicle'
  | 'fuelConsumption'
  | 'co2Emission'
  | 'urlToAttachment1'
  | 'urlToAttachment2'
  | 'urlToAttachment3'
> & {
  isPowerInHp: boolean;
};

export class VehicleInfoUtils {
  public static readonly commercialUsageOptions: EVehicleCommercialUsage[][] = [
    [],
    [EVehicleCommercialUsage.RENTAL],
    [EVehicleCommercialUsage.TAXI],
    [EVehicleCommercialUsage.OFFICIAL],
    [EVehicleCommercialUsage.DRIVING_SCHOOL],
  ];

  public static readonly categoryOptions: EVehicleCategory[] = [
    EVehicleCategory.COMPACT,
    EVehicleCategory.COUPE,
    EVehicleCategory.SEDAN,
    EVehicleCategory.ROADSTER_CONVERTIBLE,
    EVehicleCategory.STATION,
    EVehicleCategory.SUV,
    EVehicleCategory.VAN,
    EVehicleCategory.MULTIVAN,
    EVehicleCategory.PICK_UP,
    EVehicleCategory.FLATBED_TRUCK,
    EVehicleCategory.UNKNOWN,
  ];

  public static readonly transmissionOptions: ETransmissionType[] = [ETransmissionType.MANUAL, ETransmissionType.AUTOMATIC];

  public static readonly colorOptions: EVehicleColor[] = [
    EVehicleColor.RED,
    EVehicleColor.YELLOW,
    EVehicleColor.BLUE,
    EVehicleColor.BLACK,
    EVehicleColor.SILVER,
    EVehicleColor.WHITE,
    EVehicleColor.GRAY,
    EVehicleColor.BROWN,
    EVehicleColor.GREEN,
    EVehicleColor.ORANGE,
    EVehicleColor.PURPLE,
    EVehicleColor.OTHER,
  ];

  public static readonly upholsteryOptions: EUpholsteryType[] = [
    EUpholsteryType.CLOTH,
    EUpholsteryType.PART_LEATHER,
    EUpholsteryType.LEATHER,
    EUpholsteryType.ALCANTARA,
  ];

  public static readonly doorOptions: EVehicleDoors[] = [EVehicleDoors.D2_3, EVehicleDoors.D4_5, EVehicleDoors.D6_7];

  public static readonly euroNormsOptions: EEuroNorm[] = [
    EEuroNorm.EURO_1,
    EEuroNorm.EURO_2,
    EEuroNorm.EURO_3,
    EEuroNorm.EURO_4,
    EEuroNorm.EURO_5,
    EEuroNorm.EURO_5A,
    EEuroNorm.EURO_5B,
    EEuroNorm.EURO_6,
    EEuroNorm.EURO_6B,
    EEuroNorm.EURO_6C,
    EEuroNorm.EURO_6D,
    EEuroNorm.EURO_6DT,

    EEuroNorm.EURO_I,
    EEuroNorm.EURO_II,
    EEuroNorm.EURO_III,
    EEuroNorm.EURO_IV,
    EEuroNorm.EURO_V,
    EEuroNorm.EURO_VI,

    EEuroNorm.OTHER,
  ];
  public static readonly lockingWheelNutOptions: ELockingWheelNut[] = [ELockingWheelNut.AVAILABLE, ELockingWheelNut.NOT_AVAILABLE, ELockingWheelNut.NOT_NEEDED];
  public static readonly acOptions = [EAirCondition.AC, EAirCondition.AUTO_AC, EAirCondition.NONE];
  public static readonly couplingOptions = [ECoupling.NONE, ECoupling.DETACHABLE, ECoupling.FIXED, ECoupling.SWIVELING];
  public static readonly navigationOptions = [1, 0];
  public static readonly parkingAssistanceOptions = [
    EParkingAssistance.NONE,
    EParkingAssistance.BACK,
    EParkingAssistance.FRONT,
    EParkingAssistance.FRONT_AND_BACK,
  ];
  public static readonly headlightOptions = [EHeadlights.HALOGEN, EHeadlights.XENON, EHeadlights.BI_XENON, EHeadlights.LED];
  public static readonly sunRoofOptions = [1, 0];
  public static readonly heaterOptions = [EVehicleHeater.NONE, EVehicleHeater.WITHOUT_REMOTE, EVehicleHeater.WITH_REMOTE];
  public static readonly sportPackageOptions = [1, 0];

  /**
   * The order of tire details is as following:
   * Assuming that the vehicle is seen from a bird view with the front facing up (north), tires are
   * stored from left to right and from top to bottom, e.g. the front left tire is at position 0, the front right
   * tire at position 1, the rear left tire at position 3 and the rear right tire at position 4.
   */
  public static tireOrder: Array<
    EVehiclePart.FRONT_WHEEL_LEFT | EVehiclePart.FRONT_WHEEL_RIGHT | EVehiclePart.REAR_WHEEL_LEFT | EVehiclePart.REAR_WHEEL_RIGHT
  > = [EVehiclePart.FRONT_WHEEL_LEFT, EVehiclePart.FRONT_WHEEL_RIGHT, EVehiclePart.REAR_WHEEL_LEFT, EVehiclePart.REAR_WHEEL_RIGHT];

  public static readonly previousOwnersOptions: { value: number | EUnknownValues; label: string }[] = [
    { value: EUnknownValues.PRE_OWNERS, label: 'general.unknown' },
    { value: 0, label: 'vehicle.new-vehicle' },
    ...Array.from(Array(9), (_, i) => ({ value: i + 1, label: (i + 1).toString() })),
  ];

  public static markAsTouchedIfVehicle(form: UntypedFormGroup, vehicleUuid: string): void {
    const vehicleForm: UntypedFormGroup = form.get('vehicle') as UntypedFormGroup;

    if (vehicleUuid) {
      vehicleForm.get('ez').markAsTouched();
      vehicleForm.get('fuelType').markAsTouched();
      vehicleForm.get('enginePowerInHp').markAsTouched();
      vehicleForm.get('lastHu').markAsTouched();
      vehicleForm.get('serviceHistoryAvailability').markAsTouched();
      vehicleForm.get('fullServiceHistoryType').markAsTouched();
      vehicleForm.get('lastServiceInspectionDate').markAsTouched();
      vehicleForm.get('euroNorm').markAsTouched();
    }
  }

  public static addConditionalValidators(auction: IInternalAuctionView, form: UntypedFormGroup): void {
    if (VehicleInfoUtils.isAllowedToEditLicensePlate(auction.sellerAccount.countryCode)) {
      form.get('licensePlate').addValidators(Validators.required);
    }

    if (!VehicleInfoUtils.isAllowedToEditLicensePlate(auction.sellerAccount.countryCode)) {
      form.get('licensePlate').disable();
    }
  }

  public static isAllowedToEditLicensePlate(sellerCountryCode: ECountryCode): boolean {
    const allowedCountries = [ECountryCode.NL, ECountryCode.FR];
    return allowedCountries.includes(sellerCountryCode);
  }

  public static getVehicleWithCalculatedUnits<T extends VehicleInfoFormValue>(vehicle: T): Omit<T, 'isPowerInHp'> {
    // remove extra props
    const { isPowerInHp, ...vehicleValue } = vehicle;

    // converting kW into horsepower
    const enginePowerInHp = isPowerInHp ? vehicleValue.enginePowerInHp : Math.round(vehicleValue.enginePowerInHp * CarOnSaleUtils.CONVERSION_RATIOS.KW_TO_HP);

    // converting miles into km
    const mileageInKm = vehicleValue.isOdometerInMiles
      ? Math.round(vehicleValue.mileageInKm * CarOnSaleUtils.CONVERSION_RATIOS.MI_TO_KM)
      : vehicleValue.mileageInKm;

    return {
      ...vehicleValue,
      enginePowerInHp,
      mileageInKm,
    };
  }

  public static getFormControls(formBuilder: UntypedFormBuilder): { [key: string]: AbstractControl } {
    return {
      make: formBuilder.control(''),
      model: formBuilder.control(''),
      linesByLanguage: formBuilder.control({ value: {}, disabled: true }),
      ez: formBuilder.control('', [
        Validators.required,
        Validators.pattern(/^(?!01\/1900)(\d{2}\/\d{4})/), // evaluates 01/1900 as invalid value
      ]),
      mileageInKm: formBuilder.control('', [Validators.required]),
      commercialUsage: formBuilder.control(''),
      category: formBuilder.control(''),
      transmission: formBuilder.control(''),
      bodyColorCode: formBuilder.control('', [Validators.required, VehicleInfoUtils.excludeDefaultValueValidator([EVehicleColor.OTHER])]),
      engineSizeInCcm: formBuilder.control(''),
      fuelType: formBuilder.control('', [Validators.required, VehicleInfoUtils.excludeDefaultValueValidator([EFuelType.OTHER])]),
      upholstery: formBuilder.control(''),
      doors: formBuilder.control(''),
      numSeats: formBuilder.control(''),
      lastHu: formBuilder.control('', [
        Validators.required,
        Validators.pattern(/^(?!00\/0000)(\d{2}\/\d{4})/), // evaluates 00/0000 as invalid value
      ]),
      huReportAvailable: formBuilder.control(''),
      serviceHistoryAvailability: formBuilder.control('', [
        Validators.required,
        VehicleInfoUtils.excludeDefaultValueValidator([EVehicleServiceHistoryAvailability.UNKNOWN, EVehicleServiceHistoryAvailability.NO]),
      ]),
      fullServiceHistoryType: formBuilder.control('', [
        Validators.required,
        VehicleInfoUtils.excludeDefaultValueValidator([EVehicleFullServiceHistoryType.UNKNOWN, EVehicleFullServiceHistoryType.NO]),
      ]),
      lastServiceInspectionDate: formBuilder.control('', [
        Validators.pattern(/^(?!00\/0000)(\d{2}\/\d{4})/), // evaluates 00/0000 as invalid value
      ]),
      lastServiceInspectionMileage: formBuilder.control(null),
      isCocDocumentAvailable: formBuilder.control(''),
      hasMaintenanceBook: formBuilder.control(''),
      isRegistrationDocumentAvailable: formBuilder.control(''),
      isRegistrationDocumentPart2Available: formBuilder.control(''),
      isDataExcerptAvailable: formBuilder.control(''),
      countryOfLastRegistration: formBuilder.control(''),
      isOdometerInMiles: formBuilder.control(false),
      numKeys: formBuilder.control(null),
      numPreOwners: formBuilder.control(null, [Validators.required]),
      euroNorm: formBuilder.control('', [Validators.required, VehicleInfoUtils.excludeDefaultValueValidator([EEuroNorm.OTHER])]),
      isReimportedVehicle: formBuilder.control(''),
      fuelConsumption: formBuilder.group({
        wltp: formBuilder.control(null),
        nedc: formBuilder.control(null),
      }),
      co2Emission: formBuilder.group({
        wltp: formBuilder.control(null),
        nedc: formBuilder.control(null),
      }),
      urlToAttachment1: formBuilder.control(''),
      urlToAttachment2: formBuilder.control(''),
      urlToAttachment3: formBuilder.control(''),
      urlToBatteryConditionCertificate: formBuilder.control(''),
      enginePowerInHp: formBuilder.control('', [Validators.required]),
      isPowerInHp: formBuilder.control(true),
      vin: formBuilder.control(''),
      licensePlate: formBuilder.control(''),
      origin: formBuilder.control(''),
      hasAnimalSmell: formBuilder.control(null),
      hasSmokeSmell: formBuilder.control(null),
      lockingWheelNut: formBuilder.control(null),
      isToolkitIncluded: formBuilder.control(null),
      batteryRangeInKm: formBuilder.control(''),
      batteryCapacityInKwh: formBuilder.control(''),
      batteryVoltage: formBuilder.control(''),
      energyConsumption: formBuilder.control(''),
      quickChargeInHours: formBuilder.control(''),
      normalChargeInHours: formBuilder.control(''),
      hasRentalBattery: formBuilder.control(null),
    };
  }

  private static excludeDefaultValueValidator(
    valuesToExclude: (EVehicleColor | EFuelType | EVehicleServiceHistoryAvailability | EVehicleFullServiceHistoryType | EEuroNorm)[],
  ): ValidatorFn {
    return (control: AbstractControl) => (valuesToExclude.includes(control.value) ? { required: { value: control.value } } : null);
  }
}
