import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-guarantee-badge',
  templateUrl: './guarantee-badge.component.html',
  styleUrls: ['./guarantee-badge.component.scss'],
})
export class GuaranteeBadgeComponent {
  @Input() public iconPath: string;
  @Input() public labelPath: string;
  @Input() public noBorder: boolean;
  @Input() public size: 'lg' | 'md' = 'lg';
}
