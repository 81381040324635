import { Injectable } from '@angular/core';
import { IVoucherAssignment, Validation } from '@caronsale/cos-models';
import { CosBuyerClientService } from '@cosCoreServices/cos-salesman-client/cos-buyer-client.service';
import { BehaviorSubject, Observable, combineLatest, of, switchMap, take, tap, catchError } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class VoucherService {
  private availableVouchers: BehaviorSubject<IVoucherAssignment[]> = new BehaviorSubject([]);
  private assignedVoucher?: BehaviorSubject<IVoucherAssignment> = new BehaviorSubject(null);
  private selectedVoucher?: BehaviorSubject<IVoucherAssignment> = new BehaviorSubject(null);

  public constructor(private cosBuyerClient: CosBuyerClientService) {}

  public listAvailableVouchersForAuction(auctionUuid: string): Observable<{
    availableVouchers: IVoucherAssignment[];
    selectedVoucher: IVoucherAssignment;
  }> {
    return this.cosBuyerClient.listAvailableVouchersForAuction(auctionUuid).pipe(
      tap(vouchers => {
        if (vouchers.selectedVoucher) {
          vouchers.availableVouchers.push(vouchers.selectedVoucher);
          vouchers.availableVouchers = [...new Set(vouchers.availableVouchers)];
        }
        this.availableVouchers.next(vouchers.availableVouchers);
        this.selectedVoucher.next(vouchers.selectedVoucher);
        this.assignedVoucher.next(vouchers.selectedVoucher);
      }),
      catchError(() =>
        of({
          availableVouchers: [],
          selectedVoucher: null,
        }),
      ),
    );
  }

  public getAvailableVouchers(): Observable<IVoucherAssignment[]> {
    return this.availableVouchers.asObservable();
  }

  public getAssignedVoucher(): Observable<IVoucherAssignment> {
    return this.assignedVoucher.asObservable();
  }

  public getSelectedVoucher(): Observable<IVoucherAssignment> {
    return this.selectedVoucher.asObservable();
  }

  public changeVoucher(selectedVoucher: IVoucherAssignment): void {
    this.selectedVoucher.next(selectedVoucher);
  }

  public persistVoucherAssignment(auctionUuid: string): Observable<void> {
    return combineLatest({
      assignedVoucher: this.getAssignedVoucher(),
      selectedVoucher: this.getSelectedVoucher(),
    }).pipe(
      switchMap(({ assignedVoucher, selectedVoucher }) => {
        return this.updateVoucherAssignmentIfNeeded(auctionUuid, assignedVoucher?.voucherUuid, selectedVoucher?.voucherUuid);
      }),
      take(1),
    );
  }

  private updateVoucherAssignmentIfNeeded(auctionUuid: string, assignedVoucherUuid: string, selectedVoucherUuid: string): Observable<void> {
    if (Validation.isValidUUID(assignedVoucherUuid)) {
      if (assignedVoucherUuid === selectedVoucherUuid) {
        return of(null);
      } else if (Validation.isValidUUID(selectedVoucherUuid)) {
        return this.cosBuyerClient.applyVoucher(auctionUuid, selectedVoucherUuid);
      } else {
        return this.cosBuyerClient.undoVoucher(auctionUuid);
      }
    } else {
      if (Validation.isValidUUID(selectedVoucherUuid)) {
        return this.cosBuyerClient.applyVoucher(auctionUuid, selectedVoucherUuid);
      } else {
        return of(null);
      }
    }
  }
}
