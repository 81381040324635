import { booleanAttribute, ChangeDetectionStrategy, Component, inject, input, Input, OnChanges, OnInit } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { differenceInYears } from 'date-fns';

import { ESellerBusinessType, IAuction, IBuyerAuctionView, Validation } from '@caronsale/cos-models';
import { EFuelType, EVehicleCreationOrigin } from '@caronsale/cos-vehicle-models';
import { CosI18nService, CountryCodeService } from '@caronsale/frontend-services';
import { ITaxationInfo } from '@cos/partials/taxation-info/taxation-info.component';
import { BuyerTransportationService } from '@cosCoreServices/buyer-transportation/buyer-transportation.service';

import { CarOnSaleUtils, EUnknownValues } from '@cosCoreUtils/CarOnSaleUtils';

@Component({
  selector: 'app-specific-info',
  templateUrl: './specific-info.component.html',
  styleUrls: ['./specific-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: { '[class.single-column]': 'singleColumn()' },
})
export class SpecificInfoComponent implements OnInit, OnChanges {
  @Input() public auction: IAuction;
  public singleColumn = input(false, { transform: booleanAttribute });

  public isDutchToForeignAuction: boolean = false;
  public enginePowerInKW = 0;
  public taxationInfo: ITaxationInfo;
  public EFuelType = EFuelType;
  public slicedModel: string;

  public readonly unknownValues: typeof EUnknownValues = EUnknownValues;

  public consumptionFieldsEmpty = true;
  public batteryFieldsEmpty = true;

  public EUnknownValues = EUnknownValues;
  public ESellerBusinessType = ESellerBusinessType;
  public EVehicleCreationOrigin = EVehicleCreationOrigin;

  public selectedLanguage = toSignal(inject(CosI18nService).languageChanged);

  public constructor(
    private countryCodeService: CountryCodeService,
    private buyerTransportationService: BuyerTransportationService,
  ) {}

  public ngOnInit() {
    this.taxationInfo = {
      sellerCountry: this.auction.locationCountryCode,
      buyerCountry: this.countryCodeService.userCountryCode,
      vatIdReportable: this.auction.isVATReportable,
      isCrossBorderNetSale: (this.auction as IBuyerAuctionView).isCrossBorderNetSale,
    };

    this.isDutchToForeignAuction = this.buyerTransportationService.isDutchToForeignAuction(this.auction as IBuyerAuctionView);
  }

  public ngOnChanges(): void {
    this.enginePowerInKW = CarOnSaleUtils.getVehicleEnginePowerInKW(this.auction.associatedVehicle.enginePowerInHp);
    this.slicedModel = this.auction.associatedVehicle.model.split('[')[0];
    this.areFieldsEmpty();
  }

  public isUndefinedOrNull(field: any) {
    return Validation.isUndefinedOrNull(field);
  }

  private areFieldsEmpty() {
    const vehicle = this.auction.associatedVehicle;
    this.batteryFieldsEmpty =
      !vehicle.batteryRangeInKm &&
      !vehicle.batteryCapacityInKwh &&
      !vehicle.quickChargeInHours &&
      !vehicle.normalChargeInHours &&
      !vehicle.batteryVoltage &&
      !vehicle.hasRentalBattery;
    this.consumptionFieldsEmpty =
      vehicle.fuelType === EFuelType.ELECTRIC
        ? !vehicle.energyConsumption
        : !vehicle.fuelConsumption.wltp && !vehicle.fuelConsumption.nedc && !vehicle.co2Emission.wltp && !vehicle.co2Emission.nedc && !vehicle.euroNorm;
  }

  public showNoServiceNeeded(): boolean {
    let isLessThanOneYear = true;
    const hasLessThan15KKm = this.auction.associatedVehicle.mileageInKm && this.auction.associatedVehicle.mileageInKm < 15000;

    if (this.auction.associatedVehicle.ez) {
      const [month, year] = this.auction.associatedVehicle.ez.split('/').map(Number);
      isLessThanOneYear = differenceInYears(new Date(), new Date(year, month - 1)) < 1;
    }

    return isLessThanOneYear && hasLessThan15KKm;
  }
}
