import { Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';
import { combineLatest, forkJoin, from, map, Observable, of, switchMap, take, tap } from 'rxjs';
import imageCompression from 'browser-image-compression';

import { EMediaTag, IFile, ModelUtils } from '@caronsale/cos-models';
import { IVehicleImage } from '@caronsale/cos-vehicle-models';
import { ICloudinarySignature } from '@cosTypes';
import { CosCoreClient } from '@cosCoreServices/core-client/cos-core-client.service';

import { I18nErrorDialogComponent } from '@cosCoreComponentsGeneral/i18n/error-dialog/i18n-error-dialog.component';

export type CloudinaryUploadResponse = {
  secure_url: string;
  public_id: string;
};

const MAX_ALLOWED_FILE_SIZE = 10 * 1000 * 1000;
const TARGET_IMAGE_FILE_SIZE_IN_MB = 0.6;

@Injectable({
  providedIn: 'root',
})
export class FileUploadService {
  public spinner: boolean = false;

  public allowedMimeTypes: string[];

  public imageUploaded: any;

  public newImagesUrls: string[] = [];

  private directUploadParams: FormData | undefined;

  public constructor(
    private dialog: MatDialog,
    private httpClient: HttpClient,
    private cosClient: CosCoreClient,
    private sanitizer: DomSanitizer,
  ) {}

  public get videoMimeTypes(): string[] {
    return [
      'video/x-msvideo',
      'video/mp4',
      'video/mpeg',
      'video/ogg',
      'video/mp2t',
      'video/webm',
      'video/3gpp',
      'video/3gpp2',
      'video/x-matroska',
      'video/quicktime',
    ];
  }

  public uploadImage(image: IFile, tags: EMediaTag[] = []): Observable<IFile> {
    return this.cosClient.requestWithPrivileges('put', `/media/image?tags=${tags.join(',')}`, image).pipe(
      map(
        res =>
          ({
            url: res.url,
          }) as IFile,
      ),
    );
  }

  public isVideo(mimeType: string): boolean {
    return ModelUtils.isMimeTypeIncludedIn(this.videoMimeTypes, mimeType);
  }

  public uploadVehicleVideo(file: IFile): Observable<{ jobId: string }> {
    return this.cosClient.requestWithPrivileges('put', '/media/vehicle/video', file);
  }

  public uploadVehicleImage(image: IVehicleImage): Observable<IVehicleImage> {
    return this.cosClient.requestWithPrivileges('put', `/media/vehicle/image`, image).pipe(
      map(res => {
        return {
          perspective: image.perspective,
          rawData: image.rawData,
          url: res.url,
        } as IVehicleImage;
      }),
    );
  }

  public uploadVehicleDamageImage(image: IFile): Observable<IFile> {
    return this.cosClient.requestWithPrivileges('put', `/media/vehicle/damage/image`, image).pipe(
      map(res => {
        return {
          mimeType: image.mimeType,
          rawData: image.rawData,
          encoding: null,
          url: res.url,
        };
      }),
    );
  }

  private setUploadParams(folder = ''): Observable<ICloudinarySignature> {
    const timestamp = Math.round(new Date().getTime() / 1000);
    return this.cosClient.requestWithPrivileges('post', `/media/signature`, { timestamp, folder }).pipe(
      tap(result => {
        this.directUploadParams = new FormData();
        this.directUploadParams.set('folder', folder);
        this.directUploadParams.set('timestamp', timestamp.toString());
        this.directUploadParams.set('api_key', result.cloudApiKey);
        this.directUploadParams.set('signature', result.signature);
        this.directUploadParams.set('cloudName', result.cloudName);
      }),
    );
  }

  private checkUploadParamsValidity(folder = ''): Observable<ICloudinarySignature> {
    // the signature  timestamp is valid for 60 minutes, this forces it to refresh at 50 minutes or more.
    const timestampValid = Math.floor(Math.abs(new Date().getTime() / 1000 - Number(this.directUploadParams?.get('timestamp'))) / 60) < 50;

    // signatures only accept uploads to a single folder, this forces a new signature to be created if needed.
    const correctFolder = folder === this.directUploadParams?.get('folder');

    if (timestampValid && correctFolder) {
      return of(null);
    }

    return this.setUploadParams(folder);
  }

  private cloudinaryUpload(file: File | Blob, headers = {}): Observable<CloudinaryUploadResponse> {
    this.directUploadParams.set('file', file);

    return this.httpClient.post<CloudinaryUploadResponse>(
      `https://api.cloudinary.com/v1_1/${this.directUploadParams.get('cloudName')}/auto/upload`,
      this.directUploadParams,
      { headers },
    );
  }

  private chunkedUpload(blobs: { chunk: Blob; contentRange: string }[], headers: { 'X-Unique-Upload-Id': string }): Observable<CloudinaryUploadResponse> {
    const blob = blobs.shift();
    return this.cloudinaryUpload(blob.chunk, { ...headers, 'Content-Range': `${blob.contentRange}` }).pipe(
      switchMap(result => {
        if (blobs.length) {
          return this.chunkedUpload(blobs, headers);
        }
        return of(result);
      }),
    );
  }

  public directUpload(file: File | Blob, folder = '', headers = {}): Observable<CloudinaryUploadResponse> {
    return this.checkUploadParamsValidity(folder).pipe(
      switchMap(() => {
        const chunkSize = 20 * 1024 * 1024; // 20mb chunks
        const fileSize = file.size;

        if (fileSize > chunkSize) {
          const blobs = Array.from(Array(Math.ceil(fileSize / chunkSize)), (_, index) => {
            const start = index * chunkSize;
            const end = Math.min(start + chunkSize, fileSize);

            return {
              chunk: file.slice(start, end),
              contentRange: `bytes ${start}-${end - 1}/${fileSize}`,
            };
          });

          return this.chunkedUpload(blobs, { 'X-Unique-Upload-Id': crypto.randomUUID() });
        }

        return this.cloudinaryUpload(file, headers);
      }),
    );
  }

  public uploadVehicleMotorSound(vin: string, file: IFile): Observable<IFile> {
    return this.cosClient.requestWithPrivileges('put', `/media/vehicle/${vin}/motor/sound`, file).pipe(map(res => ({ url: res.url }) as IFile));
  }

  /**
   * @deprecated
   */
  public onImageChange(event: any): string[] {
    this.multipleImageUpload(event.target.files);
    return this.newImagesUrls;
  }

  // TODO: Put in FormatUtils
  public makeMegaBytes(bytes: number): number {
    return Math.floor(bytes / (1000 * 1000));
  }

  /**
   * @deprecated
   */
  private multipleImageUpload(files: FileList): void {
    const index = null;

    // Check file size of all files against maximum size
    for (let i = 0; i < files.length; i++) {
      if (files[i].size > MAX_ALLOWED_FILE_SIZE) {
        I18nErrorDialogComponent.show(this.dialog, 'image-upload.dialogs.file-size-too-large', '', {
          actualSize: this.makeMegaBytes(files[i].size),
          maxSize: this.makeMegaBytes(MAX_ALLOWED_FILE_SIZE),
        });
        return;
      }
    }

    this.spinner = true;

    // Check MIME type of all files
    for (let i = 0; i < files.length; i++) {
      if (!ModelUtils.isMimeTypeIncludedIn(this.allowedMimeTypes, files[i].type)) {
        I18nErrorDialogComponent.show(this.dialog, 'image-upload.dialogs.invalid-file-format');
        this.spinner = false;
        return;
      }
    }

    // Create compression requests for all images
    const compressionRequests: Observable<File>[] = [];
    for (let i = 0; i < files.length; i++) {
      compressionRequests.push(from(imageCompression(files[i], { maxSizeMB: TARGET_IMAGE_FILE_SIZE_IN_MB })).pipe(take(1)));
    }

    // Join all compression requests
    const readRequests = [];

    forkJoin(compressionRequests).subscribe({
      next: (images: File[]) => {
        // Create upload requests for all images
        for (const image of images) {
          const resizedImageFile = new File([image], image.name);
          const reader = new FileReader();

          reader.readAsDataURL(resizedImageFile);
          readRequests.push(
            new Observable(obs => {
              reader.onload = () => {
                obs.next([resizedImageFile.type, reader.result as string]);
                obs.complete();
              };
            }),
          );
        }

        // Join all upload requests
        forkJoin(readRequests).subscribe({
          next: readerResults => {
            const uploadRequests: Observable<IVehicleImage>[] = [];
            let perspectiveIndex = index;
            for (const readerResult of readerResults) {
              const fileToUpload = {
                perspective: perspectiveIndex,
                rawData: readerResult[1],
                url: null,
              } as IVehicleImage;
              // Upload file using /media/vehicle/image endpoint, and attach to vehicle urls
              uploadRequests.push(this.uploadVehicleImage(fileToUpload));
              perspectiveIndex += 1;
            }

            // Join upload requests
            combineLatest(uploadRequests).subscribe({
              next: (uploadedImages: IVehicleImage[]) => {
                for (const uploadedImage of uploadedImages) {
                  this.imageUploaded = this.sanitizer.bypassSecurityTrustResourceUrl(uploadedImage.rawData as string);
                  this.newImagesUrls.push(uploadedImage.url);
                }
                this.spinner = false;
              },
              error: this.uploadErrorHandler,
            });
          },
          error: this.uploadErrorHandler,
        });
      },
      error: this.uploadErrorHandler,
    });
  }

  /**
   * @deprecated
   */
  private uploadErrorHandler() {
    I18nErrorDialogComponent.show(this.dialog, 'image-upload.dialogs.image-file-corrupted');
  }
}
