export class MediaUtils {
  public static downloadPDF(blobFile: Blob, filename: string): void {
    const file = new Blob([blobFile]);
    const fileURL = URL.createObjectURL(file);
    const a = document.createElement('a');
    a.download = filename;
    a.href = fileURL;
    a.target = '_blank';
    a.click();
    a.remove();
  }
}
