import { Component, Inject, OnInit } from '@angular/core';
import { PrebookedServicesUtils } from '@caronsale/frontend-utils';
import { EPrebookedServiceType, IBuyerAuctionView, IPrebookedService, PrebookedServiceGuaranteeOptionType } from '@caronsale/cos-models';
import { IGuaranteeSelectionChange } from '@cosBuyer/partials/prebooked-services/prebooked-guarantee/prebooked-guarantee.component';
import { PrebookedServicesService } from '@cosCoreServices/prebooked-services/prebooked-services.service';

import { CommonModule } from '@angular/common';
import { PrebookedServicesModule } from '@cosBuyer/partials/prebooked-services/prebooked-services.module';
import { GeneralPipesModule } from '@caronsale/frontend-pipes';
import { EEnzoDialogResult, EnzoDialogRef } from '@cosCoreComponents/modal-dialogs/enzo-dialog.service';
import { EnzoDialogBase, EnzoDialogType } from '@cosCoreComponents/modal-dialogs/enzo-dialog-base';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { EnzoComponentsModule } from '@caronsale/enzo-angular';
import { TranslateModule } from '@ngx-translate/core';
import { COS_CONSTANTS } from '@caronsale/frontend-services';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { FormsModule } from '@angular/forms';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

export interface IBuyerGuaranteeOptionsDialogParams {
  auction: IBuyerAuctionView;
  prebookedServices: IPrebookedService[];
}

export interface IBuyerGuaranteeOptionsDialogResult {
  confirmed: boolean;
  prebookedServices?: IPrebookedService[];
}

@Component({
  selector: 'app-buyer-guarantee-options-dialog',
  templateUrl: './buyer-guarantee-options-dialog.component.html',
  styleUrls: ['./buyer-guarantee-options-dialog.component.scss'],
  standalone: true,
  imports: [CommonModule, GeneralPipesModule, PrebookedServicesModule, EnzoComponentsModule, TranslateModule, MatCheckboxModule, FormsModule],
})
export class BuyerGuaranteeOptionsDialogComponent extends EnzoDialogBase implements OnInit {
  public auction: IBuyerAuctionView;
  public prebookedServices: IPrebookedService[];

  public footerButtonBaseKey: string = 'auction.guarantee-dialog';
  public enzoDialogType: EnzoDialogType = 'confirmCancel';
  public guaranteeTermsAccepted: boolean;
  public confirmationTemplate: string = `{prefix} <a class="confirmation-link" href="${this.constants.GUARANTEE_CONDITIONS_URL}" target="_blank">{link}</a> {suffix}`;
  public isConfirmedAndInvalid: boolean = false;

  public requireGuaranteeTerms: boolean;
  private requiredGuaranteeTermsOptionTypes: PrebookedServiceGuaranteeOptionType[];

  public constructor(
    @Inject(DIALOG_DATA) public data: IBuyerGuaranteeOptionsDialogParams,
    private dialogRef: DialogRef<IBuyerGuaranteeOptionsDialogResult, IBuyerGuaranteeOptionsDialogParams>,
    private prebookedServicesService: PrebookedServicesService,
    private enzoDialogRef: EnzoDialogRef,
    private constants: COS_CONSTANTS,
  ) {
    super();

    this.enzoDialogRef.footerButtonClick$.pipe(takeUntilDestroyed()).subscribe(res => {
      let result: IBuyerGuaranteeOptionsDialogResult;
      if (res === EEnzoDialogResult.CANCEL) {
        result = { confirmed: false };
      } else {
        if (!this.guaranteeTermsAccepted && this.requireGuaranteeTerms) {
          this.isConfirmedAndInvalid = true;
          return;
        }
        result = { confirmed: true };
        if (this.requiredGuaranteeTermsOptionTypes) {
          this.prebookedServicesService.addAcceptedTermsGuaranteeOptions(this.requiredGuaranteeTermsOptionTypes);
        }
        if (!PrebookedServicesUtils.areSelectedOptionsEqual(this.prebookedServices, this.auction.prebookedServices)) {
          result.prebookedServices = this.prebookedServices;
        }
      }
      this.dialogRef.close(result);
    });
  }

  public ngOnInit(): void {
    this.auction = this.data.auction;
    this.prebookedServices = this.data.prebookedServices;
  }

  public onGuaranteeSelectionChange(guaranteeSelectionChange: IGuaranteeSelectionChange): void {
    let optionType: PrebookedServiceGuaranteeOptionType;
    const guaranteeOptionValues = guaranteeSelectionChange.guaranteeOptionValues;

    for (optionType in guaranteeOptionValues) {
      if (guaranteeOptionValues[optionType]) {
        this.prebookedServices = PrebookedServicesUtils.selectServiceOption(
          this.auction.uuid,
          this.prebookedServices,
          EPrebookedServiceType.GUARANTEE,
          optionType,
          false,
        );
      } else {
        this.prebookedServices = PrebookedServicesUtils.deselectServiceOption(
          this.auction.uuid,
          this.prebookedServices,
          EPrebookedServiceType.GUARANTEE,
          optionType,
        );
      }
    }

    this.onRequireGuaranteeTermsAcceptance(guaranteeSelectionChange.guaranteeOptionsRequiringAcceptance);
  }

  public onRequireGuaranteeTermsAcceptance(requiredGuaranteeTermsOptionTypes: PrebookedServiceGuaranteeOptionType[]): void {
    this.requiredGuaranteeTermsOptionTypes = requiredGuaranteeTermsOptionTypes;
    this.requireGuaranteeTerms = requiredGuaranteeTermsOptionTypes?.length > 0;

    if (!this.requireGuaranteeTerms) {
      this.guaranteeTermsAccepted = false;
    }
  }
}
