import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IBuyerAuctionView } from '@caronsale/cos-models';
import { IsTransportBookedPipe } from '@caronsale/frontend-pipes';
import { CosBuyerClientService } from '@cosCoreServices/cos-salesman-client/cos-buyer-client.service';

@Component({
  selector: 'app-pickup-info',
  templateUrl: './pickup-info.component.html',
  styleUrls: ['./pickup-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PickupInfoComponent {
  @Input()
  public purchase: IBuyerAuctionView;

  @Input()
  public minimumDaysUntilLatePickup: number;

  @Input()
  public showPickupAddress = true;

  public constructor(
    private isTransportBookedPipe: IsTransportBookedPipe,
    private buyerCosClient: CosBuyerClientService,
  ) {}

  public get showPickupDocument(): boolean {
    return (
      this.purchase.amIHighestBidder &&
      !this.isTransportBookedPipe.transform(this.purchase.transportationTask) &&
      this.purchase.urlToPickupBuyerDocument &&
      !this.purchase.enforceTransportation
    );
  }

  public get showLatePickupMessage(): boolean {
    return (
      !this.isTransportBookedPipe.transform(this.purchase.transportationTask) && this.purchase.remainingDaysUntilLatePickup < this.minimumDaysUntilLatePickup
    );
  }

  // TODO: Do we need still?
  public isPickupLate(): boolean {
    // TODO disabled because of Corona
    return false;
    // return this.purchase.remainingDaysUntilLatePickup <= 0;
  }

  public handleOnPickupDocumentClick($event) {
    this.buyerCosClient.downloadBuyerPickupDocument(this.purchase);
    $event.stopPropagation();
  }
}
