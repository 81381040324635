import { Injectable } from '@angular/core';
import { IVehicleMakeModel } from '@caronsale/cos-models';
import { CosCoreClient } from '@cosCoreServices/core-client/cos-core-client.service';
import { Observable, map, of, shareReplay, switchMap, tap } from 'rxjs';

const TOP_MAKES = ['Audi', 'BMW', 'Ford', 'Mercedes-Benz', 'Volkswagen'];

@Injectable({
  providedIn: 'root',
})
export class VehicleModelsService {
  private makeModels: { [make: string]: string[] } = {};

  public makes$: Observable<string[]> = of([]).pipe(
    switchMap(() => this.cosCoreClient.requestWithPrivileges('get', '/vehicle-models/makes')),
    map(makes => this.reorderMakes(makes)),
    shareReplay(1),
  );

  public constructor(private cosCoreClient: CosCoreClient) {}

  public getVehicleModels(make: string): Observable<string[]> {
    if (!make) {
      return of([]);
    }

    if (this.makeModels[make]) {
      return of(this.makeModels[make]);
    }

    return this.cosCoreClient.requestWithPrivileges('get', `/vehicle-models/?make=${make}`).pipe(
      map((models: IVehicleMakeModel[]) => models.map(({ model }) => model)),
      tap(models => (this.makeModels[make] = models)),
    );
  }

  private reorderMakes(makes: string[]): string[] {
    const newMakes = TOP_MAKES.filter(make => makes.includes(make));
    const oldMakes = makes.filter(make => !newMakes.includes(make));

    return [...newMakes, ...oldMakes];
  }
}
