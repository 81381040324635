import { Component, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { ICompanyContactData } from '@caronsale/cos-models';
import { BaseModule } from '../base.module';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-company-contact-modal',
  templateUrl: './company-contact-modal.component.html',
  styleUrls: ['./company-contact-modal.component.scss'],
  standalone: true,
  imports: [CommonModule, BaseModule],
})
export class CompanyContactModalComponent {
  public constructor(
    public dialogRef: MatDialogRef<CompanyContactModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ICompanyContactData,
  ) {}
}
