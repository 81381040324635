import { Injectable } from '@angular/core';
import { Observable, map, shareReplay } from 'rxjs';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { UAParser } from 'ua-parser-js';

@Injectable({
  providedIn: 'root',
})
export class BrowserDetectionService {
  public isMobileOrTabletWidth$: Observable<boolean>;
  public isDesktopWidth$: Observable<boolean>;
  public isLargeDesktopWidth$: Observable<boolean>;

  private parser = new UAParser();

  public constructor(private breakpointObserver: BreakpointObserver) {
    const breakpointStates$ = this.breakpointObserver.observe([Breakpoints.Medium, Breakpoints.Large, Breakpoints.XLarge]).pipe(
      map(({ breakpoints }) => [breakpoints[Breakpoints.Medium], breakpoints[Breakpoints.Large], breakpoints[Breakpoints.XLarge]]),
      shareReplay({ bufferSize: 1, refCount: false }),
    );
    this.isMobileOrTabletWidth$ = breakpointStates$.pipe(map(([md, lg, xl]) => !md && !lg && !xl));
    this.isDesktopWidth$ = breakpointStates$.pipe(map(([md, lg, xl]) => md || lg || xl));
    this.isLargeDesktopWidth$ = breakpointStates$.pipe(map(([, lg, xl]) => lg || xl));
  }

  public isInternetExplorer(): boolean {
    // https://docs.microsoft.com/en-us/previous-versions/windows/internet-explorer/ie-developer/compatibility/hh869301(v=vs.85)?redirectedfrom=MSDN
    return navigator.userAgent.indexOf('MSIE') > -1 || navigator.userAgent.indexOf('Trident') > -1;
  }

  public isAndroid(): boolean {
    if (this.parser.getOS().name === 'Android') {
      return true;
    }

    return false;
  }

  public isIOS(): boolean {
    if (this.parser.getOS().name === 'iOS') {
      return true;
    }

    return false;
  }

  public getDeviceType() {
    const { type } = this.parser.getDevice();

    if (typeof type === 'undefined') {
      return 'desktop';
    }

    return type;
  }
}
