import { Component, Inject } from '@angular/core';
import { NgClass, NgOptimizedImage } from '@angular/common';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { EnzoComponentsModule } from '@caronsale/enzo-angular';

import { EEnzoDialogResult, EnzoDialogRef } from '@cosCoreComponents/modal-dialogs/enzo-dialog.service';
import { EnzoDialogBase } from '@cosCoreComponents/modal-dialogs/enzo-dialog-base';
import {
  EducationalMessage,
  doorsAndSillsClaims,
  exteriorAndHoodClaims,
  lightsClaims,
  rearWindowAndTailgateClaims,
  roofInteriorConvertibleClaims,
  wheelsAndMudguardsClaims,
  windscreenClaims,
} from './educational-modal-data';
import { VehicleDamageLocation } from '@cos/partials/general-info/vehicle-info/vehicle-damages/vehicle-damages.service';

export const dialogPerLocationMap: Record<VehicleDamageLocation, EducationalMessage[]> = {
  'bumper-front': wheelsAndMudguardsClaims,
  'bumper-rear': wheelsAndMudguardsClaims,
  'front-wheel-left': wheelsAndMudguardsClaims,
  'front-wheel-right': wheelsAndMudguardsClaims,
  'rear-wheel-right': wheelsAndMudguardsClaims,
  'rear-wheel-left': wheelsAndMudguardsClaims,
  'headlight-front-right': lightsClaims,
  'headlight-front-left': lightsClaims,
  'headlight-rear-right': lightsClaims,
  'headlight-rear-left': lightsClaims,
  'door-front-right': doorsAndSillsClaims,
  'door-front-left': doorsAndSillsClaims,
  'door-rear-right': doorsAndSillsClaims,
  'door-rear-left': doorsAndSillsClaims,
  'front-windshield': windscreenClaims,
  'rear-windshield': rearWindowAndTailgateClaims,
  roof: roofInteriorConvertibleClaims,
  hood: exteriorAndHoodClaims,
};

export interface IEducationalMessageParams {
  claimStatuses: EducationalMessage[];
}

@Component({
  selector: 'app-educational-modal',
  templateUrl: './educational-modal.component.html',
  styleUrls: ['./educational-modal.component.scss'],
  standalone: true,
  imports: [EnzoComponentsModule, TranslateModule, NgClass, NgOptimizedImage],
})
export class EducationalModalComponent extends EnzoDialogBase {
  public footerButtonBaseKey: string;
  public description: string;

  public constructor(
    @Inject(DIALOG_DATA) public params: IEducationalMessageParams,
    private dialogRef: DialogRef<EEnzoDialogResult, IEducationalMessageParams>,
    private enzoDialogRef: EnzoDialogRef,
  ) {
    super();
    this.enzoDialogType = 'rightCancel';
    this.footerButtonBaseKey = 'complaint';
    this.enzoDialogRef.footerButtonClick$.pipe(takeUntilDestroyed()).subscribe(res => this.dialogRef.close(res));
    this.description = `{prefix} <b>{valid}</b> {and} <b>{invalid}</b> {sufix}`;
  }
}
