import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { EnzoComponentsModule } from '@caronsale/enzo-angular';

@Component({
  standalone: true,
  selector: 'app-video-gallery',
  templateUrl: './video-gallery.component.html',
  styleUrls: ['./video-gallery.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [EnzoComponentsModule],
})
export class VideoGalleryComponent implements OnChanges {
  @Input() public videoUrls: string[];
  public selectedIndex = 0;

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.videoUrls) {
      this.selectedIndex = 0;
    }
  }

  public increaseSelectedIndex() {
    this.selectedIndex = (this.selectedIndex + 1) % this.videoUrls.length;
  }

  public decreaseSelectedIndex() {
    this.selectedIndex = (this.selectedIndex - 1 + this.videoUrls.length) % this.videoUrls.length;
  }
}
