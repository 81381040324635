import { effect, inject, Injectable, signal } from '@angular/core';
import { catchError, EMPTY, Observable, tap } from 'rxjs';

import { IUserNote } from '@caronsale/cos-models';
import { CosCoreClient } from '@cosCoreServices/core-client/cos-core-client.service';
import { NotesRepository } from './notes.repository';

@Injectable({ providedIn: 'root' })
export class NotesService {
  private cosCoreClient = inject(CosCoreClient);
  private notesRepository = inject(NotesRepository);

  public noteIdsToFetch = signal<string[]>([]);
  public noteIdsAlreadyFetched: string[] = [];

  public constructor() {
    effect(() => {
      if (this.noteIdsToFetch().length) this.getNotes(this.noteIdsToFetch());
    });
  }

  public requestNote(id: string): void {
    if (this.noteIdsAlreadyFetched.includes(id)) {
      return;
    }
    this.noteIdsToFetch.update(value => [...value, id]);
  }

  public getNotes(noteIds: string[]): void {
    this.cosCoreClient.requestWithPrivileges('get', `/user-notes/auction/${noteIds.join(',')}`).subscribe(notes => {
      this.noteIdsToFetch.set([]);
      this.noteIdsAlreadyFetched = [...this.noteIdsAlreadyFetched, ...noteIds];
      this.notesRepository.upsertNotes(notes);
    });
  }

  public setNote(content: string, id: string): Observable<IUserNote> {
    return this.cosCoreClient.requestWithPrivileges('post', `/user-notes/auction/${id}`, { content }).pipe(
      // TODO add retry error handling
      catchError(() => EMPTY),
      tap(note => this.notesRepository.upsertNotes(note)),
    );
  }

  public resetNotes(): void {
    this.notesRepository.reset();
    this.noteIdsAlreadyFetched = [];
  }
}
