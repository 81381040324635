@if (hasAdditionalTax()) {
  {{ 'taxation.approx' | translate }}
  @if (isAdditionalTaxRelative()) {
    {{ auctionBiddingData().additionalTaxValue | i18nPercent }}
  }
  @if (isAdditionalTaxAbsolute()) {
    {{ auctionBiddingData().additionalTaxValue | i18nCurrency: 'EUR' }}
  }
  {{ auctionBiddingData().additionalTaxType | i18nAdditionalTaxType | translate }}
}
