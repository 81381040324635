import { NgModule } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { OverlayModule } from '@angular/cdk/overlay';
import { TranslateModule } from '@ngx-translate/core';
import { NgxMaskModule } from 'ngx-mask';
import { CosBottomOverlayProvider } from './cos-bottom-overlay/cos-bottom-overlay-provider.service';

@NgModule({
  imports: [FontAwesomeModule, OverlayModule, TranslateModule, NgxMaskModule],
  declarations: [],
  exports: [],
  providers: [CosBottomOverlayProvider],
})
export class CosCommonModule {}
