import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { CurrencyPipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { IBuyerAuctionView } from '@caronsale/cos-models';
import { COS_CONSTANTS } from '@caronsale/frontend-services';
import { EnzoDialogBase, EnzoDialogType, EnzoVariant } from '@cosCoreComponents/modal-dialogs/enzo-dialog-base';
import { EEnzoDialogResult, EnzoDialogRef } from '@cosCoreComponents/modal-dialogs/enzo-dialog.service';

import { CommonModule } from '@angular/common';
import { GeneralPipesModule, I18nGeneralPipesModule } from '@caronsale/frontend-pipes';
import { EnzoComponentsModule } from '@caronsale/enzo-angular';
import { TranslateModule } from '@ngx-translate/core';
import { AppMaterialModule } from '@cosCoreModules/app-material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

export interface IBuyNowPayLaterOptionsDialogParams {
  auction: IBuyerAuctionView;
  totalAmount: number;
}

@Component({
  selector: 'app-buy-now-pay-later-options-dialog',
  templateUrl: './buy-now-pay-later-options-dialog.component.html',
  styleUrls: ['./buy-now-pay-later-options-dialog.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    GeneralPipesModule,
    I18nGeneralPipesModule,
    EnzoComponentsModule,
    TranslateModule,
    AppMaterialModule,
    FormsModule,
    ReactiveFormsModule,
  ],
})
export class BuyNowPayLaterDialogComponent extends EnzoDialogBase implements OnInit {
  public totalAmount: string;

  public confirmationInput = new FormControl(false, Validators.requiredTrue);

  public confirmationTemplate: string = `{prefix} <a class="confirmation-link" href="${this.cosConstants.BNPL_CONDITIONS_URL}" target="_blank">{link}</a> {suffix}`;
  public footerButtonBaseKey: string = 'prebooked-service.buy-now-pay-later';
  public enzoDialogType: EnzoDialogType = 'confirmCancel';
  public enzoDialogVariant: EnzoVariant = 'normal';
  public isConfirmedAndInvalid: boolean = false;

  public constructor(
    @Inject(DIALOG_DATA) public data: IBuyNowPayLaterOptionsDialogParams,
    private dialogRef: DialogRef<EEnzoDialogResult, BuyNowPayLaterDialogComponent>,
    private currencyPipe: CurrencyPipe,
    private cosConstants: COS_CONSTANTS,
    private enzoDialogRef: EnzoDialogRef,
  ) {
    super();

    this.enzoDialogRef.footerButtonClick$.pipe(takeUntilDestroyed()).subscribe(result => {
      if (result === EEnzoDialogResult.CONFIRM && this.confirmationInput.invalid) {
        this.isConfirmedAndInvalid = true;
        return;
      }
      this.dialogRef.close(result);
    });
  }

  public ngOnInit(): void {
    this.totalAmount = this.currencyPipe.transform(this.data.totalAmount, 'EUR', 'symbol', '1.0-0');
  }
}
