import { Component, Input } from '@angular/core';
import { IBuyerAuctionBidView } from '@caronsale/cos-models';
import { CdkTableDataSourceInput } from '@angular/cdk/table';

@Component({
  selector: 'app-bidders-list',
  templateUrl: './bidders-list.component.html',
  styleUrls: ['./bidders-list.component.scss'],
})
export class BiddersListComponent {
  @Input()
  public set bids(listOfBids: IBuyerAuctionBidView[]) {
    if (listOfBids?.length > 0) {
      this.dataSource = listOfBids;
      this.displayedColumns = this.dataColumns;
    }
  }

  // an initial data source entry is needed for the skeleton row
  public dataSource: CdkTableDataSourceInput<IBuyerAuctionBidView> = [{} as IBuyerAuctionBidView];
  public displayedColumns: string[] = ['skeleton', 'skeleton', 'skeleton'];
  public dataColumns: string[] = ['buyerName', 'bidValue', 'bidTime'];
}
