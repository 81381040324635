import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VoucherSelectionComponent } from '@cosBuyer/partials/voucher-selection/voucher-selection.component';
import { BaseModule } from '@cosCoreComponentsGeneral/base.module';
import { EnzoComponentsModule } from '@caronsale/enzo-angular';
import { AuctionPipesModule } from '@caronsale/frontend-pipes';

@NgModule({
  imports: [AuctionPipesModule, BaseModule, CommonModule, EnzoComponentsModule],
  exports: [VoucherSelectionComponent],
  declarations: [VoucherSelectionComponent],
})
export class VoucherSelectionModule {}
