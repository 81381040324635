import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { EAddressType, IAccountAddress } from '@caronsale/cos-models';

@Injectable()
export abstract class AddressService {
  public abstract getAddresses(accountUuid?: string): Observable<IAccountAddress[]>;

  public abstract createAddress(address: Partial<IAccountAddress>, accountUuid?: string, index?: number): Observable<IAccountAddress>;

  public abstract deleteAddress(uuid: string, accountUuid?: string): Observable<void>;

  public abstract updateAddress(address: IAccountAddress, accountUuid?: string): Observable<IAccountAddress>;

  public abstract updateAddressType(uuid: string, type: EAddressType, accountUuid?: string): Observable<void>;

  public abstract removeAddressType(uuid: string, type: EAddressType, accountUuid?: string): Observable<void>;

  public saveAddress(address: IAccountAddress, accountUuid?: string): Observable<IAccountAddress> {
    if (address.uuid) {
      return this.updateAddress(address, accountUuid);
    }
    return this.createAddress(address, accountUuid);
  }
}
