import { Component, Input } from '@angular/core';

import { IAuction } from '@caronsale/cos-models';

@Component({
  selector: 'app-highlights',
  templateUrl: './highlights.component.html',
  styleUrls: ['./highlights.component.scss'],
})
export class HighlightsComponent {
  @Input()
  public auction: IAuction;
}
