import { ChangeDetectionStrategy, Component } from '@angular/core';
import { EnzoComponentsModule } from '@caronsale/enzo-angular';
import { ExpandablePanelModule } from '@cos/partials/expandable-panel/expandable-panel.module';
import { TranslateModule } from '@ngx-translate/core';
import { COS_CONSTANTS } from '@caronsale/frontend-services';

@Component({
  selector: 'app-vehicle-unspecified-info',
  standalone: true,
  imports: [EnzoComponentsModule, ExpandablePanelModule, TranslateModule],
  templateUrl: './vehicle-unspecified-info.component.html',
  styleUrls: ['./vehicle-unspecified-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VehicleUnspecifiedInfoComponent {
  public catalogUrlTemplate: string;

  public constructor(public constants: COS_CONSTANTS) {
    this.catalogUrlTemplate = `{prefix} <a target="_blank" href="${constants.DAMAGE_CATALOG_URL}"><u>{link}</u></a> {sufix}`;
  }
}
