import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BaseModule } from '../base.module';
import { GuaranteeBadgeComponent } from './guarantee-badge.component';

@NgModule({
  declarations: [GuaranteeBadgeComponent],
  imports: [CommonModule, BaseModule],
  exports: [GuaranteeBadgeComponent],
})
export class GuaranteeBadgeModule {}
