<div class="container" [attr.data-alignment]="textAlignment">
  @if (showText) {
    <enzo-text kind="caption" color="black" *ngIf="isStartPrice" testid="start-price-text">{{ 'auction.starting-price' | translate }}</enzo-text>
    <ng-container *ngIf="auction.didIBidAtLeastOnce">
      <enzo-text kind="caption" color="black" *ngIf="auction.amIHighestBidder" testid="highest-bidder-text">{{
        'auction.highest-bidder' | translate
      }}</enzo-text>
      <enzo-text kind="caption" color="black" *ngIf="!auction.amIHighestBidder" testid="outbid-text">{{ 'auction.outbid' | translate }}</enzo-text>
    </ng-container>
  }
  <div
    class="bid-value-and-icon"
    [class.animate]="animatePrice"
    [class.clickable]="auction.didIBidAtLeastOnce"
    (click)="auction.didIBidAtLeastOnce && showHighestBidderPopover($event)"
    testid="highest-bid-value"
    [ngClass]="{ 'highest-bidder': auction.didIBidAtLeastOnce && auction.amIHighestBidder, outbid: auction.didIBidAtLeastOnce && !auction.amIHighestBidder }"
    data-cy="bidding_highest-bid-value">
    <ng-container *ngIf="auction.didIBidAtLeastOnce">
      <enzo-icon *ngIf="auction.amIHighestBidder" icon="top-bid" testid="highest-bidder-img"></enzo-icon>
      <enzo-icon *ngIf="!auction.amIHighestBidder" icon="outbid" testid="outbid-img"></enzo-icon>
    </ng-container>
    <enzo-headline [level]="bidValueSize === 'small' ? '4' : '3'" balance="false" testid="highest-bid-headline">
      {{ auction.currentHighestBidValue | currencyEuro }}
    </enzo-headline>
  </div>
</div>
