import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, filter, finalize, map, Observable, of, share, tap, throwError } from 'rxjs';

import { TranslateService } from '@ngx-translate/core';

import { CountryCodeService, ErrorHandlers } from '@caronsale/frontend-services';
import { CosCoreClient } from '@cosCoreServices/core-client/cos-core-client.service';
import {
  EAuctionListType,
  EUserClientCategory,
  IAccountAddress,
  IAuction,
  IAuctionBid,
  IAuctionComplaintCreationParams,
  IAuctionComplaintPossibility,
  IAuctionFilter,
  IAuctionPaymentAmount,
  IAuctionRoom,
  IAuctionRoomBuyerView,
  IAuctionRoomFilter,
  IBankAccount,
  IBuyerActivationTasks,
  IBuyerAuctionBiddingDataView,
  IBuyerAuctionBidView,
  IBuyerAuctionSavedSearch,
  IBuyerAuctionView,
  IBuyerBalanceAuctionView,
  IBuyerBalanceRemainingFunds,
  IBuyerUser,
  IBuyNowPayLaterAmount,
  IBuyNowPayLaterBalanceWithLabel,
  IBuyNowPayLaterCreateOrderClientRequest,
  IBuyNowPayLaterOrder,
  IBuyNowPayLaterSessionWithBillingData,
  ICompanyContactData,
  ICreateUserRequest,
  ICustomerBalance,
  IFile,
  IGeneralBusinessTerms,
  IInvoice,
  IInvoiceCreditNote,
  IPage,
  IPaymentLinkCreationResponse,
  IPrebookedService,
  IPurchaseFeeBucketPrice,
  IRating,
  IRegularBuyerState,
  IResultMap,
  ISurvey,
  ITransportationTask,
  IUserRequest,
  IVoucherAssignment,
  Validation,
} from '@caronsale/cos-models';

import { IRenegotiationClient } from '@cosCoreServices/interfaces/renegotiation-client';
import { HttpClient, HttpEventType, HttpHeaders, HttpResponse } from '@angular/common/http';
import { MediaUtils } from '../../utils/MediaUtils';
import { IPurchasePreferences } from '@cosTypes';
import { EBuyerAuctionTabId } from '@cosCoreFeatures/auction-detail/common/auction-service/buyer-auction.service';
import { KYCDocumentType } from '@cosCoreServices/cos-salesman-client/types';
import { IBuyNowPayLaterEstimate } from '@cosCoreServices/buy-now-pay-later/buy-now-pay-later.service';
import { BrowserDetectionService } from '@cosCoreServices/browser-detection/browser-detection.service';
import { RecommendationIdService } from '@cosBuyer/partials/services/recommendation-id.service';

export interface IUploadEvent {
  url: string;
  percentComplete: number;
}

@Injectable()
export class CosBuyerClientService implements IRenegotiationClient {
  private cachedObservable: { [s: string]: Observable<any> } = {};
  public currentBuyerUserSubject: BehaviorSubject<IBuyerUser> = new BehaviorSubject<IBuyerUser>(null);

  public constructor(
    //
    private cosClient: CosCoreClient,
    private countryCodeService: CountryCodeService,
    private translateService: TranslateService,
    private httpClient: HttpClient,
    private browserDetectionService: BrowserDetectionService,
  ) {}

  public reset(): void {
    this.cachedObservable = {};
    this.currentBuyerUserSubject.next(null);
  }

  public refreshCurrentBuyerUser(): Observable<IBuyerUser> {
    return this.getBuyerUserProfile();
  }

  public getCurrentBuyerUser$(): Observable<IBuyerUser> {
    return this.currentBuyerUserSubject.asObservable();
  }

  // TODO: Refactor this to use IGeneralUser:
  public getCurrentBuyerUser(): Observable<IBuyerUser> {
    if (Validation.isUndefinedOrNull(this.currentBuyerUserSubject.value)) {
      return this.getBuyerUserProfile();
    } else {
      return of(this.currentBuyerUserSubject.value);
    }
  }

  public registerAuctionVisit(auction: IAuction, clientCategory?: EUserClientCategory, recommendationId?: string): Observable<void> {
    return this.authenticatedRequestWithPrivileges<void>(
      'post',
      userId => RecommendationIdService.attachRecommendationIdToUrl(`/auction/salesman/${userId}/${auction.id}/access`, recommendationId),
      {
        clientCategory: clientCategory ?? EUserClientCategory.BROWSER,
      },
    );
  }

  public requestTransportationForAuction(auction: IBuyerAuctionView, selectedAddress?: IAccountAddress): Observable<ITransportationTask> {
    return this.cacheableAuthenticatedRequestWithPrivileges<ITransportationTask>(
      'post',
      userId => `/auction/salesman/${userId}/auction/${auction.id}/transport`,
      selectedAddress,
    );
  }

  public updateTransportationDropOffAddressForAuction(
    auctionId: string,
    transportationTaskUuid: string,
    shouldPersistPrice: boolean,
    destinationAddress: IAccountAddress,
  ): Observable<ITransportationTask> {
    return this.cosClient.requestWithPrivileges(
      'put',
      `/auction/salesman/auction/${auctionId}/transport/${transportationTaskUuid}/dropoff-location?shouldPersistPrice=${shouldPersistPrice}`,
      destinationAddress,
    );
  }

  public confirmTransportationForAuction(auction: IBuyerAuctionView): Observable<void> {
    return this.authenticatedRequestWithPrivileges<void>(
      'post',
      userId => `/auction/salesman/${userId}/auction/${auction.id}/transport/confirmation`,
      undefined,
    );
  }

  public updateDocumentShippingAddress(auctionUuid: string, address: IAccountAddress): Observable<void> {
    return this.authenticatedRequestWithPrivileges<void>('post', () => `/buyer/document-shipping/auction/${auctionUuid}/update-address`, address);
  }

  public updatePrebookedServices(auctionUuid: string, prebookedServices: IPrebookedService[]): Observable<void> {
    return this.authenticatedRequestWithPrivileges<void>(
      'put',
      (_, internalUserUuid) => `/auction/salesman/${internalUserUuid}/${auctionUuid}/prebooked-services`,
      prebookedServices,
    );
  }

  public startWatchingAuctionAsSalesman(uuid: string, recommendationId?: string): Observable<void> {
    return this.authenticatedRequestWithPrivileges<void>(
      'put',
      userId => RecommendationIdService.attachRecommendationIdToUrl(`/auction/salesman/${userId}/${uuid}/watch`, recommendationId),
      undefined,
    );
  }

  public stopWatchingAuctionAsSalesman(uuid: string, recommendationId?: string): Observable<void> {
    return this.authenticatedRequestWithPrivileges<void>('delete', userId =>
      RecommendationIdService.attachRecommendationIdToUrl(`/auction/salesman/${userId}/${uuid}/watch`, recommendationId),
    );
  }

  public getBuyerUserProfile(): Observable<IBuyerUser> {
    return this.cacheableAuthenticatedRequestWithPrivileges<IBuyerUser>('get', userId => `/profile/salesman/${userId}`).pipe(
      tap((profile: IBuyerUser) => {
        this.currentBuyerUserSubject.next(profile);
        this.countryCodeService.userCountryCode = profile.countryCode;
      }),
    );
    // TODO: Add error handling?
  }

  public isRegularBuyer(): Observable<IRegularBuyerState> {
    return this.authenticatedRequestWithPrivileges<IRegularBuyerState>('get', userId => `/profile/salesman/${userId}/regular-buyer-state`);
  }

  public updateProfile(buyerUser: IBuyerUser): Observable<any> {
    return this.cosClient.requestWithPrivileges('post', `/profile/salesman/${buyerUser.mailAddress}`, buyerUser);
  }

  public getBuyerUserCompanyContact(): Observable<ICompanyContactData> {
    return this.authenticatedRequestWithPrivileges<ICompanyContactData>('get', userId => `/profile/salesman/${userId}/contact`);
  }

  public rateDealershipForAuction(auction: IAuction, rating: IRating): Observable<void> {
    return this.authenticatedRequestWithPrivileges<void>('put', userId => `/auction/salesman/${userId}/${auction.id}/rating`, rating);
  }

  public confirmOutgoingPayment(purchase: IAuction): Observable<void> {
    return this.authenticatedRequestWithPrivileges<void>('post', userId => `/auction/salesman/${userId}/${purchase.id}/payment`);
  }

  public getPurchases(filter?: IAuctionFilter): Observable<IBuyerAuctionView[]> {
    return this.authenticatedRequestWithPrivileges<IBuyerAuctionView[]>(
      'get',
      userId => `/auction/salesman/${userId}/purchases?filter=${this.cosClient.encodeParamObject(filter)}`,
    );
  }

  public getRunningAuctionsPage(filterRequest?: IAuctionFilter, isPolling?: boolean, countOnly?: boolean): Observable<IPage<IBuyerAuctionView>> {
    const countQuery = countOnly ? '&count=true' : '';

    return this.authenticatedV2RequestWithPrivileges<IPage<IBuyerAuctionView>>(
      'get',
      () => `/auction/buyer/?filter=${this.cosClient.encodeParamObject(filterRequest)}${countQuery}`,
      undefined,
      this.getPollingHeaders(isPolling),
    );
  }

  public getWatchlistAuctions(filter: IAuctionFilter, isPolling?: boolean): Observable<IPage<IBuyerAuctionView>> {
    if (!this.cosClient.getLastAuthenticationResult()) {
      // TODO: Trigger redirection?
      return of(null);
    }

    return this.authenticatedV2RequestWithPrivileges<IPage<IBuyerAuctionView>>(
      'get',
      () => `/auction/buyer/parked?filter=${this.cosClient.encodeParamObject(filter)}`,
      undefined,
      this.getPollingHeaders(isPolling),
    );
  }

  public getRecommendedAuctions(_?: IAuctionFilter, isPolling?: boolean): Observable<IPage<IBuyerAuctionView>> {
    if (!this.cosClient.getLastAuthenticationResult()) {
      // TODO: Trigger redirection?
      return of(null);
    }

    return this.authenticatedV2RequestWithPrivileges<IPage<IBuyerAuctionView>>(
      'get',
      () => '/auction/buyer/recommended/score',
      undefined,
      this.getPollingHeaders(isPolling),
    );
  }

  public getRunningAuctionsWithActiveInstantPurchasePage(filterRequest: IAuctionFilter, isPolling?: boolean): Observable<IPage<IBuyerAuctionView>> {
    return this.authenticatedV2RequestWithPrivileges<IPage<IBuyerAuctionView>>(
      'get',
      () => `/auction/buyer/instant-purchase?filter=${this.cosClient.encodeParamObject(filterRequest)}`,
      undefined,
      this.getPollingHeaders(isPolling),
    );
  }

  public getFailedAuctions(): Observable<IBuyerAuctionView[]> {
    return this.authenticatedRequestWithPrivileges<IBuyerAuctionView[]>('get', userId => `/auction/salesman/${userId}/failed`);
  }

  public getPurchasesAll(filter?: IAuctionFilter): Observable<IPage<IBuyerAuctionView>> {
    return this.authenticatedV2RequestWithPrivileges<IPage<IBuyerAuctionView>>(
      'get',
      () => `/auction/buyer/my-auctions?filter=${this.cosClient.encodeParamObject(filter || {})}`,
    );
  }

  public getPurchasesBelowMinAsk(filter?: IAuctionFilter): Observable<IPage<IBuyerAuctionView>> {
    return this.authenticatedV2RequestWithPrivileges<IPage<IBuyerAuctionView>>(
      'get',
      () => `/auction/buyer/below-min-ask?filter=${this.cosClient.encodeParamObject(filter || {})}`,
    );
  }

  public getPurchasesWaitingForPickup(filter?: IAuctionFilter): Observable<IPage<IBuyerAuctionView>> {
    return this.authenticatedV2RequestWithPrivileges<IPage<IBuyerAuctionView>>(
      'get',
      () => `/auction/buyer/waiting/pickup?filter=${this.cosClient.encodeParamObject(filter || {})}`,
    );
  }

  public getPurchasesWaitingForPayment(filter?: IAuctionFilter): Observable<IPage<IBuyerAuctionView>> {
    return this.authenticatedV2RequestWithPrivileges<IPage<IBuyerAuctionView>>(
      'get',
      () => `/auction/buyer/waiting/payment?filter=${this.cosClient.encodeParamObject(filter || {})}`,
    );
  }

  public getPurchasesFinished(filter?: IAuctionFilter): Observable<IPage<IBuyerAuctionView>> {
    return this.authenticatedV2RequestWithPrivileges<IPage<IBuyerAuctionView>>(
      'get',
      () => `/auction/buyer/finished?filter=${this.cosClient.encodeParamObject(filter || {})}`,
    );
  }

  public getPurchasesLost(filter?: IAuctionFilter): Observable<IPage<IBuyerAuctionView>> {
    return this.authenticatedV2RequestWithPrivileges<IPage<IBuyerAuctionView>>(
      'get',
      () => `/auction/buyer/lost?filter=${this.cosClient.encodeParamObject(filter || {})}`,
    );
  }

  public getListTypeBasedOnTabId(tabId: EBuyerAuctionTabId): EAuctionListType {
    switch (tabId) {
      case EBuyerAuctionTabId.RUNNING_AUCTIONS:
        return EAuctionListType.RUNNING;
      case EBuyerAuctionTabId.INSTANT_PURCHASE:
        return EAuctionListType.INSTANT_PURCHASE;
      case EBuyerAuctionTabId.WATCHLIST:
        return EAuctionListType.PARKED;
      default:
        return undefined;
    }
  }

  public getAuction(
    auctionUuid: string,
    auctionFilter?: IAuctionFilter,
    tabId?: EBuyerAuctionTabId,
    isPolling?: boolean,
    errorHandlers?: ErrorHandlers,
  ): Observable<IBuyerAuctionView> {
    if (!auctionUuid) {
      return throwError(() => ({ status: 404 }));
    }

    const listType = this.getListTypeBasedOnTabId(tabId);

    const directionalInfoParams: string = auctionFilter
      ? `&withDirectionalTravelInfo=true&filter=${this.cosClient.encodeParamObject(this.omitOffsetAndLimitFromAuctionFilter(auctionFilter))}`
      : '';

    return this.cacheableAuthenticatedRequestWithPrivileges<IBuyerAuctionView>(
      'get',
      userId => `/auction/salesman/${listType || userId}/${auctionUuid}?&language=${this.translateService.currentLang}` + directionalInfoParams,
      undefined,
      this.getPollingHeaders(isPolling),
      errorHandlers,
    );
  }

  public getPreviousAuction(
    auctionUuid: string,
    auctionFilter: IAuctionFilter,
    tabId: EBuyerAuctionTabId,
    errorHandlers?: ErrorHandlers,
  ): Observable<IBuyerAuctionView> {
    const listType = this.getListTypeBasedOnTabId(tabId);

    return this.authenticatedRequestWithPrivileges<IBuyerAuctionView>(
      'get',
      () =>
        `/auction/salesman/${listType}/${auctionUuid}/previous-auction?filter=${this.cosClient.encodeParamObject(
          this.omitOffsetAndLimitFromAuctionFilter(auctionFilter),
        )}` +
        `&language=${this.translateService.currentLang}` +
        `&withDirectionalTravelInfo=true`,
      undefined,
      undefined,
      errorHandlers,
    );
  }

  public getNextAuction(
    auctionUuid: string,
    auctionFilter: IAuctionFilter,
    tabId: EBuyerAuctionTabId,
    errorHandlers?: ErrorHandlers,
  ): Observable<IBuyerAuctionView> {
    const listType = this.getListTypeBasedOnTabId(tabId);

    return this.authenticatedRequestWithPrivileges<IBuyerAuctionView>(
      'get',
      () =>
        `/auction/salesman/${listType}/${auctionUuid}/next-auction?filter=${this.cosClient.encodeParamObject(
          this.omitOffsetAndLimitFromAuctionFilter(auctionFilter),
        )}` +
        `&language=${this.translateService.currentLang}` +
        `&withDirectionalTravelInfo=true`,
      undefined,
      undefined,
      errorHandlers,
    );
  }

  public getBiddingDataView(filter?: IAuctionFilter, isPolling?: boolean): Observable<IBuyerAuctionBiddingDataView[]> {
    return this.cacheableAuthenticatedRequestWithPrivileges<IBuyerAuctionBiddingDataView[]>(
      'get',
      userId => `/auction/salesman/${userId}/_all/bidding-data?filter=${this.cosClient.encodeParamObject(filter || {})}`,
      undefined,
      this.getPollingHeaders(isPolling),
    );
  }

  public getBiddersList(auction: IBuyerAuctionView): Observable<IBuyerAuctionBidView[]> {
    return this.authenticatedRequestWithPrivileges<IBuyerAuctionBidView[]>('get', userId => `/auction/salesman/${userId}/${auction.uuid}/bids`);
  }

  public bidOnAuction(auctionUuid: string, bidValue: number, isListViewBid?: boolean, recommendationId?: string): Observable<any> {
    return this.authenticatedRequestWithPrivileges(
      'put',
      userId => RecommendationIdService.attachRecommendationIdToUrl(`/auction/salesman/${userId}/${auctionUuid}`, recommendationId),
      {
        isListViewBid: Boolean(isListViewBid),
        value: bidValue,
      },
    );
  }

  public makeInstantPurchase(auction: IBuyerAuctionView): Observable<void> {
    return this.authenticatedRequestWithPrivileges<void>('put', userId => `/auction/salesman/${userId}/${auction.id}/instant-purchase`, {
      value: auction.instantPurchasePrice,
    } as IAuctionBid);
  }

  public placeBiddingAgent(auctionUuid: string, maxValue: number): Observable<void> {
    return this.authenticatedRequestWithPrivileges<void>('put', userId => `/auction/salesman/${userId}/${auctionUuid}/agent`, {
      maxValue,
    });
  }

  public removeBiddingAgent(auctionUuid: string): Observable<void> {
    return this.authenticatedRequestWithPrivileges<void>('delete', userId => `/auction/salesman/${userId}/${auctionUuid}/agent`);
  }

  public getBalance(): Observable<ICustomerBalance> {
    return this.cosClient.requestWithPrivileges('get', `/buyer/payment/balance`);
  }

  public getRemainingFunds(): Observable<IBuyerBalanceRemainingFunds> {
    return this.cosClient.requestWithPrivileges('get', `/buyer/payment/remaining-funds`);
  }

  public getBalanceHistory(filter: IAuctionFilter): Observable<IPage<IBuyerBalanceAuctionView>> {
    return this.cosClient.requestWithPrivileges('get', `/buyer/payment/balance/history?filter=${this.cosClient.encodeParamObject(filter)}`);
  }

  public getTargetBankAccount(): Observable<IBankAccount> {
    return this.cosClient.requestWithPrivileges('get', `/buyer/payment/target-bank-account`);
  }

  public getCreditNotes(): Observable<IInvoiceCreditNote[]> {
    return this.authenticatedRequestWithPrivileges<IInvoiceCreditNote[]>('get', userId => `/salesman/invoice/${userId}/credit-notes`);
  }

  public getPendingInvoices(): Observable<IInvoice[]> {
    return this.authenticatedRequestWithPrivileges<IInvoice[]>('get', userId => `/salesman/invoice/${userId}/pending`);
  }

  public getPaidInvoices(): Observable<IInvoice[]> {
    return this.authenticatedRequestWithPrivileges<IInvoice[]>('get', userId => `/salesman/invoice/${userId}/paid`);
  }

  public getGeneralBusinessTermsToAccept(): Observable<IGeneralBusinessTerms> {
    return this.authenticatedRequestWithPrivileges<IGeneralBusinessTerms>('get', userId => `/profile/salesman/${userId}/general-business-terms/to-accept`);
  }

  public acceptGeneralBusinessTerms(generalBusinessTerms: IGeneralBusinessTerms): Observable<IGeneralBusinessTerms> {
    return this.authenticatedRequestWithPrivileges<IGeneralBusinessTerms>(
      'post',
      userId => `/profile/salesman/${userId}/general-business-terms/${generalBusinessTerms.uuid}`,
      undefined,
    );
  }

  public uploadKYCDocument(file: IFile, accountUUID: string, documentType: KYCDocumentType, origin?: string): Observable<IFile> {
    const deviceType = this.browserDetectionService.getDeviceType();

    const actualFileName = file.fileName || file['name'];

    file['name'] = actualFileName;
    file.fileName = actualFileName;

    return this.cosClient
      .requestWithPrivileges('put', `/media/document/kyc`, { file, accountUUID, documentType, origin, deviceType })
      .pipe(map(res => ({ ...file, url: res.url })));
  }

  public deleteKYCDocument(documentURL: string): Observable<{ message: string }> {
    // TODO: Note that delete with body and headers is not working
    const newParams = new URLSearchParams({ documentURL });
    const paramsString = newParams.toString();

    return this.cosClient.requestWithPrivileges('delete', `/media/document/kyc/?${paramsString}`).pipe(
      map(res => {
        return {
          message: res.message,
        };
      }),
    );
  }

  public uploadComplaintFormDocument(document: IFile): Observable<IFile> {
    return this.cosClient.requestWithPrivileges('put', `/media/document/complaint`, document).pipe(
      map(
        res =>
          ({
            mimeType: null,
            rawData: null,
            encoding: null,
            url: res.url,
          }) as IFile,
      ),
    );
  }

  public uploadSupportFormDocument(document: IFile): Observable<IUploadEvent> {
    return this.cosClient.requestWithPrivileges('put', `/media/document/user-request`, document, { reportProgress: true, observe: 'events' }).pipe(
      map((event: any) => {
        if (event instanceof HttpResponse) {
          return {
            url: event.body.url,
            percentComplete: 100,
          };
        }
        if (event?.type === HttpEventType.UploadProgress) {
          return {
            url: null,
            percentComplete: Math.round((100 * event.loaded) / event.total),
          };
        }
      }),
      filter(Boolean), // suppress everything that was not mapped (HttpHeaderResponse, Event types Sent and DownloadProgress)
    );
  }

  public getNextSurvey(): Observable<ISurvey> {
    return this.authenticatedRequestWithPrivileges<ISurvey>('get', (_, internalUserUUID) => `/survey/salesman/${internalUserUUID}/next`);
  }

  public captureBuyerSurveyParticipation(survey: ISurvey): Observable<void> {
    return this.authenticatedRequestWithPrivileges<void>('post', (_, internalUserUUID) => `/survey/salesman/${internalUserUUID}`, survey);
  }

  public confirmPickup(auctionUuid: string, pinCodeValue: string): Observable<void> {
    return this.authenticatedRequestWithPrivileges<void>('post', userId => `/auction/salesman/${userId}/${auctionUuid}/pickup-confirm`, {
      value: pinCodeValue,
    });
  }

  public createPurchaseComplaintForm(purchase: IBuyerAuctionView, complaint?: IAuctionComplaintCreationParams): Observable<void> {
    return this.authenticatedRequestWithPrivileges<void>('put', userId => `/auction/salesman/${userId}/${purchase.id}/complaint`, complaint);
  }

  public getComplaintProcessStartPossible(purchase: IBuyerAuctionView): Observable<IAuctionComplaintPossibility> {
    return this.authenticatedRequestWithPrivileges<IAuctionComplaintPossibility>('get', userId => `/auction/salesman/${userId}/${purchase.id}/complaint`);
  }

  public getCosInvoicesForBuyer(auctionUuid: string): Observable<IInvoice[]> {
    return this.authenticatedRequestWithPrivileges<IInvoice[]>('get', (_, internalUserUUID) => `/salesman/invoice/${internalUserUUID}/auction/${auctionUuid}`);
  }

  /*
   * IRenegotiationClient methods
   */

  public newOwnOffer(auctionUuid: string, counterOffer: number): Observable<IBuyerAuctionView> {
    return this.authenticatedRequestWithPrivileges<IBuyerAuctionView>(
      'post',
      () => `/auction/salesman/${auctionUuid}/renegotiate/make-counter-offer?counterOffer=${counterOffer}`,
    );
  }

  public acceptOtherPartysOffer(auction: IAuction): Observable<void> {
    return this.authenticatedRequestWithPrivileges<void>('post', () =>
      auction.lastOfferBySeller ? `/auction/salesman/${auction.uuid}/renegotiate/accept-last-offer` : `/auction/salesman/${auction.uuid}/accept-min-ask`,
    );
  }

  public acceptMidpoint(auctionUuid: string): Observable<IBuyerAuctionView> {
    return this.authenticatedRequestWithPrivileges<IBuyerAuctionView>('post', () => `/auction/salesman/${auctionUuid}/renegotiation-midpoint`);
  }

  public withdrawFromRenegotiation(auctionUuid: string): Observable<IBuyerAuctionView> {
    return this.authenticatedRequestWithPrivileges<IBuyerAuctionView>('post', () => `/auction/salesman/${auctionUuid}/renegotiation-stop`);
  }

  public getPurchaseFeePriceList(showAll: boolean = false): Observable<IPurchaseFeeBucketPrice[]> {
    return this.cosClient.requestWithPrivileges('get', `/secured/config/purchase-fee?all=${showAll}`);
  }

  public listAvailableVouchersForAuction(auctionUuid: string): Observable<{ availableVouchers: IVoucherAssignment[]; selectedVoucher: IVoucherAssignment }> {
    return this.authenticatedRequestWithPrivileges<{ availableVouchers: IVoucherAssignment[]; selectedVoucher: IVoucherAssignment }>(
      'get',
      (_, internalUserUUID) => `/auction/salesman/${internalUserUUID}/${auctionUuid}/vouchers`,
    );
  }

  public applyVoucher(auctionUuid: string, voucherUuid: string): Observable<void> {
    return this.authenticatedRequestWithPrivileges<void>(
      'put',
      (_, internalUserUUID) => `/auction/salesman/${internalUserUUID}/${auctionUuid}/vouchers/${voucherUuid}`,
    );
  }

  public undoVoucher(auctionUuid: string): Observable<void> {
    return this.authenticatedRequestWithPrivileges<void>(
      'delete',
      (_, internalUserUUID) => `/auction/salesman/${internalUserUUID}/${auctionUuid}/vouchers`,
    ).pipe(
      map(() => null),
      catchError(errorResponse => {
        if (!this.isVoucherNotFoundError(errorResponse)) {
          return of(errorResponse);
        }

        return of(null);
      }),
    );
  }

  private isVoucherNotFoundError(errorResponse: any): boolean {
    return errorResponse?.error?.msgKey === 'voucher.not-found';
  }

  public saveNewSearch(filter: IAuctionFilter): Observable<void> {
    return this.authenticatedRequestWithPrivileges<void>('post', (_, internalUserUUID) => `/auction/salesman/${internalUserUUID}/saved-search`, filter);
  }

  public getSavedSearches(limit?: number, offset?: number): Observable<IPage<IBuyerAuctionSavedSearch>> {
    let pagination = '';
    if (limit !== undefined && offset !== undefined) {
      pagination = `?filter=${JSON.stringify({ limit, offset })}`;
    }
    return this.authenticatedV2RequestWithPrivileges<IPage<IBuyerAuctionSavedSearch>>('get', () => `/auction/buyer/saved-search${pagination}`);
  }

  public countResultsForSavedSearches(uuids: string[], type: EAuctionListType = EAuctionListType.RUNNING): Observable<IResultMap<number>> {
    const uuidsParam = uuids.map(uuid => `searches=${uuid}`).join('&');
    const typeParam = `type=${type}`;

    return this.authenticatedRequestWithPrivileges<IResultMap<number>>('get', () => `/auction/salesman/count-saved-searches?${uuidsParam}&${typeParam}`);
  }

  public removeSavedSearch(searchId: string): Observable<void> {
    return this.authenticatedRequestWithPrivileges<void>('delete', (_, internalUserUUID) => `/auction/salesman/${internalUserUUID}/saved-search/${searchId}`);
  }

  public editSavedSearch(search: IBuyerAuctionSavedSearch): Observable<void> {
    return this.authenticatedRequestWithPrivileges<void>('put', () => `/auction/salesman/saved-search/${search.uuid}`, search);
  }

  public downloadBuyerPickupDocument(auction: IBuyerAuctionView): void {
    let headers = new HttpHeaders();
    headers = headers.set('Accept', 'application/pdf').set('Cache-control', 'no-store');
    this.httpClient
      .get(`${auction.urlToPickupBuyerDocument}?v=${Date.now()}`, {
        headers,
        responseType: 'blob',
      })
      .subscribe((blob: Blob): void => {
        MediaUtils.downloadPDF(blob, `self-pickup-document-${auction.associatedVehicle.vin}.pdf`);
      });
  }

  public toggleNotificationPreferences(savedSearchId: string, shouldNotify: boolean): Observable<void> {
    return this.authenticatedRequestWithPrivileges<void>(
      'post',
      userId => `/auction/salesman/${userId}/saved-search/${savedSearchId}/notification-preference?shouldNotify=${shouldNotify}`,
    );
  }

  public getAuctionRoom(auctionRoomUuid: string): Observable<IAuctionRoomBuyerView> {
    return this.cosClient.requestWithPrivileges('get', `/auction-room/buyer/${auctionRoomUuid}`);
  }

  public getAuctionRooms(isPolling?: boolean): Observable<IPage<IAuctionRoom>> {
    const filter: IAuctionRoomFilter = {
      limit: 20,
      offset: 0,
    };

    return this.cosClient.requestWithPrivileges(
      'get',
      `/auction-room/buyer/?filter=${this.cosClient.encodeParamObject(filter)}`,
      undefined,
      this.getPollingHeaders(isPolling),
    );
  }

  public getCockpitActivityCardData(): Observable<IBuyerActivationTasks> {
    return this.authenticatedRequestWithPrivileges<IBuyerActivationTasks>('get', userId => `/profile/salesman/${userId}/activation-tasks`);
  }

  public getAuctionPaymentAmount(auctionUuid: string): Observable<IAuctionPaymentAmount> {
    return this.cosClient.requestWithPrivileges('get', `/buyer/payment/auction/${auctionUuid}/payment-amount`);
  }

  public getBuyNowPayLaterFeeAmount(auctionUuid: string): Observable<IBuyNowPayLaterAmount> {
    return this.cosClient.requestWithPrivileges('get', `/buyer/payment/auction/${auctionUuid}/buy-now-pay-later/fee`);
  }

  public getRunningAuctionBNPLPaymentAmount(auctionUuid: string): Observable<IBuyNowPayLaterEstimate> {
    return this.cosClient.requestWithPrivileges('get', `/buyer/payment/auction/${auctionUuid}/buy-now-pay-later/estimate`);
  }

  public createBuyNowPayLaterSessionWithBillingData(auctionUuid: string): Observable<IBuyNowPayLaterSessionWithBillingData> {
    return this.cosClient.requestWithPrivileges('post', `/buyer/payment/auction/${auctionUuid}/buy-now-pay-later/session`);
  }

  public confirmBuyNowPayLaterSession(
    auctionUuid: string,
    bnplOrderCreationRequest: IBuyNowPayLaterCreateOrderClientRequest,
  ): Observable<IBuyNowPayLaterOrder> {
    return this.cosClient.requestWithPrivileges('post', `/buyer/payment/auction/${auctionUuid}/buy-now-pay-later/confirm-session`, bnplOrderCreationRequest);
  }

  public createBuyNowPayLaterOrder(auctionUuid: string): Observable<IBuyNowPayLaterOrder> {
    return this.cosClient.requestWithPrivileges('post', `/buyer/payment/auction/${auctionUuid}/buy-now-pay-later/order`);
  }

  public getAuctionCounts(filter: IAuctionFilter, types: EAuctionListType[]): Observable<IResultMap<number>> {
    return this.cosClient.requestWithPrivileges(
      'get',
      `/auction/salesman/counts?${types.map(t => `types=${t}`).join('&')}&filter=${this.cosClient.encodeParamObject(filter)}`,
    );
  }

  public getBuyNowPayLaterBalance(): Observable<IBuyNowPayLaterBalanceWithLabel> {
    return this.cosClient.requestWithPrivileges('get', `/buyer/payment/buy-now-pay-later/balance`);
  }

  public getBuyNowPayLaterStatus(): Observable<{ enabled: boolean }> {
    return this.cosClient.requestWithPrivileges('get', `/buyer/payment/buy-now-pay-later/status`);
  }

  public submitSupportRequestForm(requestForm: ICreateUserRequest): Observable<IUserRequest> {
    return this.cosClient.requestWithPrivileges('post', `/users/user-request`, requestForm);
  }

  public createPaymentLinkForAuction(auctionUuid: string): Observable<IPaymentLinkCreationResponse> {
    return this.cosClient.requestWithPrivileges('post', `/buyer/payment/auction/${auctionUuid}/payment-links`);
  }

  private omitOffsetAndLimitFromAuctionFilter(auctionFilter: IAuctionFilter): Omit<IAuctionFilter, 'offset' | 'limit'> | undefined {
    if (!auctionFilter) {
      return;
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { offset, limit, ...filter } = auctionFilter;
    return filter;
  }

  protected cacheableAuthenticatedRequestWithPrivileges<T>(
    method: 'post' | 'get' | 'put' | 'delete' | 'patch',
    pathBuilder: (userId: string, internalUserUUID: string) => string,
    body?: any,
    headersAndOptions?: Record<string, string>,
    errorHAndlers?: ErrorHandlers,
  ): Observable<T> {
    const { userId, internalUserUUID } = this.cosClient.getLastAuthenticationResult();

    if (!userId) {
      return throwError(() => ({ status: 401 }));
    }

    const url = pathBuilder(userId, internalUserUUID);

    if (!this.cachedObservable[url]) {
      this.cachedObservable[url] = this.cosClient.requestWithPrivileges(method, url, body, headersAndOptions, errorHAndlers).pipe(
        share(),
        finalize(() => {
          delete this.cachedObservable[url];
        }),
      );
    }
    return this.cachedObservable[url];
  }

  protected authenticatedRequestWithPrivileges<T>(
    method: 'post' | 'get' | 'put' | 'delete' | 'patch',
    pathBuilder: (userId: string, internalUserUUID: string) => string,
    body?: any,
    headersAndOptions?: Record<string, string>,
    errorHAndlers?: ErrorHandlers,
  ): Observable<T> {
    const { userId, internalUserUUID } = this.cosClient.getLastAuthenticationResult();

    if (!userId) {
      return throwError(() => ({ status: 401 }));
    }

    return this.cosClient.requestWithPrivileges(method, pathBuilder(userId, internalUserUUID), body, headersAndOptions, errorHAndlers);
  }

  protected authenticatedV2RequestWithPrivileges<T>(
    method: 'post' | 'get' | 'put' | 'delete' | 'patch',
    pathBuilder: (userId: string, internalUserUUID?: string) => string,
    body?: any,
    headersAndOptions?: Record<string, string>,
    errorHAndlers?: ErrorHandlers,
  ): Observable<T> {
    const { userId, internalUserUUID } = this.cosClient.getLastAuthenticationResult();

    if (!userId) {
      return throwError(() => ({ status: 401 }));
    }

    return this.cosClient.requestV2WithPrivileges(method, pathBuilder(userId, internalUserUUID), body, headersAndOptions, errorHAndlers);
  }

  private getPollingHeaders(isPolling: boolean): Record<string, string> {
    return isPolling ? { polling: 'true' } : undefined;
  }

  public trackComplainFormStarted(auctionUuid: string): Observable<{ message: string }> {
    return this.cosClient.requestWithPrivileges('post', `/auction/salesman/complaints/tracking/complaint-started `, { auctionUuid });
  }

  public getNotificatioPreferencesByUserId(userId: string, errorHandlers?: ErrorHandlers): Observable<{ config: Record<string, any> }> {
    return this.cosClient.requestWithPrivileges('get', `/notification-preferences/${userId}/BUYER`, undefined, undefined, errorHandlers);
  }

  public updateNotificatioPreferencesByUserId(userId: string, updatedPreferences, errorHandlers?: ErrorHandlers): Observable<any> {
    return this.cosClient.requestWithPrivileges('patch', `/notification-preferences/${userId}/BUYER`, updatedPreferences, undefined, errorHandlers);
  }

  public getPurchasePreferences(errorHandlers?: ErrorHandlers): Observable<IPurchasePreferences> {
    return this.cosClient.requestWithPrivileges('get', `/purchase-preferences`, undefined, undefined, errorHandlers);
  }

  public createPurchasePreferences(preferences: IPurchasePreferences): Observable<void> {
    return this.cosClient.requestWithPrivileges('post', '/purchase-preferences', preferences);
  }

  public updatePurchasePreferences(preferences: IPurchasePreferences): Observable<void> {
    return this.cosClient.requestWithPrivileges('patch', '/purchase-preferences', preferences);
  }
}
