<ng-container *ngIf="auction">
  <ng-container *ngIf="(auction.paymentProcess | isCosPay) && auction.paymentState">
    <div class="text-bold" matTooltip="{{ auction.paymentState | sellerAuctionPaymentStateDetails }}">{{ auctionPaymentState | translate }}</div>

    <div *ngIf="auction.targetSellerBankAccount" class="text-grey" matTooltip="{{ 'payment.money-arrives-at-account' | translate }}">
      {{ auction.targetSellerBankAccount.iban }}
    </div>

    <img matTooltip="{{ 'payment.securely-via-cospay' | translate }}" src="/assets/logo/cos-pay/cos-pay.png" />
  </ng-container>

  <div *ngIf="!(auction.paymentProcess | isCosPay)">
    <span *ngIf="auction.isPaidByBuyer" class="text-success"> {{ 'payment.seller.buyer-confirmed-transfer' | translate }} </span>
    <span *ngIf="!auction.isPaidByBuyer" class="text-warn"> {{ 'payment.seller.buyer-not-confirmed-transfer' | translate }} </span>
  </div>
</ng-container>
