import { Component } from '@angular/core';

import { BaseModule } from '@cosCoreComponentsGeneral/base.module';
import { EnzoComponentsModule } from '@caronsale/enzo-angular';
import { CosPopoverModule } from '@cosCoreFeatures/@cos/cos-popover/cos-popover.module';

@Component({
  standalone: true,
  imports: [BaseModule, EnzoComponentsModule, CosPopoverModule],
  selector: 'app-live-auction-info',
  templateUrl: './live-auction-info.component.html',
  styleUrls: ['./live-auction-info.component.scss'],
})
export class LiveAuctionInfoComponent {}
