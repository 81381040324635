import { Injectable } from '@angular/core';
import { Subject, map } from 'rxjs';

import { createStore, emitOnce, select, setProp, withProps } from '@ngneat/elf';
import { persistState, localStorageStrategy, excludeKeys } from '@ngneat/elf-persist-state';
import {
  addEntities,
  deleteEntities,
  getEntity,
  setEntities,
  upsertEntities,
  withEntities,
  withActiveId,
  resetActiveId,
  selectActiveEntity,
  setActiveId,
  getActiveEntity,
} from '@ngneat/elf-entities';
import {
  withPagination,
  selectCurrentPageEntities,
  updatePaginationData,
  setPage,
  selectPaginationData,
  getPaginationData,
  setCurrentPage,
  PaginationData,
  PaginationState,
} from '@ngneat/elf-pagination';

import {
  EInspectionRequestState,
  IVehicleInspectionRequest,
  IVehicleInspectionRequestView,
  IInspectionRequestCountByState,
  IInspectionRequestFilter,
  IPageIVehicleInspectionRequestView,
  EInspectionAppointmentStatus,
  IInspectionAppointmentCountByState,
  IInspectionAppointmentFilter,
} from '@cosTypes';
import { EMessageCode, Time } from '@caronsale/cos-models';

export const INSPECTIONS_PAGE_SIZE = 11;

export enum EInspectionRequestSteps {
  APPOINTMENT = 1,
  DETAILS = 2,
  ASSESSMENT = 3,
}

export enum ERequestInputMethod {
  MANUAL = 'MANUAL',
  SHEET = 'SHEET',
}

export type vehicleValidationError = EMessageCode.INSPECTION_REQUEST_VIN_ALREADY_AUCTIONED | EMessageCode.INSPECTION_REQUEST_VIN_ALREADY_BOOKED;

export type MergedFilters = IInspectionRequestFilter & IInspectionAppointmentFilter;

export type InspectionRequestStore = {
  vehicleInspectionRequest: IVehicleInspectionRequest;
  currentStep: EInspectionRequestSteps;
  inputMethod: ERequestInputMethod;
  availableMakes: string[];
  buildingRequest: boolean;
  loadingRequest: boolean;
  loadingDraft: boolean;
  vehicleErrors: vehicleValidationError[];
  stateSums: Partial<IInspectionRequestCountByState & IInspectionAppointmentCountByState>;
  filter: MergedFilters;
  cachedOn: Date;
};

@Injectable({ providedIn: 'root' })
// TODO: remove providedIn root when subroutes are created between MRP requests and Inspection requests
export class InspectionRequestRepository {
  public store = createStore(
    { name: 'inspectionRequest' },
    withActiveId(),
    withPagination(),
    withEntities<IVehicleInspectionRequestView, 'uuid'>({ idKey: 'uuid' }),
    withProps<InspectionRequestStore>({
      vehicleInspectionRequest: {
        numberOfVehicles: 0,
        userHasRegularAppointment: true,
        desiredDateRangeStart: null,
        desiredDateRangeEnd: null,
        _fk_uuid_address: null,
        vehicles: [],
        uuid: null,
      },
      currentStep: EInspectionRequestSteps.APPOINTMENT,
      inputMethod: null,
      availableMakes: [],
      buildingRequest: true,
      loadingRequest: false,
      loadingDraft: false,
      vehicleErrors: [],
      stateSums: {},
      filter: { limit: INSPECTIONS_PAGE_SIZE, userUuids: [], states: [] },
      cachedOn: null,
    }),
  );

  public persist = persistState(this.store, {
    storage: localStorageStrategy,
    source: store =>
      store.pipe(
        excludeKeys(['buildingRequest', 'loadingRequest', 'loadingDraft']),
        map(store => ({ ...store, cachedOn: new Date() })),
      ),

    preStoreInit: store => {
      const cacheDaysLimit = 9;
      if (Time.getDaysBetween(store.cachedOn, new Date()) > cacheDaysLimit) {
        return {
          ...store,
          inputMethod: null,
          vehicleErrors: [],
          currentStep: EInspectionRequestSteps.APPOINTMENT,
          vehicleInspectionRequest: {
            userHasRegularAppointment: true,
            desiredDateRangeStart: null,
            desiredDateRangeEnd: null,
            _fk_uuid_address: null,
            numberOfVehicles: 0,
            vehicles: [],
            uuid: null,
          },
        };
      }

      return store;
    },
  });

  public readonly initialized$ = this.persist.initialized$.pipe(
    map(() => {
      const { vehicleInspectionRequest, inputMethod } = this.store.getValue();
      return { vehicleInspectionRequest, inputMethod };
    }),
  );
  public readonly currentStep$ = this.store.pipe(select(state => state.currentStep));
  public readonly numberOfVehicles$ = this.store.pipe(select(state => state.vehicleInspectionRequest.numberOfVehicles));
  public readonly availableMakes$ = this.store.pipe(select(state => state.availableMakes));
  public readonly buildingRequest$ = this.store.pipe(select(state => state.buildingRequest));
  public readonly loadingRequest$ = this.store.pipe(select(state => state.loadingRequest));
  public readonly loadingDraft$ = this.store.pipe(select(state => state.loadingDraft));
  public readonly vehicleErrors$ = this.store.pipe(select(state => state.vehicleErrors));
  public readonly stateSums$ = this.store.pipe(select(state => state.stateSums));
  public readonly filter$ = this.store.pipe(select(state => state.filter));
  public readonly activeRequestedInspection$ = this.store.pipe(selectActiveEntity());
  public readonly requestedInspections$ = this.store.pipe(selectCurrentPageEntities());
  public readonly paginationData$ = this.store.pipe(selectPaginationData());
  public resetPageFetch$ = new Subject<void>(); // TODO: remove these whenever the price and inspection request pages share a subroute

  public setRequestedInspections(requestedInspections: IVehicleInspectionRequestView[]): void {
    this.store.update(setEntities(requestedInspections));
  }

  public addRequestedInspection(requestedInspection: IVehicleInspectionRequestView): void {
    this.store.update(addEntities(requestedInspection, { prepend: true }));
  }

  public getRequestedInspection(inspectionUuid: string): IVehicleInspectionRequestView {
    return this.store.query(getEntity(inspectionUuid));
  }

  public upsertRequestedInspection(requestedInspection: IVehicleInspectionRequestView): void {
    this.store.update(upsertEntities(requestedInspection, { prepend: true }));
  }

  public deleteRequestedInspection(inspectionUuid: string): void {
    this.store.update(deleteEntities(inspectionUuid));
  }

  public setActiveRequestedInspection(inspectionUuid: string): void {
    this.store.update(setActiveId(inspectionUuid));
  }

  public getActiveRequestedInspection(): IVehicleInspectionRequestView {
    return this.store.query(getActiveEntity());
  }

  public resetActiveRequestedInspection(): void {
    this.store.update(resetActiveId());
  }

  public setRequestedInspectionsPage(response: IPageIVehicleInspectionRequestView): void {
    this.store.update(
      upsertEntities(response.items),
      updatePaginationData({
        currentPage: response.page,
        perPage: INSPECTIONS_PAGE_SIZE,
        total: response.total,
        lastPage: Math.ceil(response.total / INSPECTIONS_PAGE_SIZE),
      }),
      setPage(
        response.page,
        response.items.map(i => i.uuid),
      ),
    );
  }

  public setCurrentPage(page: number): void {
    this.store.update(setCurrentPage(page));
  }

  public getPaginationData(): PaginationData<number> {
    return this.store.query(getPaginationData());
  }

  public updateInspectionRequest(params: IVehicleInspectionRequest): void {
    this.store.update(setProp('vehicleInspectionRequest', params));
  }

  public updateStep(currentStep: EInspectionRequestSteps): void {
    this.store.update(setProp('currentStep', currentStep));
  }

  public updateInputMethod(inputMethod: ERequestInputMethod): void {
    this.store.update(setProp('inputMethod', inputMethod));
  }

  public updateAvailableMakes(availableMakes: string[]): void {
    this.store.update(setProp('availableMakes', availableMakes));
  }

  public updateBuildingRequest(buildingRequest: boolean): void {
    this.store.update(setProp('buildingRequest', buildingRequest));
  }

  public updateLoadingRequest(loadingRequest: boolean): void {
    this.store.update(setProp('loadingRequest', loadingRequest));
  }

  public updateLoadingDraft(loadingDraft: boolean): void {
    this.store.update(setProp('loadingDraft', loadingDraft));
  }

  public updateVehicleErrors(vehicleErrors: vehicleValidationError[]): void {
    this.store.update(setProp('vehicleErrors', vehicleErrors));
  }

  public removeVehicleError(vehicleIndex: number): void {
    this.store.update(
      setProp('vehicleErrors', vehicleErrors => {
        vehicleErrors.splice(vehicleIndex, 1);
        return vehicleErrors;
      }),
    );
  }

  public resetInspectionState(): void {
    const resetValues = {
      userHasRegularAppointment: true,
      desiredDateRangeStart: null,
      desiredDateRangeEnd: null,
      _fk_uuid_address: null,
      numberOfVehicles: 0,
      vehicles: [],
      uuid: null,
    };

    emitOnce(() => {
      this.updateVehicleErrors([]);
      this.updateLoadingRequest(false);
      this.updateStep(EInspectionRequestSteps.APPOINTMENT);
      this.updateInputMethod(null);
      this.updateInspectionRequest(resetValues);
      this.resetActiveRequestedInspection();
    });
  }

  public updateStateSum(updatedStateSums: Partial<IInspectionRequestCountByState & IInspectionAppointmentCountByState>): void {
    this.store.update(setProp('stateSums', stateSums => ({ ...stateSums, ...updatedStateSums })));
  }

  public updateUserFilter(userUuids: string[]): void {
    this.store.update(setProp('filter', filter => ({ ...filter, userUuids })));
  }

  public updateStateFilter(states: (EInspectionRequestState | EInspectionAppointmentStatus)[]): void {
    this.store.update(setProp('filter', filter => ({ ...filter, states: states as MergedFilters['states'] })));
  }

  public resetFilters(): void {
    this.store.update(setProp('filter', { limit: INSPECTIONS_PAGE_SIZE, userUuids: [], states: [] }));
  }

  public currentValue(): InspectionRequestStore & { entities: Record<string, IVehicleInspectionRequestView>; ids: string[] } & PaginationState<number> & {
      activeId: string;
    } {
    return this.store.getValue();
  }

  public reset(): void {
    this.store.reset();
  }
}
