import { Validation } from '@caronsale/cos-models';

export function removeEmptyValuesFromObject(obj: Record<string, any>): Record<string, any> {
  if (typeof obj !== 'object') {
    return null;
  }

  const isEmptyArray = (value: any) => Array.isArray(value) && value.length === 0;
  const isEmptyObject = (value: any) => typeof value === 'object' && Object.keys(value).length === 0;

  return Object.fromEntries(
    Object.entries(obj).filter(([_, value]) => !Validation.isUndefinedNullOrEmptyString(value) && !isEmptyArray(value) && !isEmptyObject(value)),
  );
}

// examples:
// createNumberRangeArray(1, 10) returns [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
// createNumberRangeArray(1, 10, 5) returns [1, 6]
export function createNumberRangeArray(from: number, to: number, _step?: number): number[] {
  const direction = Math.sign(to - from) || 1;
  // default and sanitize the step: change 0/null/undefined to 1, remove the sign (Math.abs) and add it again
  const step = Math.abs(_step || 1) * direction;
  const length = Math.floor((to - from) / step) + 1;
  return Array.from({ length }, (_, index) => from + index * step);
}

// return a new array with the last n elements from the given array
export function getLastNElementsFromArray(arr: number[], n: number) {
  if (!arr?.length || n === 0) {
    return [];
  }
  if (n >= arr.length) {
    return [...arr];
  }
  return arr.slice(arr.length - n, arr.length);
}

export function enumToArray<T>(enumClass, lastElement?: T): T[] {
  const values: T[] = Object.keys(enumClass)
    .filter(type => isNaN(type as any))
    .map(type => enumClass[type]);
  if (lastElement) {
    values.splice(values.indexOf(lastElement), 1);
    values.push(lastElement);
  }
  return values;
}

/**
 * Rounds a number to a given number of decimals
 * @param value The value you want to round
 * @param decimals How many decimals you want to round to
 * @returns The rounded value
 *
 * @example
 * roundNumber(1.234, 2) // returns 1.23
 * roundNumber(1.235, 2) // returns 1.24
 */
export const roundNumber = (value: number, decimals: number): number => {
  return Number(Math.round(Number(value + 'e' + decimals)) + 'e-' + decimals);
};
