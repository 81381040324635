// Angular
import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { BehaviorSubject, Observable, of, Subject, throwError, catchError, finalize, switchMap, takeUntil, tap } from 'rxjs';

import { I18nCurrencyPipe } from '@caronsale/frontend-pipes';
import { IAccountAddress, IBuyerAuctionView, ITransportationTask, Time, Validation } from '@caronsale/cos-models';

// CoS
import { I18nConfirmationDialogComponent } from '@cosCoreComponentsGeneral/i18n/confirmation-dialog/i18n-confirmation-dialog.component';
import { I18nInfoDialogComponent } from '@cosCoreComponentsGeneral/i18n/info-dialog/i18n-info-dialog.component';
import { BuyerTransportationService, ETransportationAvailability } from '@cosCoreServices/buyer-transportation/buyer-transportation.service';
import { CosBuyerClientService } from '@cosCoreServices/cos-salesman-client/cos-buyer-client.service';
import { UserAddressService } from '@cosCoreServices/user-address/user-address.service';
import {
  IUserSelectAddressModalData,
  IUserSelectAddressModalTranslationParams,
  UserSelectAddressModalComponent,
} from '@cosCoreComponents/address-management/user-select-address-modal/user-select-address-modal.component';
import { EEnzoDialogResult, EnzoDialogService } from '@cosCoreComponents/modal-dialogs/enzo-dialog.service';

@Component({
  selector: 'app-transportation-button',
  templateUrl: './transportation-button.component.html',
  styleUrls: ['./transportation-button.component.scss'],
})
export class TransportationButtonComponent implements OnChanges, OnDestroy {
  @Input()
  public auction: IBuyerAuctionView;

  @Input()
  public showBookedLabel = true;

  // the buyer-detail-view has a transportation info that shows if transport is not available
  // only the purchased auction card does not have any status info about the transport, so it sets this flag.
  @Input()
  public showIfNotAvailable: boolean = false;

  @Output()
  public requestAuctionUpdate: EventEmitter<void> = new EventEmitter<void>();

  public transportationRequestLoading = false;
  public isDiscountAvailable: boolean;
  public discountAvailableUntil: Date = null;
  public transportationAvailability: ETransportationAvailability = ETransportationAvailability.NOT_AVAILABLE;
  public ETransportationAvailability = ETransportationAvailability;

  private unsubscribe$: Subject<void> = new Subject<void>();

  public constructor(
    private addressSvc: UserAddressService,
    private dialog: MatDialog,
    private buyerTransportationService: BuyerTransportationService,
    private cosBuyerClient: CosBuyerClientService,
    private i18nCurrencyPipe: I18nCurrencyPipe,
    private enzoDialogService: EnzoDialogService,
  ) {}

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.auction) {
      // initially and due to our auctionUpdate request
      this.transportationRequestLoading = false;

      if (Validation.isUndefinedOrNull(this.auction.transportationTask)) {
        this.isDiscountAvailable = false;
        this.discountAvailableUntil = null;
      } else {
        this.isDiscountAvailable = this.buyerTransportationService.doesDiscountApply(this.auction);
        this.discountAvailableUntil = Time.getDateXDaysFromDate(new Date(this.auction.purchaseConfirmedAt), 1);
      }

      this.transportationAvailability = this.buyerTransportationService.getTransportationAvailability(this.auction);
    }
  }

  public ngOnDestroy(): void {
    this.unsubscribe$.next();
  }

  public get discount(): number {
    return 1 - this.auction.transportationTask.discountedNetPrice / this.auction.transportationTask.netPrice;
  }

  public requestTransportPrice($event: Event, auction: IBuyerAuctionView): void {
    $event.stopPropagation();
    I18nConfirmationDialogComponent.showConfirmDialog(this.dialog, 'buyer.transport-button').subscribe(() => {
      this.transportationRequestLoading = true;
      this.cosBuyerClient
        .requestTransportationForAuction(auction)
        .pipe(
          takeUntil(this.unsubscribe$),
          finalize(() => {
            // refresh even on error (since the error might be because we have an outdated version of the auction)
            // ngOnChanges will reset the loading flag when the updated auction arrives
            this.requestAuctionUpdate.emit();
          }),
        )
        .subscribe();
    });
  }

  public editDeliveryAddress() {
    this.confirmTransportation(this.auction, true);
  }

  public confirmTransportation(auction: IBuyerAuctionView, reschedule: boolean = false): void {
    let errorData: {
      msgKey: string;
      params?: any;
    };
    const netPrice = this.buyerTransportationService.doesDiscountApply(auction)
      ? auction.transportationTask.discountedNetPrice || auction.transportationTask.netPrice
      : auction.transportationTask.netPrice;

    const formattedNetPrice: string = this.i18nCurrencyPipe.transform(netPrice, '€', 'EUR');

    if (!Validation.isAuctionClosedSuccessfully(auction)) {
      I18nInfoDialogComponent.show(this.dialog, 'dialog.buyer.transport-booking-not-yet-possible', { price: formattedNetPrice });
      return;
    }

    const translationParams$ = new BehaviorSubject<IUserSelectAddressModalTranslationParams>({
      interpolationParams: { transportPrice: formattedNetPrice },
    } as IUserSelectAddressModalTranslationParams);
    const addressSelected$ = new Subject<IAccountAddress>();
    let selectedAddress: IAccountAddress;

    addressSelected$
      .pipe(
        tap(address => (selectedAddress = address)),
        switchMap(selectedAddress =>
          this.getPriceServiceCall(reschedule, auction, selectedAddress).pipe(
            catchError(err => {
              errorData = err.error;
              return of({ netPrice: -1 });
            }),
          ),
        ),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((rcvd: Partial<ITransportationTask>) => {
        if (rcvd.netPrice < 0) {
          translationParams$.next({
            errorKey: errorData.msgKey,
            errorParams: errorData.params,
          } as IUserSelectAddressModalTranslationParams);
        } else {
          translationParams$.next({
            interpolationParams: { transportPrice: this.i18nCurrencyPipe.transform(rcvd.netPrice, '€', 'EUR') },
          } as IUserSelectAddressModalTranslationParams);
        }
      });

    const originalAddress = this.getAddressFromTransportationTask(auction.transportationTask);
    this.addressSvc
      .getAddresses()
      .pipe(
        switchMap((rcvdAddresses: IAccountAddress[]) => {
          return this.enzoDialogService
            .openModal<EEnzoDialogResult, IUserSelectAddressModalData, UserSelectAddressModalComponent>(UserSelectAddressModalComponent, {
              width: '480px',
              data: {
                addresses: rcvdAddresses,
                addressSelected$: addressSelected$,
                currentlySelectedAddress: originalAddress,
                keyBase: 'dialog.buyer.select-address-then-confirm-transport-booking',
                translationParams$: translationParams$.asObservable(),
                isBooked: false,
              } as IUserSelectAddressModalData,
            })
            .pipe(
              switchMap(result => {
                this.transportationRequestLoading = true;
                if (result === EEnzoDialogResult.CONFIRM && this.shouldRrescheduleTransportation(originalAddress, selectedAddress, reschedule)) {
                  return this.getTransportConfirmationServiceCall(reschedule, auction, selectedAddress);
                } else {
                  throwError(() => new Error('NoTransportAuthorization'));
                }
              }),
            );
        }),

        takeUntil(this.unsubscribe$),
      )
      .subscribe({
        // refresh only on next (i.e. success), not in finalize
        next: () => this.requestAuctionUpdate.emit(), // keep the loading flag active (ngOnChanges will reset it)
        error: () => (this.transportationRequestLoading = false),
      });
  }

  public showTransportationRequestedInfo($event: Event): void {
    $event.stopPropagation();
    I18nInfoDialogComponent.show(this.dialog, 'dialog.buyer.transport-requested');
  }

  public shouldDisplayDiscountCountdown(): boolean {
    const isDateInTheFuture = new Date() < this.discountAvailableUntil;
    return isDateInTheFuture && Validation.isAuctionClosedSuccessfully(this.auction);
  }

  public isVehicleReauctioned(): boolean {
    return this.auction.remainingDaysUntilReauctioning === 0;
  }

  private getPriceServiceCall(reschedule: boolean, auction: IBuyerAuctionView, selectedAddress: IAccountAddress): Observable<ITransportationTask> {
    if (reschedule) {
      return this.cosBuyerClient.updateTransportationDropOffAddressForAuction(auction.uuid, auction.transportationTask.uuid, false, selectedAddress);
    }

    return this.cosBuyerClient.requestTransportationForAuction(auction, selectedAddress);
  }

  private getTransportConfirmationServiceCall(
    reschedule: boolean,
    auction: IBuyerAuctionView,
    selectedAddress: IAccountAddress,
  ): Observable<ITransportationTask | void> {
    if (reschedule) {
      return this.cosBuyerClient.updateTransportationDropOffAddressForAuction(auction.uuid, auction.transportationTask.uuid, true, selectedAddress);
    }

    return this.cosBuyerClient.confirmTransportationForAuction(auction);
  }

  private isDifferentAddress(newAddress: IAccountAddress, originalAddress: Partial<IAccountAddress>): boolean {
    return (
      newAddress.countryCode !== originalAddress.countryCode ||
      newAddress.city !== originalAddress.city ||
      newAddress.addressLine !== originalAddress.addressLine ||
      newAddress.zipCode !== originalAddress.zipCode
    );
  }

  private getAddressFromTransportationTask(transportationTask: ITransportationTask): Partial<IAccountAddress> {
    return {
      addressLine: transportationTask.toLocationAddressLine,
      city: transportationTask.toLocationCity,
      countryCode: transportationTask.toLocationCountryCode,
      zipCode: transportationTask.toLocationZipCode,
    };
  }

  private shouldRrescheduleTransportation(originalAddress: Partial<IAccountAddress>, selectedAddress: IAccountAddress, reschedule: boolean): boolean {
    if (!reschedule) {
      return true;
    }

    return this.isDifferentAddress(selectedAddress, originalAddress);
  }
}
