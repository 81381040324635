import { NgModule } from '@angular/core';

import { AuctionVatComponent } from './auction-vat.component';
import { EnzoComponentsModule } from '@caronsale/enzo-angular';
import { AuctionPipesModule, GeneralPipesModule, I18nAuctionPipesModule } from '@caronsale/frontend-pipes';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [AuctionVatComponent],
  exports: [AuctionVatComponent],
  imports: [
    //
    AuctionPipesModule,
    CommonModule,
    EnzoComponentsModule,
    GeneralPipesModule,
    TranslateModule,
    I18nAuctionPipesModule,
  ],
})
export class AuctionVatModule {}
