import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CrossBorderFeeOrDiscountService {
  public getCrossBorderTaxFeeAndDiscount(amount: number): {
    crossBorderTaxFee: number;
    crossBorderTaxDiscount: number;
  } {
    return {
      crossBorderTaxFee: this.isFee(amount) ? Math.abs(amount) : 0,
      crossBorderTaxDiscount: !this.isFee(amount) ? amount : 0,
    };
  }

  private isFee(amount: number): boolean {
    return amount < 0;
  }
}
