const MINIMAL_BID_VALUE_INCREMENT_THRESHOLD = 5000;
const MINIMAL_BID_INCREMENT_BELOW_THRESHOLD = 50;
const MINIMAL_BID_INCREMENT_ABOVE_THRESHOLD = 100;

export function minimalBidIncrement(currentBidValue: number) {
  return (currentBidValue ?? 1) < MINIMAL_BID_VALUE_INCREMENT_THRESHOLD ? MINIMAL_BID_INCREMENT_BELOW_THRESHOLD : MINIMAL_BID_INCREMENT_ABOVE_THRESHOLD;
}

export function remainingBids(currentBidValue: number, targetValue: number): number {
  let difference: number;

  const isTargetAboveThreshold: boolean = targetValue > MINIMAL_BID_VALUE_INCREMENT_THRESHOLD;
  const isCurrentBidAboveThreshold: boolean = currentBidValue > MINIMAL_BID_VALUE_INCREMENT_THRESHOLD;

  if (isCurrentBidAboveThreshold) {
    // if the currentBid is already above threshold, all bids are "above threshold" bids
    difference = Math.ceil((targetValue - currentBidValue) / MINIMAL_BID_INCREMENT_ABOVE_THRESHOLD);
  } else if (isTargetAboveThreshold) {
    // currentBid is under, target is above threshold
    const bidsUntilThreshold = Math.ceil((MINIMAL_BID_VALUE_INCREMENT_THRESHOLD - currentBidValue) / MINIMAL_BID_INCREMENT_BELOW_THRESHOLD);
    const bidsAboveThreshold = Math.ceil((targetValue - MINIMAL_BID_VALUE_INCREMENT_THRESHOLD) / MINIMAL_BID_INCREMENT_ABOVE_THRESHOLD);
    difference = bidsUntilThreshold + bidsAboveThreshold;
  } else {
    // currentBid is under threshold, target is under threshold => only "below threshold" bids
    difference = Math.ceil((targetValue - currentBidValue) / MINIMAL_BID_INCREMENT_BELOW_THRESHOLD);
  }

  return Math.max(1, difference);
}
