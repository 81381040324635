<table mat-table [dataSource]="dataSource">
  <ng-container matColumnDef="buyerName">
    <th mat-header-cell *matHeaderCellDef>{{ 'auction.bidder' | translate }}</th>
    <td mat-cell *matCellDef="let element">{{ element.buyerName }}</td>
  </ng-container>

  <ng-container matColumnDef="bidValue">
    <th mat-header-cell *matHeaderCellDef>{{ 'auction.bid-value' | translate }}</th>
    <td mat-cell *matCellDef="let element">{{ element.value | currencyEuro }}</td>
  </ng-container>

  <ng-container matColumnDef="bidTime">
    <th mat-header-cell *matHeaderCellDef>{{ 'auction.bid-time' | translate }}</th>
    <td mat-cell *matCellDef="let element">
      {{ element.placedAt | date: 'dd.MM.yy (HH:mm)' }}
      <div *ngIf="element.triggeringBuyerName" class="text-grey">[{{ 'auction.bid-triggered-text' | translate }} {{ element.triggeringBuyerName }}]</div>
    </td>
  </ng-container>

  <ng-container matColumnDef="skeleton">
    <td class="skeleton" *matCellDef>
      <ngx-skeleton-loader></ngx-skeleton-loader>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="dataColumns; sticky: true"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
</table>
