import { Component, Input } from '@angular/core';
import { IBuyerAuctionView } from '@caronsale/cos-models';

@Component({
  selector: 'app-invoice-download',
  templateUrl: './invoice-download.component.html',
  styleUrls: ['./invoice-download.component.scss'],
})
export class InvoiceDownloadComponent {
  @Input()
  public auction: IBuyerAuctionView;
}
