import { Component, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { IAuction } from '@caronsale/cos-models';

export interface IVehicleReportTranslateModalData {
  auction: IAuction;
  reportUrl: string;
}

@Component({
  selector: 'app-vehicle-report-translated-modal',
  templateUrl: './vehicle-report-translated-modal.component.html',
  styleUrls: ['./vehicle-report-translated-modal.component.scss'],
})
export class VehicleReportTranslatedModalComponent {
  public constructor(
    public dialogRef: MatDialogRef<VehicleReportTranslatedModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IVehicleReportTranslateModalData,
  ) {}

  public downloadAndClose(): void {
    window.open(this.data.reportUrl, '_blank');
    this.dialogRef.close();
  }
}
