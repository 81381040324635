<enzo-headline level="6" [balance]="false"> {{ 'prebooked-service.buy-now-pay-later.confirmation-modal-title' | translate }} </enzo-headline>
<enzo-text
  kind="label"
  balance="true"
  balanceRatio="0.8"
  class="content-description"
  [innerHTML]="'prebooked-service.buy-now-pay-later.confirmation-modal-description' | translate: { totalAmount: totalAmount }">
</enzo-text>
<div class="confirmation">
  <mat-checkbox [formControl]="confirmationInput"> </mat-checkbox>
  <div class="confirmation-text">
    <enzo-text
      kind="label"
      color="grey-ultradark"
      [innerHTML]="'prebooked-service.buy-now-pay-later.confirmation-text.message' | translate: { messageTemplate: confirmationTemplate }">
    </enzo-text>
    <enzo-text class="error-message" color="red-dark" kind="caption" *ngIf="confirmationInput.invalid && isConfirmedAndInvalid" testid="error-message">
      {{ 'error.terms-and-conditions-error' | translate }}
    </enzo-text>
  </div>
</div>
