import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { BuyerAuctionService } from '@cosCoreFeatures/auction-detail/common/auction-service/buyer-auction.service';
import intervalToDuration from 'date-fns/intervalToDuration';
import { addSeconds, Duration, setMilliseconds, isPast, isEqual, isTomorrow, isAfter, set, isToday } from 'date-fns';
import { CommonModule } from '@angular/common';
import { EnzoComponentsModule } from '@caronsale/enzo-angular';
import { TranslateModule } from '@ngx-translate/core';
import { ConfigService } from '@cosCoreServices/config/config.service';

const THRESHOLD_FOR_ABSOLUTE_DATE_IN_SECONDS = 10 * 60;
const ROUND_UP_AFTER_SECONDS = 30;

enum EEndingDateDisplayFormat {
  NOT_SLOTTED,
  TOMORROW,
  ABSOLUTE_ENDING_DATE,
  REMAINING_MINUTES_SECONDS,
  HOT_BID_PHASE,
  ZERO,
}

interface IEndingDateViewModel {
  duration: Duration;
  displayFormat: EEndingDateDisplayFormat;
  expirationDate: Date;
  endingAtDateKey: string;
  endingWithinTimeSpanKey: string;
}

@Component({
  selector: 'app-ending-date',
  templateUrl: './ending-date.component.html',
  styleUrls: ['./ending-date.component.scss'],
  imports: [CommonModule, EnzoComponentsModule, TranslateModule],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EndingDateComponent implements OnChanges {
  @Input()
  public remainingTimeInSeconds: number;

  @Input()
  public showAfterOverKey: string = 'auction.state.closed';

  @Input()
  public isInstantPurchase: boolean;

  public EEndingDateDisplayFormat = EEndingDateDisplayFormat;

  public vm$: ReplaySubject<IEndingDateViewModel> = new ReplaySubject<IEndingDateViewModel>();

  public constructor(
    private buyerAuctionService: BuyerAuctionService,
    private configService: ConfigService,
  ) {}

  public ngOnChanges() {
    const safeRemainingTimeInSeconds = this.remainingTimeInSeconds ?? 0;
    const state = this.getState(safeRemainingTimeInSeconds);

    const shouldRoundDate =
      state === EEndingDateDisplayFormat.ABSOLUTE_ENDING_DATE || state === EEndingDateDisplayFormat.NOT_SLOTTED || state === EEndingDateDisplayFormat.TOMORROW;

    let expirationDate: Date = addSeconds(new Date(), safeRemainingTimeInSeconds);

    expirationDate = shouldRoundDate ? this.roundUpToNextMinute(expirationDate) : expirationDate;

    const remainingTimeDuration: Duration = this.expirationDateToDuration(expirationDate);

    this.vm$.next({
      expirationDate,
      duration: remainingTimeDuration,
      displayFormat: state,
      endingAtDateKey: this.getEndingAtKey(state),
      endingWithinTimeSpanKey: this.isInstantPurchase ? 'remaining-time.instant-purchase-ending-in' : 'remaining-time.ending-in',
    });
  }

  private getState(remainingTimeInSeconds: number): EEndingDateDisplayFormat {
    if (remainingTimeInSeconds <= 0) {
      return EEndingDateDisplayFormat.ZERO;
    }
    const expirationDate: Date = addSeconds(new Date(), remainingTimeInSeconds);

    if (!this.isInstantPurchase && !this.isAuctionSlotted(expirationDate, this.configService.getConfig().DEFAULT_AUCTION_ENDING_HOUR)) {
      return EEndingDateDisplayFormat.NOT_SLOTTED;
    }

    if (isTomorrow(expirationDate)) {
      return EEndingDateDisplayFormat.TOMORROW;
    }

    if (remainingTimeInSeconds >= THRESHOLD_FOR_ABSOLUTE_DATE_IN_SECONDS) {
      return EEndingDateDisplayFormat.ABSOLUTE_ENDING_DATE;
    }

    if (this.buyerAuctionService.isHotBidPhaseActive(remainingTimeInSeconds)) {
      return EEndingDateDisplayFormat.HOT_BID_PHASE;
    }

    return EEndingDateDisplayFormat.REMAINING_MINUTES_SECONDS;
  }

  private expirationDateToDuration(expirationDate: Date): Duration {
    const now = setMilliseconds(new Date(), 0);
    const safeExpirationDate = setMilliseconds(expirationDate, 0);

    if (isPast(safeExpirationDate) || isEqual(now, safeExpirationDate)) {
      return {
        years: 0,
        months: 0,
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
      };
    }

    return intervalToDuration({
      start: now,
      end: safeExpirationDate,
    });
  }

  private isAuctionSlotted(expirationDate: Date, auctionEndingHour: string): boolean {
    if (isToday(expirationDate)) {
      return true;
    }

    const now = new Date();
    const todayAtFirstSlottingHour = set(now, { hours: Number(auctionEndingHour), minutes: 0, seconds: 0, milliseconds: 0 });
    const didTheSlottingAlgorithmRun = isAfter(now, todayAtFirstSlottingHour) || isEqual(now, todayAtFirstSlottingHour);

    if (isTomorrow(expirationDate) && didTheSlottingAlgorithmRun) {
      return true;
    }

    return false;
  }

  private getEndingAtKey(state: EEndingDateDisplayFormat): string {
    const isTomorrow = state === EEndingDateDisplayFormat.TOMORROW;
    const expirationDate: Date = addSeconds(new Date(), this.remainingTimeInSeconds ?? 0);

    // Check if the auction ends today
    if (isToday(expirationDate)) {
      return this.isInstantPurchase ? 'remaining-time.instant-purchase-ends-today-at' : 'remaining-time.ending-today-at';
    }

    if (this.isInstantPurchase && isTomorrow) {
      return 'remaining-time.instant-purchase-ends-tomorrow-at';
    }

    if (this.isInstantPurchase) {
      return 'remaining-time.instant-purchase-ending-at';
    }

    if (isTomorrow) {
      return 'remaining-time.tomorrow';
    }

    return 'remaining-time.ending-at';
  }

  private roundUpToNextMinute(expirationDate: Date): Date {
    const roundedDate = new Date(expirationDate);
    roundedDate.setMilliseconds(0);
    roundedDate.setSeconds(0);
    roundedDate.setMinutes(roundedDate.getMinutes() + 1);

    return expirationDate.getSeconds() > ROUND_UP_AFTER_SECONDS ? roundedDate : expirationDate;
  }
}
