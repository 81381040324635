<ng-container *ngIf="vm$ | async as vm">
  <div class="header">
    <enzo-headline level="2">{{ vm.auction.label }}</enzo-headline>
    <ng-container *ngTemplateOutlet="statusInfo"></ng-container>
  </div>
  <div class="vehicle-images-and-bid-info-container">
    <div class="vehicle-images-container">
      <div class="sub-headline">
        <div class="sub-vehicle-info">
          <enzo-flag [country]="vm.auction.locationCountryCode" [matTooltip]="vm.auction.locationCountryCode | i18nCountryCode | translate"></enzo-flag>
          <enzo-text kind="label" color="black-light" icon="dot" icon-size="small" icon-spacing="1">{{ vm.auction.associatedVehicle.ez }}</enzo-text>
          <enzo-text kind="label" color="black-light" icon="dot" icon-size="small" icon-spacing="1">
            {{ vm.auction.associatedVehicle.mileageInKm | mileage }}
          </enzo-text>
          <enzo-text kind="label" color="black-light" icon="dot" icon-size="small" icon-spacing="1">
            {{ vm.auction.associatedVehicle.fuelType | i18nVehicleFuelType | translate }}
          </enzo-text>
          <enzo-text kind="label" color="black-light" icon="dot" icon-size="small" icon-spacing="1">
            {{ vm.auction.associatedVehicle.transmission | i18nVehicleTransmission | translate }}
          </enzo-text>
          <enzo-text kind="label" color="black-light" icon="dot" icon-size="small" icon-spacing="1">
            {{ 'unit.power-x-hp' | translate: { value: vm.auction.associatedVehicle.enginePowerInHp } }}
          </enzo-text>
        </div>

        <div class="auction-id">
          <enzo-text kind="label" color="black-light">{{ 'general.offer-id' | translate }} {{ vm.auction.id | auctionIdentifier }}</enzo-text>
          <app-live-auction-info *ngIf="vm.auction.isLive"></app-live-auction-info>
        </div>
      </div>

      <mat-tab-group testid="vehicle-tabs">
        <mat-tab *ngIf="vm.vehicleGalleryImages.length > 0">
          <ng-template mat-tab-label>
            <enzo-text kind="emphasis-label" icon="gallery" icon-size="small">{{ 'general.all-images' | translate }}</enzo-text>
          </ng-template>
          <ng-template matTabContent>
            <div class="gallery-container">
              <div class="interests-container">
                <app-watchlist-button
                  *ngIf="!!vm.auctionBuyer"
                  [auction]="vm.auctionBuyer"
                  watchlistLocation="Detail view"
                  (isParkedChange)="auctionRefresh.emit()" />
                <app-notes-button [auctionUuid]="vm.auction.uuid" />
              </div>
              <app-image-gallery [galleryImages]="vm.vehicleGalleryImages" testid="vehicle-images"></app-image-gallery>
            </div>
          </ng-template>
        </mat-tab>

        <mat-tab *ngIf="vm.vehicleVideoUrl.length > 0">
          <ng-template mat-tab-label>
            <enzo-text kind="emphasis-label" icon="play-arrow" icon-size="small">{{ 'general.video' | translate }}</enzo-text>
          </ng-template>
          <ng-template matTabContent>
            <app-video-gallery [videoUrls]="vm.vehicleVideoUrl"></app-video-gallery>
          </ng-template>
        </mat-tab>

        <mat-tab *ngIf="vm.motorVideoUrl && vm.motorVideoUrl.length > 0">
          <ng-template mat-tab-label>
            <enzo-text kind="emphasis-label" icon="play-arrow" icon-size="small">{{ 'vehicle.motor-video' | translate }}</enzo-text>
          </ng-template>
          <ng-template matTabContent>
            <app-video-gallery [videoUrls]="vm.motorVideoUrl"></app-video-gallery>
          </ng-template>
        </mat-tab>

        <mat-tab *ngIf="vm.damageGalleryImages.length > 0">
          <ng-template mat-tab-label>
            <enzo-text kind="emphasis-label" icon="condition" icon-size="small">{{ 'general.condition-images' | translate }}</enzo-text>
          </ng-template>
          <ng-template matTabContent>
            <div class="gallery-container">
              <app-image-gallery [galleryImages]="vm.damageGalleryImages" testid="vehicle-damages"></app-image-gallery>
            </div>
          </ng-template>
        </mat-tab>

        <mat-tab *ngIf="vm.documentGalleryImages.length > 0">
          <ng-template mat-tab-label>
            <enzo-text kind="emphasis-label" icon="document" icon-size="small">{{ 'general.documents' | translate }}</enzo-text>
          </ng-template>
          <ng-template matTabContent>
            <div class="gallery-container">
              <app-image-gallery [galleryImages]="vm.documentGalleryImages"></app-image-gallery>
            </div>
          </ng-template>
        </mat-tab>
      </mat-tab-group>
    </div>

    <div class="bid-info-container">
      <ng-container *ngTemplateOutlet="statusInfo"></ng-container>
      <ng-content select="[bid-info]"></ng-content>
    </div>
  </div>
  <div class="report-document-container">
    <div *ngIf="displayReportButton" class="report-document-item">
      <app-vehicle-origin-report-button [auction]="vm.auction" #reportButton></app-vehicle-origin-report-button>
      <enzo-ghost-button
        *ngIf="vm.auction.associatedVehicle.origin !== eVehicleCreationOrigin.SELF_REVIEWED && vm.auction.associatedVehicle.urlToAttachment1"
        [label]="'general.view-condition-report' | translate"
        variant="highlight"
        (enzoClick)="reportButton.openReport()">
      </enzo-ghost-button>
    </div>
    <div *ngIf="vm.auction.associatedVehicle.urlToBatteryConditionCertificate" class="report-document-item">
      <app-vehicle-origin-report-button [auction]="vm.auction" [theme]="'battery'" #certificateButton></app-vehicle-origin-report-button>
      <enzo-ghost-button
        label="{{ 'vehicle.battery.certificate' | translate }} {{ 'general.show' | translate }}"
        variant="highlight"
        (enzoClick)="certificateButton.openBatteryCertificate()">
      </enzo-ghost-button>
    </div>
  </div>

  <div class="seller-progress-diagram-container">
    <ng-content select="[seller-progress-diagram]"></ng-content>
  </div>

  <app-vehicle-equipment-icons [vehicle]="vm.auction.associatedVehicle" />

  <ng-template #vehicleCondition><app-vehicle-condition-data [auction]="vm.auction" /></ng-template>
  <ng-template #additionalInfo><app-vehicle-additional-info [vehicle]="vm.auction.associatedVehicle" /></ng-template>
  <ng-template #specificInfo><app-specific-info [auction]="vm.auction" /></ng-template>
  <ng-template #documentInfo><app-document-info [auction]="vm.auction" [auctionSeller]="vm.auctionSeller" /></ng-template>
  <ng-template #equipmentInfo><app-equipment-info [auction]="vm.auction" /></ng-template>

  @for (section of (advPreferencesService.advPreferences$ | async).sectionOrder; track section) {
    <ng-container *ngTemplateOutlet="sectionTemplateMapping[section]?.()" />
  }

  <app-vehicle-unspecified-info />

  <ng-template #statusInfo>
    <app-status-info [auctionBuyer]="auctionBuyer" [auctionSeller]="auctionSeller" progressBar="hot-bid-bottom">
      <app-bids-amount *ngIf="auctionBuyer" [auction]="auctionBuyer" bids-amount></app-bids-amount>
    </app-status-info>
  </ng-template>

  <ng-template #badgeOverlayTemplate>
    <div class="badges">
      @if (vm.auction.isExpressPickupAvailable) {
        <app-auction-badge [icon]="{ enzoIcon: 'express-pickup' }" color="white" label="pickup.express-pickup" testid="express-pick-up-badge" />
      }
      @if (vm.auction.isSelectiveWare) {
        <app-auction-badge
          color="yellow"
          label="auction.selective-ware"
          [icon]="{ iconFromSource: vm.lookAndFeelIcon }"
          [highlight]="true"
          testid="selective-ware-badge" />
      } @else if (!!vm.auctionBuyer && !vm.auction.isTest) {
        <app-auction-badge
          color="yellow"
          [label]="vm.exclusiveBadgeContent.key"
          [icon]="vm.exclusiveBadgeContent.icon ? { iconFromSource: vm.exclusiveBadgeContent.icon } : { enzoIcon: 'exclusive' }"
          testid="exclusive-badge" />
      } @else if (!!vm.auctionBuyer && vm.auctionBuyer.sellerType === ESellerBusinessType.PRIVATE) {
        <app-auction-badge [icon]="{ enzoIcon: 'private-owner' }" color="yellow" label="auction.seller-type-label.private" testid="private-badge" />
      }
    </div>
  </ng-template>
</ng-container>
