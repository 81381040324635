import { NgModule } from '@angular/core';
import { BidInfoFooterComponent } from './bid-info-footer.component';
import { HighestBidInfoModule } from '../highest-bid-info/highest-bid-info.module';
import { AuctionVatModule } from '../auction-vat/auction-vat.module';
import { StatusInfoModule } from '@cos/partials/status-info/status-info.module';
import { EnzoComponentsModule } from '@caronsale/enzo-angular';
import { HighestBidValueModule } from '../../highest-bid-value/highest-bid-value.module';
import { AuctionPipesModule, GeneralPipesModule } from '@caronsale/frontend-pipes';
import { CosCommonModule } from '@cosCoreComponents/cos-common/cos-common.module';
import { BaseModule } from '@cosCoreComponents/general/base.module';
import { CommonModule } from '@angular/common';
import { PriceTargetInfoComponent } from '../../price-target-info/price-target-info.component';
import { MobileBidDialogModule } from './mobile-bid-dialog/mobile-bid-dialog.module';
import { WatchlistButtonComponent } from '@cosBuyer/partials/watchlist-button/watchlist-button.component';
import { NotesButtonComponent } from '@cosBuyer/partials/notes-button/notes-button.component';

@NgModule({
  declarations: [BidInfoFooterComponent],
  exports: [BidInfoFooterComponent],
  imports: [
    //
    PriceTargetInfoComponent,
    HighestBidInfoModule,
    AuctionVatModule,
    StatusInfoModule,
    EnzoComponentsModule,
    HighestBidValueModule,
    GeneralPipesModule,
    AuctionPipesModule,
    CosCommonModule,
    BaseModule,
    CommonModule,
    MobileBidDialogModule,
    WatchlistButtonComponent,
    NotesButtonComponent,
  ],
})
export class BidInfoFooterModule {}
