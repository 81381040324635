<ng-container *ngIf="isInstantPurchaseActive()">
  <enzo-button
    variant="highlight"
    [label]="'action.buy-now-for' | translate: { price: auction.instantPurchasePrice | currency: 'EUR' }"
    (enzoClick)="makeInstantPurchase()"
    testid="instant-purchase-button"
    stretch>
  </enzo-button>

  <app-cross-border-discount-badge
    testid="app-cross-border-discount-badge"
    [amount]="auction.buyerCrossBorderProcessingAmount"></app-cross-border-discount-badge>

  <div class="fees-and-taxes">
    <app-auction-vat [auction]="auction"></app-auction-vat>
    <app-fee-info [auction]="auction" [highestBidOrNextBid]="auction.instantPurchasePrice" [currentPrebookedServices]="currentPrebookedServices"></app-fee-info>
  </div>
  <enzo-text kind="caption" align="center">{{ 'auction.instant-purchase-info-text' | translate }}</enzo-text>
</ng-container>
