<ng-container *ngIf="vm$ | async as vm">
  <div class="bidding-actions">
    <enzo-textfield
      type="number"
      [class.animate-error-state]="animateErrorState"
      (animationend)="animateErrorState = false"
      [placeholder]="'action.min-price' | translate: { price: vm.minimalBidThatIsHigher | currency: 'EUR' : 'symbol' : '1.0-0' }"
      [min]="vm.minimalBidThatIsHigher"
      [max]="vm.maxBidValue"
      [step]="vm.bidStep"
      [fieldSuffix]="{ type: 'currency' }"
      [formControl]="bidAmount"
      testid="bid-value-input"
      data-cy="bidding_bid-amount-input"></enzo-textfield>
    <enzo-button
      kind="primary"
      [label]="'action.do-bid' | translate"
      stretch
      (enzoClick)="bidOnAuction()"
      testid="place-bid-button"
      data-cy="bidding_place-bid-amount-button"></enzo-button>
    <enzo-button
      *ngIf="vm.canSetBiddingAgent && !vm.isBiddingAgentSet"
      kind="primary"
      [label]="'action.do-bid-with-agent' | translate"
      stretch
      (enzoClick)="setBiddingAgent()"
      testid="set-bidding-agent-button"
      data-cy="bidding_set-bidding-agent-button">
    </enzo-button>
    <enzo-button
      *ngIf="vm.isBiddingAgentSet"
      [label]="vm.auction.biddingAgentValue | currency: 'EUR'"
      variant="danger"
      icon="delete"
      iconPosition="leading"
      [matTooltip]="'action.delete-bidding-agent' | translate"
      (enzoClick)="removeBiddingAgent()"
      stretch
      testid="remove-bidding-agent-button"
      data-cy="bidding_remove-bidding-agent-button"></enzo-button>
    <div class="bid-increments">
      @for (increment of vm.bidIncrements; let i = $index; track i) {
        <div class="bid-increment-button-wrapper">
          <enzo-button
            kind="secondary"
            variant="highlight"
            (enzoClick)="incrementBidValue(increment)"
            testid="increment-bid-button"
            [label]="'+' + (increment | currency: 'EUR' : 'symbol' : '1.0-0')"
            stretch
            [attr.data-cy]="'bidding_instantly-add-to-bid-button-' + i"></enzo-button>
        </div>
      }
    </div>
    <div class="bid-agent-label" *ngIf="vm.isBiddingAgentSet && !vm.auction.biddingAgentActive; else biddingAgentLabel" testid="bidding-agent-label">
      <enzo-text kind="caption"> {{ 'auction.agent-inactive' | translate }} </enzo-text>
      <enzo-icon icon="info-outline" color="purple" size="small" [popoverTemplate]="bidAgentInactiveInfoPopover" popoverWidth="25%"></enzo-icon>
    </div>
  </div>

  <ng-template #biddingAgentLabel>
    <div class="bid-agent-label" *ngIf="vm.isBiddingAgentSet || vm.canSetBiddingAgent" testid="bidding-agent-label">
      <enzo-text kind="caption"> {{ 'auction.bidding-agent-caption' | translate }} </enzo-text>
      <enzo-icon icon="info-outline" color="purple" size="small" [popoverTemplate]="bidAgentInfoPopover" popoverWidth="25%"></enzo-icon>
    </div>
  </ng-template>
</ng-container>

<ng-template #bidAgentInfoPopover>
  <enzo-text kind="label">
    <b>{{ 'dialog.buyer.bidding-agent-info.title' | translate }}</b>
  </enzo-text>
  <enzo-text kind="label" [innerHTML]="'dialog.buyer.bidding-agent-info.message' | translate"></enzo-text>
</ng-template>

<ng-template #bidAgentInactiveInfoPopover>
  <enzo-text kind="label">{{ 'auction.agent-inactive-tooltip' | translate }}</enzo-text>
</ng-template>
