<table>
  <tr>
    <th>{{ 'auction.color-coding-wheel-info.column-one-title' | translate }}</th>
    <th>{{ 'vehicle.tire-type-label.summer' | translate }}</th>
    <th>{{ 'vehicle.tire-type-label.winter' | translate }}</th>
    <th>{{ 'vehicle.tire-type-label.all-weather' | translate }}</th>
  </tr>
  <tr>
    <td>{{ 'auction.color-coding-wheel-info.column-one-row-one' | translate }}</td>
    <td>> 3.0mm</td>
    <td>> 4.0mm</td>
    <td>> 4.0mm</td>
  </tr>
  <tr>
    <td>{{ 'auction.color-coding-wheel-info.column-one-row-two' | translate }}</td>
    <td>1.6 - 3.0mm</td>
    <td>1.6 - 4.0mm</td>
    <td>1.6 - 4.0mm</td>
  </tr>
  <tr>
    <td>{{ 'auction.color-coding-wheel-info.column-one-row-three' | translate }}</td>
    <td>< 1.6mm</td>
    <td>< 1.6mm</td>
    <td>< 1.6mm</td>
  </tr>
</table>
