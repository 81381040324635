<!-- eslint-disable @angular-eslint/template/eqeqeq -->
<app-expandable-panel [expandedState]="true">
  <div panelTitle class="panel-title">
    <enzo-icon [icon]="'document'"></enzo-icon>
    <enzo-headline level="4" color="blackish">{{ 'vehicle.vehicle-documents' | translate }}</enzo-headline>
  </div>
  <div panelContent class="panel-content">
    <app-vehicle-details-grid>
      <div
        *ngIf="
          auction.associatedVehicle.serviceHistoryAvailability !== null &&
          auction.associatedVehicle.serviceHistoryAvailability !== eVehicleServiceHistoryAvailability.UNKNOWN
        ">
        <enzo-text kind="overline" color="grey-dark">{{ 'vehicle.service-history-available-statement' | translate }}</enzo-text>
        <enzo-text [icon]="serviceHistoryIconConfig.type" [iconColor]="serviceHistoryIconConfig.color" iconSize="small">
          {{ auction.associatedVehicle.serviceHistoryAvailability | i18nServiceHistoryAvailability | translate }}
        </enzo-text>
      </div>
      <div *ngIf="auction.associatedVehicle.isCocDocumentAvailable !== null">
        <enzo-text kind="overline" color="grey-dark">{{ 'vehicle.coc-documents-available' | translate }}</enzo-text>
        <enzo-text
          [icon]="auction.associatedVehicle.isCocDocumentAvailable ? 'cos-approved' : 'warning'"
          [iconColor]="auction.associatedVehicle.isCocDocumentAvailable ? 'green' : 'orange'"
          iconSize="small">
          {{ auction.associatedVehicle.isCocDocumentAvailable | i18nAvailabilityOrUnknown | translate }}
        </enzo-text>
      </div>
      <div *ngIf="auction.associatedVehicle.hasMaintenanceBook !== null">
        <enzo-text kind="overline" color="grey-dark">{{ 'vehicle.has-maintenance-book' | translate }}</enzo-text>
        <enzo-text
          [icon]="auction.associatedVehicle.hasMaintenanceBook ? 'cos-approved' : 'warning'"
          [iconColor]="auction.associatedVehicle.hasMaintenanceBook ? 'green' : 'orange'"
          iconSize="small"
          >{{ auction.associatedVehicle.hasMaintenanceBook | i18nMaintenanceBook | translate }}</enzo-text
        >
      </div>
      <div *ngIf="auction.associatedVehicle.huReportAvailable !== null && auction.associatedVehicle.huReportAvailable !== eHuReportAvailable.UNKNOWN">
        <enzo-text kind="overline" color="grey-dark">{{ inspectionAvailableKey | translate }}</enzo-text>
        <enzo-text [icon]="eHuReportIconConfig.type" [iconColor]="eHuReportIconConfig.color" iconSize="small">{{
          auction.associatedVehicle.huReportAvailable | i18nVehicleHuReportAvailable | translate
        }}</enzo-text>
      </div>
      <ng-container *ngIf="!auctionSeller">
        <div *ngIf="auction.associatedVehicle.isRegistrationDocumentAvailable != null">
          <enzo-text kind="overline" color="grey-dark">{{ 'general.vehicle-registration' | translate }}</enzo-text>
          <enzo-text
            [icon]="auction.associatedVehicle.isRegistrationDocumentAvailable ? 'cos-approved' : 'warning'"
            [iconColor]="auction.associatedVehicle.isRegistrationDocumentAvailable ? 'green' : 'orange'"
            iconSize="small"
            >{{ auction.associatedVehicle.isRegistrationDocumentAvailable | i18nAvailabilityOrUnknown | translate }}</enzo-text
          >
        </div>
        <div *ngIf="auction.associatedVehicle.isRegistrationDocumentPart2Available != null">
          <enzo-text kind="overline" color="grey-dark">{{ 'general.vehicle-title' | translate }}</enzo-text>
          <enzo-text
            [icon]="auction.associatedVehicle.isRegistrationDocumentPart2Available ? 'cos-approved' : 'warning'"
            [iconColor]="auction.associatedVehicle.isRegistrationDocumentPart2Available ? 'green' : 'orange'"
            iconSize="small"
            >{{ auction.associatedVehicle.isRegistrationDocumentPart2Available | i18nAvailabilityOrUnknown | translate }}</enzo-text
          >
        </div>
        <div *ngIf="auction.associatedVehicle.isDataExcerptAvailable != null">
          <enzo-text kind="overline" color="grey-dark">{{ 'vehicle.data-excerpt-available' | translate }}</enzo-text>
          <enzo-text
            [icon]="auction.associatedVehicle.isDataExcerptAvailable ? 'cos-approved' : 'warning'"
            [iconColor]="auction.associatedVehicle.isDataExcerptAvailable ? 'green' : 'orange'"
            iconSize="small"
            >{{ auction.associatedVehicle.isDataExcerptAvailable | i18nAvailabilityOrUnknown | translate }}</enzo-text
          >
        </div>
      </ng-container>
    </app-vehicle-details-grid>
  </div>
</app-expandable-panel>
