import { inject, Injectable } from '@angular/core';
import { Observable, switchMap } from 'rxjs';

import { CosCoreClient } from '@cosCoreServices/core-client/cos-core-client.service';
import { IGeneralUser, IGeneralUserSettings } from '@caronsale/cos-models';

@Injectable({
  providedIn: 'root',
})
export class CosGeneralUserClientService {
  private cosClient = inject(CosCoreClient);

  public readonly generalUser$: Observable<IGeneralUser> = this.cosClient.generalUser$;

  public getCurrentGeneralUserSnapshot(): IGeneralUser {
    return this.cosClient.getCurrentGeneralUserSnapshot();
  }

  public getGeneralUserProfile(): Observable<IGeneralUser> {
    return this.cosClient.refreshCurrentGeneralUser();
  }

  public updateGeneralUserSettings(settings: IGeneralUserSettings): Observable<any> {
    return this.cosClient.requestWithPrivileges('put', `/users/profile/settings`, settings).pipe(switchMap(() => this.cosClient.refreshCurrentGeneralUser()));
  }

  public getSupervisorBySubordinate(): Observable<IGeneralUser> {
    return this.cosClient.requestWithPrivileges('get', `/users/profile/supervisor`);
  }
}
