import { Component, input, numberAttribute } from '@angular/core';
import { EnzoComponentsModule } from '@caronsale/enzo-angular';
import { types as ENZO_TYPES } from '@caronsale/enzo/dist/types';
import { TestingModule } from '@cos/components/general/testing/testing.module';

// Todo: provide by enzo module
type EnzoColor = (typeof ENZO_TYPES.keys.colors)[number];
type EnzoIcon = (typeof ENZO_TYPES.keys.icons)[number];

@Component({
  selector: 'app-header-button',
  standalone: true,
  imports: [EnzoComponentsModule, TestingModule],
  templateUrl: './header-button.component.html',
  styleUrls: ['./header-button.component.scss'],
})
export class HeaderButtonComponent {
  public icon = input.required<EnzoIcon>();
  public badgeCount = input(0, { transform: numberAttribute });
  public badgeColor = input<EnzoColor>('red');
}
