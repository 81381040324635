import { Component, DestroyRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { EVoucherDiscountType, IBuyerAuctionView, IVoucherAssignment, Time, Validation } from '@caronsale/cos-models';
import { I18nCurrencyPipe } from '@caronsale/frontend-pipes';
import { combineLatest, map, Observable, take, tap } from 'rxjs';
import { VoucherService } from '@cosBuyer/partials/services/voucher.service';
import { UntypedFormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

type VoucherExpirationTextKey = 'voucher.display.expires-today' | 'voucher.display.expires-tomorrow' | 'voucher.display.expires-in-x-days';

type VoucherExpiration = {
  expirationInDays: number;
  expirationTextKey: VoucherExpirationTextKey;
};

type VouchersObservable = Observable<{
  availableVouchers: (IVoucherAssignment & VoucherExpiration)[];
  selectedVoucher: IVoucherAssignment & VoucherExpiration;
}>;

@Component({
  selector: 'app-voucher-selection',
  templateUrl: './voucher-selection.component.html',
  styleUrls: ['./voucher-selection.component.scss'],
})
export class VoucherSelectionComponent implements OnInit {
  public readonly vm$: VouchersObservable = combineLatest({
    availableVouchers: this.voucherService.getAvailableVouchers().pipe(map(vouchers => vouchers.map(voucher => this.augmentVoucherWithExpiration(voucher)))),
    selectedVoucher: this.voucherService.getSelectedVoucher().pipe(map(voucher => this.augmentVoucherWithExpiration(voucher))),
  });

  public voucherControl = new UntypedFormControl('');

  @Input()
  public auction: IBuyerAuctionView;

  @Output()
  public readonly onSelectVoucher: EventEmitter<boolean> = new EventEmitter<boolean>();

  public constructor(
    public i18nCurrencyPipe: I18nCurrencyPipe,
    private voucherService: VoucherService,
    private translateService: TranslateService,
    private destroyRef: DestroyRef,
  ) {}

  public ngOnInit(): void {
    combineLatest({
      assignedVoucher: this.voucherService.getAssignedVoucher(),
      selectedVoucher: this.voucherService.getSelectedVoucher(),
    })
      .pipe(
        map(({ selectedVoucher, assignedVoucher }) => selectedVoucher?.voucherUuid !== assignedVoucher?.voucherUuid),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe(isDirty => {
        this.onSelectVoucher.emit(isDirty);
      });

    this.voucherService
      .getSelectedVoucher()
      .pipe(
        tap(selectedVoucher => this.voucherControl.setValue(selectedVoucher, { emitEvent: false })),
        take(1),
      )
      .subscribe();
  }

  public formatVoucherOption(voucher: IVoucherAssignment): string {
    if (!voucher) {
      return 'unknown';
    }

    const translatedLabel = this.getTranslatedVoucherLabel(voucher);

    switch (voucher.discountType) {
      case EVoucherDiscountType.FIXED:
        return `${this.i18nCurrencyPipe.transform(voucher.remainingBalance, '€', 'EUR')} ${translatedLabel}`;
      case EVoucherDiscountType.PERCENT:
        return `${voucher.amount}% ${translatedLabel}`;
    }
  }

  public displayVoucherExpirationNotice(voucher: IVoucherAssignment): boolean {
    return voucher?.validUntil && this.getVoucherExpirationInDays(voucher) < 15;
  }

  public getVoucherExpirationInDays(voucher: IVoucherAssignment): number {
    return Time.getDaysBetween(new Date(), new Date(voucher.validUntil));
  }

  public selectVoucher(voucher: IVoucherAssignment): void {
    this.voucherService.changeVoucher(voucher);
  }

  public compareVouchers(voucher1: IVoucherAssignment, voucher2: IVoucherAssignment) {
    return voucher1 && voucher2 && voucher1.voucherUuid === voucher2.voucherUuid;
  }

  private getTranslatedVoucherLabel(voucher: IVoucherAssignment): string {
    if (!voucher.labelTranslationKey) {
      return voucher.displayLabel;
    }

    const translatedLabel = this.translateService.instant(voucher.labelTranslationKey);

    // When a translation is missing, the translation service might return the key itself.
    if (Validation.isUndefinedNullOrEmptyString(translatedLabel) || translatedLabel === voucher.labelTranslationKey) {
      return voucher.displayLabel;
    }

    return translatedLabel;
  }

  private augmentVoucherWithExpiration(voucher: IVoucherAssignment): IVoucherAssignment & VoucherExpiration {
    if (Validation.isUndefinedOrNull(voucher)) {
      return null;
    }

    const expirationInDays = this.getVoucherExpirationInDays(voucher);
    let expirationTextKey: VoucherExpirationTextKey;

    if (expirationInDays < 1) {
      expirationTextKey = 'voucher.display.expires-today';
    } else if (expirationInDays === 1) {
      expirationTextKey = 'voucher.display.expires-tomorrow';
    } else {
      expirationTextKey = 'voucher.display.expires-in-x-days';
    }

    return {
      ...voucher,
      expirationInDays,
      expirationTextKey,
    };
  }
}
