<div class="title">{{ 'auction.enter-bid' | translate }}</div>

<mat-form-field [color]="isBidOutOfRange() ? 'warn' : 'primary'">
  <input
    matInput
    placeholder="{{ 'auction.your-bid' | translate }}"
    type="number"
    pattern="[0-9]*"
    [(ngModel)]="newBidValue"
    (ngModelChange)="bidValueChangedSubject.next($event)"
    [min]="minimalBidThatIsHigher"
    [max]="maxBidValue"
    [step]="minimalBidIncrement"
    (focus)="onBidInputFocused()" />
  <mat-hint *ngIf="!isBidHighEnough() && newBidValue !== null && !auction.amIHighestBidder">{{ 'auction.bid-is-too-low' | translate }}</mat-hint>
  <mat-hint *ngIf="isBidTooHigh()">{{ 'auction.maximum-bid' | translate }}</mat-hint>
  <span matSuffix>€</span>
</mat-form-field>

<button mat-raised-button color="primary" [disabled]="isBidOutOfRange()" (click)="bidOnAuction()">{{ 'auction.instant-bid' | translate }}</button>

<button *ngIf="isBiddingAgentVisible && !isBiddingAgentSet" mat-raised-button color="primary" [disabled]="isBidOutOfRange()" (click)="setBiddingAgent()">
  {{ 'auction.bid-with-agent' | translate }}
</button>

<button
  *ngIf="isBiddingAgentSet"
  mat-raised-button
  color="warn"
  (click)="removeBiddingAgent()"
  matTooltip="{{ 'general.click-here-to-delete' | translate }}"
  testid="remove-bidding-agent-button">
  <fa-icon icon="trash"></fa-icon>
  <br />
  {{ (auction.biddingAgentActive ? 'auction.agent-active' : 'auction.agent-inactive') | translate }} ({{ auction.biddingAgentValue | currency: 'EUR' }})
</button>

<button mat-button (click)="showBiddingAgentInfo()" class="info-button">{{ 'auction.what-is-the-bid-agent' | translate }}</button>
