import { ChangeDetectionStrategy, Component, computed, input } from '@angular/core';

import { TranslateModule } from '@ngx-translate/core';

import { AdditionalTax, IAuctionBiddingData } from '@caronsale/cos-models';
import { I18nGeneralPipesModule } from '@caronsale/frontend-pipes';

import { I18nAdditionalTaxTypePipe } from './i18n-additional-tax-type.pipe';

@Component({
  standalone: true,
  selector: 'app-additional-tax-info',
  templateUrl: './additional-tax-info.component.html',
  styleUrls: ['./additional-tax-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [TranslateModule, I18nGeneralPipesModule, I18nAdditionalTaxTypePipe],
})
export class AdditionalTaxInfoComponent {
  public auctionBiddingData = input.required<IAuctionBiddingData>();

  public hasAdditionalTax = computed(() => AdditionalTax.isAdditionalTax(this.auctionBiddingData().additionalTaxType));
  public isAdditionalTaxRelative = computed(() => AdditionalTax.isRelativeTax(this.auctionBiddingData().additionalTaxType));
  public isAdditionalTaxAbsolute = computed(() => AdditionalTax.isAbsoluteTax(this.auctionBiddingData().additionalTaxType));
}
