import { Component, forwardRef, Input, OnDestroy } from '@angular/core';
import { ControlValueAccessor, UntypedFormControl, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';

export interface IFormValidationStyles {
  label: { [cssProperty: string]: any };
  field: { [cssProperty: string]: any };
  input: { [cssProperty: string]: any };
}

const emptyFn = () => {
  //
};

@Component({
  selector: 'app-form-validate-integer',
  templateUrl: './form-validate-integer.component.html',
  styleUrls: ['./form-validate-integer.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormValidateIntegerComponent),
      multi: true,
    },
  ],
})
export class FormValidateIntegerComponent implements ControlValueAccessor, OnDestroy {
  public _labelKey: string;
  public _hintKey: string;
  public _errorKey: string;
  public _placeholderKey: string;
  public _suffixKey: string;
  public _styles: IFormValidationStyles;
  public _maxlength: any;
  public _autocomplete: any;
  public _type: any;
  public _step: any;
  public _min: any;
  public _max: any;

  public integerFormControl = new UntypedFormControl(null, [Validators.required, Validators.pattern(/^([0-9]+)$/)]);

  public onChange: any = emptyFn;

  public onTouched: any = emptyFn;

  @Input()
  public set labelKey(labelKey: string) {
    this._labelKey = labelKey;
  }

  @Input()
  public set hintKey(hintKey: string) {
    this._hintKey = hintKey;
  }

  @Input()
  public set errorKey(errorKey: string) {
    this._errorKey = errorKey;
  }

  @Input()
  public set placeholderKey(placeholderKey: string) {
    this._placeholderKey = placeholderKey;
  }

  @Input()
  public set styles(style: IFormValidationStyles) {
    this._styles = style;
  }

  @Input()
  public set suffixKey(suffixKey: string) {
    this._suffixKey = suffixKey;
  }

  @Input()
  public set maxlength(maxlength: string) {
    this._maxlength = maxlength;
  }

  @Input()
  public set autocomplete(autocomplete: string) {
    this._autocomplete = autocomplete;
  }

  @Input()
  public set type(type: string) {
    this._type = type;
  }

  @Input()
  public set step(step: string) {
    this._step = step;
  }

  @Input()
  public set min(min: string) {
    this._min = min;
  }

  @Input()
  public set max(max: string) {
    this._max = max;
  }

  @Input()
  public set validateOnLoad(val: boolean) {
    if (val) {
      this.integerFormControl.markAsTouched();
    }
  }

  @Input()
  public dataCy: string = '';

  private sub: Subscription;

  public constructor() {
    this.sub = this.integerFormControl.valueChanges.subscribe(value => {
      this.onChange(+value);
      this.onTouched();
    });
  }

  public ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public writeValue(value: number): void {
    if (value) {
      this.integerFormControl.setValue(value);
      this.onChange(value);
      this.onTouched();
    }
  }
}
