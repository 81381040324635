import { Component, Input, OnChanges, OnInit } from '@angular/core';
import {
  EBuyNowPayLaterApprovalChance,
  I18nFormatUtils,
  IBuyerAuctionView,
  IBuyNowPayLaterApprovalChanceThresholds,
  IBuyNowPayLaterBalanceWithLabel,
} from '@caronsale/cos-models';
import { BuyNowPayLaterService, IBuyNowPayLaterEstimate } from '@cosCoreServices/buy-now-pay-later/buy-now-pay-later.service';
import { catchError, Observable, of, switchMap, take, tap } from 'rxjs';
import { CosBuyerClientService } from '@cosCoreServices/cos-salesman-client/cos-buyer-client.service';

@Component({
  selector: 'app-auction-bnpl-approval-chance',
  templateUrl: './auction-bnpl-approval-chance.component.html',
  styleUrls: ['./auction-bnpl-approval-chance.component.scss'],
})
export class AuctionBnplApprovalChanceComponent implements OnChanges, OnInit {
  @Input()
  public auction: IBuyerAuctionView;

  private approvalChanceThresholds: IBuyNowPayLaterApprovalChanceThresholds = {
    [EBuyNowPayLaterApprovalChance.VERY_HIGH]: 0,
    [EBuyNowPayLaterApprovalChance.HIGH]: 0,
    [EBuyNowPayLaterApprovalChance.MEDIUM]: 0,
    [EBuyNowPayLaterApprovalChance.LOW]: 0,
    [EBuyNowPayLaterApprovalChance.VERY_LOW]: 0,
  };

  public approvalChance = EBuyNowPayLaterApprovalChance.UNAVAILABLE;

  public bnplEstimate: IBuyNowPayLaterEstimate = {
    feeAmount: 0,
    totalAmount: 0,
  };

  public couldDetermineApprovalChance = true;

  private lastBidCalculated: number = null;

  public isPossibleToBookBNPL = true;

  public isUserEnabledForBNPL = false;

  public constructor(
    private buyNowPayLaterService: BuyNowPayLaterService,
    private cosBuyerClientService: CosBuyerClientService,
  ) {}

  public ngOnInit(): void {
    this.buyNowPayLaterService
      .isEnabledForUser()
      .pipe(take(1))
      .subscribe(enabled => {
        this.isUserEnabledForBNPL = enabled;
        this.calculateBNPLApprovalChance();
      });
  }

  public ngOnChanges(): void {
    this.calculateBNPLApprovalChance();
  }

  private calculateBNPLApprovalChance() {
    if (!this.isUserEnabledForBNPL) {
      return;
    }

    if (this.lastBidCalculated !== this.auction.currentHighestBidValue) {
      this.getBuyNowPayLaterBalance()
        .pipe(
          take(1),
          tap(balance => {
            this.approvalChanceThresholds = balance.approvalChanceThresholds;
          }),
          switchMap(() =>
            this.buyNowPayLaterService.getRunningAuctionPaymentAmount(this.auction.uuid).pipe(
              take(1),
              catchError(() => {
                this.couldDetermineApprovalChance = false;
                // Return a fallback value or an empty observable to continue the stream
                return of(null); // Ensure that the stream continues even if there's an error
              }),
            ),
          ),
          catchError(() => {
            this.couldDetermineApprovalChance = false;
            // Return a fallback value or an empty observable to continue the stream
            return of(null); // Ensure that the stream continues even if there's an error
          }),
        )
        .subscribe(estimate => {
          if (estimate !== null) {
            this.bnplEstimate = estimate;
            this.couldDetermineApprovalChance = true;
          }
          this.approvalChance = this.getApprovalChanceForBidAmount();
          this.updateApprovalChance();
          this.lastBidCalculated = this.auction.currentHighestBidValue;
        });
    }
  }

  public updateApprovalChance() {
    this.isPossibleToBookBNPL =
      this.approvalChance !== EBuyNowPayLaterApprovalChance.VERY_LOW && this.approvalChance !== EBuyNowPayLaterApprovalChance.UNAVAILABLE;
  }

  public getBuyNowPayLaterBalance(): Observable<IBuyNowPayLaterBalanceWithLabel> {
    return this.cosBuyerClientService.getBuyNowPayLaterBalance();
  }

  private getApprovalChanceForBidAmount(): EBuyNowPayLaterApprovalChance {
    if (!this.auction.isBuyNowPayLaterAvailable || !this.couldDetermineApprovalChance) {
      return EBuyNowPayLaterApprovalChance.UNAVAILABLE;
    }

    if (this.bnplEstimate.totalAmount < this.approvalChanceThresholds[EBuyNowPayLaterApprovalChance.VERY_HIGH]) {
      return EBuyNowPayLaterApprovalChance.VERY_HIGH;
    } else if (this.bnplEstimate.totalAmount < this.approvalChanceThresholds[EBuyNowPayLaterApprovalChance.HIGH]) {
      return EBuyNowPayLaterApprovalChance.HIGH;
    } else if (this.bnplEstimate.totalAmount < this.approvalChanceThresholds[EBuyNowPayLaterApprovalChance.MEDIUM]) {
      return EBuyNowPayLaterApprovalChance.MEDIUM;
    } else if (this.bnplEstimate.totalAmount < this.approvalChanceThresholds[EBuyNowPayLaterApprovalChance.LOW]) {
      return EBuyNowPayLaterApprovalChance.LOW;
    } else {
      return EBuyNowPayLaterApprovalChance.VERY_LOW;
    }
  }

  protected readonly EBuyNowPayLaterApprovalChance = EBuyNowPayLaterApprovalChance;
  protected readonly I18nFormatUtils = I18nFormatUtils;
}
