import { Component, Inject, OnDestroy } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { Observable, Subject, takeUntil } from 'rxjs';

import { IAccountAddress, Validation } from '@caronsale/cos-models';
import { EnzoDialogBase, EnzoDialogType } from '@cosCoreComponents/modal-dialogs/enzo-dialog-base';
import { EEnzoDialogResult, EnzoDialogRef } from '@cosCoreComponents/modal-dialogs/enzo-dialog.service';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';

export interface IUserSelectAddressModalData {
  addresses: IAccountAddress[];
  addressSelected$: Subject<IAccountAddress>;
  currentlySelectedAddress: IAccountAddress;
  keyBase: string;
  translationParams$: Observable<IUserSelectAddressModalTranslationParams>;
}

export interface IUserSelectAddressModalTranslationParams {
  errorKey?: string;
  errorParams?: any;
  interpolationParams?: any;
}

@Component({
  selector: 'app-user-address-select-modal',
  templateUrl: './user-select-address-modal.component.html',
  styleUrls: ['./user-select-address-modal.component.scss'],
})
export class UserSelectAddressModalComponent extends EnzoDialogBase implements OnDestroy {
  public addressControl: UntypedFormControl;

  public selectableAddresses: IAccountAddress[] = [];
  public busy = false;

  public titleKey: string;
  public messageKey: string;
  public confirmKey: string;
  public rejectKey: string;
  public dropdownLabelKey: string;
  public footerButtonBaseKey: string = 'dialog.buyer.select-address-then-confirm-transport-booking';
  public enzoDialogType: EnzoDialogType = 'confirmCancel';

  public translationParams: IUserSelectAddressModalTranslationParams;
  private unsubscribe$: Subject<void> = new Subject<void>();

  public constructor(
    //
    @Inject(DIALOG_DATA) public data: IUserSelectAddressModalData,
    private dialogRef: DialogRef<EEnzoDialogResult, UserSelectAddressModalComponent>,
    private enzoDialogRef: EnzoDialogRef,
  ) {
    super();

    this.enzoDialogRef.footerButtonClick$.pipe(takeUntil(this.unsubscribe$)).subscribe(res => {
      this.dialogRef.close(res);
    });

    const keyBase = this.data.keyBase;

    this.titleKey = keyBase + '.title';
    this.messageKey = keyBase + '.message';
    this.confirmKey = keyBase + '.confirm';
    this.rejectKey = keyBase + '.reject';
    this.dropdownLabelKey = keyBase + '.dropdown-label';
    this.footerButtonBaseKey = keyBase;

    this.data.translationParams$.pipe(takeUntil(this.unsubscribe$)).subscribe((tParams: IUserSelectAddressModalTranslationParams) => {
      this.translationParams = tParams;
      this.busy = Validation.isUndefinedNullOrEmptyObject(tParams?.interpolationParams);
    });

    const foundSelectedAddress = this.data.addresses.find(a => {
      return (
        a.addressLine === this.data.currentlySelectedAddress?.addressLine &&
        a.city === this.data.currentlySelectedAddress?.city &&
        a.countryCode === this.data.currentlySelectedAddress?.countryCode &&
        a.zipCode === this.data.currentlySelectedAddress?.zipCode
      );
    });

    this.selectableAddresses = [...this.data.addresses];
    this.addressControl = new UntypedFormControl('', Validators.required);

    if (foundSelectedAddress) {
      this.addressControl.setValue(foundSelectedAddress.uuid);
    } else if (this.data.currentlySelectedAddress) {
      this.selectableAddresses = [this.data.currentlySelectedAddress, ...this.selectableAddresses];
      this.addressControl.setValue(this.data.currentlySelectedAddress.uuid);
    }

    this.addressControl.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(selected => {
      this.busy = true;
      this.data.addressSelected$.next(this.selectableAddresses.find(a => a.uuid === selected));
    });
  }

  public ngOnDestroy() {
    this.unsubscribe$.next();
  }
}
