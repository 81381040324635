import { Component, EventEmitter, Input, Output } from '@angular/core';

import { I18nSnackService } from '@cosCoreServices/i18n-snack/i18n-snack.service';

@Component({
  selector: 'app-copy-on-click',
  templateUrl: './copy-on-click.component.html',
})
export class CopyOnClickComponent {
  @Input()
  public label: string;

  @Input()
  public toCopy: string;

  @Output()
  public copiedToClipboard: EventEmitter<void> = new EventEmitter<void>();

  public constructor(private snackSvc: I18nSnackService) {}

  public copyToClipboard(event) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = this.toCopy;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);

    this.snackSvc.openWithOk('general.item-copied-to-clipboard', null, { item: this.label });
    event.stopPropagation();
  }
}
