@if (isUserEnabledForBNPL) {
  @if (couldDetermineApprovalChance) {
    <div class="component" testid="component">
      <div class="title">
        <enzo-text kind="body-bold">{{ 'buy-now-pay-later.title' | translate }}</enzo-text>
        <enzo-icon color="purple-dark" icon="info-outline" size="small" class="icon" [popoverTemplate]="titleTipPopover" popoverWidth="300px" />
      </div>
      <div class="line">
        <ng-container *ngTemplateOutlet="approvalChanceLine"></ng-container>
      </div>

      @if (isPossibleToBookBNPL) {
        <div class="line" testid="bnpl-approval-chance">
          <enzo-text kind="emphasis-label" icon="price">{{ 'buy-now-pay-later.price.label' | translate }}</enzo-text>
          <div class="text-with-icon">
            <enzo-text kind="emphasis-label">{{ bnplEstimate.feeAmount | currencyEuro }}</enzo-text>
            <enzo-icon color="purple-dark" icon="info-outline" size="small" class="icon" [popoverTemplate]="pricePopover" popoverWidth="300px" />
          </div>
        </div>

        <div class="line">
          <div class="text-with-icon">
            <enzo-icon color="grey-dark" icon="info-outline" size="small" class="icon" />
            <enzo-text
              kind="label"
              color="grey-dark"
              [innerHTML]="'buy-now-pay-later.booking-instruction' | translate: { myPurchasesUrl: '/salesman/purchases' }"></enzo-text>
          </div>
        </div>
      }
    </div>
  } @else {
    <div class="text-with-icon">
      <enzo-text kind="emphasis-label" icon="cos-check">{{ 'buy-now-pay-later.estimate-not-available-for-auction.label' | translate }} </enzo-text>
      <enzo-icon color="purple-dark" icon="info-outline" size="small" class="icon" [popoverTemplate]="estimateNotAvailablePopover" popoverWidth="300px" />
    </div>
  }

  <ng-template #approvalChanceLine>
    @switch (approvalChance) {
      @case (EBuyNowPayLaterApprovalChance.VERY_LOW) {
        <div class="text-with-icon">
          <enzo-text kind="emphasis-label" icon="cos-check">{{ 'buy-now-pay-later.not-enough-limit.label' | translate }} </enzo-text>
          <enzo-icon color="purple-dark" icon="info-outline" size="small" class="icon" [popoverTemplate]="notEnoughLimitPopover" popoverWidth="300px" />
        </div>
      }
      @case (EBuyNowPayLaterApprovalChance.UNAVAILABLE) {
        <div class="text-with-icon">
          <enzo-text kind="emphasis-label" icon="cos-check">{{ 'buy-now-pay-later.not-available-for-auction.label' | translate }} </enzo-text>
          <enzo-icon color="purple-dark" icon="info-outline" size="small" class="icon" [popoverTemplate]="bnplNotAvailablePopover" popoverWidth="300px" />
        </div>
      }
      @default {
        <enzo-text kind="emphasis-label" icon="cos-check">{{ 'buy-now-pay-later.approval-chance.label' | translate }} </enzo-text>
        <ng-container *ngTemplateOutlet="approvalChanceValue"></ng-container>
      }
    }
  </ng-template>

  <ng-template #approvalChanceValue>
    <div class="text-with-icon">
      @switch (approvalChance) {
        @case (EBuyNowPayLaterApprovalChance.MEDIUM) {
          <enzo-text kind="emphasis-label" color="green-dark">{{ I18nFormatUtils.formatBNPLApprovalChance(approvalChance) | translate }} </enzo-text>
        }
        @case (EBuyNowPayLaterApprovalChance.LOW) {
          <enzo-text kind="emphasis-label" color="orange">{{ I18nFormatUtils.formatBNPLApprovalChance(approvalChance) | translate }} </enzo-text>
        }
        @case (EBuyNowPayLaterApprovalChance.VERY_LOW) {
          <enzo-text kind="emphasis-label" color="red">{{ I18nFormatUtils.formatBNPLApprovalChance(approvalChance) | translate }} </enzo-text>
        }
        @case (EBuyNowPayLaterApprovalChance.UNAVAILABLE) {
          <enzo-text kind="emphasis-label" color="orange">{{ I18nFormatUtils.formatBNPLApprovalChance(approvalChance) | translate }} </enzo-text>
        }
        @default {
          <enzo-text kind="emphasis-label" color="green">{{ I18nFormatUtils.formatBNPLApprovalChance(approvalChance) | translate }} </enzo-text>
        }
      }
      <enzo-icon color="purple-dark" icon="info-outline" size="small" class="icon" [popoverTemplate]="approvalChancePopover" popoverWidth="300px" />
    </div>
  </ng-template>

  <ng-template #titleTipPopover>
    <p [innerHTML]="'buy-now-pay-later.hint' | translate"></p>
  </ng-template>

  <ng-template #approvalChancePopover>
    <p [innerHTML]="I18nFormatUtils.formatBNPLApprovalChanceHint(approvalChance) | translate"></p>
  </ng-template>

  <ng-template #notEnoughLimitPopover>
    <p [innerHTML]="'buy-now-pay-later.not-enough-limit.hint' | translate"></p>
  </ng-template>

  <ng-template #bnplNotAvailablePopover>
    <p [innerHTML]="'buy-now-pay-later.not-available-for-auction.hint' | translate"></p>
  </ng-template>

  <ng-template #estimateNotAvailablePopover>
    <p [innerHTML]="'buy-now-pay-later.estimate-not-available-for-auction.hint' | translate"></p>
  </ng-template>

  <ng-template #pricePopover>
    {{ 'buy-now-pay-later.price.hint' | translate }}
  </ng-template>
}
