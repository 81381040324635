<div class="loading-layer animated fadeIn" [hidden]="!this.showLoadingLayerWhen">
  <div class="sk-folding-cube">
    <div class="sk-cube1 sk-cube"></div>
    <div class="sk-cube2 sk-cube"></div>
    <div class="sk-cube4 sk-cube"></div>
    <div class="sk-cube3 sk-cube"></div>
  </div>

  <!--<div class="loading-layer-spinner"></div>-->
  <div class="loading-layer-hint-text">
    <div>{{ this.loadingHintText }}</div>
  </div>

  <div class="loading-layer-reset-button">
    <div>
      <button mat-flat-button (click)="this.reset()">{{ 'general.cancel' | translate }}</button>
    </div>
  </div>
</div>
