import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'app-vehicle-details-grid',
  templateUrl: './vehicle-details-grid.component.html',
  styleUrls: ['./vehicle-details-grid.component.scss'],
  standalone: true,
})
export class VehicleDetailsGridComponent {
  @Input()
  @HostBinding('class.color-odds')
  public bgColorOnOdds: boolean = false;

  @Input()
  @HostBinding('class.striped')
  public bgStriped: boolean = true;
}
