import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output } from '@angular/core';
import {
  EPrebookedServiceBNPLOptionType,
  EPrebookedServiceType,
  IBuyerAuctionView,
  IPrebookedService,
  IPrebookedServiceBNPLOption,
  IVoucherAssignment,
  PrebookedServiceBNPLOptionType,
  Sanitization,
} from '@caronsale/cos-models';
import { Subject, takeUntil } from 'rxjs';
import { BuyerAuctionService } from '@cosCoreFeatures/auction-detail/common/auction-service/buyer-auction.service';
import { PrebookedServicesUtils } from '@caronsale/frontend-utils';
import { BuyNowPayLaterService } from '@cosCoreServices/buy-now-pay-later/buy-now-pay-later.service';
import { VoucherService } from '@cosBuyer/partials/services/voucher.service';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-prebooked-buy-now-pay-later',
  templateUrl: './prebooked-buy-now-pay-later.component.html',
  styleUrls: ['./prebooked-buy-now-pay-later.component.scss'],
})
export class PrebookedBuyNowPayLaterComponent implements OnChanges, OnDestroy {
  public readonly OPTION_PAY_NOW = EPrebookedServiceBNPLOptionType.PAY_NOW;
  public readonly OPTION_PAY_IN_60_DAYS = EPrebookedServiceBNPLOptionType.PAY_IN_60_DAYS;

  @Input()
  public auction: IBuyerAuctionView;

  @Input()
  public auctionPrice: number;

  @Input()
  public currentPrebookedServices: IPrebookedService[];

  @Input()
  public displayBuyNowPayLaterDisclaimer: boolean = false;

  @Output()
  public buyNowPayLaterSelectionChange = new EventEmitter<PrebookedServiceBNPLOptionType>();

  public buyNowPayLaterFormControl: FormControl;

  public payNowOption: IPrebookedServiceBNPLOption;
  public payIn60DaysOption: IPrebookedServiceBNPLOption;

  public buyNowPayLaterPrice: number;
  public isHotBidPhaseActive = false;
  public selectedVoucher: IVoucherAssignment;

  public isBuyNowPayLaterSelected = false;

  private unsubscribe$ = new Subject<void>();

  public constructor(
    private buyerAuctionService: BuyerAuctionService,
    private buyNowPayLaterService: BuyNowPayLaterService,
    private voucherService: VoucherService,
  ) {
    this.buyNowPayLaterFormControl = new FormControl<EPrebookedServiceBNPLOptionType>(this.OPTION_PAY_NOW);
    this.buyNowPayLaterFormControl.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(buyNowPayLater => {
      this.buyNowPayLaterSelectionChange.emit(buyNowPayLater);
    });
    this.voucherService
      .getSelectedVoucher()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(selectedVoucher => {
        this.selectedVoucher = selectedVoucher;
        this.handleChanges();
      });
  }

  public ngOnChanges(): void {
    this.handleChanges();
  }

  private handleChanges(): void {
    if (!this.auction) {
      return;
    }

    const auctionPrice = Sanitization.valueOrDefault(this.auctionPrice, this.auction.currentHighestBidValue);

    this.extractBNPLOptionsFromPrebookedServices(this.currentPrebookedServices);
    this.isBuyNowPayLaterSelected = !this.payNowOption?.isSelected && this.payIn60DaysOption?.isSelected;

    this.buyNowPayLaterFormControl.setValue(this.isBuyNowPayLaterSelected ? this.OPTION_PAY_IN_60_DAYS : this.OPTION_PAY_NOW, { emitEvent: false });

    this.isHotBidPhaseActive = this.buyerAuctionService.isHotBidPhaseActive(this.auction);
    this.buyNowPayLaterPrice = this.buyNowPayLaterService.getBNPL60DaysPrice(auctionPrice, this.auction, this.currentPrebookedServices, this.selectedVoucher);
  }

  public ngOnDestroy(): void {
    this.unsubscribe$.next();
  }

  private extractBNPLOptionsFromPrebookedServices(prebookedServices: IPrebookedService[]) {
    const bnplService: IPrebookedService = PrebookedServicesUtils.getService(prebookedServices, EPrebookedServiceType.BUY_NOW_PAY_LATER);

    if (bnplService) {
      this.payNowOption = PrebookedServicesUtils.getOption<IPrebookedServiceBNPLOption>(bnplService, this.OPTION_PAY_NOW);
      this.payIn60DaysOption = PrebookedServicesUtils.getOption<IPrebookedServiceBNPLOption>(bnplService, this.OPTION_PAY_IN_60_DAYS);
    }
  }
}
