<ng-container *ngIf="vm$ | async as vm">
  <div class="heading">
    <div class="headline-container">
      <enzo-headline level="3" align="center">
        {{ (vm.isPreregisteredAccount ? 'buyer-vehicle-preferences.welcome-title' : 'buyer-vehicle-preferences.title') | translate }}
      </enzo-headline>
      <enzo-icon icon="close" size="medium" matDialogClose data-cy="vehicle-preferences_close-button"></enzo-icon>
    </div>
    <enzo-text align="center" balance [innerHTML]="'buyer-vehicle-preferences.description' | translate"></enzo-text>
  </div>
  <div *ngIf="vm.isMobileOrTabletWidth" class="stepper">
    <div class="steps">
      <hr />
      <div class="step" *ngFor="let step of vm.steps" class="step" [class.active]="vm.activeStep >= step" (click)="showStep(step)" testid="step-number">
        {{ step }}
      </div>
    </div>
  </div>
  <hr *ngIf="!vm.isMobileOrTabletWidth" class="divider" />
  <form [formGroup]="vm.vehiclePreferencesForm">
    <div class="inputs">
      <div *ngIf="vm.activeStep === vm.EVehiclePreferencesSteps.LOCATIONS || vm.activeStep === vm.EVehiclePreferencesSteps.ALL" class="column">
        <enzo-text kind="body-bold" class="input-title"> {{ 'buyer-vehicle-preferences.vehicle-location' | translate }} </enzo-text>
        <mat-checkbox
          [indeterminate]="vm.countryCodesAllSelected.indeterminate"
          [checked]="vm.countryCodesAllSelected.all"
          (change)="setAllCountryCodes($event.checked)"
          testid="select-all-country-codes-input">
          {{ 'buyer-vehicle-preferences.all' | translate }}
        </mat-checkbox>
        <mat-checkbox
          *ngFor="let countryCode of vm.countryCodeFormControls | keyvalue: keepCheckboxesOrder"
          [formControl]="countryCode.value"
          testid="country-code-input">
          {{ countryCode.key | i18nCountryCode | translate }}
        </mat-checkbox>
      </div>
      <div class="column" *ngIf="vm.activeStep === vm.EVehiclePreferencesSteps.MAKES || vm.activeStep === vm.EVehiclePreferencesSteps.ALL">
        <enzo-text kind="body-bold" class="input-title">{{ 'buyer-vehicle-preferences.makes' | translate }}</enzo-text>
        <div class="column column-scroll">
          <mat-checkbox
            [indeterminate]="vm.makesAllSelected.indeterminate"
            [checked]="vm.makesAllSelected.all"
            (change)="setAllMakes($event.checked)"
            testid="select-all-makes-input">
            {{ 'buyer-vehicle-preferences.all' | translate }}
          </mat-checkbox>
          <mat-checkbox *ngFor="let make of vm.makeFormControls | keyvalue: keepCheckboxesOrder" [formControl]="make.value" testid="make-input">
            {{ make.key }}
          </mat-checkbox>
        </div>
      </div>
      <div class="column" *ngIf="vm.activeStep === vm.EVehiclePreferencesSteps.RANGES || vm.activeStep === vm.EVehiclePreferencesSteps.ALL">
        <enzo-text kind="body-bold" class="input-title"> {{ 'buyer-vehicle-preferences.price' | translate }} </enzo-text>
        <div class="range-inputs">
          <mat-form-field>
            <mat-label>{{ 'buyer-vehicle-preferences.from' | translate }}</mat-label>
            <mat-select formControlName="priceFrom" testid="price-from-input" (selectionChange)="setPriceToIfNeeded($event)">
              <mat-option *ngFor="let price of vm.priceFrom" [value]="price"> {{ price | currencyEuro }} </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field>
            <mat-label>{{ 'buyer-vehicle-preferences.to' | translate }}</mat-label>
            <mat-select formControlName="priceTo" testid="price-to-input">
              <mat-option *ngFor="let price of vm.priceTo" [value]="price">{{ price | currencyEuro }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <enzo-text kind="body-bold" class="input-title">{{ 'buyer-vehicle-preferences.mileage' | translate }}</enzo-text>
        <div class="range-inputs">
          <mat-form-field>
            <mat-label>{{ 'buyer-vehicle-preferences.from' | translate }}</mat-label>
            <mat-select formControlName="mileageFrom" testid="mileage-from-input" (selectionChange)="setMileageToIfNeeded($event)">
              <mat-option *ngFor="let mileage of vm.mileageFrom" [value]="mileage"> {{ mileage | mileage }} </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field>
            <mat-label>{{ 'buyer-vehicle-preferences.to' | translate }}</mat-label>
            <mat-select formControlName="mileageTo" testid="mileage-to-input">
              <mat-option *ngFor="let mileage of vm.mileageTo" [value]="mileage">{{ mileage | mileage }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <enzo-text kind="body-bold" class="input-title">{{ 'buyer-vehicle-preferences.age' | translate }}</enzo-text>
        <div class="range-inputs">
          <mat-form-field>
            <mat-label>{{ 'buyer-vehicle-preferences.from' | translate }}</mat-label>
            <mat-select formControlName="ezFrom" testid="ez-from-input" (selectionChange)="setEzToIfNeeded($event)">
              <mat-option *ngFor="let year of vm.ezFromYears" [value]="year">{{ year }}</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field>
            <mat-label>{{ 'buyer-vehicle-preferences.to' | translate }}</mat-label>
            <mat-select formControlName="ezTo" testid="ez-to-input">
              <mat-option *ngFor="let year of vm.ezToYears" [value]="year">{{ year }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>
    <div *ngIf="vm.isMobileOrTabletWidth" class="mobile-buttons">
      <enzo-button
        *ngIf="vm.activeStep !== vm.EVehiclePreferencesSteps.RANGES"
        kind="primary"
        [label]="'buyer-vehicle-preferences.next-step' | translate"
        stretch
        (enzoClick)="showNextStep()"
        testid="next-step-button"></enzo-button>
      <enzo-button
        *ngIf="vm.activeStep === vm.EVehiclePreferencesSteps.RANGES"
        kind="primary"
        [label]="'buyer-vehicle-preferences.save' | translate"
        stretch
        (enzoClick)="handleSave()"
        testid="save-button"></enzo-button>
      <enzo-button kind="secondary" [label]="'buyer-vehicle-preferences.later' | translate" stretch (enzoClick)="closeModal()"></enzo-button>
    </div>
    <div *ngIf="!vm.isMobileOrTabletWidth" class="desktop-buttons">
      <enzo-button kind="secondary" [label]="'buyer-vehicle-preferences.later' | translate" (enzoClick)="closeModal()"></enzo-button>
      <enzo-button kind="primary" [label]="'buyer-vehicle-preferences.save' | translate" (enzoClick)="handleSave()" testid="save-button"></enzo-button>
    </div>
  </form>
</ng-container>
