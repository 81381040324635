<app-expandable-panel [expandedState]="true">
  <div panelTitle class="panel-title">
    <enzo-icon icon="info-outline" />
    <enzo-headline level="4" color="blackish">{{ 'vehicle.general-information' | translate }}</enzo-headline>
  </div>
  <div panelContent class="panel-content">
    <enzo-text color="grey-dark" [innerHTML]="'vehicle.condition-disclaimer-text.message' | translate: { messageTemplate: catalogUrlTemplate }" />
    <enzo-text color="grey-dark" [innerHTML]="'vehicle.additional-info-note' | translate" />
  </div>
</app-expandable-panel>
