<div class="highlights" *ngIf="auction">
  <h2>{{ 'vehicle.equipment-highlights.label' | translate }}</h2>
  <ul>
    <li *ngFor="let highlight of auction.associatedVehicle.equipmentHighlights | slice: 0 : 9">
      <span class="highlights__icon icon-{{ highlight.externalId }}" *ngIf="highlight.externalId"></span>
      <span class="highlights__icon icon-checkmark" *ngIf="!highlight.externalId"></span>
      <span class="highlights__label">{{ highlight.description }}</span>
    </li>
  </ul>
</div>
