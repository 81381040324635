import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OverlayModule } from '@angular/cdk/overlay';
import { CosPopoverComponent } from './cos-popover.component';
import { CosPopoverDirective } from '@cosCoreFeatures/@cos/cos-popover/cos-popover.directive';

@NgModule({
  declarations: [CosPopoverComponent, CosPopoverDirective],
  imports: [CommonModule, OverlayModule],
  exports: [CosPopoverDirective],
})
export class CosPopoverModule {}
