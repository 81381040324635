<div class="main-layout">
  <div class="toolbar">
    <app-logo></app-logo>
    <app-language-select></app-language-select>
  </div>

  <div class="content">
    <ng-container *ngIf="!error">
      <app-pickup-confirmation-by-scan *ngIf="uuid" [uuid]="uuid" (confirmationError)="onError($event)"> </app-pickup-confirmation-by-scan>

      <app-manual-pickup-confirmation *ngIf="!uuid" (confirmationError)="onError($event)"> </app-manual-pickup-confirmation>
    </ng-container>

    <div *ngIf="error" class="errorContainer">
      <app-pickup-confirmation-error [errorCode]="error"> </app-pickup-confirmation-error>

      <button mat-flat-button color="primary" (click)="resetErrorCode()">{{ 'self-pickup.return' | translate }}</button>
    </div>
  </div>

  <app-footer></app-footer>
</div>
