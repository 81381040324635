import { Inject, Injectable } from '@angular/core';
import { NotificationSocket } from '@cosCoreFeatures/common/notification-center/util/notification-socket';
import {
  EEngagementEventType,
  EIncomingSocketEvent,
  EOutgoingSocketEvent,
  ICosNotification,
  IDeleteDataResponse,
  IEngageDataResponse,
  IGetUnreadCountResponse,
  INewNotificationPushUpdate,
  IPage,
} from '@caronsale/cos-models';
import { NotificationToastComponent } from '@cosCoreFeatures/common/notification-center/components/notification-toast/notification-toast.component';
import { ActiveToast, IndividualConfig, ToastrService } from 'ngx-toastr';
import { catchError, filter, firstValueFrom, map, Observable, of, shareReplay, Subject, switchMap, tap } from 'rxjs';
import { CosCoreClient } from '@cosCoreServices/core-client/cos-core-client.service';
import { GoogleAnalyticsService } from '@cosCoreServices/google-analytics/google-analytics.service';
import { WINDOW } from '@cosCoreServices/window';
import { ProductAnalyticsService } from '@cosCoreServices/product-analytics/product-analytics.service';

@Injectable({
  providedIn: 'root',
})
export class NotificationCenterService {
  /**
   * this stream is firing if this.getAllNotifications was triggered,
   */
  public notificationPageLoaded$: Observable<IPage<ICosNotification>>;

  /**
   * this stream is firing if a new notifications arises
   */
  public newNotification$: Observable<ICosNotification>;

  /**
   * this stream is firing if a new technical push arises
   * technical pushes are used to send messages to the client that are not necessarily visible notifications
   *
   * @experimental this feature is experimental and should not be widely used yet
   */
  public newTechnicalPush$: Observable<ITechnicalPush>;

  /**
   * this stream is firing if this.engageNotification was triggered
   */
  public engagedNotifications$: Observable<ICosNotification[]>;

  /**
   * firing if this.getUnreadNotificationCount was triggered
   */
  public countUnreadNotifications$: Observable<IGetUnreadCountResponse>;

  /**
   * this stream is firing if this.deleteNotification was triggered
   * currently unused (only returns a boolean status, not the uuid that was deleted)
   */
  public deletedNotification$: Observable<IDeleteDataResponse>;

  /**
   * request to close the notification dialog (close button or click on a notification)
   */
  public closeNotificationDialog$: Subject<void> = new Subject();

  // notification toast params
  public toastParams: Partial<IndividualConfig> = {
    toastComponent: NotificationToastComponent,
    closeButton: true,
    timeOut: 10000,
    extendedTimeOut: 10000,
    tapToDismiss: true,
  };

  public constructor(
    private googleAnalyticsService: GoogleAnalyticsService,
    private notificationSocket: NotificationSocket,
    private toastService: ToastrService,
    private coreClient: CosCoreClient,
    private productAnalyticsService: ProductAnalyticsService,
    @Inject(WINDOW) private window: Window,
  ) {
    this.toastService.toastrConfig.newestOnTop = false;
    this.connect();
  }

  // fire an "engage" event to the backend (READ OR CLICK). this.engagedNotification$ will emit the backend response.
  public sendEngage(notificationInternalUuids: string[], engagementEvent: EEngagementEventType) {
    this.notificationSocket.emit(EIncomingSocketEvent.ENGAGE, {
      notificationInternalUuids,
      engagementEvent,
    });
  }

  // send a delete request to the backend. this.deletedNotification$ will receive the backend response.
  public delete(notificationInternalUuids: string[]) {
    this.notificationSocket.emit(EIncomingSocketEvent.DELETE, {
      notificationInternalUuids,
    });
  }

  // request a page of notification objects. The notificationPageLoaded$ stream will receive the data
  public loadNotificationPage(page: number) {
    this.notificationSocket.emit(EIncomingSocketEvent.GET_ALL_NOTIFICATIONS, {
      page,
    });
  }

  // request the total count of all unread notifications. countUnreadNotifications$ will emit the result
  public getUnreadNotificationsCount() {
    this.notificationSocket.emit(EIncomingSocketEvent.GET_UNREAD_COUNT);
  }

  public disconnect() {
    this.notificationSocket.ioSocket.io.opts.query = undefined;
    this.notificationSocket.emit(EIncomingSocketEvent.DISCONNECTION);
    this.notificationSocket.removeAllListeners();
  }

  public connect() {
    this.addAllListeners();

    this.notificationSocket.ioSocket.io.opts.query = {
      accessToken: this.coreClient.getLastAuthenticationResult().token,
      userRole: this.coreClient.getLastAuthenticationResult().userRole,
    };

    this.notificationSocket.connect();

    this.notificationSocket.ioSocket.on('reconnect_attempt', async () => {
      const { accessToken } = await firstValueFrom(this.coreClient.getFreshAccessTokens());
      this.notificationSocket.ioSocket.io.opts.query = {
        ...this.notificationSocket.ioSocket.io.opts.query,
        accessToken,
      };
    });
  }

  private addAllListeners() {
    this.notificationPageLoaded$ = this.notificationSocket.fromEvent<IPage<ICosNotification>>(EOutgoingSocketEvent.DATA_RESPONSE_GET_ALL_NOTIFICATIONS);
    this.newNotification$ = this.notificationSocket.fromEvent<INewNotificationPushUpdate>(EOutgoingSocketEvent.NEW_NOTIFICATION_PUSH_UPDATE);
    this.engagedNotifications$ = this.notificationSocket.fromEvent<IEngageDataResponse>(EOutgoingSocketEvent.DATA_RESPONSE_ENGAGE);
    this.countUnreadNotifications$ = this.notificationSocket.fromEvent<IGetUnreadCountResponse>(EOutgoingSocketEvent.DATA_RESPONSE_GET_UNREAD_COUNT);
    this.deletedNotification$ = this.notificationSocket.fromEvent<IDeleteDataResponse>(EOutgoingSocketEvent.DATA_RESPONSE_DELETE);
    this.newTechnicalPush$ = this.notificationSocket.fromEvent<ITechnicalPush>('TECHNICAL_PUSH').pipe(
      shareReplay({ refCount: true }),
      catchError(() => of(null)), // we catch any error
      filter(Boolean), // we filter the null value to not break any property access
    );

    this.newTechnicalPush$
      .pipe(
        switchMap(newTechnicalPush =>
          this.productAnalyticsService.isOn('mpw-technical-push-logging').pipe(map(isLoggingFFOn => ({ newTechnicalPush, isLoggingFFOn }))),
        ),
        tap(({ newTechnicalPush, isLoggingFFOn }) => {
          if (isLoggingFFOn) {
            console.log(`Technical push on topic: ${newTechnicalPush.topic}`);
            console.log(newTechnicalPush);
          }
        }),
      )
      .subscribe();

    this.newTechnicalPush$
      .pipe(
        switchMap(newTechnicalPush =>
          this.productAnalyticsService.isOn('mpw-technical-push-report-delivery').pipe(map(isReportingFFOn => ({ newTechnicalPush, isReportingFFOn }))),
        ),
        tap(({ newTechnicalPush, isReportingFFOn }) => {
          if (isReportingFFOn) {
            this.notificationSocket.emit('PUSH_DELIVERY_REPORT', {
              internalUuid: newTechnicalPush.internalUuid,
              createdAt: newTechnicalPush.createdAt,
              sentAt: newTechnicalPush.sentAt,
              deliveredAt: new Date().toISOString(),
            });
          }
        }),
      )
      .subscribe();
  }

  public showToastIfNotOnNotificationTargetPage(notification: ICosNotification): void {
    const currentRelativePath = (this.window.location.pathname + this.window.location.search).substr(1);
    const notificationRelativePath = this.getRelativeLink(notification.primaryLink);

    if (currentRelativePath !== notificationRelativePath) {
      const toast: ActiveToast<NotificationToastComponent> = this.toastService.show('', '', this.toastParams);

      toast.toastRef.componentInstance.notification = notification;
      this.toastParams.onActivateTick = true;
      if (notification.engagementEvents.length === 0) {
        // probably not necessary to test, since it is a new notification
        this.sendEngage([notification.internalUuid], EEngagementEventType.READ);
      }
    }
  }

  public showGuaranteeAdvertiseToast(notification: ICosNotification): void {
    const adjustedToastParams = { ...this.toastParams, timeOut: 20000 };

    const toast: ActiveToast<NotificationToastComponent> = this.toastService.show('', '', adjustedToastParams);
    toast.toastRef.componentInstance.notification = notification;

    this.toastParams.onActivateTick = true;
    this.trackGuaranteeNotificationTrigger('guarantee_notification_triggered', notification.metadata.auctionUuid);

    if (notification.engagementEvents.length === 0) {
      // probably not necessary to test, since it is a new notification
      this.sendEngage([notification.internalUuid], EEngagementEventType.READ);
    }
  }

  public trackGuaranteeNotificationTrigger(eventName: string, auctionUuid: string): void {
    this.googleAnalyticsService.eventEmitter(eventName, 'buyer', 'click', 'guarantee', auctionUuid);
  }

  public clearAllToastNotifications() {
    this.toastService.clear();
  }

  /**
   * returns the relative path of an URL
   */
  public getRelativeLink(url: string): string {
    // delete at the beginning of the string: repeated fragments that are either "//" or "[^/]+" (any number of characters that are not slashes)
    // followed by one slash
    // example: 'https://www.any-domain.tld/salesman/dashboard/whatever'
    // 'https:' is a bunch of characters that are not slashes
    // '//' is matched literally
    // 'www.any-domain.tld' is a again a bunch of characters that are not slashes
    // from the rest '/salesman/dashboard/whatever' the first slash is matched and that is the end of the match (the part that will be deleted)
    return url.replace(/^(?:\/\/|[^/]+)*\//, '');
  }
}

/**
 * technical pushes are used to send messages to the client that are not necessarily visible notifications
 *
 * @experimental this feature is experimental and should not be widely used yet
 */
interface ITechnicalPush {
  internalUuid: string;
  topic: string;
  createdAt: string;
  sentAt: string;
}
