import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { ConfigService } from '@cosCoreServices/config/config.service';

@Component({
  selector: 'app-hot-bid-progress-bar',
  templateUrl: './hot-bid-progress-bar.component.html',
  styleUrls: ['./hot-bid-progress-bar.component.scss'],
})
export class HotBidProgressBarComponent {
  @ViewChild('progressBar')
  private progressBar: ElementRef<HTMLDivElement>;

  @Input()
  public kind: 'thin' | 'normal' = 'normal';

  @Input()
  public set remainingTimeInSeconds(value: number) {
    if (value > this.hotbidDuration) {
      this.state = 'inactive';
    } else {
      this.state = 'active';

      // This guard is just to make sure test doesnt break
      if (this.progressBar && this.progressBar.nativeElement && this.progressBar.nativeElement.getAnimations) {
        this.progressBar.nativeElement.getAnimations().every(animation => {
          animation.cancel();
        });

        if (value > 0) {
          this.progressBar.nativeElement.animate([{ transform: `scaleX(${value / this.hotbidDuration})` }, { transform: 'scaleX(0)' }], {
            duration: value * 1000,
          });
        } else {
          this.progressBar.nativeElement.style.transform = 'scaleX(0)';
        }
      }
    }
  }

  public state: 'active' | 'inactive';
  private hotbidDuration: number;

  public constructor(private configService: ConfigService) {
    this.hotbidDuration = Number(this.configService.getConfig().DEFAULT_AUCTION_HOT_BID_DURATION_SECONDS);
    this.state = 'inactive';
  }
}
