<mat-select #languageSelect [ngModel]="i18nService.languageChanged | async" (selectionChange)="handleOnSelectChange(languageSelect.value)">
  <mat-select-trigger>
    <app-country-flag [countryCode]="languageSelect.value"></app-country-flag>
    {{ 'general.language-names.' + languageSelect.value | translate }}
  </mat-select-trigger>
  <mat-option *ngFor="let lang of i18nService.languages | async" [value]="lang">
    <app-country-flag [countryCode]="lang"></app-country-flag>
    {{ 'general.language-names.' + lang | translate }}
  </mat-option>
</mat-select>
