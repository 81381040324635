import { Injectable } from '@angular/core';
import { CosBuyerClientService } from '@cosCoreServices/cos-salesman-client/cos-buyer-client.service';
import { environment } from '@cosCoreEnvironments/environment';
import { catchError, filter, first, map, switchMap, tap, timeout } from 'rxjs/operators';
import { from, interval, Observable, of, throwError } from 'rxjs';
import {
  EBuyNowPayLaterDeclineReason,
  EBuyNowPayLaterOrderStatus,
  EBuyNowPayLaterProvider,
  IBuyNowPayLaterBillingData,
  IBuyNowPayLaterCreateOrderClientRequest,
  IBuyNowPayLaterOrder,
  IBuyNowPayLaterSessionWithBillingData,
} from '@caronsale/cos-models';
import { BuyNowPayLaterProviderService } from '../buy-now-pay-later-provider-service.interface';
import {
  EBuyNowPayLaterBillieDeclineReason,
  EBuyNowPayLaterBillieDeclineReasonErrors,
  EBuyNowPayLaterBillieOrderStatus,
  IBuyNowPayLaterBillieWidgetResponse,
} from './billie-buy-now-pay-later.types';

@Injectable()
export class BillieBuyNowPayLaterService implements BuyNowPayLaterProviderService {
  private isBillieScriptLoaded = false;

  private readonly statusConversionMap: {
    [key in EBuyNowPayLaterBillieOrderStatus]: EBuyNowPayLaterOrderStatus;
  } = Object.freeze({
    [EBuyNowPayLaterBillieOrderStatus.AUTHORIZED]: EBuyNowPayLaterOrderStatus.APPROVED,
    [EBuyNowPayLaterBillieOrderStatus.PRE_WAITING]: EBuyNowPayLaterOrderStatus.PENDING,
    [EBuyNowPayLaterBillieOrderStatus.WAITING]: EBuyNowPayLaterOrderStatus.PENDING,
    [EBuyNowPayLaterBillieOrderStatus.DECLINED]: EBuyNowPayLaterOrderStatus.DECLINED,
  });

  private readonly declineReasonConversionMap: {
    [key in EBuyNowPayLaterBillieDeclineReason]: EBuyNowPayLaterDeclineReason;
  } = Object.freeze({
    [EBuyNowPayLaterBillieDeclineReason.DEBTOR_ADDRESS]: EBuyNowPayLaterDeclineReason.DEBTOR_ADDRESS,
    [EBuyNowPayLaterBillieDeclineReason.DEBTOR_LIMIT_EXCEEDED]: EBuyNowPayLaterDeclineReason.DEBTOR_LIMIT_EXCEEDED,
    [EBuyNowPayLaterBillieDeclineReason.DEBTOR_NOT_IDENTIFIED]: EBuyNowPayLaterDeclineReason.DEBTOR_NOT_IDENTIFIED,
    [EBuyNowPayLaterBillieDeclineReason.RISK_POLICY]: EBuyNowPayLaterDeclineReason.RISK_POLICY,
    [EBuyNowPayLaterBillieDeclineReason.RISK_SCORING_FAILED]: EBuyNowPayLaterDeclineReason.RISK_SCORING_FAILED,
  });

  public constructor(private cosBuyerClientService: CosBuyerClientService) {}

  public performOrderCreationWithSession(correlationId: string, session: IBuyNowPayLaterSessionWithBillingData): Observable<IBuyNowPayLaterOrder> {
    const ensureBillieScriptLoaded$ = this.isBillieScriptLoaded ? of(null) : from(this.loadBillieScript());
    const billingData = session.billingData;
    return ensureBillieScriptLoaded$.pipe(
      switchMap(() => {
        const billie_config_data = {
          session_id: session.id,
          merchant_name: 'Car On Sale',
        };
        const billie_order_data = this.parseBillingDataToBillieWidgetOrderData(billingData);
        return from(
          window['BillieCheckoutWidget'].mount({
            billie_config_data: billie_config_data,
            billie_order_data: billie_order_data,
            billie_widget_options: { payment_method: 'invoice' },
          }) as Promise<IBuyNowPayLaterBillieWidgetResponse>,
        ).pipe(
          switchMap((widgetResult: IBuyNowPayLaterBillieWidgetResponse) => {
            return this.cosBuyerClientService.confirmBuyNowPayLaterSession(correlationId, this.parseBillieWidgetResultToOrderCreation(widgetResult, session));
          }),
          catchError((widgetResult: IBuyNowPayLaterBillieWidgetResponse | Error) => {
            if (
              this.isWidgetResultError(widgetResult) ||
              Object.values(EBuyNowPayLaterBillieDeclineReasonErrors).includes(widgetResult.decline_reason as EBuyNowPayLaterBillieDeclineReasonErrors)
            ) {
              return throwError(() => widgetResult);
            }
            return this.cosBuyerClientService.confirmBuyNowPayLaterSession(correlationId, this.parseBillieWidgetResultToOrderCreation(widgetResult, session));
          }),
        );
      }),
    );
  }

  private isWidgetResultError(widgetResult: IBuyNowPayLaterBillieWidgetResponse | Error): widgetResult is Error {
    return widgetResult instanceof Error || (widgetResult as IBuyNowPayLaterBillieWidgetResponse).decline_reason == undefined;
  }

  private parseBillingDataToBillieWidgetOrderData(billingData: IBuyNowPayLaterBillingData) {
    return {
      amount: billingData.totalAmount,
      duration: billingData.durationInDays || 60,
      delivery_address: {
        house_number: billingData.deliveryAddress.houseNumber,
        street: billingData.deliveryAddress.streetName,
        city: billingData.deliveryAddress.city,
        postal_code: billingData.deliveryAddress.zipCode,
        country: billingData.deliveryAddress.country,
      },
      debtor_company: {
        address_city: billingData.buyerBusiness.address.city,
        address_country: billingData.buyerBusiness.address.country,
        address_house_number: billingData.buyerBusiness.address.houseNumber,
        address_postal_code: billingData.buyerBusiness.address.zipCode,
        address_street: billingData.buyerBusiness.address.streetName,
        name: billingData.buyerBusiness.name,
      },
      debtor_person: {
        first_name: billingData.buyer.firstName,
        last_name: billingData.buyer.lastName,
        phone_number: billingData.buyer.phoneNumber,
        email: billingData.buyer.email,
      },
      line_items: billingData.items,
    };
  }

  public convertStatus(billieStatus: EBuyNowPayLaterBillieOrderStatus): EBuyNowPayLaterOrderStatus {
    if (!billieStatus) {
      return null;
    }
    return this.statusConversionMap[billieStatus];
  }

  public convertDeclineReason(billieDeclineReason: EBuyNowPayLaterBillieDeclineReason): EBuyNowPayLaterDeclineReason {
    if (!billieDeclineReason) {
      return null;
    }
    return this.declineReasonConversionMap[billieDeclineReason];
  }

  private parseBillieWidgetResultToOrderCreation(
    billieResult: IBuyNowPayLaterBillieWidgetResponse,
    session: IBuyNowPayLaterSessionWithBillingData,
  ): IBuyNowPayLaterCreateOrderClientRequest {
    return {
      provider: EBuyNowPayLaterProvider.BILLIE,
      session: {
        id: session.id,
        preResult: this.convertStatus(billieResult.state),
        declineReason: this.convertDeclineReason(billieResult.decline_reason as EBuyNowPayLaterBillieDeclineReason),
      },
      items: billieResult.line_items,
      buyer: {
        email: billieResult.debtor_person.email,
        firstName: billieResult.debtor_person.first_name,
        lastName: billieResult.debtor_person.last_name,
        phoneNumber: billieResult.debtor_person.phone_number,
      },
      buyerBusiness: {
        name: billieResult.debtor_company.name,
        vatId: session.billingData.buyerBusiness.vatId,
        address: {
          city: billieResult.debtor_company.address_city,
          country: billieResult.debtor_company.address_country,
          streetName: billieResult.debtor_company.address_street,
          zipCode: billieResult.debtor_company.address_postal_code,
          houseNumber: billieResult.debtor_company.address_house_number,
        },
      },
      deliveryAddress: {
        city: billieResult.delivery_address.city,
        country: billieResult.delivery_address.country,
        streetName: billieResult.delivery_address.street,
        zipCode: billieResult.delivery_address.postal_code,
        houseNumber: billieResult.delivery_address.house_number,
      },
      durationInDays: billieResult.duration,
      totalAmount: billieResult.amount,
      feeAmount: session.billingData.feeAmount,
      feeRate: session.billingData.feeRate,
    };
  }

  private loadBillieScript(): Observable<void> {
    const bcwSrc = `${environment.billieStaticUrl}/checkout/billie-checkout.js`;
    const BILLIE_SCRIPT_LOADING_TIMEOUT = 10000;
    return from<Promise<void>>(
      new Promise((resolve, reject) => {
        (function (w, d, s, o, f, js, fjs) {
          w['BillieCheckoutWidget'] = o;
          w[o] =
            w[o] ||
            function (...args) {
              (w[o].q = w[o].q || []).push(args);
            };
          w['billieSrc'] = f;
          js = d.createElement(s);
          fjs = d.getElementsByTagName(s)[0];
          js.id = o;
          if (js.readyState) {
            //IE
            js.onreadystatechange = () => {
              if (js.readyState === 'loaded' || js.readyState === 'complete') {
                js.onreadystatechange = null;
                resolve(null);
              }
            };
          } else {
            //Others
            js.onload = () => {
              resolve(null);
            };
          }
          js.onerror = err => reject({ loaded: false, status: 'Loaded', err });
          js.src = f;
          js.charset = 'utf-8';
          js.async = 1;
          fjs.parentNode.insertBefore(js, fjs);
          window['bcw']('init');
        })(window, document, 'script', 'bcw', bcwSrc);
      }),
    ).pipe(
      switchMap(() =>
        interval(100).pipe(
          filter(() => !!window['__BILLIE_CHECKOUT_CONFIG__']),
          first(),
          timeout({
            each: BILLIE_SCRIPT_LOADING_TIMEOUT,
            with: () => throwError(() => new Error('Billie script did not load')),
          }),
        ),
      ),
      map(() => void 0),
      tap(() => {
        this.isBillieScriptLoaded = true;
      }),
    );
  }
}
