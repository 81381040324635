import { Component, inject } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { EnzoComponentsModule } from '@caronsale/enzo-angular';
import { TranslateModule } from '@ngx-translate/core';
import { debounceTime } from 'rxjs';
import { AuctionFilterService } from '@cosBuyer/auctions/services/auction-filter.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { TrackFormControlDirective } from '@cosBuyer/directives/track-form-control.directive';

export const QUICK_SEARCH_MAX_LENGTH = 100;

@Component({
  standalone: true,
  selector: 'app-quick-search',
  templateUrl: './quick-search.component.html',
  styleUrls: ['./quick-search.component.scss'],
  imports: [EnzoComponentsModule, ReactiveFormsModule, TrackFormControlDirective, TranslateModule],
})
export class QuickSearchComponent {
  public readonly QUICK_SEARCH_MAX_LENGTH = QUICK_SEARCH_MAX_LENGTH;
  public input = new FormControl('');
  private stateService = inject(AuctionFilterService);

  public constructor() {
    this.input.valueChanges.pipe(debounceTime(300), takeUntilDestroyed()).subscribe(value => {
      this.stateService.updateQuickSearch(value);
    });

    this.stateService.selectQuickSearch.pipe(takeUntilDestroyed()).subscribe(search => {
      if (this.input.value !== search) {
        this.input.setValue(search, { emitEvent: false });
      }
    });
  }
}
