import { ChangeDetectionStrategy, Component, inject, input } from '@angular/core';
import { AsyncPipe } from '@angular/common';
import { map } from 'rxjs';

import { TranslateModule } from '@ngx-translate/core';

import { IAuction } from '@caronsale/cos-models';
import { EnzoComponentsModule } from '@caronsale/enzo-angular';

import { BirdViewComponent } from '../bird-view/bird-view.component';
import { VehicleDamagesGalleryComponent } from '../vehicle-damages-gallery/vehicle-damages-gallery.component';
import { VEHICLE_DAMAGE_LOCATIONS, VehicleDamagesService } from '../vehicle-damages.service';
import { NoDamagesCardComponent } from '../no-damage-card/no-damage-card.component';

@Component({
  selector: 'app-vehicle-damages-info',
  standalone: true,
  imports: [TranslateModule, EnzoComponentsModule, BirdViewComponent, VehicleDamagesGalleryComponent, NoDamagesCardComponent, AsyncPipe],
  templateUrl: './vehicle-damages-info.component.html',
  styleUrls: ['./vehicle-damages-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VehicleDamagesInfoComponent {
  public auction = input.required<IAuction>();
  public editView = input(false);
  public hasVehicleDamages$ = inject(VehicleDamagesService).vehicleDamages$.pipe(
    map(vehicleDamages => VEHICLE_DAMAGE_LOCATIONS.filter(key => Boolean(vehicleDamages[key].length)).length),
  );
}
