import { Pipe, PipeTransform } from '@angular/core';
import { EVehicleTireConditionAssessment, IVehicleTireDetails } from '@caronsale/cos-vehicle-models';

@Pipe({
  standalone: true,
  name: 'tireConditionClass',
})
export class TireConditionClassPipe implements PipeTransform {
  public transform(tireSet: IVehicleTireDetails): string {
    switch (tireSet.conditionAssessment) {
      case EVehicleTireConditionAssessment.BAD:
        return 'bad';

      case EVehicleTireConditionAssessment.MEDIUM:
        return 'medium';

      case EVehicleTireConditionAssessment.GOOD:
        return 'good';

      case EVehicleTireConditionAssessment.NONE:
        return 'unknown';

      default:
        return 'info';
    }
  }
}
