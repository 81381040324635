import { Component, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { Subject } from 'rxjs';

import { IAuction, IBuyerAuctionView, ISellerAuctionView } from '@caronsale/cos-models';
import { BuyerAuctionService } from '@cosCoreFeatures/auction-detail/common/auction-service/buyer-auction.service';

@Component({
  selector: 'app-status-info',
  templateUrl: './status-info.component.html',
  styleUrls: ['./status-info.component.scss'],
})
export class StatusInfoComponent implements OnDestroy, OnChanges {
  @Input()
  public auctionBuyer: IBuyerAuctionView;

  @Input()
  public auctionSeller: ISellerAuctionView;

  @Input()
  public progressBar: 'hot-bid-top' | 'hot-bid-bottom' | 'border-only-bottom' | 'none' = 'border-only-bottom';

  @Input()
  public kind: 'thin' | 'normal' = 'normal';

  public remainingTimeInSeconds: number;
  public auction: IAuction;
  public isHotBidPhase: boolean;
  public isAuctionRatedYet = false;

  private unsubscribe$: Subject<void> = new Subject<void>();

  public constructor(public buyerAuctionService: BuyerAuctionService) {}

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.auctionBuyer && this.auctionBuyer) {
      this.initFromAuctionBuyer(this.auctionBuyer);
    }
    if (changes.auctionSeller && this.auctionSeller) {
      this.initFromAuctionSeller(this.auctionSeller);
    }
  }

  private initFromAuctionSeller(auctionSeller: ISellerAuctionView) {
    this.auction = auctionSeller;
    this.isHotBidPhase = this.buyerAuctionService.isHotBidPhaseActive(auctionSeller);
    this.isAuctionRatedYet = auctionSeller.isRatedByDealership;
    this.remainingTimeInSeconds = auctionSeller.remainingTimeInSeconds || 0;
  }

  private initFromAuctionBuyer(auctionBuyer: IBuyerAuctionView) {
    this.auction = auctionBuyer;
    this.isHotBidPhase = this.buyerAuctionService.isHotBidPhaseActive(auctionBuyer);
    this.remainingTimeInSeconds = this.buyerAuctionService.isInstantPurchaseActive(auctionBuyer)
      ? auctionBuyer.remainingTimeForInstantPurchaseInSeconds
      : auctionBuyer.remainingTimeInSeconds;
  }

  public ngOnDestroy(): void {
    this.unsubscribe$.next();
  }
}
