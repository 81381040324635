<div class="root">
  <div class="background" @enter-fade (click)="this.close()"></div>

  <div class="content" @enter-slide [@leave-slide]="this.isClosing && 'leaving'" (@leave-slide.done)="this.onLeaveSlideDone($event)">
    <ng-container [ngSwitch]="contentType">
      <ng-container *ngSwitchCase="'string'">
        <div class="text-content">
          <div [innerHTML]="content"></div>
        </div>
      </ng-container>

      <ng-container *ngSwitchCase="'template'">
        <ng-container *ngTemplateOutlet="content; context: context"></ng-container>
      </ng-container>

      <ng-container *ngSwitchCase="'component'">
        <ng-container *ngComponentOutlet="content"></ng-container>
      </ng-container>
    </ng-container>
  </div>
</div>
