import { Inject, Injectable, NgZone } from '@angular/core';
import { AppEnvironment, IAppEnvironment } from '@cosCoreEnvironments/IAppEnvironment';
import { Observable, share } from 'rxjs';
import * as io from 'socket.io-client';

// this code is adapted from https://github.com/rodgc/ngx-socket-io/blob/master/src/socket-io.service.ts
// runOutsideAngular is used to make the poll timer (keep-alive of the socket) run outside angular to avoid changeDetection runs.
// only the incoming data (observer.next()) is handled in the angular zone

@Injectable({
  providedIn: 'root',
})
export class NotificationSocket {
  public ioSocket: SocketIOClient.Socket;

  private subscribersCounter: Record<string, number> = {};
  private eventObservables$: Record<string, Observable<any>> = {};

  public constructor(
    @Inject(AppEnvironment) private environment: IAppEnvironment,
    private readonly zone: NgZone,
  ) {
    const ioFunc: SocketIOClientStatic = (io as any).default ? (io as any).default : io;
    this.zone.runOutsideAngular(() => {
      this.ioSocket = ioFunc(environment.notificationCenterSocketUrl, { autoConnect: false, transports: ['websocket', 'polling'] });
    });
  }

  public connect() {
    this.zone.runOutsideAngular(() => {
      return this.ioSocket.connect();
    });
  }

  public emit(eventName: string, ...args: any[]) {
    this.zone.runOutsideAngular(() => {
      return this.ioSocket.emit(eventName, ...args);
    });
  }

  public removeAllListeners() {
    this.zone.runOutsideAngular(() => {
      return this.ioSocket.removeAllListeners();
    });
  }

  public fromEvent<T>(eventName: string): Observable<T> {
    if (!this.subscribersCounter[eventName]) {
      this.subscribersCounter[eventName] = 0;
    }
    this.subscribersCounter[eventName]++;

    if (!this.eventObservables$[eventName]) {
      this.eventObservables$[eventName] = new Observable((observer: any) => {
        const listener = (data: T) => {
          this.zone.run(() => observer.next(data));
        };
        this.zone.runOutsideAngular(() => {
          this.ioSocket.on(eventName, listener);
        });
        return () => {
          if (--this.subscribersCounter[eventName] === 0) {
            this.ioSocket.removeListener(eventName, listener);
            delete this.eventObservables$[eventName];
          }
        };
      }).pipe(share());
    }
    return this.eventObservables$[eventName];
  }
}
