import { Component, Input, OnChanges } from '@angular/core';

import { IBuyerAuctionView } from '@caronsale/cos-models';
import { Popover } from '@cosCoreFeatures/@cos/cos-popover/services/popover.service';
import { TranslateService } from '@ngx-translate/core';
import { BuyerAuctionService } from '@cosCoreFeatures/auction-detail/common/auction-service/buyer-auction.service';

// TODO: This is a boilerplate to test potential financing integrations in the future

// TODO: This will be moved to cos-models:

/**
 * The types of financing products that can be used for a transaction.
 *
 */
export enum EFinancingProductType {
  /**
   * This is a special type, indicating that "no financing" is used as a product.
   *
   */
  NONE = 0,

  /**
   * This type indicates money is paid out to the recipient upfront and the buyer has to pay it back later.
   * The payment due date can be determined via a different parameter.
   *
   */
  PAY_LATER = 1,
}

/**
 * An option for financing a transaction (Buy Now, Pay Later) for a buyer.
 *
 * Both the payment in days
 *
 */
export interface IBuyerFinancingOption {
  /**
   * The product type of this option.
   *
   */
  financingProductType: EFinancingProductType;

  /**
   * The number of days which a buyer has to pay back the financing.
   *
   */
  paymentDueInDays: number;

  /**
   * The net price that the buyer would have to pay for the option.
   *
   */
  netPrice: number;
}

@Component({
  selector: 'app-financing-actions',
  templateUrl: './financing-actions.component.html',
  styleUrls: ['./financing-actions.component.scss'],
})
export class FinancingActionsComponent implements OnChanges {
  @Input()
  public auction: IBuyerAuctionView;

  // TODO: Get limit from backend?
  public currentFinancingLimit: number = 90000;

  // TODO: Get data type for that & obtain from backend dynamically
  public financingOptions: IBuyerFinancingOption[] = [
    {
      financingProductType: EFinancingProductType.NONE,
      paymentDueInDays: 8,
      netPrice: 0,
    },
    {
      financingProductType: EFinancingProductType.PAY_LATER,
      paymentDueInDays: 60,
      netPrice: 99, // TODO: This will be calculated on the backend side
    },
    {
      financingProductType: EFinancingProductType.PAY_LATER,
      paymentDueInDays: 90,
      netPrice: 199, // TODO: This will be calculated on the backend side
    },
    {
      financingProductType: EFinancingProductType.PAY_LATER,
      paymentDueInDays: 120,
      netPrice: 299, // TODO: This will be calculated on the backend side
    },
  ];

  public selectedFinancingOption: IBuyerFinancingOption = this.financingOptions[0]; // Default is "Standard Payment"

  public constructor(
    private translateService: TranslateService,
    private popover: Popover,
    private buyerAuctionService: BuyerAuctionService,
  ) {}

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  public ngOnChanges(): void {
    // TODO: Financing options might need to be updated, since the highest bid might have changed
    // TODO: Credit line might need to get updated here as well (in case financing is used & the highest bid changed)
  }

  public financingSelectionChanged(): void {
    // Require confirmation in case a "Pay Later" option is selected
    // TODO: Implement
    // if (this.selectedFinancingOption.financingProductType === EFinancingProductType.PAY_LATER) {
    //   I18nConfirmationDialogComponent.showConfirmDialogWithRejection(this.dialog, 'financing.dialogs.selection-confirmation', {}, '450px').subscribe(
    //     isConfirmed => {
    //       if (!isConfirmed) {
    //         // If buyer does not confirm, reset to standard:
    //         this.selectedFinancingOption = this.financingOptions[0]; // Reset to default payment terms
    //       } else {
    //         // TODO: Trigger upsert of the backend financing product object?
    //       }
    //     },
    //   );
    // }
  }

  public showFinancingInfoPopover(_event: Event): void {
    // TODO: Implement
    // this.translateService.get([`financing.buyer-info.label`, `financing.buyer-info.content`]).subscribe(values => {
    //   const label = values[`financing.buyer-info.label`];
    //   const content = values[`financing.buyer-info.content`];
    //
    //   this.popover.open({
    //     width: `450px`,
    //     content: `<h3><strong>${label}</strong></h3>` + `<p>${content}</p>`,
    //     origin: event.currentTarget as HTMLElement,
    //   });
    // });
  }
}
