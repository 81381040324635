<ng-container *ngIf="vm$ | async as vm">
  <mat-form-field
    testid="voucher-form-field"
    *ngIf="((vm.availableVouchers && vm.availableVouchers.length > 0) || vm.selectedVoucher) && !(auction | isAuctionSuccessful)"
    appearance="outline">
    <mat-select [formControl]="voucherControl" (selectionChange)="selectVoucher($event.value)" [compareWith]="compareVouchers">
      <mat-select-trigger>
        <enzo-icon icon="voucher" size="small"></enzo-icon>
        {{ formatVoucherOption(vm.selectedVoucher) }}
      </mat-select-trigger>

      <mat-option [value]="null"> {{ 'voucher.display.no-voucher' | translate }} </mat-option>
      <mat-option *ngIf="!vm.availableVouchers && vm.selectedVoucher" [value]="vm.selectedVoucher">
        <enzo-text kind="label">{{ formatVoucherOption(vm.selectedVoucher) }}</enzo-text>

        <enzo-text *ngIf="vm.selectedVoucher.expirationInDays < 15" kind="caption" class="voucher-caption">
          {{ vm.selectedVoucher.expirationTextKey | translate: { numberOfDays: vm.selectedVoucher.expirationInDays } }}</enzo-text
        >
      </mat-option>
      <mat-option *ngFor="let voucher of vm.availableVouchers" [value]="voucher" testid="voucher-option">
        <enzo-text kind="label">{{ formatVoucherOption(voucher) }}</enzo-text>

        <enzo-text *ngIf="voucher.expirationInDays < 15" kind="caption" class="voucher-caption">{{
          voucher.expirationTextKey | translate: { numberOfDays: voucher.expirationInDays }
        }}</enzo-text>
      </mat-option>
    </mat-select>

    <mat-label [class.iconless]="vm.selectedVoucher">
      <enzo-icon icon="voucher" size="small" *ngIf="!vm.selectedVoucher"></enzo-icon>
      {{ 'voucher.voucher' | translate }}
    </mat-label>

    <mat-hint>*{{ 'voucher.disclaimer' | translate }}</mat-hint>
  </mat-form-field>
</ng-container>
