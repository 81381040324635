import { EVehicleEquipmentGroup, IVehicleEquipmentData } from '@caronsale/cos-vehicle-models';

export interface IEquipmentData extends IVehicleEquipmentData {
  removed?: boolean;
}

export const groupOrder: EVehicleEquipmentGroup[] = [
  EVehicleEquipmentGroup.EXTERIOR,
  EVehicleEquipmentGroup.INTERIOR,
  EVehicleEquipmentGroup.MULTIMEDIA,
  EVehicleEquipmentGroup.SAFETY_COMFORT,
  EVehicleEquipmentGroup.LIGHTING,
  EVehicleEquipmentGroup.ENGINE,
  EVehicleEquipmentGroup.TRANSMISSION,
  EVehicleEquipmentGroup.AXLES_CHASSIS,
];

export type GroupedEquipmentData = Partial<{
  [key in EVehicleEquipmentGroup]: {
    equipments: IEquipmentData[];
    translationKey: string;
  };
}>;

export function processEquipmentData(equipmentList: IVehicleEquipmentData[], groups: GroupedEquipmentData, otherGroup: IVehicleEquipmentData[]): void {
  equipmentList
    .sort((a, b) => groupOrder.indexOf(a.group) - groupOrder.indexOf(b.group))
    .forEach(equipment => {
      const group = equipment.group;
      if (!group) {
        otherGroup.push(equipment);
      } else {
        if (!groups[group]) {
          groups[group] = { equipments: [], translationKey: `vehicle.equipmentGroups.${group.toLowerCase().replace('_', '-')}` };
        }
        groups[group].equipments.push(equipment);
      }
    });
}

export function sortEquipmentData(groups: GroupedEquipmentData, otherGroup: IVehicleEquipmentData[]): void {
  otherGroup.sort((a, b) => a.type - b.type);
  (Object.keys(groups) as Array<keyof typeof EVehicleEquipmentGroup>).forEach(groupKey => {
    const group = groups[groupKey];
    group.equipments.sort((a, b) => a.type - b.type);
  });
}
