import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { FormControlName, ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { KeyValuePipe } from '@angular/common';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-pin-code',
  templateUrl: './pin-code.component.html',
  styleUrls: ['./pin-code.component.scss'],
  standalone: true,
  imports: [KeyValuePipe, ReactiveFormsModule],
})
export class PinCodeComponent implements OnInit, OnDestroy {
  public pinCode: UntypedFormGroup = new UntypedFormGroup({});

  @Input()
  public id: string;

  @Input()
  public numberDigits: number = 0;

  @Output()
  public pinComplete = new EventEmitter<string>();

  @ViewChildren(FormControlName, { read: ElementRef })
  public inputElements: QueryList<ElementRef>;

  private destroyed$ = new Subject<void>();

  // Allow decimal numbers and letters only
  private forbiddenKeys = /[^0-9a-zA-Z]/;

  public constructor(private formBuilder: UntypedFormBuilder) {}

  public ngOnInit(): void {
    if (this.numberDigits > 0) {
      for (let i = 0; i < this.numberDigits; i++) {
        this.pinCode.addControl('digit' + i, this.formBuilder.control('', Validators.required));
        const inputControl = this.pinCode.get('digit' + i);
        inputControl.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(value => {
          let sanitizedValue = this.sanitize(value);
          const hasNext = i < this.numberDigits - 1;
          if (sanitizedValue.length > 1) {
            if (hasNext) {
              this.pinCode.get('digit' + (i + 1)).setValue(sanitizedValue[1], { emitEvent: false });
            }
            sanitizedValue = sanitizedValue[0];
          }
          if (value !== sanitizedValue) {
            inputControl.setValue(sanitizedValue, { emitEvent: false });
          }
          if (sanitizedValue.length !== 0 && hasNext) {
            this.setFocusTo(i + 1);
          }
        });
      }
      this.pinCode.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(allInputValues => {
        const pin = Object.values(allInputValues).join('');
        console.log(pin);
        this.pinComplete.emit(pin.length === this.numberDigits ? pin : null);
      });
    }
  }

  public ngOnDestroy(): void {
    this.destroyed$.next();
  }

  public keyDown(event: KeyboardEvent) {
    const specialKeys: Array<string> = ['Backspace', 'Delete', 'Tab', 'ArrowLeft', 'ArrowRight'];
    const target = event.target as HTMLInputElement;

    if (specialKeys.indexOf(event.key) !== -1) {
      let elToGoTo: Element;
      if (event.key === 'ArrowLeft' || (event.key === 'Backspace' && target.value === '')) {
        // on backspace, go to previous field if the current one is empty.
        // The default handling of backspace will erase its value.
        elToGoTo = target.previousElementSibling;
      }
      if (event.key === 'ArrowRight') {
        elToGoTo = target.nextElementSibling;
      }
      // go there if it exists
      (elToGoTo as HTMLInputElement)?.focus();
      // allow all special keys
      return;
    }

    if (String(event.key).match(this.forbiddenKeys)) {
      event.preventDefault();
    }
  }

  public handleInputClick($event) {
    $event.stopPropagation();
  }

  private sanitize(value: string) {
    // map null and undefined to empty string
    if (!value) {
      return '';
    }
    return value.replaceAll(new RegExp(this.forbiddenKeys, 'g'), '').toUpperCase();
  }

  private setFocusTo(number: number) {
    this.inputElements.toArray()[number].nativeElement.focus();
  }
}
