import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { TranslateModule } from '@ngx-translate/core';

import { AppMaterialModule } from '../app-material.module';
import { FormValidateIntegerComponent } from './form-validate-integer/form-validate-integer.component';

@NgModule({
  declarations: [
    //
    FormValidateIntegerComponent,
  ],
  imports: [
    //
    CommonModule,
    AppMaterialModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
  ],
  exports: [
    //
    FormValidateIntegerComponent,
  ],
})
export class FormValidationModule {}
