import { Pipe, PipeTransform } from '@angular/core';
import { I18nFormatUtils } from '@caronsale/cos-models';
import { EVehicleTireLocation } from '@caronsale/cos-vehicle-models';

@Pipe({
  standalone: true,
  name: 'i18nTireLocation',
})
export class I18nTireLocationPipe implements PipeTransform {
  public transform(tireLocation: EVehicleTireLocation): string {
    return I18nFormatUtils.formatVehicleTireLocation(tireLocation);
  }
}
