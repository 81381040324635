import { Injectable } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';

import {
  ECountryCode,
  EPrebookedServiceType,
  ETransportationTaskState,
  IBuyerAuctionView,
  IPrebookedService,
  PrebookedServiceTransportOptionType,
  Time,
  Validation,
} from '@caronsale/cos-models';
import { CountryCodeService } from '@caronsale/frontend-services';

import { CosBuyerClientService } from '@cosCoreServices/cos-salesman-client/cos-buyer-client.service';
import { BuyerAuctionService } from '@cosCoreFeatures/auction-detail/common/auction-service/buyer-auction.service';
import { PrebookedServicesUtils } from '@caronsale/frontend-utils';

export interface IEstimatedDelivery {
  from: number;
  to: number;
}

export enum ETransportationAvailability {
  ENFORCED_PRICE_NOT_REQUESTED_YET,
  ENFORCED_PRICE_IS_REQUESTED,
  ENFORCED_PRICE_IS_KNOWN,
  AVAILABLE_PRICE_NOT_REQUESTED_YET,
  AVAILABLE_PRICE_IS_REQUESTED,
  AVAILABLE_PRICE_IS_KNOWN,
  NOT_ALLOWED_FOR_REGION,
  NOT_AVAILABLE, // vehicle not ready to drive
  DISABLED_MANUALLY, // by the seller for this auction
  BOOKING_NOT_POSSIBLE_ANY_MORE, // more than 1 working day after auction closed
  JOB_REJECTED,
  BOOKED_OR_PREBOOKED,
  BOOKED_OR_PREBOOKED_BOOKING_NOT_POSSIBLE_ANY_MORE,
}

export interface ITransportationViewModel {
  transportationAvailability: ETransportationAvailability;
  transportationPrice: number;
  isPriceRequestPossible: boolean;
  isDiscountAvailable: boolean;
}

export enum ESelfPickupAvailability {
  AVAILABLE, // in bidding confirmation dialog, when transport is selected but not persisted at the backend (user can still switch back to self pickup)
  NOT_AVAILABLE_TRANSPORT_ENFORCED,
  AVAILABLE_BUT_TRANSPORT_SELECTED, // if transportation is prebooked (prebooking is persisted at the backend), we do not offer self-pickup any more
  SELECTED,
}

@Injectable({
  providedIn: 'root',
})
export class BuyerTransportationService {
  public static readonly COMPOUND_ESTIMATED_DELIVERY: IEstimatedDelivery = { from: 12, to: 15 };
  public static readonly CROSS_BORDER_ESTIMATED_DELIVERY: IEstimatedDelivery = { from: 10, to: 12 };
  public static readonly REGULAR_ESTIMATED_DELIVERY: IEstimatedDelivery = { from: 8, to: 10 };

  public constructor(
    //
    private cosBuyerClientService: CosBuyerClientService,
    private buyerAuctionService: BuyerAuctionService,
    private countryCodeService: CountryCodeService,
  ) {}

  public getTransportationViewModel(auction: IBuyerAuctionView, prebookedServices?: IPrebookedService[]): ITransportationViewModel {
    return {
      transportationAvailability: this.getTransportationAvailability(auction, prebookedServices),
      transportationPrice: this.getTransportationPrice(auction),
      isPriceRequestPossible: this.isPriceRequestPossible(auction),
      isDiscountAvailable: this.doesDiscountApply(auction),
    };
  }

  public getTransportationAvailability(auction: IBuyerAuctionView, prebookedServices?: IPrebookedService[]): ETransportationAvailability {
    if (Validation.isTransportationJobBooked(auction.transportationTask) || this.isTransportationSelected(prebookedServices || auction.prebookedServices)) {
      if (auction.enforceTransportation) {
        return ETransportationAvailability.ENFORCED_PRICE_IS_KNOWN;
      } else {
        if (Validation.isAuctionClosedAndWaitingForPickup(auction) || !Validation.isUndefinedOrNull(auction.incomingPaymentConfirmedAt)) {
          return ETransportationAvailability.BOOKED_OR_PREBOOKED_BOOKING_NOT_POSSIBLE_ANY_MORE;
        }
        return ETransportationAvailability.BOOKED_OR_PREBOOKED;
      }
    }
    if (!auction.isTransportationAllowedForRegion) {
      return ETransportationAvailability.NOT_ALLOWED_FOR_REGION;
    }
    if (!auction.isTransportationAvailable) {
      return ETransportationAvailability.NOT_AVAILABLE;
    }
    if (auction.isTransportationDisabledManually) {
      return ETransportationAvailability.DISABLED_MANUALLY;
    }
    if (auction.transportationTask?.state === ETransportationTaskState.REJECTED_JOB) {
      return ETransportationAvailability.JOB_REJECTED;
    }
    if (!this.isPriceRequestPossible(auction)) {
      return ETransportationAvailability.BOOKING_NOT_POSSIBLE_ANY_MORE;
    }
    if (!auction.transportationTask) {
      return auction.enforceTransportation
        ? ETransportationAvailability.ENFORCED_PRICE_NOT_REQUESTED_YET
        : ETransportationAvailability.AVAILABLE_PRICE_NOT_REQUESTED_YET;
    }
    if (auction.transportationTask.state === ETransportationTaskState.REQUESTED_PRICE) {
      return auction.enforceTransportation ? ETransportationAvailability.ENFORCED_PRICE_IS_REQUESTED : ETransportationAvailability.AVAILABLE_PRICE_IS_REQUESTED;
    }
    // ETransportationTaskState.PROPOSED_PRICE is the only remaining state
    return auction.enforceTransportation ? ETransportationAvailability.ENFORCED_PRICE_IS_KNOWN : ETransportationAvailability.AVAILABLE_PRICE_IS_KNOWN;
  }

  public getSelfPickupAvailability(auction: IBuyerAuctionView, prebookedServices?: IPrebookedService[]): ESelfPickupAvailability {
    if (auction.enforceTransportation) {
      return ESelfPickupAvailability.NOT_AVAILABLE_TRANSPORT_ENFORCED;
    }
    if (this.isTransportationSelected(prebookedServices || auction.prebookedServices)) {
      return this.isSelfPickupEnabled(prebookedServices || auction.prebookedServices)
        ? ESelfPickupAvailability.AVAILABLE
        : ESelfPickupAvailability.AVAILABLE_BUT_TRANSPORT_SELECTED;
    }
    return ESelfPickupAvailability.SELECTED;
  }

  public isPriceRequestPossible(auction: IBuyerAuctionView): boolean {
    return Validation.isAuctionRunning(auction) || Validation.isAuctionClosedWithBidBelowMinimumAsk(auction) || auction.isTransportationBookingPossible;
  }

  private getTransportationPrice(auction: IBuyerAuctionView): number | null {
    // if the transportation task is not present we would theoretically need to request a transportation price
    // but we rely on the transportation-info component to do so, so sooner or later a transportationTask will be there
    // and we will be asked again by ngOnChanges()

    if (auction.transportationTask && !Validation.isUndefinedOrNull(auction.transportationTask.netPrice)) {
      if (Validation.isTransportationJobBooked(auction.transportationTask)) {
        return auction.transportationTask.netPrice; // then we do not need to care for the discount (netPrice is the one he got)
      }
      return this.doesDiscountApply(auction) ? auction.transportationTask.discountedNetPrice : auction.transportationTask.netPrice;
    }

    // Todo: check if we have a price in the prebooked services
    return null;
  }

  public getTransportationPriceForFeeInfo(auction: IBuyerAuctionView, prebookedServices?: IPrebookedService[]): number {
    const transportationViewModel: ITransportationViewModel = this.getTransportationViewModel(auction, prebookedServices);
    const includeTransportationFee =
      transportationViewModel.transportationAvailability === ETransportationAvailability.ENFORCED_PRICE_IS_KNOWN ||
      transportationViewModel.transportationAvailability === ETransportationAvailability.BOOKED_OR_PREBOOKED;
    return includeTransportationFee ? transportationViewModel.transportationPrice : 0;
  }

  public isTransportationSelected(prebookedServices: IPrebookedService[]): boolean {
    const transportService = PrebookedServicesUtils.getService(prebookedServices, EPrebookedServiceType.TRANSPORT);
    const selectedOption = transportService?.options.find(option => option.isSelected);
    return selectedOption && selectedOption.type !== 'selfPickup';
  }

  public isSelfPickupEnabled(prebookedServices: IPrebookedService[]): boolean {
    const transportService = PrebookedServicesUtils.getService(prebookedServices, EPrebookedServiceType.TRANSPORT);
    const selfPickupOption = transportService?.options.find(option => option.type === 'selfPickup');
    return selfPickupOption && selfPickupOption.isEnabled;
  }

  public doesDiscountApply(auction: IBuyerAuctionView) {
    return (
      auction.transportationTask?.discountedNetPrice &&
      (Validation.isAuctionRunning(auction) || Time.getHoursBetween(auction.purchaseConfirmedAt, Time.now()) < 24) &&
      auction.transportationTask.netPrice !== auction.transportationTask.discountedNetPrice &&
      auction.transportationTask.netPrice > auction.transportationTask.discountedNetPrice
    );
  }

  public isTransportationBookingAvailable(auction: IBuyerAuctionView): boolean {
    const availability = this.getTransportationAvailability(auction);
    const availableStates = [
      ETransportationAvailability.AVAILABLE_PRICE_IS_KNOWN,
      ETransportationAvailability.AVAILABLE_PRICE_IS_REQUESTED,
      ETransportationAvailability.AVAILABLE_PRICE_NOT_REQUESTED_YET,
    ];

    return availableStates.includes(availability);
  }

  public requestTransportationPrice(auction: IBuyerAuctionView, unsubscribe$: Subject<void>) {
    const auctionUuid = auction.uuid; // keep a copy of the uuid. In case the input changes during our request

    this.cosBuyerClientService
      .requestTransportationForAuction(auction)
      .pipe(takeUntil(unsubscribe$))
      .subscribe({
        next: () => this.buyerAuctionService.refreshBuyerAuction(auctionUuid), // ngOnChanges is called when the update arrives
        error: errorResponse => {
          if (errorResponse.status === 409) {
            // the transport request already exists in the auction so we need to update the auction
            this.buyerAuctionService.refreshBuyerAuction(auctionUuid);
          }
        },
      });
  }

  public selectPrebookedTransportOption(
    auctionUuid: string, // for debug output only
    prebookedServices: IPrebookedService[],
    transportOptionToSelect: PrebookedServiceTransportOptionType,
    isAdmin?: boolean, // allow selection of disabled options.
  ): IPrebookedService[] {
    return PrebookedServicesUtils.selectServiceOption(auctionUuid, prebookedServices, EPrebookedServiceType.TRANSPORT, transportOptionToSelect, true, isAdmin);
  }

  public getEstimatedDeliveryDays(auction: IBuyerAuctionView): IEstimatedDelivery {
    const currentTransportationTask = auction.transportationTask;

    if (Validation.isNumber(currentTransportationTask?.minDaysToPickup) && Validation.isNumber(currentTransportationTask?.maxDaysToPickup)) {
      return {
        from: currentTransportationTask.minDaysToPickup,
        to: currentTransportationTask.maxDaysToPickup,
      };
    }

    if (auction.isCompoundPickup || auction.sellerAccount.isCompoundEnabled) {
      return BuyerTransportationService.COMPOUND_ESTIMATED_DELIVERY;
    }

    if (this.isCrossBorderAuction(auction)) {
      return BuyerTransportationService.CROSS_BORDER_ESTIMATED_DELIVERY;
    }

    return BuyerTransportationService.REGULAR_ESTIMATED_DELIVERY;
  }

  public isExpressPickup(auction: IBuyerAuctionView): boolean {
    return auction.isExpressPickupAvailable && auction.isExternalPaymentAllowed && !auction.enforceTransportation;
  }

  public isDutchToForeignAuction(auction: IBuyerAuctionView): boolean {
    return auction.locationCountryCode === ECountryCode.NL && this.isCrossBorderAuction(auction);
  }

  private isCrossBorderAuction(auction: IBuyerAuctionView): boolean {
    return this.countryCodeService.userCountryCode !== auction.locationCountryCode;
  }
}
