import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BaseModule } from '@cosCoreComponentsGeneral/base.module';
import { AuctionPipesModule, GeneralPipesModule } from '@caronsale/frontend-pipes';
import { HighestBidValueComponent } from './highest-bid-value.component';
import { EnzoComponentsModule } from '@caronsale/enzo-angular';

@NgModule({
  declarations: [HighestBidValueComponent],
  exports: [HighestBidValueComponent],
  imports: [
    //
    AuctionPipesModule,
    BaseModule,
    CommonModule,
    GeneralPipesModule,
    EnzoComponentsModule,
  ],
})
export class HighestBidValueModule {}
