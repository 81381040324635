import { ChangeDetectionStrategy, Component, booleanAttribute, inject, input, output } from '@angular/core';

import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { EnzoComponentsModule } from '@caronsale/enzo-angular';

import { BuyerAuctionService } from '@cosCoreFeatures/auction-detail/common/auction-service/buyer-auction.service';
import { GoogleAnalyticsService } from '@cosCoreServices/google-analytics/google-analytics.service';
import { I18nSnackService } from '@cosCoreServices/i18n-snack/i18n-snack.service';
import { IBuyerAuctionView } from '@caronsale/cos-models';
import { ProductAnalyticsService } from '@cosCoreServices/product-analytics/product-analytics.service';
import { WatchlistAddedProperties } from '@cosCoreServices/product-analytics/amplitude/ampli';
import { RecommendationIdService } from '../services/recommendation-id.service';

@Component({
  standalone: true,
  selector: 'app-watchlist-button',
  templateUrl: './watchlist-button.component.html',
  styleUrls: ['./watchlist-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [EnzoComponentsModule, TranslateModule, MatTooltipModule],
})
export class WatchlistButtonComponent {
  private recommendationIdService = inject(RecommendationIdService, { optional: true });

  private i18nSnackService = inject(I18nSnackService);
  private buyerAuctionService = inject(BuyerAuctionService);
  private googleAnalyticsService = inject(GoogleAnalyticsService);
  private productAnalyticsService = inject(ProductAnalyticsService);

  public auction = input.required<IBuyerAuctionView>();
  public watchlistLocation = input.required<WatchlistAddedProperties['Watchlist type']>();
  public bright = input<boolean, boolean>(false, { transform: booleanAttribute });
  public isParkedChange = output<boolean>();
  public iconSize = input<'small' | 'medium'>('medium');

  public updateWatchlist() {
    if (this.auction().amIHighestBidder) {
      return this.i18nSnackService.open('auction.bidding.highest-bid-can-not-remove-from-watchlist');
    }

    if (!this.auction().isParked) {
      this.buyerAuctionService.addToWatchlist(this.auction().uuid, this.recommendationIdService?.getRecommendationId()).subscribe(() => {
        this.i18nSnackService.open('auction.bidding.vehicle-parked');
        this.isParkedChange.emit(true);
        this.googleAnalyticsService.eventEmitter(
          'auction_detail-click-watchlist',
          'auction_detail',
          'click',
          'watchlist',
          this.auction().uuid,
          this.auction().uuid,
        );
        this.productAnalyticsService.trackEvent('watchlistAdded', {
          'Watchlist type': this.watchlistLocation(),
          'Auction end time': new Date(this.auction().endingTime).toISOString(),
          Timestamp: new Date().toISOString(),
          'Auction uuid': this.auction().uuid,
        });
      });
    } else {
      this.buyerAuctionService.removeFromWatchlist(this.auction().uuid, this.recommendationIdService?.getRecommendationId()).subscribe(() => {
        this.isParkedChange.emit(false);
        this.i18nSnackService.open('auction.bidding.vehicle-removed-from-parking-place');
      });
    }
  }
}
