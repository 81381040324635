import { Injectable } from '@angular/core';
import { Dialog, DialogConfig, DialogRef } from '@angular/cdk/dialog';
import { Observable, Subject, tap } from 'rxjs';
import { IEnzoModalMessageParams, EnzoModalMessageComponent } from '@cosCoreComponents/modal-dialogs/enzo-modal-message.component';
import {
  NG_ENZO_DIALOG_CONTAINER_PARAMS,
  EnzoDialogContainerComponent,
  IEnzoDialogContainerParams,
} from '@cosCoreComponents/modal-dialogs/enzo-dialog-container.component';
import { ComponentType } from '@angular/cdk/overlay';
import { EnzoDialogBase, EnzoDialogType, EnzoVariant, IEnzoDialogBaseParams } from '@cosCoreComponents/modal-dialogs/enzo-dialog-base';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

export class EnzoDialogRef {
  public footerButtonClick$: Observable<EEnzoDialogResult>;
}

export enum EEnzoDialogResult {
  OK,
  CANCEL,
  YES,
  NO,
  LEFT,
  RIGHT,
  CONFIRM,
  REJECT,
}

export type EnzoDialogTranslationParams = { [key: string]: string | boolean };

// exported to find the dialogs by id, e.g. in tests or to to close them
export enum EDialogId {
  BIDDING_IS_PROHIBITED = 'dialog.buyer.bidding-is-prohibited',
  BIDDING_CONFIRMATION = 'BiddingConfirmationDialog',
  MOBILE_BID = 'MobileBidDialog',
  SELF_REGISTER_MODAL = 'SelfRegisterModalDialog',
}

export type DialogRefOrMatDialogRef<C> = DialogRef<unknown, C> | MatDialogRef<C>;

const MODAL_MESSAGE_DIALOG_WIDTH = '450px';

@Injectable({ providedIn: 'root' })
export class EnzoDialogService {
  public constructor(
    private cdkDialog: Dialog,
    private matDialog: MatDialog,
  ) {}

  public getDialogById<C>(id: EDialogId): DialogRefOrMatDialogRef<C> {
    // check matDialog first, since matDialog creates a cdkDialog with the same id.
    return this.matDialog.getDialogById(id) || this.cdkDialog.getDialogById(id);
  }

  public openLeftRightCancelModal(translationBaseKey: string, variant?: EnzoVariant, width?: string): Observable<EEnzoDialogResult> {
    return EnzoDialogService.openMessageWithButtons(this.cdkDialog, 'leftRightCancel', translationBaseKey, {}, variant, width);
  }

  public openLeftRightModal(translationBaseKey: string, variant?: EnzoVariant): Observable<EEnzoDialogResult> {
    return EnzoDialogService.openMessageWithButtons(this.cdkDialog, 'leftRight', translationBaseKey, variant);
  }

  public openRightCancelModal(translationBaseKey: string, variant?: EnzoVariant): Observable<EEnzoDialogResult> {
    return EnzoDialogService.openMessageWithButtons(this.cdkDialog, 'rightCancel', translationBaseKey, variant);
  }

  public openYesNoCancelModal(translationBaseKey: string, variant?: EnzoVariant): Observable<EEnzoDialogResult> {
    return EnzoDialogService.openMessageWithButtons(this.cdkDialog, 'yesNoCancel', translationBaseKey, variant);
  }

  public openYesNoModal(translationBaseKey: string, variant?: EnzoVariant): Observable<EEnzoDialogResult> {
    return EnzoDialogService.openMessageWithButtons(this.cdkDialog, 'yesNo', translationBaseKey, variant);
  }

  public openOkCancelModal(translationBaseKey: string, variant?: EnzoVariant): Observable<EEnzoDialogResult> {
    return EnzoDialogService.openMessageWithButtons(this.cdkDialog, 'okCancel', translationBaseKey, variant);
  }

  public openOkModal(
    translationBaseKey: string,
    variantOrTranslationParams?: EnzoVariant | EnzoDialogTranslationParams,
    variant?: EnzoVariant,
    width?: string,
  ): Observable<EEnzoDialogResult> {
    return EnzoDialogService.staticOpenOkModal(this.cdkDialog, translationBaseKey, variantOrTranslationParams, variant, width);
  }

  public openConfirmCancelModal(translationBaseKey: string, variant: EnzoVariant): Observable<EEnzoDialogResult>;
  public openConfirmCancelModal(translationBaseKey: string, translationParams: EnzoDialogTranslationParams): Observable<EEnzoDialogResult>;
  public openConfirmCancelModal(
    translationBaseKey: string,
    translationParams: EnzoDialogTranslationParams,
    variant: EnzoVariant,
  ): Observable<EEnzoDialogResult>;
  public openConfirmCancelModal(
    translationBaseKey: string,
    variantOrTranslationParams?: EnzoVariant | EnzoDialogTranslationParams,
    variant?: EnzoVariant,
  ): Observable<EEnzoDialogResult> {
    return EnzoDialogService.openMessageWithButtons(this.cdkDialog, 'confirmCancel', translationBaseKey, variantOrTranslationParams, variant);
  }

  public openModal<R, D, C extends EnzoDialogBase>(component: ComponentType<C>, config?: DialogConfig<D, DialogRef<R, C>>): Observable<R> {
    return EnzoDialogService.staticOpenModal(this.cdkDialog, component, config);
  }

  public openConfirmRejectModal(
    translationBaseKey: string,
    variantOrTranslationParams?: EnzoVariant | EnzoDialogTranslationParams,
    variant?: EnzoVariant,
    width?: string,
    dataCyBase?: string,
  ): Observable<EEnzoDialogResult> {
    return EnzoDialogService.openMessageWithButtons(
      this.cdkDialog,
      'confirmReject',
      translationBaseKey,
      variantOrTranslationParams,
      variant,
      width,
      dataCyBase,
    );
  }

  public static staticOpenOkModal(
    cdkDialog: Dialog,
    translationBaseKey: string,
    variantOrTranslationParams?: EnzoVariant | EnzoDialogTranslationParams,
    variant?: EnzoVariant,
    width?: string,
  ): Observable<EEnzoDialogResult> {
    return EnzoDialogService.openMessageWithButtons(cdkDialog, 'ok', translationBaseKey, variantOrTranslationParams, variant, width);
  }

  public static staticOpenConfirmRejectModal(
    cdkDialog: Dialog,
    translationBaseKey: string,
    variantOrTranslationParams?: EnzoVariant | EnzoDialogTranslationParams,
    variant?: EnzoVariant,
    width?: string,
    dataCyBase?: string,
  ): Observable<EEnzoDialogResult> {
    return EnzoDialogService.openMessageWithButtons(cdkDialog, 'confirmReject', translationBaseKey, variantOrTranslationParams, variant, width, dataCyBase);
  }

  private static openMessageWithButtons(
    cdkDialog: Dialog,
    enzoDialogType: EnzoDialogType,
    translationBaseKey: string,
    variantOrTranslationParams?: EnzoVariant | EnzoDialogTranslationParams,
    variant?: EnzoVariant,
    width?: string,
    dataCyBase?: string,
  ): Observable<EEnzoDialogResult> {
    const hasCancelButton = ['leftRightCancel', 'rightCancel', 'confirmCancel', 'confirmReject', 'yesNoCancel', 'okCancel'].includes(enzoDialogType);
    const translationParams: EnzoDialogTranslationParams = typeof variantOrTranslationParams === 'object' ? variantOrTranslationParams : {};
    const enzoDialogVariant = typeof variantOrTranslationParams === 'object' ? variant : variantOrTranslationParams;
    return EnzoDialogService.staticOpenModal<EEnzoDialogResult, IEnzoModalMessageParams, EnzoModalMessageComponent>(cdkDialog, EnzoModalMessageComponent, {
      id: translationBaseKey,
      disableClose: !hasCancelButton || (translationParams?.disableClose as boolean),
      ...(width ? { width } : {}),
      data: {
        translationBaseKey,
        translationParams,
        enzoDialogType,
        ...(enzoDialogVariant ? { enzoDialogVariant } : {}),
        ...(dataCyBase ? { dataCyBase } : {}),
      },
    });
  }

  private static staticOpenModal<R, D extends IEnzoDialogBaseParams, C extends EnzoDialogBase>(
    cdkDialog: Dialog,
    component: ComponentType<C>,
    config?: DialogConfig<D, DialogRef<R, C>>,
  ): Observable<R> {
    const footerButtonClickSubject = new Subject<EEnzoDialogResult>();
    const openResult = cdkDialog.open<R, D, C>(component, {
      width: MODAL_MESSAGE_DIALOG_WIDTH,
      panelClass: 'enzo-modal-message',
      ...config,
      container: {
        type: EnzoDialogContainerComponent,
        providers: () => [
          {
            provide: NG_ENZO_DIALOG_CONTAINER_PARAMS,
            useValue: {
              footerButtonClickSubject,
              dataCyBase: config?.data?.dataCyBase,
              showLoadingIndicator: config?.data?.showLoadingIndicator,
            } as IEnzoDialogContainerParams,
          },
        ],
      },
      providers: [
        {
          provide: EnzoDialogRef,
          useValue: { footerButtonClick$: footerButtonClickSubject.asObservable() },
        },
      ],
    });
    return openResult.closed.pipe(tap(() => footerButtonClickSubject.complete()));
  }
}
