import { animate, AnimationTransitionMetadata, AnimationTriggerMetadata, style, transition, trigger } from '@angular/animations';

export const FADE_IN_TIMING = '0.2s ease-in';

export function fadeInTransition(timings: string = FADE_IN_TIMING): AnimationTransitionMetadata {
  return transition(':enter', [style({ opacity: 0 }), animate(timings, style({ opacity: 1 }))]);
}

export function fadeIn(timings: string = FADE_IN_TIMING): AnimationTriggerMetadata {
  return trigger('fadeIn', [fadeInTransition(timings)]);
}
