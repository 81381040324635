import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';
import { IAuction } from '@caronsale/cos-models';
import { EVehiclePaintLocation, IVehiclePaintState } from '@caronsale/cos-vehicle-models';

@Component({
  selector: 'app-paint-status-info',
  templateUrl: './paint-status-info.component.html',
  styleUrls: ['./paint-status-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaintStatusInfoComponent implements OnChanges {
  @Input()
  public auction: IAuction;

  public paintStates: IVehiclePaintState[];
  public hasUnusualValues: boolean;
  public displayNotice: boolean;

  private readonly PAINT_STATE_ORDER: EVehiclePaintLocation[] = [
    EVehiclePaintLocation.ENGINE_HOOD,
    EVehiclePaintLocation.FENDER_FRONT_LEFT,
    EVehiclePaintLocation.ROOF,
    EVehiclePaintLocation.FENDER_FRONT_RIGHT,
    EVehiclePaintLocation.DOOR_FRONT_LEFT,
    EVehiclePaintLocation.DOOR_REAR_LEFT,
    EVehiclePaintLocation.DOOR_FRONT_RIGHT,
    EVehiclePaintLocation.DOOR_REAR_RIGHT,
    EVehiclePaintLocation.SIDE_PART_BACK_LEFT,
    EVehiclePaintLocation.TAILGATE,
    EVehiclePaintLocation.SIDE_PART_BACK_RIGHT,
  ];

  public ngOnChanges(): void {
    if (this.auction) {
      const paintState = this.auction.associatedVehicle.paintState;
      this.hasUnusualValues = paintState.some(state => state.isUnusualValue);
      this.paintStates = this.PAINT_STATE_ORDER.map(index => paintState.filter(val => val.part === index)[0]).filter(Boolean);
      this.displayNotice = this.paintStates.some(state => !state.value);
    }
  }
}
