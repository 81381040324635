<div class="general-info">
  <div class="section">
    <!-- headline -->
    <ngx-skeleton-loader [theme]="{ height: '70px' }"></ngx-skeleton-loader>
    <!-- headline-buttons -->
    <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
    <!-- highlights -->
    <ngx-skeleton-loader count="1" [theme]="{ height: '32px' }"></ngx-skeleton-loader>
    <!-- gallery -->
    <ngx-skeleton-loader [theme]="{ height: '350px' }"> </ngx-skeleton-loader>
    <ngx-skeleton-loader count="6" appearance="circle" [theme]="{ width: '83px', height: '60px', 'border-radius': '0' }"> </ngx-skeleton-loader>
  </div>
  <div class="section">
    <!-- remaining-time -->
    <ngx-skeleton-loader [theme]="{ height: '70px' }"></ngx-skeleton-loader>
    <!-- bid-info -->
    <ngx-skeleton-loader count="6"></ngx-skeleton-loader>
  </div>
</div>
<!-- auction-details -->
<div class="section auction-details">
  <ngx-skeleton-loader count="4"></ngx-skeleton-loader>
</div>
