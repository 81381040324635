<div class="panel-container" *ngIf="!isInternalView && hasTransportationService && auction | isAuctionRunning">
  <app-expandable-panel [expandedState]="true">
    <enzo-text kind="body-bold" panelTitle>{{ 'prebooked-service.type.cos-cargo-transport' | translate }}</enzo-text>

    <app-prebooked-transport
      [auction]="auction"
      [currentPrebookedServices]="currentPrebookedServices"
      [view]="view"
      (transportSelectionChange)="onTransportSelectionChange($event)"
      panelContent></app-prebooked-transport>
  </app-expandable-panel>
</div>

<div class="panel-container" *ngIf="!(auction | isAuctionRunning) && displayPrebookedSevices && hasGuaranteeService">
  <app-expandable-panel [expandedState]="view === 'CONFIRMATION_MODAL'">
    <enzo-text kind="body-bold" panelTitle>{{ 'prebooked-service.type.cos-guarantee' | translate }}</enzo-text>

    <app-prebooked-guarantee
      [auction]="auction"
      [currentPrebookedServices]="currentPrebookedServices"
      (guaranteeSelectionChange)="onGuaranteeSelectionChange($event)"
      panelContent></app-prebooked-guarantee>
  </app-expandable-panel>
</div>
