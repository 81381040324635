import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BaseModule } from '@cosCoreComponentsGeneral/base.module';
import { FinancingActionsComponent } from './financing-actions.component';

@NgModule({
  declarations: [FinancingActionsComponent],
  exports: [FinancingActionsComponent],
  imports: [CommonModule, BaseModule],
})
export class FinancingActionsModule {}
