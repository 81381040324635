import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BaseModule } from '@cosCoreComponentsGeneral/base.module';
import { InvoiceDownloadComponent } from './invoice-download.component';

@NgModule({
  declarations: [InvoiceDownloadComponent],
  exports: [InvoiceDownloadComponent],
  imports: [CommonModule, BaseModule],
})
export class InvoiceDownloadModule {}
