import { CurrencyPipe, NgIf } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { IBuyerAuctionView } from '@caronsale/cos-models';
import { CopyOnClickModule } from '@cos/components/copy-on-click/copy-on-click.module';
import { TranslateModule } from '@ngx-translate/core';

export interface IBuyerVehiclePaymentDialogData {
  purchase: IBuyerAuctionView;
}

@Component({
  selector: 'app-buyer-vehicle-payment-dialog',
  templateUrl: './buyer-vehicle-payment-dialog.component.html',
  styleUrls: ['./buyer-vehicle-payment-dialog.component.scss'],
  imports: [CopyOnClickModule, CurrencyPipe, MatButtonModule, MatDialogModule, NgIf, TranslateModule],
  standalone: true,
})
export class BuyerVehiclePaymentDialogComponent {
  public referenceId: string;

  public constructor(@Inject(MAT_DIALOG_DATA) public data: IBuyerVehiclePaymentDialogData) {
    this.referenceId = data.purchase.associatedVehicle.vin ?? data.purchase.id.toString();
  }
}
