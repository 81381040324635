import { IRequestIntervalConfig } from './IRequestIntervalConfig';

export const REQ_INTERVAL: IRequestIntervalConfig = {
  BUYER_USER: {
    FETCH_NON_HOT_BID_BIDDING_INFO_IN_SECONDS: 10,
    FETCH_HOT_BID_BIDDING_INFO_IN_SECONDS: 1,
    FETCH_BELOW_MIN_ASK_AUCTIONS: 30 * 1000,
    FETCH_RUNNING_AUCTIONS_LIST_IN_SECONDS: 60,
    AUCTION_ROOMS_REFRESH_INTERVAL_IN_SECONDS: 30,
    PRIVATE_AUCTIONS_COUNT_REFRESH_INTERVAL_IN_SECONDS: 30,
    ACTIVITY_CARDS_REFRESH_INTERVAL_IN_SECONDS: 60 * 5,
  },

  SELLER_USER: {
    FETCH_RUNNING_AUCTIONS: 10 * 1000,
    FETCH_DRAFTED_AUCTIONS: 15 * 1000,
    FETCH_TO_REINSERT_AUCTIONS: 15 * 1000,
    FETCH_CLOSED_AUCTIONS: 15 * 1000,
    FETCH_AUCTION_DETAILS: 30 * 1000,
    FETCH_BELOW_MIN_ASK_AUCTIONS: 30 * 1000,
  },

  INTERNAL_USER: {
    FETCH_INVOICES: 60 * 1000,
    FETCH_USERS: 60 * 1000,
    CHAT_LIST_REFRESH_TIME: 300 * 1000,
    FETCH_KPIS: 5 * 60 * 1000,
  },
};
