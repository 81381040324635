import { Overlay, OverlayConfig } from '@angular/cdk/overlay';
import { ComponentPortal, PortalInjector } from '@angular/cdk/portal';
import { Injectable, Injector, TemplateRef, Type } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { CosBottomOverlayRef } from './cos-bottom-overlay-ref';
import { CosBottomOverlayComponent } from './overlay/cos-bottom-overlay.component';

@Injectable({
  providedIn: 'root',
})
export class CosBottomOverlayProvider {
  // TODO: generic to get output data?
  private close = new Subject<void>();

  public constructor(
    private overlay: Overlay,
    private injector: Injector,
  ) {}

  public open<R = any, T = any>(content: string | TemplateRef<any> | Type<any>, data?: T): CosBottomOverlayRef<R> {
    const configs = new OverlayConfig({
      hasBackdrop: true,
      panelClass: ['cos-bottom-overlay'],
    });

    const overlayRef = this.overlay.create(configs);

    const myOverlayRef = new CosBottomOverlayRef<R, T>(overlayRef, content, data);

    const injector = this.createInjector(myOverlayRef, this.injector);
    overlayRef.attach(new ComponentPortal(CosBottomOverlayComponent, null, injector));

    return myOverlayRef;
  }

  private createInjector(ref: CosBottomOverlayRef, inj: Injector) {
    const injectorTokens = new WeakMap([[CosBottomOverlayRef, ref]]);
    return new PortalInjector(inj, injectorTokens);
  }

  public get closing$(): Observable<void> {
    return this.close.asObservable();
  }

  public triggerClose() {
    this.close.next();
  }
}
