import { Component, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { Platform } from '@angular/cdk/platform';
import { DIALOG_DATA } from '@angular/cdk/dialog';

@Component({
  selector: 'app-mobile-banner-overlay',
  templateUrl: './mobile-banner-overlay.component.html',
  styleUrls: ['./mobile-banner-overlay.component.scss'],
})
export class MobileBannerOverlayComponent {
  private readonly GOOGLE_PLAY_LINK = 'https://play.google.com/store/apps/details?id=com.castletech.caronsale';
  private readonly APP_STORE_LINK = 'https://apps.apple.com/us/app/caronsale/id1455361415';

  public constructor(
    public dialogRef: MatDialogRef<MobileBannerOverlayComponent>,
    public platform: Platform,
    @Inject(DIALOG_DATA) public debugString,
  ) {}

  public close(): void {
    this.dialogRef.close();
  }

  public openApp() {
    window.location.href = this.platform.ANDROID ? this.GOOGLE_PLAY_LINK : this.APP_STORE_LINK;
    this.close();
  }
}
