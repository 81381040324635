<div class="notification-dialog-header">
  {{ 'general.notifications' | translate }}
  <fa-icon class="close-button" icon="times" (click)="handleCloseButtonClick()"></fa-icon>
</div>

<div class="group-label" *ngIf="todayNotifications.length !== 0">{{ 'general.today' | translate }}</div>

<app-notification-dialog-item-component
  *ngFor="let notification of todayNotifications; trackBy: trackById"
  (requestDelete)="handleRequestDeleteNotification($event)"
  [notification]="notification">
</app-notification-dialog-item-component>

<div class="group-label" *ngIf="oldNotifications.length !== 0">{{ 'general.older' | translate }}</div>

<app-notification-dialog-item-component
  *ngFor="let notification of oldNotifications; trackBy: trackById"
  (requestDelete)="handleRequestDeleteNotification($event)"
  [notification]="notification">
</app-notification-dialog-item-component>

<ng-container *ngIf="!isLoading; else showLoadingSpinner">
  <div class="load-more-button" *ngIf="hasMore" (click)="handleLoadMoreButtonClick($event)">{{ 'general.load-more' | translate }}</div>
  <div class="empty-placeholder" *ngIf="todayNotifications.length + oldNotifications.length === 0">
    <ng-lottie [options]="lottieConfig" [width]="172"></ng-lottie>
    {{ 'general.nothing-new-at-the-moment' | translate }}
  </div>
</ng-container>

<ng-template #showLoadingSpinner>
  <mat-progress-spinner [diameter]="26" color="primary" mode="indeterminate"></mat-progress-spinner>
</ng-template>
