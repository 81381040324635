import { Component, EventEmitter, Output } from '@angular/core';

import { IVehicle } from '@caronsale/cos-vehicle-models';

import { environment } from '@cosCoreEnvironments/environment';
import { CosPickupService } from '@cosCoreServices/cos-pickup/cos-pickup.service';

@Component({
  selector: 'app-manual-pickup-confirmation',
  templateUrl: './manual-pickup-confirmation.component.html',
  styleUrls: ['./manual-pickup-confirmation.component.scss'],
})
export class ManualPickupConfirmationComponent {
  @Output()
  public confirmationError: EventEmitter<any> = new EventEmitter();

  public siteKey = environment.pickupCaptchaSiteKey;
  public pin: string;
  public submitting: boolean;
  public confirmed: boolean;
  public captchaResponse: any;
  public vehicle: IVehicle;

  public constructor(private pickupService: CosPickupService) {}

  public captchaIsResolved(captchaResponse: string) {
    // emits null when it times out to indicate that the user has to click it again
    this.captchaResponse = captchaResponse;
  }

  public async submit(): Promise<void> {
    try {
      this.submitting = true;
      const response = await this.pickupService.confirmByPin(this.pin, this.captchaResponse);
      this.confirmed = true;

      this.vehicle = response.associatedVehicle;
    } catch (errorResponse: any) {
      this.confirmationError.emit({
        status: errorResponse.status,
        ...errorResponse.error,
      });
    } finally {
      this.submitting = false;
    }
  }

  public isSubmitDisabled() {
    return !this.pin || !this.captchaResponse || this.submitting;
  }
}
