<form class="option" [class.disable-all]="isHotBidPhaseActive">
  <mat-radio-group color="primary" [formControl]="buyNowPayLaterFormControl">
    <div class="option">
      <mat-radio-button [value]="OPTION_PAY_NOW">
        <div class="radio-button-label">{{ 'prebooked-service.buy-now-pay-later.not-selected-label' | translate }}</div>
      </mat-radio-button>
      <span class="price">{{ 0 | currencyEuro }}</span>
    </div>
    <div class="option">
      <mat-radio-button [value]="OPTION_PAY_IN_60_DAYS">
        <div class="radio-button-label">{{ 'prebooked-service.buy-now-pay-later.selected-label' | translate }}</div>
        <enzo-text color="grey" kind="caption" class="option-label-caption">
          {{ 'prebooked-service.buy-now-pay-later.selected-caption' | translate: { bnplPrice: buyNowPayLaterPrice | currency: 'EUR' : 'symbol' } }}
        </enzo-text>
      </mat-radio-button>
      <span class="price">{{ buyNowPayLaterPrice | currency: 'EUR' : 'symbol' }}</span>
    </div>
  </mat-radio-group>
</form>

<div class="bnpl-disclaimer" *ngIf="displayBuyNowPayLaterDisclaimer && isBuyNowPayLaterSelected">
  <enzo-icon color="purple-dark" icon="info-outline" size="medium" />
  <ul>
    <li>{{ 'prebooked-service.buy-now-pay-later.disclaimer.must-be-requested' | translate }}</li>
    <li>{{ 'prebooked-service.buy-now-pay-later.disclaimer.in-case-of-rejection' | translate }}</li>
  </ul>
</div>
