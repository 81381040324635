import { Pipe, PipeTransform } from '@angular/core';
import { I18nFormatUtils } from '@caronsale/cos-models';
import { EVehicleTireSetType } from '@caronsale/cos-vehicle-models';

@Pipe({
  standalone: true,
  name: 'i18nTireSetType',
})
export class I18nTireSetTypePipe implements PipeTransform {
  public transform(tireSetType: EVehicleTireSetType): string {
    return I18nFormatUtils.formatVehicleTireSetType(tireSetType);
  }
}
