<enzo-headline level="6" balance="false"> {{ titleKey | translate }} </enzo-headline>

<enzo-text *ngIf="translationParams.errorKey; else message" kind="body">{{ translationParams.errorKey | translate: translationParams.errorParams }}</enzo-text>

<ng-template #message>
  <enzo-text *ngIf="translationParams.interpolationParams" [innerHtml]="messageKey | translate: translationParams.interpolationParams" kind="body"> </enzo-text>
</ng-template>

<app-address-selection
  class="full-width select-address-dropdown"
  [addresses]="selectableAddresses"
  [label]="dropdownLabelKey | translate"
  [formControl]="addressControl">
</app-address-selection>
