import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NgxResizeObserverModule } from 'ngx-resize-observer';

import { BaseModule } from '@cosCoreComponentsGeneral/base.module';
import { GeneralInfoComponent } from './general-info.component';
import {
  AuctionPipesModule,
  GeneralPipesModule,
  I18nGeneralPipesModule,
  I18nVehicleDamageTypePipe,
  I18nVehiclePipesModule,
  SellerPipesModule,
  VehiclePipesModule,
} from '@caronsale/frontend-pipes';
import {
  DocumentInfoComponent,
  EquipmentInfoComponent,
  SpecificInfoModule,
  VehicleAdditionalInfoComponent,
  VehicleConditionDataComponent,
  VehicleEquipmentIconsComponent,
  VehicleOriginReportButtonModule,
  VehicleUnspecifiedInfoComponent,
} from '@cos/partials/general-info/vehicle-info';
import { TransportationOnlyPopoverModule } from '@cosCoreFeatures/@cos/cos-popover/components/transportation-only-popover/transportation-only-popover.module';
import { ImageGalleryComponent } from './image-gallery/image-gallery.component';
import { HighlightsModule } from '@cosCoreFeatures/auction-detail/common/highlights/highlights.module';
import { EnzoComponentsModule } from '@caronsale/enzo-angular';
import { AuctionBadgeComponent } from '@cosBuyer/auctions/partials/auction-wide-card/auction-badge/auction-badge.component';
import { StatusInfoModule } from '../status-info/status-info.module';
import { BidsAmountModule } from '@cosCoreFeatures/auction-detail/buyer/bids-amount/bids-amount.module';
import { LiveAuctionInfoComponent } from '@cos/partials/general-info/live-auction-info/live-auction-info.component';
import { WatchlistButtonComponent } from '@cosBuyer/partials/watchlist-button/watchlist-button.component';
import { NotesButtonComponent } from '@cosBuyer/partials/notes-button/notes-button.component';
import { VideoGalleryComponent } from './video-gallery/video-gallery.component';

@NgModule({
  declarations: [GeneralInfoComponent],
  exports: [GeneralInfoComponent],
  imports: [
    AuctionPipesModule,
    BaseModule,
    BidsAmountModule,
    CommonModule,
    DocumentInfoComponent,
    EquipmentInfoComponent,
    EnzoComponentsModule,
    AuctionBadgeComponent,
    GeneralPipesModule,
    I18nGeneralPipesModule,
    I18nVehiclePipesModule,
    HighlightsModule,
    ImageGalleryComponent,
    LiveAuctionInfoComponent,
    NgxResizeObserverModule,
    SellerPipesModule,
    SpecificInfoModule,
    StatusInfoModule,
    TransportationOnlyPopoverModule,
    VehicleConditionDataComponent,
    VehicleEquipmentIconsComponent,
    VehicleOriginReportButtonModule,
    VehiclePipesModule,
    VehicleAdditionalInfoComponent,
    VehicleUnspecifiedInfoComponent,
    VideoGalleryComponent,
    WatchlistButtonComponent,
    NotesButtonComponent,
  ],
  providers: [I18nVehicleDamageTypePipe],
})
export class GeneralInfoModule {}
