import { Component, ElementRef, Input, OnChanges, OnInit, QueryList, SimpleChanges, ViewChildren, Inject, DestroyRef, signal, NgZone } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { forkJoin, filter, switchMap, tap, fromEvent } from 'rxjs';

import { TranslateService } from '@ngx-translate/core';

import {
  EPrebookedServiceType,
  EUserClientCategory,
  I18nFormatUtils,
  IBuyerAuctionView,
  IBuyerUser,
  IPrebookedService,
  IPrebookedServiceGuaranteeOption,
} from '@caronsale/cos-models';

import { PrebookedServiceGuaranteeOptionType } from '@caronsale/cos-models/dist/Auction/IPrebookedService';
import { CurrencyEuroPipe } from '@caronsale/frontend-pipes';
import { PrebookedServicesUtils } from '@caronsale/frontend-utils';

import { BiddingService } from '@cosBuyer/partials/services/bidding/bidding.service';
import { IBiddingConfirmationResult } from '@cosBuyer/partials/services/bidding/bidding-confirmation-dialog/bidding-confirmation-dialog.component';
import { I18nConfirmationDialogComponent } from '@cosCoreComponentsGeneral/i18n/confirmation-dialog/i18n-confirmation-dialog.component';
import { BuyerAuctionService } from '@cosCoreFeatures/auction-detail/common/auction-service/buyer-auction.service';
import { CosBuyerClientService } from '@cosCoreServices/cos-salesman-client/cos-buyer-client.service';
import { PrebookedServicesService } from '@cosCoreServices/prebooked-services/prebooked-services.service';
import {
  BuyerGuaranteeOptionsDialogComponent,
  IBuyerGuaranteeOptionsDialogParams,
  IBuyerGuaranteeOptionsDialogResult,
} from '@cosCoreFeatures/buyer/components/buyer-guarantee-options-dialog/buyer-guarantee-options-dialog.component';
import { BrowserDetectionService } from '@cosCoreServices/browser-detection/browser-detection.service';
import { VoucherService } from '../services/voucher.service';
import { DOCUMENT } from '@angular/common';
import { EnzoDialogService } from '@cosCoreComponents/modal-dialogs/enzo-dialog.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { RecommendationIdService } from '../services/recommendation-id.service';

@Component({
  selector: 'app-auction-detail-view',
  templateUrl: './auction-detail-view.component.html',
  styleUrls: ['./auction-detail-view.component.scss'],
  providers: [RecommendationIdService],
})
export class AuctionDetailViewComponent implements OnInit, OnChanges {
  @Input()
  public auction: IBuyerAuctionView;

  @Input()
  public currentPrebookedServices: IPrebookedService[];

  // have to add this new input because checking only for auction.amIHighestBidder is not enough
  // the auction could have ended with a single bid from this user and end below min ask without renegotiation
  @Input()
  public hidePaymentInfo: boolean = false;

  @ViewChildren('footerTrigger', { read: ElementRef })
  public footerTriggerRef: QueryList<ElementRef<HTMLElement>>;

  public showFooter = signal(false);

  public buyerUser: IBuyerUser;
  public isGuaranteeBookButtonVisible: boolean;
  public isHotBidPhaseActive: boolean;

  private auctionAccessHasBeenRegistered = false;

  public constructor(
    private biddingService: BiddingService,
    private buyerAuctionService: BuyerAuctionService,
    private cosBuyerClient: CosBuyerClientService,
    private currencyEuroPipe: CurrencyEuroPipe,
    private dialog: MatDialog,
    private prebookedServicesService: PrebookedServicesService,
    private translateService: TranslateService,
    private voucherService: VoucherService,
    @Inject(DOCUMENT) private document: Document,
    private ngZone: NgZone,
    public browserDetectionService: BrowserDetectionService,
    private enzoDialogService: EnzoDialogService,
    private destroyRef: DestroyRef,
    private recommendationIdService: RecommendationIdService,
  ) {}

  public ngOnInit(): void {
    this.cosBuyerClient.getCurrentBuyerUser().subscribe((buyerUser: IBuyerUser) => (this.buyerUser = buyerUser));
    this.handleShowBidInfoFooter();
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (changes.auction?.currentValue) {
      this.isGuaranteeBookButtonVisible = this.auction.prebookedServices
        ?.filter(service => service.type === EPrebookedServiceType.GUARANTEE)
        .flatMap(service => service.options)
        .some(option => option.isEnabled);
      this.isHotBidPhaseActive = this.buyerAuctionService.isHotBidPhaseActive(this.auction);

      if (
        !this.auctionAccessHasBeenRegistered &&
        (!changes.auction.previousValue || changes.auction.previousValue.uuid !== changes.auction.currentValue.uuid)
      ) {
        this.cosBuyerClient
          .registerAuctionVisit(changes.auction.currentValue, EUserClientCategory.BROWSER, this.recommendationIdService.getRecommendationId())
          .subscribe();
        this.auctionAccessHasBeenRegistered = false;
      }
    }
  }

  // the transportation button might ask for a refresh
  public refresh(): void {
    this.buyerAuctionService.refreshBuyerAuction(this.auction.uuid);
  }

  public onSelectVoucher(isVoucherSelectionDirty: boolean): void {
    if (isVoucherSelectionDirty) {
      this.onPrebookedServiceSelectionChange(this.currentPrebookedServices, isVoucherSelectionDirty);
    }
  }

  public onPrebookedServiceSelectionChange(newPrebookedServices: IPrebookedService[], isVoucherSelectionDirty?: boolean) {
    // persist uuid of the auction to avoid submitting the values to the next auction if the current one is closed
    const auctionUuid = this.auction.uuid;

    if (PrebookedServicesUtils.areSelectedOptionsEqual(this.currentPrebookedServices, newPrebookedServices) && !isVoucherSelectionDirty) {
      return;
    }
    if (!this.auction.amIHighestBidder) {
      this.buyerAuctionService.updateCurrentPrebookedServices(auctionUuid, newPrebookedServices);
      return;
    }
    const deselectedGuaranteeOptionType = this.getDeselectedGuaranteeOptiontype(
      PrebookedServicesUtils.getService(newPrebookedServices, EPrebookedServiceType.GUARANTEE),
    );
    if (deselectedGuaranteeOptionType) {
      this.handleDeselectedGuaranteeOption(deselectedGuaranteeOptionType, newPrebookedServices);
    } else {
      this.biddingService
        .openBiddingConfirmationDialog(
          this.auction,
          newPrebookedServices,
          this.auction.currentHighestBidValue,
          'dialog.buyer.confirm-prebooked-service',
          this.currencyEuroPipe.transform(this.auction.currentHighestBidValue),
        )
        .subscribe((result: IBiddingConfirmationResult) => {
          if (result?.isConfirmed) {
            forkJoin([
              this.prebookedServicesService
                .persistPrebookedServices(auctionUuid, result.updatedPrebookedServices)
                .pipe(tap(() => this.buyerAuctionService.refreshBuyerAuction(auctionUuid))),
              this.voucherService.persistVoucherAssignment(this.auction.uuid),
            ]).subscribe();
          } else {
            this.buyerAuctionService.updateCurrentPrebookedServices(auctionUuid, this.currentPrebookedServices); // keep the current ones but trigger child updates to reset them
          }
        });
    }
  }

  private handleDeselectedGuaranteeOption(deselectedGuaranteeOptionType: PrebookedServiceGuaranteeOptionType, newPrebookedServices: IPrebookedService[]) {
    // persist uuid of the auction to avoid submitting the values to the next auction if the current one is closed
    const auctionUuid = this.auction.uuid;

    I18nConfirmationDialogComponent.showConfirmDialogWithRejection(
      this.dialog,
      'prebooked-service.guarantee.confirm-deselection',
      {
        guaranteeName: this.translateService.instant(
          I18nFormatUtils.formatPrebookedServiceOptionType(deselectedGuaranteeOptionType, EPrebookedServiceType.GUARANTEE),
        ),
      },
      '400px',
    ).subscribe(isConfirmed => {
      if (isConfirmed) {
        this.prebookedServicesService
          .persistPrebookedServices(auctionUuid, newPrebookedServices)
          .pipe(tap(() => this.buyerAuctionService.refreshBuyerAuction(auctionUuid)))
          .subscribe();
      } else {
        this.buyerAuctionService.updateCurrentPrebookedServices(auctionUuid, this.currentPrebookedServices); // keep the current ones but trigger child updates to reset them
      }
    });
  }

  private getDeselectedGuaranteeOptiontype(guaranteeService: IPrebookedService): PrebookedServiceGuaranteeOptionType | undefined {
    const guaranteeOptions: IPrebookedServiceGuaranteeOption[] = guaranteeService?.options as IPrebookedServiceGuaranteeOption[];
    if (!guaranteeOptions || guaranteeOptions.length === 0) {
      return undefined;
    }
    // find one that is deselected now and was selected before
    const guaranteeOptionThatIsDeselected = guaranteeOptions.find(
      guaranteeOption =>
        !guaranteeOption.isSelected &&
        PrebookedServicesUtils.isOptionSelected(this.currentPrebookedServices, EPrebookedServiceType.GUARANTEE, guaranteeOption.type),
    );
    return guaranteeOptionThatIsDeselected?.type;
  }

  public openGuaranteeBookingDialog(): void {
    this.enzoDialogService
      .openModal<IBuyerGuaranteeOptionsDialogResult, IBuyerGuaranteeOptionsDialogParams, BuyerGuaranteeOptionsDialogComponent>(
        BuyerGuaranteeOptionsDialogComponent,
        {
          width: '450px',
          disableClose: true,
          maxWidth: 'none',
          data: {
            auction: this.auction,
            prebookedServices: this.auction.prebookedServices,
          },
        },
      )
      .pipe(
        filter(dialogResultData => dialogResultData.confirmed && !!dialogResultData.prebookedServices),
        switchMap(dialogResultData => this.prebookedServicesService.persistPrebookedServices(this.auction.uuid, dialogResultData.prebookedServices)),
        tap(() => this.buyerAuctionService.refreshBuyerAuction(this.auction.uuid)),
      )
      .subscribe();
  }

  public openRegistrationDocument(): void {
    window.open(this.auction.associatedVehicle.urlToRegistrationDocument, '_blank');
  }

  private handleShowBidInfoFooter(): void {
    const NAV_BAR_HEIGHT_OFFSET = 40;
    this.ngZone.runOutsideAngular(() => {
      fromEvent(this.document, 'scroll')
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe(() => {
          const showFooter =
            this.auction && this.footerTriggerRef.filter(({ nativeElement }) => nativeElement?.getBoundingClientRect().y < NAV_BAR_HEIGHT_OFFSET).length > 0;

          if (showFooter !== this.showFooter()) {
            this.showFooter.set(showFooter);
          }
        });
    });
  }
}
