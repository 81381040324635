import { Component, Input, OnChanges } from '@angular/core';
import { IBuyerAuctionView } from '@caronsale/cos-models';

@Component({
  selector: 'app-auction-vat',
  templateUrl: './auction-vat.component.html',
  styleUrls: ['./auction-vat.component.scss'],
})
export class AuctionVatComponent implements OnChanges {
  @Input()
  public auction: IBuyerAuctionView;

  public vatAmount: number = 0;
  public vatAmountInstantPurchase: number = 0;

  public ngOnChanges(): void {
    this.vatAmount = Math.round((this.auction.currentHighestBidValue - this.auction.currentHighestBidValueNet) * 100) / 100;
    this.vatAmountInstantPurchase = Math.round((this.auction.instantPurchasePrice - this.auction.instantPurchasePriceNet) * 100) / 100;
  }
}
