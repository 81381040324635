import { Observable, ReplaySubject, Subject, finalize, shareReplay } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';

/**
 * ViewState subscription management. (actually a generic management of anything that has a uuid)
 * Holds a map with the subscription information per uuid
 * Subscription information consistes of
 *  - the Subject where the viewState is next()-ed into and
 *  - an observable from that subject that is shareReplay()-ed to possibly multiple subscribers
 *  When the last subscriber unsubscribes, this info is deleted (from the map)
 */

interface IViewStateSubscription<T> {
  sourceSubject: Subject<T>;
  sharedObservable: Observable<T>;
}

export class ViewStateSubscriptionManagement<T> {
  private viewStateSubscriptions: Map<string, IViewStateSubscription<T>> = new Map<string, IViewStateSubscription<T>>();

  public getViewStateObservable$(uuid: string): Observable<T> {
    return this.viewStateSubscriptions.get(uuid)?.sharedObservable;
  }

  public createViewStateObservable$(uuid: string): Observable<T> {
    // ReplaySubject because when we next into it from our cache immediately,
    // the subject needs to store this value for the first subscriber (after that, shareReplay stores it)
    const sourceSubject = new ReplaySubject<T>(1);
    const viewStateSubscription: IViewStateSubscription<T> = {
      sourceSubject,
      sharedObservable: sourceSubject.asObservable().pipe(
        // housekeeping when the last subscriber unsubscribes or an error happens
        finalize(() => this.viewStateSubscriptions.delete(uuid)),
        // do the ref counting. Not really necessary atm since we have only one subscriber at a time
        shareReplay({
          bufferSize: 1,
          refCount: true,
        }),
      ),
    };
    this.viewStateSubscriptions.set(uuid, viewStateSubscription);
    return viewStateSubscription.sharedObservable;
  }

  public isSubscribed(uuid: string): boolean {
    return !!this.viewStateSubscriptions.get(uuid);
  }

  public getAllSubscriptionUuids(): string[] {
    return [...this.viewStateSubscriptions.keys()];
  }

  public emitViewState(uuid, newViewState: T): void {
    this.viewStateSubscriptions.get(uuid)?.sourceSubject.next(newViewState);
  }

  public setViewStateError(uuid: string, errorResponse: HttpErrorResponse): void {
    this.viewStateSubscriptions.get(uuid)?.sourceSubject.error(errorResponse);
  }
}
