import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PrebookedServicesComponent } from './prebooked-services.component';
import { BaseModule } from '@cosCoreComponentsGeneral/base.module';
import { PrebookedTransportComponent } from '@cosBuyer/partials/prebooked-services/prebooked-transport/prebooked-transport.component';
import { PrebookedGuaranteeComponent } from './prebooked-guarantee/prebooked-guarantee.component';
import { GuaranteeBadgeModule } from '@cosCoreComponentsGeneral/guarantee-badge/guarantee-badge.module';
import { AuctionPipesModule, GeneralPipesModule } from '@caronsale/frontend-pipes';
import { EnzoComponentsModule } from '@caronsale/enzo-angular';
import { ExpandablePanelModule } from '@cos/partials/expandable-panel/expandable-panel.module';
import { CosPopoverModule } from '@cosCoreFeatures/@cos/cos-popover/cos-popover.module';
import { PrebookedBuyNowPayLaterComponent } from '@cosBuyer/partials/prebooked-services/prebooked-buy-now-pay-later/prebooked-buy-now-pay-later.component';
import { BuyNowPayLaterServiceModule } from '@cosCoreServices/buy-now-pay-later/buy-now-pay-later-service.module';

@NgModule({
  declarations: [PrebookedServicesComponent, PrebookedTransportComponent, PrebookedGuaranteeComponent, PrebookedBuyNowPayLaterComponent],
  imports: [
    //
    AuctionPipesModule,
    BaseModule,
    CommonModule,
    CosPopoverModule,
    EnzoComponentsModule,
    ExpandablePanelModule,
    GeneralPipesModule,
    GuaranteeBadgeModule,
    BuyNowPayLaterServiceModule,
  ],
  exports: [PrebookedServicesComponent, PrebookedGuaranteeComponent],
})
export class PrebookedServicesModule {}
