<div (click)="togglePanel()" class="title" [ngClass]="{ stretch: titleStretch, 'icon-left': iconPosition === 'left' }">
  <div #titleReference>
    <ng-content select="[panelTitle]"></ng-content>
  </div>
  <enzo-icon class="title-icon" [class.expanded]="expandedState" *ngIf="titleReference.children.length > 0" icon="arrow-down"></enzo-icon>
  <ng-content select="[panelTitleSuffix]"></ng-content>
</div>

<mat-expansion-panel [expanded]="expandedState" class="panel">
  <ng-content select="[panelContent]"></ng-content>
</mat-expansion-panel>
