import { OverlayModule } from '@angular/cdk/overlay';
import { NgModule } from '@angular/core';
import { CosBottomOverlayProvider } from './cos-bottom-overlay-provider.service';
import { CosBottomOverlayComponent } from './overlay/cos-bottom-overlay.component';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [CosBottomOverlayComponent],
  exports: [CosBottomOverlayComponent],
  imports: [CommonModule, OverlayModule],
  providers: [CosBottomOverlayProvider],
})
export class CosBottomOverlayModule {}
