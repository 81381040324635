<div *ngIf="auctionBuyer" class="status-info">
  <app-hot-bid-progress-bar *ngIf="progressBar === 'hot-bid-top'" [remainingTimeInSeconds]="remainingTimeInSeconds" [kind]="kind"></app-hot-bid-progress-bar>

  <enzo-text kind="emphasis-label" class="item state-closed" *ngIf="!(auction | isAuctionRunning)">{{ 'auction.state.closed' | translate }}</enzo-text>

  <div class="item remaining-time" *ngIf="auction | isAuctionRunning">
    <ng-content select="[bids-amount]"></ng-content>
    <app-ending-date
      [remainingTimeInSeconds]="remainingTimeInSeconds"
      [isInstantPurchase]="auction | isInstantPurchaseActive"
      showAfterOverKey="auction.remaining-time-when-over">
    </app-ending-date>
  </div>

  <app-hot-bid-progress-bar *ngIf="progressBar === 'hot-bid-bottom'" [remainingTimeInSeconds]="remainingTimeInSeconds" [kind]="kind"></app-hot-bid-progress-bar>
  <app-hot-bid-progress-bar *ngIf="progressBar === 'border-only-bottom'" [kind]="kind"></app-hot-bid-progress-bar>
</div>

<div *ngIf="auctionSeller" class="status-info">
  <!-- state text (except for running and disabled auctions) -->
  <h2 class="item" *ngIf="(auction | isAuctionClosedOrFinished) || (auction | isAuctionDrafted)">{{ auctionSeller.state | i18nAuctionState | translate }}</h2>

  <!-- removed auction: different text from what i18nAuctionState would give us -->
  <h2 class="item" *ngIf="auction | isAuctionDisabled">{{ 'auction.not-available-anymore' | translate }}</h2>

  <!-- further details for payment or rating -->
  <div class="item" *ngIf="(auction | isAuctionWaitingForPayment) && auctionSeller.paymentState">
    <app-seller-auction-payment-state [auction]="auctionSeller"></app-seller-auction-payment-state>
  </div>

  <div class="item seller-finished" *ngIf="auction | isAuctionFinished">
    <h3>{{ 'auction.ended-thank-you' | translate }}</h3>
    <h4>{{ 'auction.ended-successfully' | translate }}</h4>
    <app-rating-scale-display *ngIf="isAuctionRatedYet && auctionSeller.rating" [value]="auctionSeller.rating.value"></app-rating-scale-display>
  </div>

  <!-- Auction still running: remaining time -->
  <!-- (seller components never care for instant purchases, they always give us the auction remaining time anyway) -->
  <div class="item remaining-time" *ngIf="auction | isAuctionRunning">
    <app-ending-date [remainingTimeInSeconds]="remainingTimeInSeconds" showAfterOverKey="auction.remaining-time-when-over"></app-ending-date>
  </div>
</div>
