<ng-container *ngIf="vm$ | async as vm">
  <div
    *ngFor="let marker of vm.markers; trackBy: trackByKey"
    class="marker"
    [class]="marker.location"
    [class.active]="vm.selectedLocation === marker.location"
    [class.damage]="marker.isDamage"
    [class.empty]="!marker.hasDamages"
    [class.added]="marker.hasAddedDamages"
    [ngClass]="categoryImageMap[vehicleCategory]"
    [matTooltip]="LOCATIONS_MAP[marker.location] | slice: 0 : 3 | i18nArray: i18nVehiclePartPipe"
    matTooltipClass="bird-eye-tooltip"
    (click)="handleMarkerClick($event, marker, false)"
    [attr.testid]="marker.location">
    <enzo-text [color]="marker.hasAddedDamages ? 'white' : 'black'" kind="body-bold">
      <ng-container *ngIf="marker.hasDamages; else noDamage">{{ marker.damageNumber }}</ng-container>
      <ng-template #noDamage>
        <enzo-icon icon="plus" size="medium" />
      </ng-template>
    </enzo-text>

    <div class="border">
      <enzo-icon
        *ngIf="vm.selectedLocation === marker.location && marker.hasDamages && editView"
        [color]="marker.hasAddedDamages ? 'white' : 'black'"
        icon="plus"
        (click)="handleMarkerClick($event, marker, true)" />
    </div>
  </div>
  <img
    [ngSrc]="'vehicle-categories/' + categoryImageMap[vehicleCategory] + '.png'"
    width="335"
    height="670"
    draggable="false"
    alt="bird view vehicle image"
    testid="bird-view-image"
    data-pf="bird-view-image" />
</ng-container>
