type EducationalMessageBase = {
  isValid: boolean;
  icon: string;
  text: string;
  textColor: string;
};

export type EducationalMessage = EducationalMessageBase & {
  images: { src: string }[];
  description?: string[];
};

const baseValid: EducationalMessageBase = {
  isValid: true,
  icon: 'check-outline',
  text: 'complaint.admissible-claim',
  textColor: 'green-dark',
};

const baseInvalid: EducationalMessageBase = {
  isValid: false,
  icon: 'warning',
  text: 'complaint.inadmissible-claim',
  textColor: 'red-dark',
};

/**
 * Modal content for claims related to wheels and mudguards.
 * Covers:
 * - Wheel, Front right mudguard, Front left mudguard, Side panel rear left, Side panel rear right
 * - Bumper Front
 * - Rear Bumper
 */
export const wheelsAndMudguardsClaims: EducationalMessage[] = [
  {
    ...baseValid,
    images: [
      { src: 'inventory/buyer-complaint/assets/wheels-mudguards/admissible/1.jpg' },
      { src: 'inventory/buyer-complaint/assets/wheels-mudguards/admissible/2.jpg' },
      { src: 'inventory/buyer-complaint/assets/wheels-mudguards/admissible/3.png' },
    ],
    description: ['complaint.admissible-claim-description', 'complaint.admissible-claim-description-2'],
  },
  {
    ...baseInvalid,
    images: [
      { src: 'inventory/buyer-complaint/assets/wheels-mudguards/inadmissible/1.png' },
      { src: 'inventory/buyer-complaint/assets/wheels-mudguards/inadmissible/2.jpg' },
      { src: 'inventory/buyer-complaint/assets/wheels-mudguards/inadmissible/3.jpg' },
    ],
    description: ['complaint.inadmissible-claim-description'],
  },
];

/**
 * Modal content for claims related to headlights and taillights.
 * Covers:
 * - Headlight (left), Headlight (right)
 * - Taillight (left), Taillight (right)
 */
export const lightsClaims: EducationalMessage[] = [
  {
    ...baseValid,
    images: [
      { src: 'inventory/buyer-complaint/assets/lights/admissible/1.jpg' },
      { src: 'inventory/buyer-complaint/assets/lights/admissible/2.jpg' },
      { src: 'inventory/buyer-complaint/assets/lights/admissible/3.jpg' },
    ],
    description: ['complaint.admissible-claim-description', 'complaint.admissible-claim-description-2'],
  },
  {
    ...baseInvalid,
    images: [
      { src: 'inventory/buyer-complaint/assets/lights/inadmissible/1.jpg' },
      { src: 'inventory/buyer-complaint/assets/lights/inadmissible/2.jpg' },
      { src: 'inventory/buyer-complaint/assets/lights/inadmissible/3.jpg' },
    ],
    description: ['complaint.inadmissible-claim-description'],
  },
];

/**
 * Modal content for claims related to the windscreen.
 * Covers:
 * - Windscreen
 */
export const windscreenClaims: EducationalMessage[] = [
  {
    ...baseValid,
    images: [
      { src: 'inventory/buyer-complaint/assets/windscreen/admissible/1.jpg' },
      { src: 'inventory/buyer-complaint/assets/windscreen/admissible/2.png' },
      { src: 'inventory/buyer-complaint/assets/windscreen/admissible/3.jpg' },
    ],
    description: ['complaint.admissible-claim-description', 'complaint.admissible-claim-description-2'],
  },
  {
    ...baseInvalid,
    images: [
      { src: 'inventory/buyer-complaint/assets/windscreen/inadmissible/1.png' },
      { src: 'inventory/buyer-complaint/assets/windscreen/inadmissible/2.png' },
      { src: 'inventory/buyer-complaint/assets/windscreen/inadmissible/3.png' },
    ],
    description: ['complaint.inadmissible-claim-description'],
  },
];

/**
 * Modal content for claims related to doors and sills.
 * Covers:
 * - Passenger door, Sill right
 * - Driver's door, Sill left
 * - Rear right door
 * - Rear left door
 */
export const doorsAndSillsClaims: EducationalMessage[] = [
  {
    ...baseValid,
    images: [
      { src: 'inventory/buyer-complaint/assets/doors-sills/admissible/1.jpg' },
      { src: 'inventory/buyer-complaint/assets/doors-sills/admissible/2.jpg' },
      { src: 'inventory/buyer-complaint/assets/doors-sills/admissible/3.jpg' },
    ],
    description: ['complaint.admissible-claim-description', 'complaint.admissible-claim-description-2'],
  },
  {
    ...baseInvalid,
    images: [
      { src: 'inventory/buyer-complaint/assets/doors-sills/inadmissible/1.png' },
      { src: 'inventory/buyer-complaint/assets/doors-sills/inadmissible/2.png' },
      { src: 'inventory/buyer-complaint/assets/doors-sills/inadmissible/3.jpg' },
    ],
    description: ['complaint.inadmissible-claim-description'],
  },
];

/**
 * Modal content for claims related to roof, interior, and convertible hood.
 * Covers:
 * - Roof
 * - Interior
 * - Convertible Hood
 */
export const roofInteriorConvertibleClaims: EducationalMessage[] = [
  {
    ...baseValid,
    images: [
      { src: 'inventory/buyer-complaint/assets/roof-interior/admissible/1.png' },
      { src: 'inventory/buyer-complaint/assets/roof-interior/admissible/2.png' },
      { src: 'inventory/buyer-complaint/assets/roof-interior/admissible/3.png' },
    ],
    description: ['complaint.admissible-claim-description', 'complaint.admissible-claim-description-2'],
  },
  {
    ...baseInvalid,
    images: [
      { src: 'inventory/buyer-complaint/assets/roof-interior/inadmissible/1.png' },
      { src: 'inventory/buyer-complaint/assets/roof-interior/inadmissible/2.jpg' },
      { src: 'inventory/buyer-complaint/assets/roof-interior/inadmissible/3.png' },
    ],
    description: ['complaint.inadmissible-claim-description'],
  },
];

/**
 * Modal content for claims related to rear window and tailgate.
 * Covers:
 * - Rear Window
 * - Tailgate
 */
export const rearWindowAndTailgateClaims: EducationalMessage[] = [
  {
    ...baseValid,
    images: [
      { src: 'inventory/buyer-complaint/assets/rear-window-tailgate/admissible/1.png' },
      { src: 'inventory/buyer-complaint/assets/rear-window-tailgate/admissible/2.jpg' },
      { src: 'inventory/buyer-complaint/assets/rear-window-tailgate/admissible/3.jpg' },
    ],
    description: ['complaint.admissible-claim-description', 'complaint.admissible-claim-description-2'],
  },
  {
    ...baseInvalid,
    images: [
      { src: 'inventory/buyer-complaint/assets/rear-window-tailgate/inadmissible/1.jpg' },
      { src: 'inventory/buyer-complaint/assets/rear-window-tailgate/inadmissible/2.jpg' },
      { src: 'inventory/buyer-complaint/assets/rear-window-tailgate/inadmissible/3.jpg' },
    ],
    description: ['complaint.inadmissible-claim-description'],
  },
];

/**
 * Modal content for claims related to general exterior parts and the hood.
 * Covers:
 * - General (exterior)
 * - Hood
 */
export const exteriorAndHoodClaims: EducationalMessage[] = [
  {
    ...baseValid,
    images: [
      { src: 'inventory/buyer-complaint/assets/exterior-hoods/admissible/1.jpg' },
      { src: 'inventory/buyer-complaint/assets/exterior-hoods/admissible/2.jpg' },
      { src: 'inventory/buyer-complaint/assets/exterior-hoods/admissible/3.png' },
    ],
    description: ['complaint.admissible-claim-description', 'complaint.admissible-claim-description-2'],
  },
  {
    ...baseInvalid,
    images: [
      { src: 'inventory/buyer-complaint/assets/exterior-hoods/inadmissible/1.jpg' },
      { src: 'inventory/buyer-complaint/assets/exterior-hoods/inadmissible/2.png' },
      { src: 'inventory/buyer-complaint/assets/exterior-hoods/inadmissible/3.png' },
    ],
    description: ['complaint.inadmissible-claim-description'],
  },
];
