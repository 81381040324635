import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BaseModule } from '@cosCoreComponentsGeneral/base.module';
import { BiddersListComponent } from './bidders-list.component';
import { GeneralPipesModule } from '@caronsale/frontend-pipes';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

@NgModule({
  declarations: [BiddersListComponent],
  exports: [BiddersListComponent],
  imports: [CommonModule, BaseModule, GeneralPipesModule, MatTableModule, NgxSkeletonLoaderModule],
})
export class BiddersListModule {}
