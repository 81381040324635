import { Validation } from '@caronsale/cos-models';
import { BuyerAuctionService, DETECT_AUCTION_CLOSED, IS_POLLING } from '@cosCoreFeatures/auction-detail/common/auction-service/buyer-auction.service';
import { Observable, filter } from 'rxjs';
import { BuyerAuctionViewStates, IBuyerAuctionViewState } from '@cosCoreFeatures/auction-detail/common/auction-service/buyer-auction-view-states';
import { ViewStateSubscriptionManagement } from '@cosCoreFeatures/auction-detail/common/auction-service/view-state-subscription-management';
import { REQ_INTERVAL } from '@cosCoreConfig/RequestIntervalConfig';
import { FEATURE_FLAGS_DEFAULTS, ProductAnalyticsService } from '@cosCoreServices/product-analytics/product-analytics.service';

export class BuyerAuctionHeartbeatHandler {
  private nonHotBidRefreshSeconds = REQ_INTERVAL.BUYER_USER.FETCH_NON_HOT_BID_BIDDING_INFO_IN_SECONDS;
  private hotBidRefreshSeconds = REQ_INTERVAL.BUYER_USER.FETCH_HOT_BID_BIDDING_INFO_IN_SECONDS;

  // feature flag to enable/disable push updates
  // important: this is experimental
  private isPushUpdatesFeatureFlagOn = false;
  // upper limit to refresh auction in hot bid, even if the server didn't notify us about the update
  private hotBidRefreshMaxSeconds = 10;
  // upper limit to refresh auction in non-hot bid, even if the server didn't notify us about the update
  private nonHotBidRefreshMaxSeconds = 60;

  public constructor(
    private buyerAuctionService: BuyerAuctionService,
    private buyerAuctionViewStates: BuyerAuctionViewStates,
    private subscriptionManagement: ViewStateSubscriptionManagement<IBuyerAuctionViewState>,
    heartbeatSecondsElapsed$: Observable<number>,
    productAnalyticsService: ProductAnalyticsService,
  ) {
    // we never unsubscribe from feature value changes since we never die.
    // this is instantiated from a provided-in-root service, so it lives as long as the app lives.
    productAnalyticsService
      .getFeatureFlag('mpw-hot-bid-bidding-info-refresh-interval', FEATURE_FLAGS_DEFAULTS['mpw-hot-bid-bidding-info-refresh-interval'])
      .pipe(filter(({ payload }) => typeof payload === 'number' && payload > 0))
      .subscribe(({ payload: hotBidRefreshSeconds }) => (this.hotBidRefreshSeconds = hotBidRefreshSeconds));
    productAnalyticsService
      .getFeatureFlag('mpw-non-hot-bid-bidding-info-refresh-interval', FEATURE_FLAGS_DEFAULTS['mpw-non-hot-bid-bidding-info-refresh-interval'])
      .pipe(filter(({ payload }) => typeof payload === 'number' && payload > 0))
      .subscribe(({ payload: refreshSeconds }) => (this.nonHotBidRefreshSeconds = refreshSeconds));
    productAnalyticsService.isOn('mpw-use-push-updates-for-bidding-data-info').subscribe(usePushUpdates => (this.isPushUpdatesFeatureFlagOn = usePushUpdates));

    heartbeatSecondsElapsed$.subscribe(secondsElapsed => {
      const allRunningAuctionUuids = this.buyerAuctionViewStates
        .getAllViewStateUuids()
        .filter(uuid => Validation.isAuctionRunning(this.buyerAuctionViewStates.getState(uuid)?.auction));

      this.heartbeatHandlerForAllRunningAuctions(allRunningAuctionUuids, secondsElapsed);

      // do this after the remaining time is updated for every auction (don't move these lines up!)
      const hotBidAuctionUuids = allRunningAuctionUuids.filter(uuid =>
        this.buyerAuctionService.isHotBidPhaseActive(this.buyerAuctionViewStates.getState(uuid).auction),
      );
      const nonHotBidAuctionUuids = allRunningAuctionUuids.filter(uuid => !hotBidAuctionUuids.includes(uuid));

      const uuidsToRefresh = [
        ...this.heartbeatHandlerForHotBidAuctions(hotBidAuctionUuids),
        ...this.heartbeatHandlerForNonHotBidAuctions(nonHotBidAuctionUuids),
      ];
      if (uuidsToRefresh.length > 0) {
        this.buyerAuctionService.refreshBiddingInfos(uuidsToRefresh, IS_POLLING);
      }
    });
  }

  private subtractSeconds(val: number, seconds: number): number {
    // keep null, undefined and 0 as it is.
    if (!val) {
      return val;
    }
    return Math.max(val - seconds, 0);
  }

  private heartbeatHandlerForAllRunningAuctions(auctionUuids: string[], secondsElapsed: number) {
    auctionUuids.forEach(uuid => {
      const auction = this.buyerAuctionViewStates.getState(uuid).auction;
      this.buyerAuctionViewStates.updateRemainingTimesInAuction(
        uuid,
        this.subtractSeconds(auction.remainingTimeInSeconds, secondsElapsed),
        this.subtractSeconds(auction.remainingTimeForInstantPurchaseInSeconds, secondsElapsed),
      );
    });
  }

  private heartbeatHandlerForHotBidAuctions(auctionUuids: string[]): string[] {
    const uuidsToRefresh = [];
    auctionUuids.forEach(uuid => {
      const auction = this.buyerAuctionViewStates.getState(uuid)?.auction;
      // check for both 0 and null (the backend needs a full auction request if remainingTime is null)
      if (!auction.remainingTimeInSeconds) {
        this.finishHotBidPhase(uuid);
      } else if (this.subscriptionManagement.isSubscribed(uuid)) {
        uuidsToRefresh.push(uuid);
      }
    });

    // refresh only the stale ones
    return this.filterOnlyStaleAuctions(uuidsToRefresh, this.hotBidRefreshSeconds, this.hotBidRefreshMaxSeconds);
  }

  private heartbeatHandlerForNonHotBidAuctions(auctionUuids: string[]): string[] {
    // we only care about subscribed auctions, i.e. the ones that are visible in the UI
    const subscribedUuids = auctionUuids.filter(uuid => this.subscriptionManagement.isSubscribed(uuid));
    // refresh only the stale ones
    return this.filterOnlyStaleAuctions(subscribedUuids, this.nonHotBidRefreshSeconds, this.nonHotBidRefreshMaxSeconds);
  }

  /**
   * Filter only the stale auctions from the list of uuids
   *
   * @param auctionUuids
   * @param minRefreshWaitSeconds minimum time to wait before refreshing an auction
   *  This is used when we are not using the server reported updates, i.e. we consider an auction stale if it's not updated for this long
   * @param maxRefreshWaitSeconds maximum time to wait before refreshing an auction
   *  When using server reported updates, this is used as a fallback if the server didn't notify us about the update meanwhile.
   *  That means we consider an auction stale if it's not updated for this long AND the server didn't notify us about an update meanwhile.
   * @private
   */
  private filterOnlyStaleAuctions(auctionUuids: string[], minRefreshWaitSeconds: number, maxRefreshWaitSeconds: number): string[] {
    // if the feature flag is on, we consider the server reported updates
    const considerServerReportedUpdates = this.isPushUpdatesFeatureFlagOn;
    // when the feature flag is on, we consider the server reported updates and refresh using a longer rate if the server didn't report an update meanwhile
    const maxSecondsSinceLastUpdate = considerServerReportedUpdates ? maxRefreshWaitSeconds : minRefreshWaitSeconds;
    return auctionUuids.filter(uuid => this.buyerAuctionViewStates.isBiddingInfoStale(uuid, maxSecondsSinceLastUpdate, considerServerReportedUpdates));
  }

  private finishHotBidPhase(uuid: string): void {
    if (this.subscriptionManagement.isSubscribed(uuid) || this.buyerAuctionService.auctionBuyerClosedForListViews$.observed) {
      // if someone cares for it or if a list view is active: refresh to check whether it is really closed and if it is, issue auctionBuyerClosed$
      // by subscribing to auctionBuyerClosedForListViews$ the buyer-auction-overview indicates that it does care for the auctions that time out,
      // even though they are not subscribed (scrolled out of the viewport).
      this.buyerAuctionService.refreshBuyerAuction(uuid, DETECT_AUCTION_CLOSED, IS_POLLING);
    } else {
      // if no-one cares: kick it out. The next one who is interested in this uuid shall fetch it again completely.
      this.buyerAuctionViewStates.delete(uuid);
    }
  }
}
