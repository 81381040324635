import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { GeneralPipesModule } from '@caronsale/frontend-pipes';

import { BaseModule } from '@cosCoreComponentsGeneral/base.module';
import { CrossBorderDiscountBadgeComponent } from './cross-border-discount-badge.component';
import { EnzoComponentsModule } from '@caronsale/enzo-angular';

@NgModule({
  declarations: [CrossBorderDiscountBadgeComponent],
  exports: [CrossBorderDiscountBadgeComponent],
  imports: [BaseModule, CommonModule, GeneralPipesModule, EnzoComponentsModule],
})
export class CrossBorderDiscountBadgeModule {}
