import { Component, ViewChild } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { EnzoComponentsModule } from '@caronsale/enzo-angular';
import { CosBuyerClientService } from '@cosCoreServices/cos-salesman-client/cos-buyer-client.service';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { catchError, EMPTY, finalize } from 'rxjs';
import { AuctionFilterRangesService } from '@cosBuyer/services/auction-search-filter-ranges.service';
import { ReactiveFormsModule } from '@angular/forms';
import { IPurchasePreferences } from '@cosTypes';
import { I18nSnackService } from '@cosCoreServices/i18n-snack/i18n-snack.service';
import { ProductAnalyticsService } from '@cosCoreServices/product-analytics/product-analytics.service';
import { PurchasePreferencesFormComponent } from '@cosCoreComponents/buyer/buyer-account-settings/purchase-preferences/purchase-preferences-form/purchase-preferences-form.component';

@Component({
  selector: 'app-purchase-prefences-modal',
  templateUrl: './purchase-preferences-modal.component.html',
  styleUrls: ['./purchase-preferences-modal.component.scss'],
  providers: [AuctionFilterRangesService],
  imports: [EnzoComponentsModule, ReactiveFormsModule, TranslateModule, PurchasePreferencesFormComponent],
  standalone: true,
})
export class PurchasePreferencesModalComponent {
  @ViewChild(PurchasePreferencesFormComponent) public purchasePreferencesFormComponent!: PurchasePreferencesFormComponent;

  public saveInProgress: boolean = false;

  public constructor(
    public dialogRef: MatDialogRef<PurchasePreferencesModalComponent>,
    public cosBuyerSvc: CosBuyerClientService,
    public snackService: I18nSnackService,
    public productAnalyticsService: ProductAnalyticsService,
  ) {}

  public savePreferences() {
    if (this.purchasePreferencesFormComponent) {
      this.purchasePreferencesFormComponent.emitPurchasePreferences();
    }
  }

  public handlePreferencesSubmitted(preferences: IPurchasePreferences): void {
    this.saveInProgress = true;
    this.cosBuyerSvc
      .createPurchasePreferences(preferences)
      .pipe(
        catchError(() => {
          this.snackService.open('purchase-preferences-modal.server-error-generic', null, { duration: 3000 });
          return EMPTY;
        }),
        finalize(() => {
          this.saveInProgress = false;
          this.productAnalyticsService.trackEvent('purchasePreferencesModalSubmitted');
          this.closeModal();
        }),
      )
      .subscribe(() => {
        this.snackService.open('purchase-preferences-modal.succesfully-saved', null, { duration: 3000 });
      });
  }

  public closeModal() {
    this.dialogRef.close(false);
  }
}
