import { Injectable } from '@angular/core';
import { Observable, tap } from 'rxjs';

import { CosCoreClient } from '@cosCoreServices/core-client/cos-core-client.service';
import { EAddressType, IAccountAddress } from '@caronsale/cos-models';
import { AddressService } from '@cosCoreServices/user-address/address-abstract.service';
import { UserAddressStateService } from '@cosCoreServices/user-address-state/user-address-state.service';

@Injectable()
export class UserAddressService extends AddressService {
  public constructor(
    private cosClient: CosCoreClient,
    public userAddressStateService: UserAddressStateService,
  ) {
    super();
  }

  public getAddresses(_?: string): Observable<IAccountAddress[]> {
    return this.cosClient
      .requestWithPrivileges('get', '/users/address')
      .pipe(tap((addresses: IAccountAddress[]) => this.userAddressStateService.setAddresses(addresses)));
  }

  public createAddress(address: Partial<IAccountAddress>, _?: string, index?: number): Observable<IAccountAddress> {
    return this.cosClient.requestWithPrivileges('post', '/users/address', { ...address }).pipe(
      tap((savedAddress: IAccountAddress) => {
        if (index !== undefined) {
          this.userAddressStateService.switchAddressAtPosition(savedAddress, index + 1);
        }
      }),
    );
  }

  public deleteAddress(uuid: string, _?: string): Observable<void> {
    return this.cosClient.requestWithPrivileges('delete', `/users/address/${uuid}`).pipe(tap(() => this.userAddressStateService.removeAddressByUuid(uuid)));
  }

  public updateAddress(address: IAccountAddress, _?: string): Observable<IAccountAddress> {
    return this.cosClient
      .requestWithPrivileges('put', `/users/address/${address.uuid}`, { ...address })
      .pipe(tap((updatedAddress: IAccountAddress) => this.userAddressStateService.updateAddress(updatedAddress)));
  }

  public updateAddressType(uuid: string, type: Exclude<EAddressType, EAddressType.OWNER>, _?: string): Observable<void> {
    return this.cosClient
      .requestWithPrivileges('put', `/users/address/${uuid}/addressType/${type}`)
      .pipe(tap(() => this.userAddressStateService.changeAddressType(type, uuid)));
  }

  public removeAddressType(uuid: string, type: Exclude<EAddressType, EAddressType.OWNER>, _?: string): Observable<void> {
    return this.cosClient
      .requestWithPrivileges('delete', `/users/address/${uuid}/addressType/${type}`)
      .pipe(tap(() => this.userAddressStateService.changeAddressType(type)));
  }
}
