import { InjectionToken } from '@angular/core';
import { IPhraseConfig } from '@caronsale/frontend-services';

// eslint-disable-next-line @typescript-eslint/naming-convention
export const AppEnvironment = new InjectionToken<string>('app-env');

export interface IAppEnvironment {
  backendUrl: string;
  captchaSiteKey: string;
  cargoUrl: string;
  cargoApiUrl: string;
  disableInitialModals?: boolean; // disable this to speed up browser (auto) refresh for local development
  disableLogging?: boolean;
  disableSentry?: boolean;
  domain?: string;
  googleAnalyticsId?: string;
  envModules: unknown[];
  googleTagManagerId?: string;
  idpCookieDomain: string;
  idpCookieSecure: boolean;
  idpDisableCookieStorage?: boolean;
  idpUserPoolId: string;
  idpUserPoolWebClientId: string;
  label: string;
  notificationCenterSocketUrl?: string;
  oneSignalAPIKey?: string;
  pdfTronSecretKey: string;
  phraseConfig: IPhraseConfig;
  pickupCaptchaSiteKey: string;
  production: boolean;
  salesforceUrl?: string;
  vehicleIntakeJotFormUrl?: string;
  vehicleReportParsingServiceUrl?: string;
  productFruitsWorkspaceCode: string;
  zohoWorkspace: string;
  VATFactor: number;
  billieStaticUrl: string;
  amplitudeApiKey: string;
  amplitudeExperimentDeploymentKey: string;
  amplitudeSessionReplayEnabled: boolean;
  amplitudeProxyUrl: string;
  useProdCloudinaryAccount?: boolean;
}
