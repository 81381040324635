import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { IBuyerAuctionView, IPrebookedService, IVoucherAssignment } from '@caronsale/cos-models';
import { Subject, takeUntil } from 'rxjs';
import { BuyerAuctionService } from '@cosCoreFeatures/auction-detail/common/auction-service/buyer-auction.service';
import { PrebookedServiceGuaranteeOptionType } from '@caronsale/cos-models/dist/Auction/IPrebookedService';
import { PrebookedServicesService } from '@cosCoreServices/prebooked-services/prebooked-services.service';
import { GoogleAnalyticsService } from '@cosCoreServices/google-analytics/google-analytics.service';
import { PrebookedServicesUtils } from '@caronsale/frontend-utils';
import { COS_CONSTANTS } from '@caronsale/frontend-services';
import { VoucherService } from '@cosBuyer/partials/services/voucher.service';
import { CommonModule } from '@angular/common';
import { BaseModule } from '@cosCoreComponents/general/base.module';
import { FeeInfoModule } from '@cosBuyer/partials/fee-info/fee-info.module';
import { EnzoComponentsModule } from '@caronsale/enzo-angular';
import { ExpandablePanelModule } from '@cos/partials/expandable-panel/expandable-panel.module';
import { GeneralPipesModule, I18nCurrencyPipe } from '@caronsale/frontend-pipes';
import { PrebookedServicesModule } from '@cosBuyer/partials/prebooked-services/prebooked-services.module';
import { CosPopoverModule } from '@cosCoreFeatures/@cos/cos-popover/cos-popover.module';
import { VoucherSelectionModule } from '@cosBuyer/partials/voucher-selection/voucher-selection.module';

export interface IBiddingConfirmationData {
  auction: IBuyerAuctionView;
  prebookedServices: IPrebookedService[];
  bidValue: number;
  translationBaseKey: string;
  translationParams: { [key: string]: any };
}

export interface IBiddingConfirmationResult {
  isConfirmed: boolean;
  updatedPrebookedServices?: IPrebookedService[];
}

@Component({
  selector: 'app-bidding-confirmation',
  templateUrl: './bidding-confirmation-dialog.component.html',
  styleUrls: ['./bidding-confirmation-dialog.component.scss'],
  standalone: true,
  imports: [
    //
    BaseModule,
    CommonModule,
    CosPopoverModule,
    EnzoComponentsModule,
    ExpandablePanelModule,
    FeeInfoModule,
    GeneralPipesModule,
    PrebookedServicesModule,
    VoucherSelectionModule,
  ],
  providers: [I18nCurrencyPipe],
})
export class BiddingConfirmationDialogComponent implements OnInit, OnDestroy {
  public readonly CONDITIONS_LINK = {
    conditionsLink: this.constants.GUARANTEE_CONDITIONS_URL,
  };

  public auction: IBuyerAuctionView;
  public availableVouchers: IVoucherAssignment[];
  public selectedVoucher: IVoucherAssignment;
  public requireGuaranteeTerms = false;
  public guaranteeTermsAccepted = false;
  public prebookedServices: IPrebookedService[];
  public showBasicConfirmationDialog = false;
  public bidValue = 0;
  public confirmationTemplate: string = `{prefix} <a href="${this.constants.GUARANTEE_CONDITIONS_URL}" target="_blank">{link}</a> {suffix}`;

  private unsubscribe$: Subject<void> = new Subject<void>();
  private requiredGuaranteeTermsOptionTypes: PrebookedServiceGuaranteeOptionType[];

  public constructor(
    @Inject(MAT_DIALOG_DATA) public biddingConfirmationData: IBiddingConfirmationData,
    private dialogRef: MatDialogRef<BiddingConfirmationDialogComponent, IBiddingConfirmationResult>,
    private buyerAuctionService: BuyerAuctionService,
    private changeDetectorRef: ChangeDetectorRef,
    private prebookedServicesService: PrebookedServicesService,
    private googleAnalyticsService: GoogleAnalyticsService,
    private constants: COS_CONSTANTS,
    private voucherService: VoucherService,
  ) {}

  public ngOnInit(): void {
    // patch translationParams for backwards compatibility
    // to not display "undefined" in the message while the translations are not updated yet
    this.biddingConfirmationData.translationParams.formattedTotalBidValue = this.biddingConfirmationData.translationParams.formattedBidValue;
    this.prebookedServices = this.biddingConfirmationData.prebookedServices;
    this.auction = this.biddingConfirmationData.auction;
    this.bidValue = this.biddingConfirmationData.bidValue;
    this.showBasicConfirmationDialog =
      PrebookedServicesUtils.areSelectedOptionsEqual(this.auction.prebookedServices ?? [], this.prebookedServices ?? []) && this.auction.didIBidAtLeastOnce;

    this.voucherService
      .getSelectedVoucher()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(selectedVoucher => {
        this.selectedVoucher = selectedVoucher;
      });
  }

  public ngOnDestroy(): void {
    this.unsubscribe$.next();
  }

  public onServiceSelectionChange(newPrebookedServices: IPrebookedService[]) {
    this.prebookedServices = newPrebookedServices;
    this.buyerAuctionService.updateCurrentPrebookedServices(this.auction.uuid, newPrebookedServices);
  }

  public onRequireGuaranteeTermsAcceptance(requiredGuaranteeTermsOptionTypes: PrebookedServiceGuaranteeOptionType[]) {
    this.requiredGuaranteeTermsOptionTypes = requiredGuaranteeTermsOptionTypes;
    this.requireGuaranteeTerms = requiredGuaranteeTermsOptionTypes?.length > 0;
    if (!this.requireGuaranteeTerms) {
      this.guaranteeTermsAccepted = false; // reset when not required any more (when the guarantee checkbox is unchecked)
    }
    this.changeDetectorRef.detectChanges(); // child will emit in onInit (happens after parent onInit is already finished)
  }

  public onConfirm(): void {
    this.prebookedServicesService.addAcceptedTermsGuaranteeOptions(this.requiredGuaranteeTermsOptionTypes);

    if (!PrebookedServicesUtils.areSelectedOptionsEqual(this.prebookedServices, this.auction.prebookedServices)) {
      this.dialogRef.close({
        isConfirmed: true,
        updatedPrebookedServices: this.prebookedServices,
      });
    } else {
      this.dialogRef.close({ isConfirmed: true });
    }

    this.googleAnalyticsService.eventEmitter('auction_detail-click-bid', 'auction_detail', 'click', 'bid', this.auction.uuid, this.auction.uuid);
  }

  public onDecline(): void {
    this.dialogRef.close({ isConfirmed: false });
  }
}
