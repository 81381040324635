import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, OnInit, TemplateRef, Type } from '@angular/core';
import { Subject } from 'rxjs';
import { CosBottomOverlayProvider } from '../cos-bottom-overlay-provider.service';
import { CosBottomOverlayRef } from '../cos-bottom-overlay-ref';

const ANIMATION_TIMINGS = '400ms cubic-bezier(0.25, 0.8, 0.25, 1)';

const animation = [
  trigger('enter-fade', [transition(':enter', [style({ opacity: 0 }), animate(ANIMATION_TIMINGS, style({ opacity: 1 }))])]),
  trigger('enter-slide', [transition(':enter', [style({ transform: 'translateY(100%)' }), animate(ANIMATION_TIMINGS, style({ transform: 'translateY(0)' }))])]),
  trigger('leave-slide', [state('leaving', style({ transform: 'translateY(100%)' })), transition('* => *', animate(ANIMATION_TIMINGS))]),
];

@Component({
  selector: 'app-bottom-overlay',
  templateUrl: './cos-bottom-overlay.component.html',
  styleUrls: ['./cos-bottom-overlay.component.scss'],
  animations: animation,
})
export class CosBottomOverlayComponent implements OnInit {
  public contentType: 'template' | 'string' | 'component';
  public content: string | TemplateRef<any> | Type<any>;
  public context;

  public onLeaveSlideDone$ = new Subject<any>();

  public isClosing = false;

  public constructor(
    private ref: CosBottomOverlayRef,
    private overlayProvider: CosBottomOverlayProvider,
  ) {}

  // -------------------------------------------------------------------------------
  // ANGULAR CALLBACKS
  // -------------------------------------------------------------------------------
  public ngOnInit() {
    this.content = this.ref.content;

    if (typeof this.content === 'string') {
      this.contentType = 'string';
    } else if (this.content instanceof TemplateRef) {
      this.contentType = 'template';
      this.context = {
        close: this.ref.close.bind(this.ref),
      };
    } else {
      this.contentType = 'component';
    }

    this.overlayProvider.closing$.subscribe(() => {
      this.isClosing = true;
    });

    /**
     * this will close the ref
     */
    this.onLeaveSlideDone$.subscribe((event: any) => {
      if (event.toState === 'leaving') {
        this.ref.close();
      }
    });
  }

  // -------------------------------------------------------------------------------
  // Animation
  // -------------------------------------------------------------------------------
  public onLeaveSlideDone(event: AnimationEvent) {
    this.onLeaveSlideDone$.next(event);
  }

  // -------------------------------------------------------------------------------
  // CUSTOM FUNCTIONS
  // -------------------------------------------------------------------------------
  /**
   * function to close the current bottom overlay
   */
  public close() {
    this.isClosing = true;
  }
}
