import { Pipe, PipeTransform } from '@angular/core';
import { I18nFormatUtils } from '@caronsale/cos-models';
import { EVehiclePaintLocation } from '@caronsale/cos-vehicle-models';

// TODO: Extract this into frontend common
@Pipe({
  name: 'i18nPaintStateParts',
})
export class I18nPaintStatePartsPipe implements PipeTransform {
  public transform(paintLocation: EVehiclePaintLocation): string {
    return I18nFormatUtils.formatVehiclePaintLocation(paintLocation);
  }
}
