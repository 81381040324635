import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import {
  BuyerPipesModule,
  GeneralPipesModule,
  I18nAuctionPipesModule,
  I18nGeneralPipesModule,
  I18nVehiclePipesModule,
  VehiclePipesModule,
} from '@caronsale/frontend-pipes';

import { BaseModule } from '@cosCoreComponentsGeneral/base.module';
import { ExpandablePanelModule } from '@cos/partials/expandable-panel/expandable-panel.module';

import { VehicleOriginReportButtonModule } from '../vehicle-origin-report-button/vehicle-origin-report-button.module';

import { SpecificInfoComponent } from './specific-info.component';
import { VehicleDetailsGridComponent } from '../vehicle-details-grid/vehicle-details-grid.component';
import { EnzoComponentsModule } from '@caronsale/enzo-angular';
import { TaxationInfoModule } from '@cos/partials/taxation-info/taxation-info.module';
import { CosPopoverModule } from '@cosCoreFeatures/@cos/cos-popover/cos-popover.module';

@NgModule({
  declarations: [SpecificInfoComponent],
  imports: [
    CommonModule,
    CosPopoverModule,
    BaseModule,
    BuyerPipesModule,
    EnzoComponentsModule,
    ExpandablePanelModule,
    I18nGeneralPipesModule,
    I18nAuctionPipesModule,
    I18nVehiclePipesModule,
    VehiclePipesModule,
    GeneralPipesModule,
    TaxationInfoModule,
    VehicleOriginReportButtonModule,
    VehicleDetailsGridComponent,
  ],
  exports: [SpecificInfoComponent],
})
export class SpecificInfoModule {}
