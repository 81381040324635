import { Component, OnInit, TemplateRef } from '@angular/core';
import { PopoverContent, PopoverRef } from './components/popover-ref.component';

@Component({
  templateUrl: './cos-popover.component.html',
  styleUrls: ['./cos-popover.component.scss'],
})
export class CosPopoverComponent implements OnInit {
  public renderMethod: 'template' | 'component' | 'text' = 'component';
  public content: PopoverContent;
  public context;

  public constructor(private popoverRef: PopoverRef) {}

  public ngOnInit(): void {
    this.content = this.popoverRef.content;

    if (typeof this.content === 'string') {
      this.renderMethod = 'text';
    }

    if (this.content instanceof TemplateRef) {
      this.renderMethod = 'template';
      this.context = {
        close: this.popoverRef.close.bind(this.popoverRef),
      };
    }
  }
}
