import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BaseModule } from '../base.module';

import { LoadingLayerComponent } from './loading-layer.component';

@NgModule({
  imports: [CommonModule, BaseModule],
  declarations: [LoadingLayerComponent],
  exports: [LoadingLayerComponent],
})
export class LoadingLayerModule {}
