<div class="confirmation-success-container">
  <h1>{{ 'self-pickup.thank-you-for-pick-up' | translate }}</h1>

  <div class="cs-card">
    <div class="car-image" [ngStyle]="{ 'background-image': imgUrl }"></div>

    <div class="car-info">
      <h3>{{ 'self-pickup.confirmed-vehicle-pick-up' | translate: { vehicleInfo: vehicleInfo, vin: vin } }}</h3>

      <div class="status-picked">
        <div class="status-icon">
          <fa-icon icon="check"></fa-icon>
        </div>

        <div class="status-text">{{ 'self-pickup.picked-up' | translate }}</div>
      </div>
    </div>
  </div>

  <a mat-flat-button color="primary" class="homepage-button" [href]="cosLink"> {{ 'self-pickup.navigate-to-home-page' | translate }} </a>
</div>
