import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BuyerGuaranteeAdvertiseDialogComponent } from './buyer-guarantee-advertise-dialog.component';
import { BaseModule } from '@cosCoreComponentsGeneral/base.module';
import { GeneralPipesModule } from '@caronsale/frontend-pipes';
import { GuaranteeBadgeModule } from '@cosCoreComponents/general/guarantee-badge/guarantee-badge.module';
import { BuyerGuaranteeOptionsDialogComponent } from '@cosCoreFeatures/buyer/components/buyer-guarantee-options-dialog/buyer-guarantee-options-dialog.component';

@NgModule({
  declarations: [BuyerGuaranteeAdvertiseDialogComponent],
  imports: [CommonModule, BaseModule, GeneralPipesModule, GuaranteeBadgeModule, BuyerGuaranteeOptionsDialogComponent],
})
export class BuyerGuaranteeAdvertiseDialogModule {}
