import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-expandable-panel',
  templateUrl: './expandable-panel.component.html',
  styleUrls: ['./expandable-panel.component.scss'],
})
export class ExpandablePanelComponent {
  @Input()
  public expandedState: boolean = false;

  @Input()
  public titleStretch: boolean = true;

  @Input()
  public iconPosition: 'left' | 'right' = 'right';

  @Output()
  public expandedStateChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  public togglePanel(): void {
    this.expandedState = !this.expandedState;
    this.expandedStateChange.emit(this.expandedState);
  }
}
