import { Component } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-incompatible-browser-modal',
  templateUrl: './general-incompatible-browser-modal.component.html',
  styleUrls: ['./general-incompatible-browser-modal.component.scss'],
  standalone: true,
  imports: [TranslateModule],
})
export class GeneralIncompatibleBrowserModalComponent {}
