<h1>{{ titleKey | translate: data.params }}</h1>

@if (messageKey) {
  {{ messageKey | translate: data.params }}
} @else {
  @for (key of messageKeys; track key) {
    {{ key | translate: data.params }}
    <br />
  }
}

<div class="buttons">
  <button mat-flat-button (click)="close()">{{ 'general.ok' | translate }}</button>
</div>
