import { Component, Input, OnInit, Optional, Self } from '@angular/core';
import { ControlValueAccessor, UntypedFormControl, NgControl } from '@angular/forms';
import { IAccountAddress } from '@caronsale/cos-models';

const EMPTY_ADDRESS_UUID = '';

@Component({
  selector: 'app-address-selection',
  templateUrl: './address-selection.component.html',
  styleUrls: ['./address-selection.component.scss'],
})
export class AddressSelectionComponent implements OnInit, ControlValueAccessor {
  @Input()
  public addresses: IAccountAddress[];

  @Input()
  public label: string;

  @Input()
  public allowEmpty: boolean;

  public formControl: UntypedFormControl;

  public emptyAddress = { uuid: EMPTY_ADDRESS_UUID };

  public onChanged = (_value: IAccountAddress) => {
    //
  };
  public onTouched = () => {
    //
  };

  public constructor(@Optional() @Self() public ngControl: NgControl) {
    ngControl.valueAccessor = this;
  }

  public ngOnInit(): void {
    this.formControl = this.ngControl.control as UntypedFormControl;
  }

  public registerOnChange(fn: (value: IAccountAddress) => void): void {
    this.onChanged = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  public writeValue(_value: IAccountAddress): void {
    //
  }
}
