<app-auction-detail-view
  [auction]="auction"
  [hidePaymentInfo]="data?.hidePaymentInfo"
  [currentPrebookedServices]="currentPrebookedServices"></app-auction-detail-view>

<div>
  <div (click)="closeDialog()" class="dialog-close-button" matDialogClose matTooltip="{{ 'action.close-dialog' | translate }}">
    <fa-icon icon="times" size="2x"></fa-icon>
  </div>
</div>
