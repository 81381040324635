import { Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';

import { IBuyerAuctionView } from '@caronsale/cos-models';
import { I18nInfoDialogComponent } from '@cosCoreComponentsGeneral/i18n/info-dialog/i18n-info-dialog.component';
import { CosBuyerClientService } from '@cosCoreServices/cos-salesman-client/cos-buyer-client.service';
import { CurrencyPipe } from '@angular/common';
import { I18nConfirmationDialogComponent } from '@cosCoreComponentsGeneral/i18n/confirmation-dialog/i18n-confirmation-dialog.component';
import { BuyerAuctionService } from '@cosCoreFeatures/auction-detail/common/auction-service/buyer-auction.service';
import { switchMap } from 'rxjs';
import { ProductAnalyticsService } from '@cosCoreServices/product-analytics/product-analytics.service';

@Injectable({
  providedIn: 'root',
})
export class InstantPurchaseService {
  public constructor(
    public currencyPipe: CurrencyPipe,
    private dialog: MatDialog,
    private cosBuyerClient: CosBuyerClientService,
    private buyerAuctionService: BuyerAuctionService,
    private productAnalyticsService: ProductAnalyticsService,
  ) {}

  public makeInstantPurchase(auction: IBuyerAuctionView): void {
    const formattedBidValue: string = this.currencyPipe.transform(auction.instantPurchasePrice, 'EUR');

    I18nConfirmationDialogComponent.showConfirmDialog(this.dialog, 'dialog.buyer.instant-purchase-buy-now', { price: formattedBidValue })
      .pipe(switchMap(() => this.cosBuyerClient.makeInstantPurchase(auction)))
      .subscribe(() => {
        this.buyerAuctionService.refreshBiddingInfos([auction.uuid]);
        I18nInfoDialogComponent.show(this.dialog, 'dialog.buyer.buy-success').subscribe(() => this.dialog.closeAll());
        this.productAnalyticsService.trackEvent('instantlyPurchased', {
          'Auction uuid': auction.uuid,
          'Instant purchase price': auction.instantPurchasePrice,
        });
      });
  }
}
