<app-expandable-panel [expandedState]="true" testid="panel">
  <div panelTitle class="panel-title">
    <enzo-icon [icon]="'steering-wheel'"></enzo-icon>
    <enzo-headline level="4" color="blackish">{{ 'general.vehicle-equipment' | translate }}</enzo-headline>
  </div>

  <div panelContent>
    <enzo-text *ngIf="equipmentNotAvailableForDesiredLanguage" kind="caption" icon="info" icon-size="small">
      {{ 'vehicle.equipment-not-available-for-desired-language' | translate }}
    </enzo-text>
    <div class="cards-container" [style.height.px]="containerHeight">
      <div *ngFor="let group of equipmentGroups | keyvalue: asIsOrder" class="card">
        <enzo-headline level="5">{{ group.value.translationKey | translate }}</enzo-headline>
        <ul>
          <li *ngFor="let equipment of group.value.equipments" [class.star-icon]="equipment.type === EVehicleEquipmentType.SPECIAL">
            <enzo-icon
              *ngIf="equipment.type === EVehicleEquipmentType.SPECIAL"
              icon="recommended"
              color="yellow-dark"
              size="small"
              [matTooltip]="'general.special-equipment' | translate"></enzo-icon>
            <enzo-text overflow="ellipsis">
              {{ equipment.description }}
            </enzo-text>
          </li>
        </ul>
      </div>

      <div *ngIf="otherGroup.length" class="card">
        <enzo-headline level="5">{{ 'general.other-category' | translate }}</enzo-headline>
        <ul>
          <li *ngFor="let equipment of otherGroup" [class.star-icon]="equipment.type === EVehicleEquipmentType.SPECIAL">
            <enzo-icon
              *ngIf="equipment.type === EVehicleEquipmentType.SPECIAL"
              icon="recommended"
              color="yellow-dark"
              size="small"
              [matTooltip]="'Sonderausstattung'"></enzo-icon>
            <enzo-text overflow="ellipsis">
              {{ equipment.description }}
            </enzo-text>
          </li>
        </ul>
      </div>
    </div>

    @if (auction.associatedVehicle.origin !== EVehicleCreationOrigin.MERCEDES_AUSTRIA) {
      <enzo-text kind="caption" class="data-disclaimer">* {{ 'vehicle.data-disclaimer-text' | translate }}</enzo-text>
    }
  </div>
</app-expandable-panel>
