<ng-container *ngIf="!(auction | isInstantPurchaseActive); else instantPurchaseVatText">
  @if ((auction | isAuctionRunning) || ((auction | isAuctionSuccessful) && auction.amIHighestBidder)) {
    @if (auction.isVATReportable && auction.currentHighestBidValue !== auction.currentHighestBidValueNet) {
      <enzo-text kind="caption" color="grey-dark" testid="vat-amount">
        {{ 'auction.bid-contains' | translate }} {{ vatAmount | currency: 'EUR' }} {{ 'general.vat-short' | translate }}
      </enzo-text>
    } @else {
      <enzo-text kind="caption" color="grey-dark">
        {{ auction.isVATReportable | i18nVatReportable | translate }}
      </enzo-text>
    }
  }
</ng-container>

<ng-template #instantPurchaseVatText>
  @if (auction.isVATReportable && auction.instantPurchasePrice !== auction.instantPurchasePriceNet) {
    <enzo-text kind="caption" color="grey-dark" testid="vat-amount-instant-purchase">
      {{ 'auction.instant-purchase-contains' | translate }} {{ vatAmountInstantPurchase | currency: 'EUR' }} {{ 'general.vat-short' | translate }}
    </enzo-text>
  } @else {
    <enzo-text kind="caption" color="grey-dark">
      {{ auction.isVATReportable | i18nVatReportable | translate }}
    </enzo-text>
  }
</ng-template>
