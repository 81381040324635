import { Component, OnInit } from '@angular/core';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyDialog as MatDialog, MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { ImprintComponent } from '@cosCoreComponentsGeneral/imprint/imprint.component';
import { CosBuyerClientService } from '@cosCoreServices/cos-salesman-client/cos-buyer-client.service';
import { ECountryCode, EUserType } from '@caronsale/cos-models';
import { ConfigService } from '@cosCoreServices/config/config.service';
import { COS_CONSTANTS } from '@caronsale/frontend-services';
import { CosCoreClient } from '@cosCoreServices/core-client/cos-core-client.service';
import { NgIf } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { ImprintModule } from '@cos/components/general/imprint/imprint.module';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  standalone: true,
  imports: [ImprintModule, NgIf, MatButtonModule, MatDialogModule, TranslateModule],
})
export class FooterComponent implements OnInit {
  public showGuaranteeConditionsUrl = false;
  public showTermsOfServiceURL = false;

  public termsOfServiceURL: string;

  public constructor(
    private dialog: MatDialog,
    private cosBuyerClientService: CosBuyerClientService,
    private configService: ConfigService,
    public constants: COS_CONSTANTS,
    private cosCoreClient: CosCoreClient,
  ) {}

  public ngOnInit(): void {
    if (this.cosCoreClient.getLastAuthenticationResult().type === EUserType.SALESMAN) {
      this.cosBuyerClientService.getCurrentBuyerUser().subscribe(buyerUser => {
        this.showGuaranteeConditionsUrl = this.configService.isGuaranteeEnabled() && buyerUser.countryCode === ECountryCode.DE;
      });
    }
    this.constants.termsOfServiceURL.subscribe(termsOfServiceURL => {
      this.showTermsOfServiceURL = true;
      this.termsOfServiceURL = termsOfServiceURL;
    });
  }

  public get supportMail(): string {
    return this.constants.SUPPORT_EMAIL;
  }

  public get guaranteeConditionsUrl(): string {
    return this.constants.GUARANTEE_CONDITIONS_URL;
  }

  public openImprint(): void {
    this.dialog.open(ImprintComponent, {
      width: '450px',
    });
  }
}
