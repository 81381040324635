import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-country-flag',
  templateUrl: './app-country-flag.component.html',
  styleUrls: ['./app-country-flag.component.scss'],
})
export class CountryFlagComponent {
  @Input()
  public set countryCode(countryCode: string) {
    if (countryCode) {
      this.flagImageUrl = `../../../../assets/images/flags/${countryCode.toLowerCase()}.svg`;
    }
  }

  @Input()
  public size: number = 16;

  public flagImageUrl;
}
