import { Component, ElementRef, HostBinding, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { IBuyerAuctionBidView, IBuyerAuctionView } from '@caronsale/cos-models';
import { CosBuyerClientService } from '@cosCoreServices/cos-salesman-client/cos-buyer-client.service';
import { DOCUMENT } from '@angular/common';
import { fromEvent, Subject, filter, takeUntil } from 'rxjs';

@Component({
  selector: 'app-bids-amount',
  templateUrl: './bids-amount.component.html',
  styleUrls: ['./bids-amount.component.scss'],
})
export class BidsAmountComponent implements OnInit, OnDestroy {
  @Input()
  public auction: IBuyerAuctionView;

  @Input()
  public alignPanel: 'right' | 'left' = 'right';

  @HostBinding('class')
  public hostClass: string;

  public isExpanded = false;
  public bids: IBuyerAuctionBidView[] = null;

  private removeClickHandler: Subject<void> = new Subject<void>();

  public constructor(
    //
    private elementRef: ElementRef,
    private cosBuyerClient: CosBuyerClientService,
    @Inject(DOCUMENT) private document: Document,
  ) {}

  public ngOnInit() {
    this.hostClass = this.alignPanel === 'left' ? 'align-left' : 'align-right';
  }

  public ngOnDestroy() {
    this.removeWindowClickHandler();
  }

  public togglePanelOnPaddingAreaClick(event: Event) {
    if (event.currentTarget === event.target) {
      // click is directly on the div, not in the expandable panel child
      this.setPanelState(!this.isExpanded);
    }
  }

  public expandedStateChange(isExpanded: boolean) {
    if (isExpanded) {
      // fetch again every time it is opened
      this.fetchBids();
      // close the overlay if something was clicked outside of us (that bubbled up to 'window')
      fromEvent(this.document, 'click')
        .pipe(
          takeUntil(this.removeClickHandler),
          filter(event => !this.elementRef.nativeElement.firstElementChild.contains(event.target)),
        )
        .subscribe(() => this.setPanelState(false));
    } else {
      this.removeWindowClickHandler();
    }
  }

  private setPanelState(isExpanded: boolean) {
    this.isExpanded = isExpanded;
    this.expandedStateChange(isExpanded);
  }

  private fetchBids(): void {
    this.cosBuyerClient.getBiddersList(this.auction).subscribe((bids: IBuyerAuctionBidView[]) => (this.bids = bids));
  }

  private removeWindowClickHandler(): void {
    this.removeClickHandler.next();
  }
}
