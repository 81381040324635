import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BaseModule } from '../base.module';

import { ImprintComponent } from './imprint.component';

@NgModule({
  imports: [CommonModule, BaseModule],
  declarations: [ImprintComponent],
  exports: [ImprintComponent],
})
export class ImprintModule {}
