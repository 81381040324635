import { Directive, HostBinding, HostListener, Input, TemplateRef } from '@angular/core';
import { Popover } from '@cosCoreFeatures/@cos/cos-popover/services/popover.service';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[popoverText], [popoverTemplate]',
})
export class CosPopoverDirective {
  public constructor(private popover: Popover) {}
  @Input()
  public popoverText?: string;

  @Input()
  public popoverTemplate?: TemplateRef<unknown>;

  @Input()
  public popoverWidth?: string | number;

  @HostBinding('style.cursor')
  public cursor = 'pointer';

  @HostListener('click', ['$event'])
  public onClick($event: Event): void {
    $event.stopPropagation();
    $event.preventDefault();
    this.popover.open({
      content: this.popoverText ?? this.popoverTemplate,
      origin: $event.currentTarget as HTMLElement,
      width: this.popoverWidth, // can be undefined
    });
  }
}
