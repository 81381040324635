import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';

import { RecaptchaModule } from 'ng-recaptcha';

import { BaseModule } from '@cosCoreComponentsGeneral/base.module';
import { PinCodeComponent } from '@cosCoreComponentsGeneral/pin-code/pin-code.component';
import { CosPickupService } from '@cosCoreServices/cos-pickup/cos-pickup.service';
import { ManualPickupConfirmationComponent } from './components/manual-pickup-confirmation/manual-pickup-confirmation.component';
import { PickupConfirmationByScanComponent } from './components/pickup-confirmation-by-scan/pickup-confirmation-by-scan.component';
import { PickupConfirmationComponent } from './components/pickup-confirmation/pickup-confirmation.component';
import { PickupConfirmationErrorComponent } from './components/pickup-confirmation-error/pickup-confirmation-error.component';
import { PickupConfirmationSuccessComponent } from './components/pickup-confirmation-success/pickup-confirmation-success.component';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { LogoModule } from '@cos/components/general/logo/logo.module';
import { LanguageSelectComponent } from '@cos/components/general/language-select/language-select.component';
import { FooterComponent } from '@cos/partials/footer/footer.component';
import { EnzoComponentsModule } from '@caronsale/enzo-angular';

const routes: Routes = [{ path: '', component: PickupConfirmationComponent }];

@NgModule({
  imports: [
    CommonModule,
    BaseModule,
    FooterComponent,
    LanguageSelectComponent,
    LogoModule,
    MatButtonModule,
    MatFormFieldModule,
    PinCodeComponent,
    RecaptchaModule,
    RouterModule.forChild(routes),
    EnzoComponentsModule,
  ],
  providers: [CosPickupService],
  declarations: [
    PickupConfirmationByScanComponent,
    PickupConfirmationSuccessComponent,
    ManualPickupConfirmationComponent,
    PickupConfirmationComponent,
    PickupConfirmationErrorComponent,
  ],
})
export class PickupConfirmationModule {}
