import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BaseModule } from '../base.module';

import { CountryFlagComponent } from './app-country-flag.component';

@NgModule({
  imports: [CommonModule, BaseModule],
  declarations: [CountryFlagComponent],
  exports: [CountryFlagComponent],
})
export class CountryFlagModule {}
