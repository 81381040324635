import { Component, DestroyRef, OnInit, output } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { EnzoComponentsModule, EnzoValidation } from '@caronsale/enzo-angular';
import { AsyncPipe } from '@angular/common';
import { filter, map, Observable } from 'rxjs';
import { IPurchasePreferences } from '@caronsale/cos-models';
import { VehicleModelsService } from '@cosCoreServices/vehicle-models/vehicle-models.service';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { AuctionFilterRangesService } from '@cosBuyer/services/auction-search-filter-ranges.service';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { I18nSnackService } from '@cosCoreServices/i18n-snack/i18n-snack.service';
import { AuctionSearchDropdownOption } from '@cosBuyer/partials/filters/Utils';

const ANY_KEY: string = 'any';

type PurchasePreferencesForm = {
  makes: FormControl<IPurchasePreferences['makes'] | string[]>;
  vehicleAgeFrom: FormControl<IPurchasePreferences['vehicleAgeFrom'] | string>;
  vehicleAgeTo: FormControl<IPurchasePreferences['vehicleAgeTo'] | string>;
  mileageFromKM: FormControl<IPurchasePreferences['mileageFromKM'] | string>;
  mileageToKM: FormControl<IPurchasePreferences['mileageToKM'] | string>;
  priceFrom: FormControl<IPurchasePreferences['priceFrom'] | string>;
  priceTo: FormControl<IPurchasePreferences['priceTo'] | string>;
  fuelTypes: FormControl<IPurchasePreferences['fuelTypes'] | string[]>;
  distanceRadiusKM: FormControl<IPurchasePreferences['distanceRadiusKM'] | string>;
  countries: FormControl<IPurchasePreferences['countries'] | string[]>;
  vehicleCategories: FormControl<IPurchasePreferences['vehicleCategories'] | string[]>;
};

@Component({
  selector: 'app-purchase-preferences-form',
  templateUrl: './purchase-preferences-form.component.html',
  styleUrls: ['./purchase-preferences-form.component.scss'],
  providers: [AuctionFilterRangesService],
  imports: [AsyncPipe, EnzoComponentsModule, ReactiveFormsModule, TranslateModule],
  standalone: true,
})
export class PurchasePreferencesFormComponent implements OnInit {
  public preferencesSubmitted = output<IPurchasePreferences | null>();

  public purchasePreferencesForm: FormGroup<PurchasePreferencesForm>;
  public ranges = toSignal(
    this.auctionFilterRangesService.getRanges().pipe(
      map(ranges => {
        const keysToExtract = ['years', 'mileage', 'price', 'fuelTypes', 'searchRadius', 'countries', 'vehicleCategory'];
        const processedRanges: Record<string, AuctionSearchDropdownOption[]> = {};

        keysToExtract.forEach(key => {
          const existingRange = ranges[key] || [];
          let filteredRange = existingRange.filter(option => option.value !== undefined);

          if (key === 'years') {
            filteredRange = filteredRange.map(option => ({ value: Number(option.value), label: option.label }));
          }

          processedRanges[key] = [
            { value: ANY_KEY, label: this.translateService.instant('auction-filter.any') } as AuctionSearchDropdownOption,
            ...filteredRange,
          ];
        });

        return processedRanges;
      }),
    ),
  );

  public makes$: Observable<AuctionSearchDropdownOption[]> = this.vehicleModelsService.makes$.pipe(
    map(makes => [
      { value: ANY_KEY, label: this.translateService.instant('auction-filter.any') } as AuctionSearchDropdownOption,
      ...this.mapToDropdownOptions(makes),
    ]),
    filter(makes => makes.length > 1),
  );

  public constructor(
    private vehicleModelsService: VehicleModelsService,
    private auctionFilterRangesService: AuctionFilterRangesService,
    private fb: FormBuilder,
    private translateService: TranslateService,
    private destroyRef: DestroyRef,
    private snackService: I18nSnackService,
  ) {
    this.purchasePreferencesForm = this.fb.group<PurchasePreferencesForm>({
      makes: this.fb.control([], EnzoValidation.wrapValidator(Validators.required, 'error.required')),
      vehicleAgeFrom: this.fb.control(null, EnzoValidation.wrapValidator(Validators.required, 'error.required')),
      vehicleAgeTo: this.fb.control(null, EnzoValidation.wrapValidator(Validators.required, 'error.required')),
      mileageFromKM: this.fb.control(null, EnzoValidation.wrapValidator(Validators.required, 'error.required')),
      mileageToKM: this.fb.control(null, EnzoValidation.wrapValidator(Validators.required, 'error.required')),
      priceFrom: this.fb.control(null, EnzoValidation.wrapValidator(Validators.required, 'error.required')),
      priceTo: this.fb.control(null, EnzoValidation.wrapValidator(Validators.required, 'error.required')),
      fuelTypes: this.fb.control([], EnzoValidation.wrapValidator(Validators.required, 'error.required')),
      distanceRadiusKM: this.fb.control(null, EnzoValidation.wrapValidator(Validators.required, 'error.required')),
      countries: this.fb.control([], EnzoValidation.wrapValidator(Validators.required, 'error.required')),
      vehicleCategories: this.fb.control([], EnzoValidation.wrapValidator(Validators.required, 'error.required')),
    });
  }

  public ngOnInit() {
    this.purchasePreferencesForm.valueChanges
      .pipe(
        map(formValue => {
          return Object.fromEntries(
            Object.entries(formValue).map(([key, value]) => {
              if (!Array.isArray(value)) {
                return [key, value];
              }

              let hasAnyKey = false;

              for (let i = 0; i < value.length; i++) {
                if (value[i] === ANY_KEY) {
                  hasAnyKey = true;

                  break;
                }
              }

              if (hasAnyKey) {
                return [key, [ANY_KEY]];
              }

              if (value.length > 1) {
                return [key, value.filter(v => v !== ANY_KEY)];
              }

              return [key, value];
            }),
          );
        }),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe(processedValues => {
        this.purchasePreferencesForm.patchValue(processedValues, { emitEvent: false });
      });
  }

  private mapToDropdownOptions(values: string[] = []): AuctionSearchDropdownOption[] {
    return values.map(value => ({ label: value, value }));
  }

  public patchForm(data: Partial<Record<string, any>>): void {
    if (data) {
      this.purchasePreferencesForm.patchValue(this.replaceNullWithANYKEY(data));
    }
  }

  public emitPurchasePreferences() {
    this.purchasePreferencesForm.markAllAsTouched();
    EnzoValidation.updateAllValueAndValidity(this.purchasePreferencesForm);

    if (this.purchasePreferencesForm.invalid) {
      this.snackService.open('purchase-preferences-modal.please-fill-all-fields', null, { duration: 3000 });
      return;
    }

    const rawFormValue = this.purchasePreferencesForm.getRawValue();

    this.preferencesSubmitted.emit(this.replaceANYKEYWithNull(rawFormValue) as IPurchasePreferences);
  }

  private replaceANYKEYWithNull(data: Partial<Record<string, any>>) {
    return Object.fromEntries(
      Object.entries(data).map(([key, value]) => {
        if (value === ANY_KEY) {
          return [key, null];
        }

        if (Array.isArray(value)) {
          return [key, value.filter(v => v !== ANY_KEY)];
        }

        return [key, value];
      }),
    );
  }

  private replaceNullWithANYKEY(data: Partial<Record<string, any>>) {
    return Object.fromEntries(
      Object.entries(data).map(([key, value]) => {
        if (value === null) {
          return [key, ANY_KEY];
        }

        if (Array.isArray(value)) {
          return [key, value.length === 0 ? [ANY_KEY] : value];
        }

        return [key, value];
      }),
    );
  }
}
