<mat-form-field appearance="outline">
  <mat-label>{{ label }}</mat-label>

  <mat-select [formControl]="formControl">
    <mat-option *ngIf="allowEmpty" [value]="emptyAddress.uuid">{{ 'account.buyer.no-address-selected' | translate }}</mat-option>

    <mat-option *ngFor="let address of addresses" [value]="address?.uuid">
      {{ 'account.buyer.formatted-address-line' | translate: { line: address?.addressLine, zipCode: address?.zipCode, city: address?.city } }}
      {{ address?.countryCode | countryFlag }}
    </mat-option>
  </mat-select>
</mat-form-field>
