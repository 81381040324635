import { Component, Input } from '@angular/core';
import { EMessageCode } from '@caronsale/cos-models';

@Component({
  selector: 'app-pickup-confirmation-error',
  templateUrl: './pickup-confirmation-error.component.html',
  styleUrls: ['./pickup-confirmation-error.component.scss'],
})
export class PickupConfirmationErrorComponent {
  public errorDetails;

  @Input()
  public set errorCode(error: any) {
    const defaultError = {
      headerKey: 'self-pickup.confirm-error.oh-no',
      explanationKey: 'self-pickup.confirm-error.error-generic',
    };

    switch (error.status) {
      case 400:
        switch (error.msgKey) {
          case EMessageCode.AUCTION_PICKUP_INVALID_PIN:
            this.errorDetails = {
              headerKey: 'self-pickup.confirm-error.oh-no',
              explanationKey: 'self-pickup.confirm-error.error-generic',
            };
            break;
          case EMessageCode.AUCTION_PICKUP_TRANSPORT_BOOKED:
            this.errorDetails = {
              headerKey: 'self-pickup.confirm-error.oh-yes',
              explanationKey: 'self-pickup.confirm-error.error-transport-already-booked',
            };
            break;
          case EMessageCode.AUCTION_PICKUP_CANNOT_CONFIRM:
            this.errorDetails = {
              headerKey: 'self-pickup.confirm-error.oh-no',
              explanationKey: 'self-pickup.confirm-error.error-could-not-confirm-pickup',
            };
            break;
          case EMessageCode.AUCTION_NOT_WAITING_FOR_PICKUP:
            this.errorDetails = {
              headerKey: 'self-pickup.confirm-error.oh-no',
              explanationKey: 'self-pickup.confirm-error.error-not-waiting-for-pickup',
            };
            break;
          default:
            this.errorDetails = defaultError;
            break;
        }
        break;
      case 409:
        switch (error.msgKey) {
          case EMessageCode.AUCTION_PICKUP_ALREADY_CONFIRMED:
            this.errorDetails = {
              headerKey: 'self-pickup.confirm-error.oh-yes',
              explanationKey: 'self-pickup.confirm-error.error-was-already-confirmed',
            };
            break;
          default:
            this.errorDetails = defaultError;
            break;
        }
        break;
      default:
        this.errorDetails = defaultError;
        break;
    }
  }
}
