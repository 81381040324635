import { IAuctionFilter, IBuyerAuctionBiddingDataView, IBuyerAuctionView, IPrebookedService } from '@caronsale/cos-models';
import isEqual from 'lodash.isequal';
import { differenceInSeconds } from 'date-fns';
import { ViewStateSubscriptionManagement } from '@cosCoreFeatures/auction-detail/common/auction-service/view-state-subscription-management';
import { EBuyerAuctionTabId } from './buyer-auction.service';

/**
 * auction state cache for buyer auction states
 * used only by the buyer-auction service
 */

export enum EBuyerAuctionViewVariant {
  LIST_VIEW,
  DETAIL_VIEW,
}

export interface IBuyerAuctionViewState {
  readonly auction: IBuyerAuctionView | null;
  readonly auctionFilter: IAuctionFilter;
  readonly currentPrebookedServices?: IPrebookedService[];
  readonly lastBiddingInfoRequestDate: Date;
  readonly lastUpdateReportedByServer?: Date;
  readonly viewVariant: EBuyerAuctionViewVariant;
  readonly tabId?: EBuyerAuctionTabId;
}

export function removeFilterAndNavigationFlags(viewState: IBuyerAuctionViewState): IBuyerAuctionViewState {
  return {
    ...viewState,
    auctionFilter: null,
    tabId: null,
    auction: {
      ...viewState.auction,
      hasNextAuction: false,
      hasPreviousAuction: false,
    },
  };
}

export const EMPTY_BUYER_AUCTION_VIEW_STATE = {
  auction: null,
  auctionFilter: null, // hasPrevious and hasNext depend on the filter we apply
  lastBiddingInfoRequestDate: new Date(0), // as old as possible
  viewVariant: null,
  tabId: null,
} as IBuyerAuctionViewState;

export class BuyerAuctionViewStates {
  private viewStates: Map<string, IBuyerAuctionViewState> = new Map<string, IBuyerAuctionViewState>();

  public constructor(private subscriptionManagement: ViewStateSubscriptionManagement<IBuyerAuctionViewState>) {}

  public resetState(uuid: string): void {
    this.viewStates.set(uuid, EMPTY_BUYER_AUCTION_VIEW_STATE);
  }

  public delete(uuid: string): void {
    this.viewStates.delete(uuid);
  }

  public getState(uuid: string): IBuyerAuctionViewState {
    return this.viewStates.get(uuid);
  }

  public resetAuctionNavigation(uuid: string) {
    this.updateViewState(uuid, removeFilterAndNavigationFlags);
  }

  /**
   * Check if the bidding info is stale.
   *
   * @param uuid the uuid of the auction
   * @param maxSecondsSinceLastUpdate time since the last update it should consider the bidding info stale
   * @param considerServerReportedUpdates if the server reported updates should be considered
   *  In this case, if the server reported an update recently we consider the bidding info stale regardless of the maxSecondsSinceLastUpdate
   */
  public isBiddingInfoStale(uuid: string, maxSecondsSinceLastUpdate: number, considerServerReportedUpdates = false): boolean {
    const viewState = this.getState(uuid);

    if (!viewState) {
      return true;
    }

    if (considerServerReportedUpdates && differenceInSeconds(viewState.lastUpdateReportedByServer, viewState.lastBiddingInfoRequestDate) > 0) {
      return true;
    }

    return differenceInSeconds(new Date(), viewState.lastBiddingInfoRequestDate) >= maxSecondsSinceLastUpdate;
  }

  public getAllViewStateUuids(): string[] {
    return [...this.viewStates.keys()].filter(uuid => !!this.viewStates.get(uuid).auction);
  }

  public updateListOfAuctions(auctionList: IBuyerAuctionView[], _filter: IAuctionFilter, requestDate: Date) {
    // auctions fetched in a list do not contain navigation flags. Therefore we do not store a filter.
    auctionList.forEach(auction => this.updateAuctionViewState(auction.uuid, auction, requestDate, null, EBuyerAuctionViewVariant.LIST_VIEW, null));
  }

  public updateAuctionViewState(
    uuid: string,
    auction: IBuyerAuctionView,
    requestDate: Date,
    auctionFilter: IAuctionFilter,
    viewVariant: EBuyerAuctionViewVariant,
    tabId: EBuyerAuctionTabId,
  ): void {
    if (auction) {
      this.updateViewState(uuid, (currentViewState: IBuyerAuctionViewState) => {
        const prebookedServicesChangedInBackendAuction: boolean = !isEqual(auction.prebookedServices, currentViewState?.auction?.prebookedServices);
        return {
          auctionFilter,
          auction: this.preventJumpsInRemainingTime(auction, currentViewState.auction),
          currentPrebookedServices: prebookedServicesChangedInBackendAuction ? auction.prebookedServices : currentViewState.currentPrebookedServices,
          lastBiddingInfoRequestDate: requestDate,
          viewVariant,
          tabId: tabId ?? null,
        };
      });
    } else {
      // reset the whole view state if we reset the auction
      this.resetState(uuid);
    }
  }

  public updateRemainingTimesInAuction(uuid: string, newRemainingTimeInSeconds: number, newRemainingTimeForInstantPurchaseInSeconds: number): void {
    this.updateViewState(uuid, (currentViewState: IBuyerAuctionViewState) => ({
      ...currentViewState,
      auction: this.preventJumpsInRemainingTime(
        {
          ...currentViewState.auction,
          remainingTimeInSeconds: newRemainingTimeInSeconds,
          remainingTimeForInstantPurchaseInSeconds: newRemainingTimeForInstantPurchaseInSeconds,
        },
        currentViewState.auction,
      ),
    }));
  }

  public updateBiddingInfo(biddingInfo: IBuyerAuctionBiddingDataView, requestDate: Date): void {
    if (biddingInfo) {
      this.updateViewState(biddingInfo.uuid, (currentViewState: IBuyerAuctionViewState) => ({
        ...currentViewState,
        auction: {
          ...currentViewState.auction,
          ...this.preventJumpsInRemainingTime(biddingInfo, currentViewState.auction),
        },
        lastBiddingInfoRequestDate: requestDate,
      }));
    }
  }

  public resetNavigationFlag(uuid: string, directionToReset: 'previous' | 'next'): void {
    this.updateViewState(uuid, (currentViewState: IBuyerAuctionViewState) => ({
      ...currentViewState,
      auction: {
        ...currentViewState.auction,
        hasPreviousAuction: directionToReset === 'previous' ? false : currentViewState.auction.hasPreviousAuction,
        hasNextAuction: directionToReset === 'next' ? false : currentViewState.auction.hasNextAuction,
      },
    }));
  }

  public updateCurrentPrebookedServices(uuid: string, currentPrebookedServices: IPrebookedService[]): void {
    this.updateViewState(uuid, (currentViewState: IBuyerAuctionViewState) => ({
      ...currentViewState,
      currentPrebookedServices: [...(currentPrebookedServices ?? [])],
    }));
  }

  public reportUpdateFromServer(uuid: string, updateAt = new Date()): void {
    if (!this.viewStates.has(uuid)) {
      return;
    }
    this.updateViewState(uuid, (currentViewState: IBuyerAuctionViewState) => ({
      ...currentViewState,
      lastUpdateReportedByServer: updateAt,
    }));
  }

  private preventJumpsInRemainingTime<T extends IBuyerAuctionBiddingDataView>(newAuctionOrBiddingData: T, previousAuction: IBuyerAuctionView): T {
    // prevent the remainingTime to jump up by one of we have already counted it down.
    // if we count it down by one (because our heartbeat hits asynchronously to the server clock),
    // we refresh the bidding data and the response might still contain the higher number of seconds, we should not jump back up again
    if (newAuctionOrBiddingData.remainingTimeInSeconds - previousAuction?.remainingTimeInSeconds === 1) {
      newAuctionOrBiddingData.remainingTimeInSeconds = previousAuction.remainingTimeInSeconds; // keep our lower value
    }
    if (newAuctionOrBiddingData.remainingTimeForInstantPurchaseInSeconds - previousAuction?.remainingTimeForInstantPurchaseInSeconds === 1) {
      newAuctionOrBiddingData.remainingTimeForInstantPurchaseInSeconds = previousAuction.remainingTimeForInstantPurchaseInSeconds; // keep our lower value
    }
    return newAuctionOrBiddingData;
  }

  private updateViewState(uuid: string, patchFn: (viewState: IBuyerAuctionViewState) => IBuyerAuctionViewState): void {
    const currentViewState = this.viewStates.get(uuid) || EMPTY_BUYER_AUCTION_VIEW_STATE;
    const newViewState = patchFn(currentViewState);
    this.viewStates.set(uuid, newViewState);
    this.subscriptionManagement.emitViewState(uuid, newViewState);
  }
}
