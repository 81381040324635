import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BaseModule } from '@cosCoreComponentsGeneral/base.module';

import { HotBidProgressBarComponent } from './hot-bid-progress-bar.component';

@NgModule({
  imports: [CommonModule, BaseModule],
  declarations: [HotBidProgressBarComponent],
  exports: [HotBidProgressBarComponent],
})
export class HotBidProgressBarModule {}
