import { Component } from '@angular/core';
import { Toast, ToastPackage, ToastrService } from 'ngx-toastr';
import { ICosNotification } from '@caronsale/cos-models';
import { flyInOut } from '@cos/partials/animations';

@Component({
  selector: 'app-notification-toast',
  templateUrl: './notification-toast.component.html',
  styleUrls: ['./notification-toast.component.scss'],
  animations: [flyInOut()],
  preserveWhitespaces: false,
})
export class NotificationToastComponent extends Toast {
  public notification: ICosNotification;

  public constructor(
    protected toastrService: ToastrService,
    public toastPackage: ToastPackage,
  ) {
    super(toastrService, toastPackage);
  }
}
