import { ECountryCode, ESellerBusinessType, I18nFormatUtils, IAuctionFilter, IVehicleFilter, IVehicleFilterMakeAndModelPair } from '@caronsale/cos-models';
import {
  EEuroNorm,
  EFuelType,
  ETransmissionType,
  EVehicleCategory,
  EVehicleColor,
  EVehicleDoors,
  EVehicleReadyToDriveStatus,
} from '@caronsale/cos-vehicle-models';
import { currencyPipe, PS_TO_KW } from '../../services/auction-search-filter-ranges.service';
import { I18nMileageOrUnknownPipe } from '@caronsale/frontend-pipes';

const POWER_UNIT_LOCAL_STORAGE_KEY = 'USER_SELECTED_POWER_UNIT';

type FilterChipValue = string | number | IVehicleFilterMakeAndModelPair;

export enum EPowerUnit {
  HP = 'HP',
  KW = 'KW',
}

export interface IFilterChip {
  field: string;
  index?: number;
  rawValue?: FilterChipValue;
  valueTranslationKey?: string;
  valueTranslationParams?: Record<string, FilterChipValue>;
  trackingValue?: string; // for the remove-auction-filter event
  icon?: string;
}

type ChipConfig = {
  modelName?: string;
  allowsMultiple?: boolean;
  translationKey?: string;
  formatter?: (value: FilterChipValue, config?: ChipTranslationBuilderConfig, dependencies?: ChipDependencies) => string;
  translationKeyBuilder?: (config: ChipTranslationBuilderConfig) => string;
  translationParamsBuilder?: (value: FilterChipValue) => Record<string, FilterChipValue>;
  icon?: string;
};

type ChipDependencies = {
  i18nMileageOrUnknownPipe: I18nMileageOrUnknownPipe;
};

type ChipTranslationBuilderConfig = {
  powerUnit?: EPowerUnit;
  value?: FilterChipValue;
};

const genericFromConfig: ChipConfig = {
  allowsMultiple: false,
  translationKey: 'auction-filter.cell-value-start',
  translationParamsBuilder: startValue => ({ startValue }),
};

const genericToConfig: ChipConfig = {
  allowsMultiple: false,
  translationKey: 'auction-filter.cell-value-end',
  translationParamsBuilder: endValue => ({ endValue }),
};

const mileageConfig: ChipConfig = {
  formatter: (value, _, dependencies) => {
    return typeof value !== 'object' ? dependencies.i18nMileageOrUnknownPipe.transform(Number(value)) : '';
  },
};

const priceConfig: ChipConfig = {
  formatter: value => currencyPipe.transform(value as number),
};

const powerUnitConfig: ChipConfig = {
  formatter: (value, { powerUnit }) => String(powerUnit === EPowerUnit.HP ? value : Math.floor(+value * PS_TO_KW)),
};

const countryConfig: ChipConfig = {
  allowsMultiple: true,
  translationKeyBuilder: ({ value }) => `country-label.${value.toLocaleString().toLocaleLowerCase()}`,
};

const makeModelPairsConfig: ChipConfig = {
  allowsMultiple: true,
  translationKeyBuilder: ({ value }) => {
    if (typeof value !== 'object') {
      return '';
    }

    return value.model ? 'auction-filter.make-and-model-chip' : 'auction-filter.make-chip';
  },
  modelName: 'vehicleSearchQuery.makeAndModelPairs',
  translationParamsBuilder: value =>
    typeof value === 'object'
      ? {
          make: value.make,
          model: value.model,
        }
      : {},
};

const config: Record<string, ChipConfig | Record<string, ChipConfig>> = {
  currentPriceFrom: {
    ...genericFromConfig,
    ...priceConfig,
    modelName: 'currentPriceFrom',
  },
  currentPriceTo: {
    ...genericToConfig,
    ...priceConfig,
    modelName: 'currentPriceTo',
  },
  sellerAccounts: {
    sellerTypes: {
      allowsMultiple: true,
      translationKeyBuilder: ({ value }) => I18nFormatUtils.formatSellerBusinessType(value as ESellerBusinessType),
      modelName: 'sellerAccounts.sellerTypes',
    },
  },
  vehicleSearchQuery: {
    bodyColorCode: {
      allowsMultiple: true,
      translationKeyBuilder: ({ value }) => I18nFormatUtils.formatVehicleColor(value as EVehicleColor),
      modelName: 'vehicleSearchQuery.bodyColorCode',
    },
    categories: {
      allowsMultiple: true,
      translationKeyBuilder: ({ value }) => I18nFormatUtils.formatVehicleCategory(value as EVehicleCategory),
      modelName: 'vehicleSearchQuery.categories',
    },
    doors: {
      allowsMultiple: true,
      translationKeyBuilder: ({ value }) => I18nFormatUtils.formatVehicleDoors(value as EVehicleDoors),
      modelName: 'vehicleSearchQuery.doors',
    },
    numberOfSeats: {
      translationKey: 'vehicle.number-of-seats-x',
      translationParamsBuilder: numberOfSeats => ({ numberOfSeats }),
      modelName: 'vehicleSearchQuery.numberOfSeats',
    },
    euroNorm: {
      allowsMultiple: true,
      translationKeyBuilder: ({ value }) => I18nFormatUtils.formatVehicleEuroNorm(value as EEuroNorm),
      modelName: 'vehicleSearchQuery.euroNorm',
    },
    ezFrom: {
      ...genericFromConfig,
      modelName: 'vehicleSearchQuery.ezFrom',
    },
    ezTo: {
      ...genericToConfig,
      modelName: 'vehicleSearchQuery.ezTo',
    },
    mileageFrom: {
      ...genericFromConfig,
      ...mileageConfig,
      modelName: 'vehicleSearchQuery.mileageFrom',
    },
    mileageTo: {
      ...genericToConfig,
      ...mileageConfig,
      modelName: 'vehicleSearchQuery.mileageTo',
    },
    numPreOwners: {
      allowsMultiple: false,
      translationKey: 'vehicle.number-of-previous-owners-x',
      translationParamsBuilder: numPreOwners => ({ numPreOwners }),
      modelName: 'vehicleSearchQuery.numPreOwners',
    },
    hadAccident: {
      allowsMultiple: false,
      translationKey: 'auction-filter.damage-fixed',
      modelName: 'vehicleSearchQuery.hadAccident',
    },
    hasDamages: {
      allowsMultiple: false,
      translationKey: 'auction-filter.damage-not-fixed',
      modelName: 'vehicleSearchQuery.hasDamages',
    },
    hpFrom: {
      ...genericFromConfig,
      ...powerUnitConfig,
      translationKeyBuilder: ({ powerUnit }) => (powerUnit === EPowerUnit.HP ? 'auction-filter.engine-power-hp-from' : 'auction-filter.engine-power-kw-from'),
      modelName: 'vehicleSearchQuery.hpFrom',
    },
    hpTo: {
      ...genericToConfig,
      ...powerUnitConfig,
      translationKeyBuilder: ({ powerUnit }) => (powerUnit === EPowerUnit.HP ? 'auction-filter.engine-power-hp-to' : 'auction-filter.engine-power-kw-to'),
      modelName: 'vehicleSearchQuery.hpTo',
    },
    transmission: {
      allowsMultiple: true,
      translationKeyBuilder: ({ value }) => I18nFormatUtils.formatVehicleTransmission(value as ETransmissionType),
      modelName: 'vehicleSearchQuery.transmission',
    },
    fuelTypes: {
      allowsMultiple: true,
      translationKeyBuilder: ({ value }) => I18nFormatUtils.formatVehicleFuelType(value as EFuelType),
      modelName: 'vehicleSearchQuery.fuelTypes',
    },
    readyToDrive: {
      allowsMultiple: true,
      translationKeyBuilder: ({ value }) => I18nFormatUtils.formatVehicleReadyToDrive(value as EVehicleReadyToDriveStatus),
      modelName: 'vehicleSearchQuery.readyToDrive',
    },
    isHuValid: {
      allowsMultiple: false,
      translationKey: 'auction-filter.valid-hu',
      modelName: 'vehicleSearchQuery.isHuValid',
    },
    makeAndModelPairs: {
      ...makeModelPairsConfig,
    },
  },
  distance: {
    radius: {
      allowsMultiple: false,
      translationKey: 'auction-filter.distance-km',
      translationParamsBuilder: distance => ({ distance }),
      modelName: 'distance.radius',
    },
  },
  includeCountries: {
    ...countryConfig,
    modelName: 'includeCountries',
  },
  locationZipCodeQuery: {
    allowsMultiple: false,
    translationKey: 'auction-filter.zip-code-value',
    translationParamsBuilder: value => ({ value }),
    modelName: 'locationZipCodeQuery',
  },
  hasExpressPickup: {
    allowsMultiple: false,
    translationKey: 'auction-filter.express-pickup',
    modelName: 'hasExpressPickup',
  },
  isVATReportable: {
    allowsMultiple: false,
    translationKeyBuilder: ({ value }) => I18nFormatUtils.formatVatTaxationForCountry(!!value, ECountryCode.UNKNOWN),
    modelName: 'isVATReportable',
  },
  onlyForRegularBuyers: {
    uuids: {
      allowsMultiple: false,
      translationKey: 'auction-filter.regular-buyer',
      modelName: 'onlyForRegularBuyers.uuids',
    },
  },
  isMinAskReached: {
    allowsMultiple: false,
    translationKey: 'auction-filter.min-ask-reached',
    translationKeyBuilder: ({ value }) => (value ? 'auction-filter.min-ask-reached' : 'auction-filter.min-ask-not-reached'),
    modelName: 'isMinAskReached',
  },
  search: {
    allowsMultiple: false,
    modelName: 'search',
    icon: 'search',
  },
  advertisementTypes: {
    allowsMultiple: true,
    translationKey: 'auction-filter.exclusive-auctions',
    translationParamsBuilder: advertisementType => ({ advertisementType }),
    modelName: 'advertisementTypes',
  },
  hasDiscounts: {
    allowsMultiple: false,
    translationKey: 'auction-filter.has-discounts',
    modelName: 'hasDiscounts',
  },
};

export class AuctionFilterUtils {
  public static getPowerPreferenceFromLocalStorage() {
    return (localStorage.getItem(POWER_UNIT_LOCAL_STORAGE_KEY) as EPowerUnit) || EPowerUnit.HP;
  }

  public static setPowerPreferenceInLocalStorage(powerUnit: EPowerUnit) {
    localStorage.setItem(POWER_UNIT_LOCAL_STORAGE_KEY, powerUnit);
  }

  public static convertMakesInVehicleFilter(vehicleSearchQuery: IVehicleFilter): IVehicleFilter {
    if (vehicleSearchQuery?.makes?.length) {
      return {
        ...vehicleSearchQuery,
        makeAndModelPairs: vehicleSearchQuery.makes.slice(0, 5).map(make => ({ make, model: null })),
        makes: null,
      };
    }

    return vehicleSearchQuery;
  }

  public static convertFilterToChips(
    filter: IAuctionFilter,
    powerUnit: EPowerUnit = AuctionFilterUtils.getPowerPreferenceFromLocalStorage(),
    dependencies: ChipDependencies,
  ): IFilterChip[] {
    const chips: IFilterChip[] = [];
    let index = 0;

    const handleData = (filter: Record<string, any>, parentKey?: string) => {
      Object.keys(filter).forEach(key => {
        const data = filter[key];

        if (data === null || data === undefined) {
          return;
        }

        if (typeof data === 'object' && !Array.isArray(data)) {
          handleData(data, key);
        } else {
          const chipConfig: ChipConfig = parentKey ? config[parentKey][key] : config[key];
          if (chipConfig) {
            const value = Array.isArray(data) && chipConfig.allowsMultiple ? data : [data];
            value.forEach(v => {
              const chip = this.buildChip(chipConfig, powerUnit, v, dependencies);
              chip.index = index++;
              chips.push(chip);
            });
          }
        }
      });
    };

    handleData(filter);

    return chips;
  }

  private static buildChip(config: ChipConfig, powerUnit: EPowerUnit, value: FilterChipValue, dependencies: ChipDependencies): IFilterChip {
    const { modelName, translationKey, translationParamsBuilder, translationKeyBuilder, formatter, icon } = config;
    return {
      field: modelName,
      rawValue: value,
      valueTranslationKey: translationKeyBuilder ? translationKeyBuilder({ powerUnit, value }) : translationKey,
      valueTranslationParams: translationParamsBuilder
        ? translationParamsBuilder(formatter ? formatter(value, { powerUnit }, dependencies) : value)
        : undefined,
      trackingValue: modelName,
      icon,
    };
  }
}
