import { Component, Inject } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators, ValidatorFn } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ECountryCode, IAuctionFilter } from '@caronsale/cos-models';
import { BrowserDetectionService } from '@cosCoreServices/browser-detection/browser-detection.service';
import { CosBuyerClientService } from '@cosCoreServices/cos-salesman-client/cos-buyer-client.service';
import { CarOnSaleUtils } from '@cosCoreUtils/CarOnSaleUtils';
import { BehaviorSubject, combineLatest, map, Observable, pipe, startWith } from 'rxjs';
import { BuyerAuctionSearchService } from '@cosBuyer/auctions/partials/auction-search/auction-search.service';

export interface IBuyerVehiclePreferencesData {
  isPreregisteredAccount: boolean;
}

enum EVehiclePreferencesSteps {
  ALL = 0,
  LOCATIONS = 1,
  MAKES = 2,
  RANGES = 3,
}

type SupportedMakes = ['Audi', 'BMW', 'Mercedes-Benz', 'Volkswagen', 'Opel', 'Toyota', 'Skoda', 'Renault', 'Peugeot', 'Seat', 'Hyundai', 'Nissan'];
type SupportedCountryCodes = [ECountryCode.DE, ECountryCode.NL, ECountryCode.FR, ECountryCode.AT];

const NUMBER_VALIDATORS: ValidatorFn[] = [Validators.min(0), Validators.pattern(/^[0-9]*$/)];
const SUPPORTED_MAKES: SupportedMakes = [
  'Audi',
  'BMW',
  'Mercedes-Benz',
  'Volkswagen',
  'Opel',
  'Toyota',
  'Skoda',
  'Renault',
  'Peugeot',
  'Seat',
  'Hyundai',
  'Nissan',
];
const SUPPORTED_COUNTRY_CODES: SupportedCountryCodes = [ECountryCode.DE, ECountryCode.NL, ECountryCode.FR, ECountryCode.AT];

const areAllSelectedOrIndeterminate = pipe(
  map(values => {
    const all = Object.values(values).every(Boolean);
    const indeterminate = !all && Object.values(values).some(Boolean);
    return {
      all,
      indeterminate,
    };
  }),
);

@Component({
  selector: 'app-buyer-vehicle-preferences',
  templateUrl: './buyer-vehicle-preferences.component.html',
  styleUrls: ['./buyer-vehicle-preferences.component.scss'],
})
export class BuyerVehiclePreferencesComponent {
  private readonly countryCodeFormControls: Record<(typeof SUPPORTED_COUNTRY_CODES)[number], UntypedFormControl> =
    this.createCheckboxFormControls(SUPPORTED_COUNTRY_CODES);

  private readonly makeFormControls: Record<(typeof SUPPORTED_MAKES)[number], UntypedFormControl> = this.createCheckboxFormControls(SUPPORTED_MAKES);

  private readonly vehiclePreferencesForm: UntypedFormGroup = new UntypedFormGroup({
    countryCodes: new UntypedFormGroup(this.countryCodeFormControls),
    makes: new UntypedFormGroup(this.makeFormControls),
    priceFrom: new UntypedFormControl('', NUMBER_VALIDATORS),
    priceTo: new UntypedFormControl(CarOnSaleUtils.PRICES_MAX, NUMBER_VALIDATORS),
    mileageFrom: new UntypedFormControl('', NUMBER_VALIDATORS),
    mileageTo: new UntypedFormControl(CarOnSaleUtils.MILEAGES[CarOnSaleUtils.MILEAGES.length - 1], NUMBER_VALIDATORS),
    ezFrom: new UntypedFormControl('', NUMBER_VALIDATORS),
    ezTo: new UntypedFormControl(CarOnSaleUtils.EZS[0], NUMBER_VALIDATORS),
  });

  private readonly priceFrom: number[] = CarOnSaleUtils.PRICES;

  private readonly priceTo$: Observable<number[]> = this.vehiclePreferencesForm.get('priceFrom').valueChanges.pipe(
    startWith(this.priceFrom[0]),
    map(priceFrom => this.priceFrom.filter(price => price >= priceFrom && price > 0)),
  );

  private readonly ezFromYears: number[] = [...CarOnSaleUtils.EZS];

  private readonly ezToYears$: Observable<number[]> = this.vehiclePreferencesForm.get('ezFrom').valueChanges.pipe(
    startWith(CarOnSaleUtils.EZS[CarOnSaleUtils.EZS.length - 1]),
    map(yearFrom => CarOnSaleUtils.EZS.filter(year => year >= yearFrom)),
  );

  private readonly mileageFrom: number[] = [...CarOnSaleUtils.MILEAGES];

  private readonly mileageTo$: Observable<number[]> = this.vehiclePreferencesForm.get('mileageFrom').valueChanges.pipe(
    startWith(CarOnSaleUtils.MILEAGES[0]),
    map(mileageFrom => CarOnSaleUtils.MILEAGES.filter(mileage => mileage >= mileageFrom)),
  );

  private readonly countryCodesAllSelected$: Observable<{
    all: boolean;
    indeterminate: boolean;
  }> = this.vehiclePreferencesForm.get('countryCodes').valueChanges.pipe(startWith({}), areAllSelectedOrIndeterminate);

  private readonly makesAllSelected$: Observable<{
    all: boolean;
    indeterminate: boolean;
  }> = this.vehiclePreferencesForm.get('makes').valueChanges.pipe(startWith({}), areAllSelectedOrIndeterminate);

  private readonly steps: EVehiclePreferencesSteps[] = [EVehiclePreferencesSteps.LOCATIONS, EVehiclePreferencesSteps.MAKES, EVehiclePreferencesSteps.RANGES];

  private readonly activeStepSubject: BehaviorSubject<EVehiclePreferencesSteps> = new BehaviorSubject<EVehiclePreferencesSteps>(
    EVehiclePreferencesSteps.LOCATIONS,
  );

  public readonly vm$ = combineLatest({
    ezToYears: this.ezToYears$,
    countryCodesAllSelected: this.countryCodesAllSelected$,
    makesAllSelected: this.makesAllSelected$,
    activeStep: this.activeStepSubject.asObservable(),
    isMobileOrTabletWidth: this.browserDetectionService.isMobileOrTabletWidth$,
    mileageTo: this.mileageTo$,
    priceTo: this.priceTo$,
  }).pipe(
    map(({ ezToYears, countryCodesAllSelected, makesAllSelected, activeStep, isMobileOrTabletWidth, mileageTo, priceTo }) => ({
      countryCodeFormControls: this.countryCodeFormControls,
      makeFormControls: this.makeFormControls,
      vehiclePreferencesForm: this.vehiclePreferencesForm,
      ezFromYears: this.ezFromYears,
      ezToYears,
      countryCodesAllSelected,
      makesAllSelected,
      steps: this.steps,
      EVehiclePreferencesSteps: EVehiclePreferencesSteps,
      activeStep: isMobileOrTabletWidth ? activeStep : EVehiclePreferencesSteps.ALL,
      isMobileOrTabletWidth,
      mileageFrom: this.mileageFrom,
      mileageTo,
      priceFrom: this.priceFrom,
      priceTo,
      isPreregisteredAccount: this.data.isPreregisteredAccount,
    })),
  );

  public constructor(
    private buyerClientService: CosBuyerClientService,
    private browserDetectionService: BrowserDetectionService,
    private dialogRef: MatDialogRef<BuyerVehiclePreferencesComponent>,
    private buyerAuctionSearchService: BuyerAuctionSearchService,
    @Inject(MAT_DIALOG_DATA) public data: IBuyerVehiclePreferencesData,
  ) {}

  public closeModal() {
    this.dialogRef.close();
  }

  public handleSave() {
    this.buyerClientService.saveNewSearch(this.getFormValues()).subscribe(() => this.dialogRef.close());
  }

  public showNextStep() {
    this.activeStepSubject.next(this.steps[this.steps.indexOf(this.activeStepSubject.value) + 1]);
  }

  public showStep(step: EVehiclePreferencesSteps) {
    if (step < this.activeStepSubject.value) {
      this.activeStepSubject.next(step);
    }
  }

  public setAllCountryCodes(value: boolean) {
    this.vehiclePreferencesForm.get('countryCodes').patchValue(SUPPORTED_COUNTRY_CODES.reduce((acc, countryCode) => ({ ...acc, [countryCode]: value }), {}));
  }

  public setAllMakes(value: boolean) {
    this.vehiclePreferencesForm.get('makes').patchValue(SUPPORTED_MAKES.reduce((acc, make) => ({ ...acc, [make]: value }), {}));
  }

  public setEzToIfNeeded({ value }: { value: number }) {
    this.setValueToIfNeeded('ezTo', value);
  }

  public setMileageToIfNeeded({ value }: { value: number }) {
    this.setValueToIfNeeded('mileageTo', value);
  }

  public setPriceToIfNeeded({ value }: { value: number }) {
    this.setValueToIfNeeded('priceTo', value);
  }

  public keepCheckboxesOrder(): number {
    return 0;
  }

  private setValueToIfNeeded(controlName: string, value: number) {
    if (this.vehiclePreferencesForm.get(controlName).value && value > this.vehiclePreferencesForm.get(controlName).value) {
      this.vehiclePreferencesForm.get(controlName).setValue(value);
    }
  }

  private createCheckboxFormControls<K extends string | number | symbol>(keys: K[], defaultValue = true): Record<K, UntypedFormControl> {
    return keys.reduce((acc, value) => ({ ...acc, [value]: new UntypedFormControl(defaultValue) }), {} as Record<K, UntypedFormControl>);
  }

  private getFormValues(): IAuctionFilter {
    return this.buyerAuctionSearchService.removeEmptyValuesFromFilter({
      includeCountries: this.getSelectedCountryCodes(),
      currentPriceFrom: this.vehiclePreferencesForm.get('priceFrom').value,
      currentPriceTo: this.vehiclePreferencesForm.get('priceTo').value,
      vehicleSearchQuery: {
        makes: this.getSelectedMakes(),
        mileageFrom: this.vehiclePreferencesForm.get('mileageFrom').value,
        mileageTo: this.vehiclePreferencesForm.get('mileageTo').value,
        ezFrom: this.getSelectedEzFrom(),
        ezTo: this.getSelectedEzTo(),
      },
    });
  }

  private getSelectedCountryCodes(): IAuctionFilter['includeCountries'] {
    const values = this.vehiclePreferencesForm.get('countryCodes').value;
    return Object.keys(values)
      .map(countryCode => (values[countryCode] ? (countryCode as ECountryCode) : null))
      .filter(Boolean);
  }

  private getSelectedMakes(): IAuctionFilter['vehicleSearchQuery']['makes'] {
    const values = this.vehiclePreferencesForm.get('makes').value;
    return Object.keys(values)
      .map(make => (values[make] ? make : null))
      .filter(Boolean);
  }

  private getSelectedEzFrom(): IAuctionFilter['vehicleSearchQuery']['ezFrom'] {
    const year = this.vehiclePreferencesForm.get('ezFrom').value;
    return year ? `01/${year}` : null;
  }

  private getSelectedEzTo(): IAuctionFilter['vehicleSearchQuery']['ezTo'] {
    const year = this.vehiclePreferencesForm.get('ezTo').value;
    return year ? `12/${year}` : null;
  }
}
