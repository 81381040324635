<ng-container *ngIf="showLatePickupMessage">
  <enzo-text kind="emphasis-label" color="red" *ngIf="isPickupLate() && purchase.latePickupFee">
    {{ 'pickup.x-late-pickup-fee-due' | translate: { fee: purchase.latePickupFee | currency: 'EUR' : 'symbol' : '1.0-0' } }}
  </enzo-text>
  <enzo-text *ngIf="purchase.remainingDaysUntilLatePickup"
    >{{ 'pickup.late-pickup-fee-due-in-x-days' | translate: { numDays: purchase.remainingDaysUntilLatePickup } }}
  </enzo-text>
</ng-container>

<ng-container *ngIf="showPickupDocument">
  <enzo-button
    kind="secondary"
    (enzoClick)="handleOnPickupDocumentClick($event)"
    icon="pick-up"
    stretch
    iconPosition="leading"
    [label]="'pickup.buyer-document' | translate"
    testid="pickup-document-button" />

  <span *ngIf="showPickupAddress" class="pickup-address" testid="pickup-address">
    <enzo-text kind="emphasis-label" balance="false"> {{ 'general.address' | translate }}: </enzo-text>
    <enzo-text kind="label">
      {{ purchase.locationAddress }}, {{ purchase.locationZip }} {{ purchase.locationCity }} ({{ purchase.locationCountryCode }})
    </enzo-text>
  </span>
</ng-container>
