import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BaseModule } from '@cosCoreComponentsGeneral/base.module';
import { I18nPaintStatePartsPipe } from './pipes/i18n-paint-state-parts.pipe';

import { PaintStatusInfoComponent } from './paint-status-info.component';
import { EnzoComponentsModule } from '@caronsale/enzo-angular';
import { VehicleDetailsGridComponent } from '../vehicle-details-grid/vehicle-details-grid.component';

@NgModule({
  declarations: [PaintStatusInfoComponent, I18nPaintStatePartsPipe],
  imports: [CommonModule, BaseModule, EnzoComponentsModule, VehicleDetailsGridComponent],
  exports: [PaintStatusInfoComponent, I18nPaintStatePartsPipe],
})
export class PaintStatusInfoModule {}
