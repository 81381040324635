import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TransportationPipesModule } from '@caronsale/frontend-pipes';
import { AuctionTransportationInfoWithModalComponent } from '@cosBuyer/partials/auction-detail-view/auction-transportation-info-with-modal/auction-transportation-info-with-modal.component';
import { BaseModule } from '@cosCoreComponentsGeneral/base.module';
import { PickupInfoModule } from '@cosBuyer/partials/pickup-info/pickup-info.module';

@NgModule({
  imports: [CommonModule, BaseModule, TransportationPipesModule, PickupInfoModule],
  declarations: [AuctionTransportationInfoWithModalComponent],
  exports: [AuctionTransportationInfoWithModalComponent],
})
export class AuctionTransportationInfoWithModalModule {}
