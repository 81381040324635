import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { map, Observable } from 'rxjs';
import { Dialog } from '@angular/cdk/dialog';
import { EnzoDialogService } from '@cos/components/modal-dialogs/enzo-dialog.service';

export class I18nInfoDialogComponent {
  public static show(dialog: MatDialog | Dialog, translationBaseKey: string, widthOrParams?: any, _width?: string): Observable<void> {
    // if it is not an instance of MatDialog or Dialog, it is probably a mock
    // then it either mimics the layout of the MatDialog object or is a mock of Dialog itself
    const cdkDialog: Dialog = dialog instanceof MatDialog ? (dialog['_dialog'] as Dialog) : dialog instanceof Dialog ? dialog : dialog['_dialog'] || dialog;
    let params = {};
    let width: string;
    // params and width are both optional, so we have to find out what is actually given
    if (typeof widthOrParams === 'string') {
      width = widthOrParams;
    } else if (typeof widthOrParams === 'object') {
      width = _width; // might be undefined
      params = widthOrParams;
    }
    return EnzoDialogService.staticOpenOkModal(cdkDialog, translationBaseKey, params, null, width).pipe(map(() => undefined));
  }
}
