import { NgModule } from '@angular/core';
import { BuyNowPayLaterDialogComponent } from '@cosCoreFeatures/buyer/components/buy-now-pay-later-options-dialog/buy-now-pay-later-options-dialog.component';
import { BuyNowPayLaterService } from './buy-now-pay-later.service';
import { BillieBuyNowPayLaterService } from './providers/billie/billie-buy-now-pay-later.service';

@NgModule({
  imports: [BuyNowPayLaterDialogComponent],
  providers: [BuyNowPayLaterService, BillieBuyNowPayLaterService],
})
export class BuyNowPayLaterServiceModule {}
