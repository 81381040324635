import { Component, Input } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { EUserType, IBuyerAuctionView } from '@caronsale/cos-models';
import { TransportationInfoModalComponent } from '@cosCoreComponentsGeneral/transportation-info-dialog/transportation-info-modal.component';

/**
 * Display auction pickup-info and transport info modal (with button as trigger)
 * When the button is clicked, opens TransportationInfoModalComponent as a dialog, passing the required info
 */
@Component({
  selector: 'app-auction-transportation-info-with-modal',
  templateUrl: './auction-transportation-info-with-modal.component.html',
})
export class AuctionTransportationInfoWithModalComponent {
  @Input()
  public auction: IBuyerAuctionView;

  public constructor(private dialog: MatDialog) {}

  public showTransportationInfo($event: Event) {
    $event.stopPropagation();
    this.dialog.open(TransportationInfoModalComponent, {
      width: '600px',
      data: {
        transportationTask: this.auction.transportationTask,
        userType: EUserType.SALESMAN,
        transporterCompanyName: null,
        urlToSignedPowerOfAttorneyDocumentFile: null,
      },
    });
  }
}
