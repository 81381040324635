<div class="legend">
  <enzo-text color="grey-dark" kind="caption">{{ 'vehicle.birdview-icons-description.label' | translate }}</enzo-text>

  <div class="labels">
    <span class="warning"></span>
    <enzo-text color="grey-dark" kind="caption">{{ 'vehicle.birdview-icons-description.dents-and-scratches' | translate }}</enzo-text>
    <span class="damage"></span>
    <enzo-text color="grey-dark" kind="caption">{{ 'vehicle.birdview-icons-description.damages' | translate }}</enzo-text>
    @if (editView()) {
      <span class="added"></span>
      <enzo-text color="grey-dark" kind="caption">{{ 'complaint.claimed-damages' | translate }}</enzo-text>
    }
  </div>
</div>

@if (hasVehicleDamages$ | async) {
  <app-vehicle-damages-gallery />
} @else {
  <app-no-damage-card [auction]="auction()" />
}

<app-bird-view [vehicleCategory]="auction().associatedVehicle.category" [editView]="editView()" />
