// Angular
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

// libs
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TranslateModule } from '@ngx-translate/core';
import { NgxMaskModule } from 'ngx-mask';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

// CoS
import { AppMaterialModule } from '../../modules/app-material.module';
import { FormValidationModule } from '../../modules/form-validation/form-validation.module';

@NgModule({
  imports: [
    AppMaterialModule,
    CommonModule,
    FlexModule,
    FontAwesomeModule,
    FormValidationModule,
    FormsModule,
    NgxMaskModule.forRoot(),
    NgxSkeletonLoaderModule,
    ReactiveFormsModule,
    TranslateModule,
  ],
  exports: [
    AppMaterialModule,
    FlexModule,
    FontAwesomeModule,
    FormValidationModule,
    FormsModule,
    NgxMaskModule,
    NgxSkeletonLoaderModule,
    ReactiveFormsModule,
    TranslateModule,
  ],
  providers: [
    {
      provide: MatDialogRef,
      useValue: {},
    },
  ],
})
export class BaseModule {}
