import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { EEngagementEventType, ICosNotification, IPage, IResultMap } from '@caronsale/cos-models';
import { BehaviorSubject, Subject, filter, takeUntil } from 'rxjs';
import { NotificationCenterService } from '@cosCoreFeatures/common/notification-center/services/notification.service';
import { TranslateService } from '@ngx-translate/core';

const NOTIFICATION_TRANSLATION_PREFIX = 'notification.';

@Component({
  selector: 'app-notification-dialog',
  templateUrl: './notification-dialog.component.html',
  styleUrls: ['./notification-dialog.component.scss'],
})
export class NotificationDialogComponent implements OnInit, OnDestroy {
  @Input()
  public notificationPage$: BehaviorSubject<IPage<ICosNotification>>;

  public isLoading = true;
  public hasMore = false;
  public todayNotifications: ICosNotification[] = [];
  public oldNotifications: ICosNotification[] = [];

  private totalNotifications: number;
  private currentPage = 1;

  public lottieConfig = {
    path: 'assets/lottie-animations/not-found.json',
    renderer: 'canvas',
    autoplay: true,
    loop: true,
  };

  private unsubscribe$: Subject<void> = new Subject<void>();

  public constructor(
    private notificationService: NotificationCenterService,
    private translateService: TranslateService,
  ) {}

  public ngOnInit(): void {
    this.notificationPage$
      .pipe(
        filter(result => !!result), // it is a BehaviorSubject with an initial value of null
        takeUntil(this.unsubscribe$),
      )
      .subscribe(result => {
        this.isLoading = false;
        this.totalNotifications = result.total;
        this.currentPage = result.page;

        const items = result.items.map(n => this.translateNotification(n));
        const todaysNotificationsToAdd = [...items.filter(not => this.isToday(new Date(not.createdAt)))];
        const olderNotificationsToAdd = [...items.filter(not => !this.isToday(new Date(not.createdAt)))];

        // page === 1 means: start (again) with an empty list
        if (result.page === 1) {
          this.todayNotifications = this.sortByCreatedDateNewestFirst(todaysNotificationsToAdd);
          this.oldNotifications = this.sortByCreatedDateNewestFirst(olderNotificationsToAdd);
        } else {
          // add the next (25) items to our list (in response to "load more"
          this.todayNotifications = this.sortByCreatedDateNewestFirst(this.addIfNotPresent(this.todayNotifications, todaysNotificationsToAdd));
          this.oldNotifications = this.sortByCreatedDateNewestFirst(this.addIfNotPresent(this.oldNotifications, olderNotificationsToAdd));
        }

        this.markAllAsRead(items);
        this.hasMore = this.totalNotifications > this.todayNotifications.length + this.oldNotifications.length;
      });

    // listen for incoming engages
    this.notificationService.engagedNotifications$.pipe(takeUntil(this.unsubscribe$)).subscribe(engagedNotifications => {
      this.todayNotifications = this.replaceNotificationsById(this.todayNotifications, engagedNotifications);
      this.oldNotifications = this.replaceNotificationsById(this.oldNotifications, engagedNotifications);
    });

    // request the first page
    this.notificationService.loadNotificationPage(1);
  }

  public ngOnDestroy(): void {
    this.unsubscribe$.next();
  }

  public trackById(index, notification: ICosNotification): string {
    return notification.internalUuid;
  }

  public handleLoadMoreButtonClick($event) {
    // when the notification center's window click handler runs, it will detect an 'outside' click since this button will gone due to ngIf
    $event.stopPropagation();
    this.isLoading = true;
    this.notificationService.loadNotificationPage(this.currentPage + 1);
  }

  public handleCloseButtonClick() {
    this.notificationService.closeNotificationDialog$.next();
  }

  public handleRequestDeleteNotification($event: ICosNotification) {
    this.todayNotifications = this.todayNotifications.filter(notification => notification.internalUuid !== $event.internalUuid);
    this.oldNotifications = this.oldNotifications.filter(notification => notification.internalUuid !== $event.internalUuid);
  }

  private markAllAsRead(notifications: ICosNotification[]) {
    const ids = notifications.filter(notification => notification.engagementEvents.length === 0).map(notification => notification.internalUuid);
    if (ids.length > 0) {
      this.notificationService.sendEngage(ids, EEngagementEventType.READ);
    }
  }

  private isToday(date: Date): boolean {
    const today = new Date();
    return date.getDate() === today.getDate() && date.getMonth() === today.getMonth() && today.getFullYear() === date.getFullYear();
  }

  private replaceNotificationsById(notifications: ICosNotification[], toReplace: ICosNotification[]): ICosNotification[] {
    return notifications.map(existingNotification => {
      const newNotification = toReplace.find(value => value.internalUuid === existingNotification.internalUuid);
      return newNotification ? newNotification : existingNotification;
    });
  }

  private translateNotification(notification: ICosNotification): ICosNotification {
    notification.title = this.translateContent(`${NOTIFICATION_TRANSLATION_PREFIX}${notification.topic}.title`, notification.title, notification.params?.title);
    notification.text = this.translateContent(`${NOTIFICATION_TRANSLATION_PREFIX}${notification.topic}.text`, notification.text, notification.params?.text);

    return notification;
  }

  private translateContent(translationKey: string, untranslatedContent: string, params: IResultMap<string> = {}): string {
    try {
      const translatedContent: string = this.translateService.instant(translationKey, params);

      // When the translated value has "undefined" it means that one or more interpolation keys are missing on params
      if (translatedContent !== translationKey && !translatedContent.includes('undefined')) {
        return translatedContent;
      }
    } catch (err) {
      console.log(`Error translating ${translationKey}`, err);
    }

    return untranslatedContent;
  }

  private sortByCreatedDateNewestFirst(notifications: ICosNotification[]): ICosNotification[] {
    return [...notifications].sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
  }

  private addIfNotPresent(notificationList: ICosNotification[], notificationsToAdd: ICosNotification[]): ICosNotification[] {
    const notificationByInternalUuid = new Map([...notificationList, ...notificationsToAdd].map(notification => [notification.internalUuid, notification]));
    return Array.from(notificationByInternalUuid.values());
  }
}
