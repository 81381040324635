import { Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[testid]',
})
export class TestidDirective implements OnInit {
  @Input()
  public testid: string;

  public constructor(
    private _elRef: ElementRef,
    private _renderer: Renderer2,
  ) {}

  public ngOnInit(): void {
    this._renderer.setAttribute(this._elRef.nativeElement, 'testid', this.testid);
  }
}
