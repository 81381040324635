import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserAddressService } from '@cosCoreServices/user-address/user-address.service';
import { AddressService } from '@cosCoreServices/user-address/address-abstract.service';

@NgModule({
  imports: [CommonModule],
  providers: [
    UserAddressService,
    {
      provide: AddressService,
      useClass: UserAddressService,
    },
  ],
})
export class UserAddressServiceModule {}
