import { TemplateRef } from '@angular/core';

import { ModelUtils } from '@caronsale/cos-models';
import { IVehicle, IVehicleImage } from '@caronsale/cos-vehicle-models';

import { IGalleryImage } from './image-gallery.component';

export type MediaAndDocsKey = keyof Pick<
  IVehicle,
  | 'urlsToRegistrationDocument'
  | 'urlsToRegistrationDocumentPart2'
  | 'urlsToServiceHistoryDocument'
  | 'urlsToMaintenanceBook'
  | 'urlsToCocDocument'
  | 'urlsToDataExcerpt'
  | 'urlsToHuReport'
  | 'urlsToOtherDocuments'
  | 'urlsToMotorRecordings'
>;
const vehicleDocumentKeys: Array<MediaAndDocsKey> = [
  'urlsToRegistrationDocument',
  'urlsToRegistrationDocumentPart2',
  'urlsToServiceHistoryDocument',
  'urlsToMaintenanceBook',
  'urlsToCocDocument',
  'urlsToDataExcerpt',
  'urlsToHuReport',
  'urlsToOtherDocuments',
];

export function createGalleryImage(url: string, overlayText?: string[], overlayTemplate?: TemplateRef<unknown>): IGalleryImage {
  return {
    url,
    urlRef: url.split('/upload/')[1],
    overlayText,
    overlayTemplate,
  };
}

export function getVehicleGalleryImages(vehicleImages: IVehicleImage[], firstImageOverlayTemplate?: TemplateRef<unknown>): IGalleryImage[] {
  if (!vehicleImages) {
    return [];
  }

  const imageMimeTypes: string[] = ModelUtils.getImageMimeTypes().map(mimeType => mimeType.split('/')[1]);
  return vehicleImages
    .filter(image => image.url && imageMimeTypes.includes(getCloudinaryUploadedFileMimeType(image.url)))
    .map((image, index) => createGalleryImage(image.url, undefined, index === 0 ? firstImageOverlayTemplate : undefined));
}

export function getVehicleDocumentGalleryImages(vehicle: IVehicle): IGalleryImage[] {
  if (!vehicle) {
    return [];
  }

  return vehicleDocumentKeys
    .map(documentKey => {
      const documentProp = vehicle[documentKey] as string[];
      return documentProp?.length > 0 ? documentProp.map(url => createGalleryImage(url)) : [];
    })
    .flat();
}

export function getCloudinaryUploadedFileMimeType(url: string = ''): string {
  return (url.split('/upload/')[1] || '').split('.')[1] || '';
}
