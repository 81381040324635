import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';

import { IBuyerAuctionView, IBuyerUser, IPrebookedService } from '@caronsale/cos-models';

import { BiddingService } from '@cosBuyer/partials/services/bidding/bidding.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import {
  IMobileBidDialogData,
  MobileBidDialogComponent,
} from '@cosBuyer/partials/auction-detail-view/bid-info-footer/mobile-bid-dialog/mobile-bid-dialog.component';
import { InstantPurchaseService } from '../../../../features/auction-detail/buyer/instant-purchase-service/instant-purchase.service';
import { GoogleAnalyticsService } from '@cosCoreServices/google-analytics/google-analytics.service';
import { EDialogId } from '@cos/components/modal-dialogs/enzo-dialog.service';
import { ProductAnalyticsService } from '@cosCoreServices/product-analytics/product-analytics.service';
import { filter } from 'rxjs';
@Component({
  selector: 'app-bid-info-footer',
  templateUrl: './bid-info-footer.component.html',
  styleUrls: ['./bid-info-footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BidInfoFooterComponent implements OnChanges {
  @Input()
  public auction: IBuyerAuctionView;

  @Input()
  public buyerUser: IBuyerUser;

  @Input()
  public currentPrebookedServices: IPrebookedService[];

  @Output() public auctionRefresh: EventEmitter<void> = new EventEmitter<void>();

  public minimalBidIncrement: number;

  public constructor(
    private matDialog: MatDialog,
    private biddingService: BiddingService,
    private instantPurchaseService: InstantPurchaseService,
    private googleAnalyticsService: GoogleAnalyticsService,
    private productAnalyticsService: ProductAnalyticsService,
  ) {}

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.auction?.currentValue) {
      this.minimalBidIncrement = this.biddingService.getMinimalBidIncrement(changes.auction.currentValue);
    }
  }

  public placeBid(): void {
    this.matDialog.open<MobileBidDialogComponent, IMobileBidDialogData>(MobileBidDialogComponent, {
      id: EDialogId.MOBILE_BID, // to find and close it when the auction changes
      data: {
        buyerUser: this.buyerUser,
        auction: this.auction,
        currentPrebookedServices: this.currentPrebookedServices,
      },
      width: '350px',
    });
  }

  public instantlyAddToBid(add: number): void {
    this.googleAnalyticsService.trackBidByIncrementFromDetailViewFooterClick();
    this.biddingService
      .instantlyAddToBid(this.buyerUser, this.auction, this.currentPrebookedServices, add)
      .pipe(filter(Boolean))
      .subscribe(() => {
        this.productAnalyticsService.trackEvent('bidPlaced', {
          'Auction end time': new Date(this.auction.endingTime).toISOString(),
          'Auction uuid': this.auction.uuid,
          'Bid timestamp': new Date().toISOString(),
          'Bid type': 'Bid by increment',
          'Bid value': this.auction.currentHighestBidValue + add,
          'Bid source': 'Details view footer',
        });
      });
  }

  public makeInstantPurchase(): void {
    this.instantPurchaseService.makeInstantPurchase(this.auction);
  }
}
