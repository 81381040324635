import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { EEngagementEventType, ENotificationPriority, ICosNotification } from '@caronsale/cos-models';
import { NotificationCenterService } from '@cosCoreFeatures/common/notification-center/services/notification.service';
import { Router } from '@angular/router';
import { PrebookedServicesService } from '@cosCoreServices/prebooked-services/prebooked-services.service';

const PRIORITY_CLASS_MAP: { [key in ENotificationPriority]: string } = {
  [ENotificationPriority.LOW]: 'low',
  [ENotificationPriority.MEDIUM]: 'medium',
  [ENotificationPriority.HIGH]: 'high',
  [ENotificationPriority.CRITICAL]: 'critical',
};

@Component({
  selector: 'app-notification-dialog-item-component',
  templateUrl: './notification-dialog-item.component.html',
  styleUrls: ['./notification-dialog-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationDialogItemComponent implements OnChanges {
  @Input()
  public notification: ICosNotification;

  @Input()
  public type: 'toast' | 'default' = 'default';

  @Output()
  public requestDelete = new EventEmitter<ICosNotification>();

  public rootClass: string;
  public guaranteeNotification: boolean;
  public notificationStatusClasses: string;

  public constructor(
    private notificationService: NotificationCenterService,
    private router: Router,
    private prebookedServicesService: PrebookedServicesService,
  ) {}

  public ngOnChanges(): void {
    const isClicked = !!this.notification.engagementEvents.find(event => event.action === EEngagementEventType.CLICK);
    this.notificationStatusClasses = (isClicked ? 'clicked ' : '') + PRIORITY_CLASS_MAP[this.notification.priority as ENotificationPriority];
    this.rootClass = this.type === 'toast' ? 'toast' : '';
    this.guaranteeNotification = this.notification.topic === 'auction.buyer.auctionGuaranteeAvailableToBook';
  }

  public onDeleteButtonClick($event: Event): void {
    $event.stopPropagation(); // do not also trigger a notificationClick
    this.requestDelete.emit(this.notification);
    this.notificationService.delete([this.notification.internalUuid]);
  }

  public onNotificationClick(): void {
    // mark the notification as "clicked" in the backend
    if (this.notification.engagementEvents.findIndex(event => event.action === EEngagementEventType.CLICK) === -1) {
      this.notificationService.sendEngage([this.notification.internalUuid], EEngagementEventType.CLICK);
    }

    // close the dialog
    this.notificationService.closeNotificationDialog$.next();

    // clear all toasts if some are open
    this.notificationService.clearAllToastNotifications();

    if (this.guaranteeNotification) {
      // TODO uncomment and adjust code whenever BE provides the guarantee prices through the notification metadata
      // const auctionUuid = this.notification.metadata.auctionUuid;
      // this.prebookedServicesService.openGuaranteeAdvertiseDialog(auctionUuid, this.notification.metadata.auctionLabel, this.notification.imageUrl).subscribe();
      // this.notificationService.trackGuaranteeNotificationTrigger('guarantee_notification_clicked', auctionUuid);
    } else {
      // navigate to the "primary link" of the notification
      const relativePath = this.notificationService.getRelativeLink(this.notification.primaryLink);
      this.router.navigateByUrl(relativePath);
    }
  }
}
