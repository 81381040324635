import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuctionDetailSkeletonComponent } from './auction-detail-skeleton.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

@NgModule({
  declarations: [AuctionDetailSkeletonComponent],
  exports: [AuctionDetailSkeletonComponent],
  imports: [CommonModule, NgxSkeletonLoaderModule],
})
export class AuctionDetailSkeletonModule {}
