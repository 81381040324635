<div class="text-small">{{ 'payment.please-transfer' | translate }}</div>

<h1>{{ data.purchase.currentHighestBidValue | currency: 'EUR' }}</h1>

<div class="text-small">{{ 'payment.transfer-to' | translate }}</div>

<div *ngIf="data.purchase.sellerAccount" class="text-large">{{ data.purchase.sellerAccount.name }}</div>

<app-copy-on-click label="{{ 'payment.seller-iban' | translate }}" [toCopy]="data.purchase.sellerIban">
  <p class="text-large text-bold" copy-content>
    {{ data.purchase.sellerIban }}<br />
    <small class="text-grey"> {{ 'general.click-here-to-copy' | translate }} </small>
  </p>
</app-copy-on-click>

<div>{{ 'payment.transfer-reference' | translate }}:</div>
<div class="text-bold">{{ referenceId }}</div>

<div class="text-small">{{ 'payment.and-confirm-payment' | translate }}</div>

<div>
  <button mat-flat-button color="warn" [matDialogClose]="true" [disabled]="data.purchase.isPaidByBuyer">
    {{ 'action.confirm-outgoing-payment' | translate }}
  </button>
  <button mat-flat-button color="light" [matDialogClose]="false">{{ 'general.cancel' | translate }}</button>
</div>
