import { NgOptimizedImage } from '@angular/common';
import { Component, computed, input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { Color, Icon } from '@caronsale/enzo';
import { EnzoComponentsModule } from '@caronsale/enzo-angular';

type IconOptions = {
  enzoIcon?: Icon;
  iconFromSource?: string;
};

@Component({
  standalone: true,
  selector: 'app-auction-badge',
  template: `
    @if (icon().iconFromSource; as iconFromSource) {
      <img class="icon" [ngSrc]="iconFromSource" disableOptimizedSrcset [loaderParams]="{ getOriginal: true }" width="20" height="20" />
    }
    @if (icon().enzoIcon; as enzoIcon) {
      <enzo-icon [icon]="enzoIcon" [color]="color()" />
    }
    <enzo-text kind="label" [color]="activeColor()">{{ label() | translate }}</enzo-text>
  `,
  styleUrls: ['./auction-badge.component.scss'],
  imports: [EnzoComponentsModule, TranslateModule, NgOptimizedImage],
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    '[class.highlight]': 'shouldApplyHighlight()',
  },
})
export class AuctionBadgeComponent {
  public icon = input.required<IconOptions>();
  public color = input.required<Color>();
  public label = input.required<string>();
  public highlight = input<boolean>(false);
  public shouldApplyHighlight = computed(() => this.highlight() && this.icon().iconFromSource);
  public activeColor = computed<Color>(() => (this.shouldApplyHighlight() ? 'white' : this.color()));
}
