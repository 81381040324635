<mat-form-field appearance="outline" class="integer-input" [ngStyle]="_styles.field">
  <mat-label [ngStyle]="_styles.label">{{ _labelKey | translate }}</mat-label>
  <input
    [autocomplete]="_autocomplete"
    [formControl]="integerFormControl"
    [max]="_max"
    [maxlength]="_maxlength"
    [min]="_min"
    [ngStyle]="_styles.input"
    [placeholder]="_placeholderKey | translate"
    [step]="_step"
    [type]="_type"
    autofocus
    matInput
    [attr.data-cy]="dataCy" />
  <mat-hint> {{ _hintKey | translate }} </mat-hint>
  <mat-error *ngIf="integerFormControl.hasError('pattern')"> {{ _errorKey | translate }} </mat-error>
  <mat-error *ngIf="integerFormControl.hasError('required')"> {{ _errorKey | translate }} </mat-error>
  <span *ngIf="_suffixKey" matSuffix> {{ _suffixKey | translate }} </span>
</mat-form-field>
