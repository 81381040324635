import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BaseModule } from '@cosCoreComponentsGeneral/base.module';
import { BuyerVehiclePreferencesComponent } from './buyer-vehicle-preferences.component';
import { EnzoComponentsModule } from '@caronsale/enzo-angular';
import { ReactiveFormsModule } from '@angular/forms';
import { AppMaterialModule } from '@cosCoreModules/app-material.module';
import { GeneralPipesModule, I18nGeneralPipesModule } from '@caronsale/frontend-pipes';

@NgModule({
  imports: [
    //
    AppMaterialModule,
    BaseModule,
    CommonModule,
    EnzoComponentsModule,
    GeneralPipesModule,
    I18nGeneralPipesModule,
    ReactiveFormsModule,
  ],
  declarations: [BuyerVehiclePreferencesComponent],
  exports: [BuyerVehiclePreferencesComponent],
})
export class BuyerVehiclePreferencesModule {}
