import { Injectable } from '@angular/core';
import { IBuyerAuctionView, IPrebookedService } from '@caronsale/cos-models';
import { CosBuyerClientService } from '@cosCoreServices/cos-salesman-client/cos-buyer-client.service';
import { Observable, of, switchMap } from 'rxjs';
import { PrebookedServiceGuaranteeOptionType } from '@caronsale/cos-models/dist/Auction/IPrebookedService';
import {
  BuyerGuaranteeAdvertiseDialogComponent,
  IBuyerGuaranteeAdvertiseDialogParams,
  IBuyerGuaranteeAdvertiseDialogResult,
} from '@cosCoreFeatures/buyer/components/buyer-guarantee-advertise-dialog/buyer-guarantee-advertise-dialog.component';
import {
  BuyerGuaranteeOptionsDialogComponent,
  IBuyerGuaranteeOptionsDialogParams,
  IBuyerGuaranteeOptionsDialogResult,
} from '@cosCoreFeatures/buyer/components/buyer-guarantee-options-dialog/buyer-guarantee-options-dialog.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { EnzoDialogService } from '@cosCoreComponents/modal-dialogs/enzo-dialog.service';

export const STANDARD_GUARANTEE_PERIOD_IN_DAYS = 3;
export const EXTENDED_GUARANTEE_PERIOD_IN_DAYS = 7;
export const STANDARD_GUARANTEE_COVERAGE_IN_EUROS = 300;
export const EXTENDED_GUARANTEE_COVERAGE_IN_EUROS = 5000;

const ACCEPTED_TERMS_GUARANTEE_OPTIONS_KEY = 'acceptedTermsGuaranteeOptions';

@Injectable({
  providedIn: 'root',
})
export class PrebookedServicesService {
  private acceptedTermsGuaranteeOptions: PrebookedServiceGuaranteeOptionType[];

  public constructor(
    private cosBuyerClientService: CosBuyerClientService,
    private dialog: MatDialog,
    private enzoDialogService: EnzoDialogService,
  ) {
    const acceptedTermsGuaranteeOptions: any = JSON.parse(localStorage.getItem(ACCEPTED_TERMS_GUARANTEE_OPTIONS_KEY));
    if (!this.acceptedTermsGuaranteeOptions || !Array.isArray(acceptedTermsGuaranteeOptions)) {
      this.acceptedTermsGuaranteeOptions = [];
    }
    this.acceptedTermsGuaranteeOptions = acceptedTermsGuaranteeOptions || [];
  }

  public persistPrebookedServices(auctionUuid: string, newPrebookedServices?: IPrebookedService[]): Observable<void> {
    if (!newPrebookedServices) {
      return of(null);
    }

    return this.cosBuyerClientService.updatePrebookedServices(auctionUuid, newPrebookedServices);
  }

  public getAcceptedTermsGuaranteeOptions(): PrebookedServiceGuaranteeOptionType[] {
    return this.acceptedTermsGuaranteeOptions;
  }

  public hasAcceptedGuaranteeTermsFor(optionType: PrebookedServiceGuaranteeOptionType) {
    return this.acceptedTermsGuaranteeOptions.includes(optionType);
  }

  public getGuaranteeOptionsNotAccepted(requiredOptions: PrebookedServiceGuaranteeOptionType[]): PrebookedServiceGuaranteeOptionType[] {
    return requiredOptions.filter(option => !this.acceptedTermsGuaranteeOptions.includes(option));
  }

  public addAcceptedTermsGuaranteeOptions(optionTypesToAdd: PrebookedServiceGuaranteeOptionType[]) {
    this.acceptedTermsGuaranteeOptions = Array.from(new Set(this.acceptedTermsGuaranteeOptions.concat(optionTypesToAdd)));
    try {
      localStorage.setItem(ACCEPTED_TERMS_GUARANTEE_OPTIONS_KEY, JSON.stringify(this.acceptedTermsGuaranteeOptions));
    } catch (e) {
      // fail silently. ToDo: do not use localStorage. store this in the backend instead.
    }
  }

  public openGuaranteeAdvertiseDialog(auction: IBuyerAuctionView, imageUrl: string, displayButtons: boolean = true): Observable<boolean | void> {
    return this.openAdvertiseDialog(auction, imageUrl, displayButtons).pipe(
      switchMap(dialogResultData => {
        // if confirmed we fetch the auction so it can be passed to the booking dialog
        // and the booking dialog afterClosed method is passed down the stream.
        if (dialogResultData?.confirmed) {
          return this.openBookingDialog(auction).pipe(
            switchMap(dialogResultData => {
              // here we either book services (if any changes happened to the services and the user confirmed the action)
              // or we re-start this stream with the initial advertise dialog
              // so the view using it can have a clear "true" for skipped or "null" if any service was booked
              if (dialogResultData?.confirmed && !!dialogResultData?.prebookedServices) {
                return this.cosBuyerClientService.updatePrebookedServices(auction.uuid, dialogResultData.prebookedServices);
              }

              return this.openGuaranteeAdvertiseDialog(auction, imageUrl, displayButtons);
            }),
          );
        }

        // since updatePrebookedServices always returns "null" from the backend
        // we consider "null" as a success in the views that this is being used to refresh the view
        // so whenever the user skips the dialog we return "true"
        // to know that the dialog was skipped, not update the view and just continue to follow the stream normally
        // so here we either open the booking dialog or return "true".
        return of(true);
      }),
    );
  }

  private openAdvertiseDialog(auction: IBuyerAuctionView, vehicleImage: string, displayButtons: boolean): Observable<IBuyerGuaranteeAdvertiseDialogResult> {
    return this.dialog
      .open<BuyerGuaranteeAdvertiseDialogComponent, IBuyerGuaranteeAdvertiseDialogParams, IBuyerGuaranteeAdvertiseDialogResult>(
        BuyerGuaranteeAdvertiseDialogComponent,
        {
          width: '470px',
          maxWidth: 'none',
          autoFocus: false,
          data: { auction, vehicleImage, displayButtons },
        },
      )
      .afterClosed();
  }

  private openBookingDialog(auction: IBuyerAuctionView): Observable<IBuyerGuaranteeOptionsDialogResult> {
    return this.enzoDialogService.openModal<IBuyerGuaranteeOptionsDialogResult, IBuyerGuaranteeOptionsDialogParams, BuyerGuaranteeOptionsDialogComponent>(
      BuyerGuaranteeOptionsDialogComponent,
      {
        width: '450px',
        maxWidth: 'none',
        data: {
          auction: auction,
          prebookedServices: auction.prebookedServices,
        },
      },
    );
  }
}
