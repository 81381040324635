<!-- Required by MPW-2025: do not display anything if there is only one element -->
<ng-container *ngIf="equipments.length > 1">
  <div class="equipment-title">
    <enzo-icon [color]="'yellow-dark'" [icon]="'check'"></enzo-icon>
    <enzo-headline level="4" color="blackish">{{ 'general.vehicle-equipment-highlights' | translate }}</enzo-headline>
  </div>

  <div class="equipment-data" testid="equipment-data">
    <enzo-text *ngFor="let equipment of equipments" [icon]="equipment.icon" [matTooltip]="equipment.nameKey | translate" matTooltipClass="equipment-tooltip">
      {{ equipment.detail | translate }}
    </enzo-text>
  </div>
</ng-container>
