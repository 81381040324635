import { Injectable, Injector } from '@angular/core';
import { ConnectionPositionPair, Overlay, OverlayConfig, PositionStrategy } from '@angular/cdk/overlay';
import { ComponentPortal, PortalInjector } from '@angular/cdk/portal';
import { PopoverContent, PopoverRef } from '../components/popover-ref.component';
import { CosPopoverComponent } from '../cos-popover.component';

export interface PopoverParams<T> {
  origin: HTMLElement;
  content: PopoverContent;
  data?: T;
  width?: string | number;
  height?: string | number;
  customClasses?: string[];
}

@Injectable({ providedIn: 'root' })
export class Popover {
  public constructor(
    private overlay: Overlay,
    private injector: Injector,
  ) {}

  private static getPositions(): ConnectionPositionPair[] {
    // const _top = {
    //     originX : 'center',
    //     originY : 'top',
    //     overlayX: 'center',
    //     overlayY: 'bottom',
    //   },
    //   _right = {
    //     originX : 'end',
    //     originY : 'center',
    //     overlayX: 'start',
    //     overlayY: 'center',
    //   },
    //   _bottom = {
    //     originX : 'center',
    //     originY : 'bottom',
    //     overlayX: 'center',
    //     overlayY: 'top',
    //   },
    //   _left = {
    //     originX : 'start',
    //     originY : 'center',
    //     overlayX: 'end',
    //     overlayY: 'center',
    //   };
    return [
      {
        originX: 'center',
        originY: 'bottom',
        overlayX: 'center',
        overlayY: 'top',
      },
      {
        originX: 'center',
        originY: 'top',
        overlayX: 'center',
        overlayY: 'bottom',
      },
      {
        originX: 'start',
        originY: 'center',
        overlayX: 'end',
        overlayY: 'center',
      },
    ];
  }

  public open<T>({ origin, content, data, width, height, customClasses }: PopoverParams<T>): PopoverRef<T> {
    const overlayRef = this.overlay.create(this.getOverlayConfig({ origin, width, height }));
    overlayRef.addPanelClass(customClasses);

    const popoverRef = new PopoverRef<T>(overlayRef, content, data);

    const injector = this.createInjector(popoverRef, this.injector);
    overlayRef.attach(new ComponentPortal(CosPopoverComponent, null, injector));

    return popoverRef;
  }

  private getOverlayConfig({ origin, width, height }): OverlayConfig {
    return new OverlayConfig({
      hasBackdrop: true,
      width,
      height,
      backdropClass: 'cos-popover-backdrop',
      positionStrategy: this.getOverlayPosition(origin),
      scrollStrategy: this.overlay.scrollStrategies.reposition(),
    });
  }

  private getOverlayPosition(origin: HTMLElement): PositionStrategy {
    return this.overlay.position().flexibleConnectedTo(origin).withPositions(Popover.getPositions()).withFlexibleDimensions(false).withPush(true);
  }

  public createInjector(popoverRef: PopoverRef, injector: Injector): PortalInjector {
    const tokens = new WeakMap([[PopoverRef, popoverRef]]);
    return new PortalInjector(injector, tokens);
  }
}
