import { AfterViewInit, DestroyRef, Directive, Inject, InjectionToken, input, Optional } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControlName, NgControl } from '@angular/forms';
import { Validation } from '@caronsale/cos-models';
import { FiltersAppliedProperties } from '@cosCoreServices/product-analytics/amplitude/ampli';
import { ProductAnalyticsService } from '@cosCoreServices/product-analytics/product-analytics.service';
import { debounceTime, distinctUntilChanged, filter } from 'rxjs';

export type FiltersAppliedOrigin = FiltersAppliedProperties['Filter origin'];

export const TrackFormControlOrigin = new InjectionToken<FiltersAppliedOrigin>('TrackFormControlOrigin');

/**
 * Use this directive for tracking form control changes. Needs to be used together with FormControlName directive.
 */
@Directive({
  standalone: true,
  selector: '[appTrackFormControl],[appTrackFormControlAs]',
})
export class TrackFormControlDirective implements AfterViewInit {
  public skipTracking = input(false);
  public appTrackFormControlAs = input<string>('');
  public constructor(
    @Optional() private formControlName: FormControlName,
    @Optional() private formControl: NgControl,
    @Optional() @Inject(TrackFormControlOrigin) private origin: FiltersAppliedOrigin,
    private analyticsService: ProductAnalyticsService,
    private destroyRef: DestroyRef,
  ) {
    if (
      (!formControlName && !this.appTrackFormControlAs && !formControl) ||
      (this.appTrackFormControlAs && !formControl) ||
      (!this.appTrackFormControlAs && formControl)
    ) {
      throw new Error(
        'appTrackFormControl directive must be used with a FormControlName directive or a FormControl together with the appTrackFormControlAs selector',
      );
    }

    if (!origin) {
      throw new Error('TrackFormControlOrigin must be provided with a TrackFormControl');
    }
  }

  // has to happen after view init, so we don't trigger the valueChanges on the first value
  // and we don't have to worry about the formControlName having missing data due to template binding
  public ngAfterViewInit(): void {
    if (!this.skipTracking()) {
      (this.formControlName || this.formControl).valueChanges
        .pipe(
          takeUntilDestroyed(this.destroyRef),
          debounceTime(1000),
          filter(val => !Validation.isUndefinedNullOrEmptyString(val)),
          distinctUntilChanged((prev, curr) => {
            const previousValue = this.parseValue(prev);
            const currentValue = this.parseValue(curr);

            return previousValue === currentValue;
          }),
        )
        .subscribe(() => this.trackValue());
    }
  }

  private trackValue() {
    this.analyticsService.trackEvent('filtersApplied', {
      'Filter name': this.inputName,
      'Filter origin': this.origin,
    });
  }

  private get inputName(): string {
    if (this.appTrackFormControlAs()) {
      return this.appTrackFormControlAs();
    }

    return this.formControlName.path.join('.');
  }

  private parseValue(value: unknown): string {
    if (Validation.isObject(value)) {
      return JSON.stringify(value);
    }

    return String(value);
  }
}
