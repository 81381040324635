import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-cross-border-discount-badge',
  templateUrl: './cross-border-discount-badge.component.html',
  styleUrls: ['./cross-border-discount-badge.component.scss'],
})
export class CrossBorderDiscountBadgeComponent {
  @Input()
  public amount: number;
}
