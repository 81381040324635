import { Component, Input } from '@angular/core';
import { EAuctionPaymentState, ISellerAuctionView } from '@caronsale/cos-models';
import { AuctionPipesModule, SellerPipesModule } from '@caronsale/frontend-pipes';
import { NgIf } from '@angular/common';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-seller-auction-payment-state',
  templateUrl: './seller-auction-payment-state.component.html',
  styleUrls: ['./seller-auction-payment-state.component.scss'],
  imports: [NgIf, AuctionPipesModule, SellerPipesModule, MatTooltipModule, TranslateModule],
  standalone: true,
})
export class SellerAuctionPaymentStateComponent {
  @Input()
  public auction: ISellerAuctionView;

  // TODO: should be an i18n-ed pipe
  public get auctionPaymentState(): string {
    switch (this.auction.paymentState.state) {
      case EAuctionPaymentState.OUTSTANDING:
        return 'payment.status.outstanding';
      case EAuctionPaymentState.TRANSFERRED_TO_SELLER:
        return 'payment.status.transferred-to-cos';
      case EAuctionPaymentState.PAYOUT_TRIGGERED:
        return 'payment.status.payout-triggered';
      case EAuctionPaymentState.PAYOUT_PAID:
        return 'payment.status.payout-paid';
      default:
        return 'payment.status.unknown';
    }
  }
}
