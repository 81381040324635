import {
  EAirConditionerAnomalyType,
  EAirConditionerNoiseAnomalySpecification,
  EBatteryAnomalyType,
  EBatteryAttachmentsAnomaly,
  EBrakeFluidAnomalySpecification,
  EBrakeSystemAnomalyType,
  EChassisAnomalyType,
  EClutchAnomalyType,
  EClutchNoiseAnomalySpecification,
  EClutchPedalAnomalySpecification,
  ECommonAnomalyType,
  EControlLightsAnomalySpecification,
  ECoolingWaterAnomalySpecification,
  EDifferentialAnomalyType,
  EDualMassAnomalySpecification,
  EEngineAnomalyType,
  EEngineNoiseAnomalySpecification,
  EExhaustAnomalySpecification,
  EOilAnomalySpecification,
  EOilLeakAnomalySpecification,
  EPowerSteeringAnomalySpecification,
  ERearFrontAnomalySpecification,
  EStarterAnomalySpecification,
  ESteeringAnomalyType,
  ESteeringWheelNoiseAnomalySpecification,
  ETransferCaseAnomalyType,
  ETransmissionAnomalyType,
  ETransmissionNoiseAnomalySpecification,
  EVehiclePart,
  VehicleAnomalyLvl1,
  VehicleAnomalyLvl2,
} from '@caronsale/cos-vehicle-models';

export type TechStateVehicleParts =
  | EVehiclePart.STEERING
  | EVehiclePart.CHASSIS
  | EVehiclePart.CLUTCH
  | EVehiclePart.BATTERY
  | EVehiclePart.AIR_CONDITIONER
  | EVehiclePart.ENGINE
  | EVehiclePart.TRANSMISSION
  | EVehiclePart.BRAKING_SYSTEM
  | EVehiclePart.TRANSFER_CASE
  | EVehiclePart.DIFFERENTIAL
  | EVehiclePart.OTHER;

export class VehicleTechStateMap {
  public static readonly OTHER_ANOMALY: ECommonAnomalyType = ECommonAnomalyType.OTHER;

  public static readonly VEHICLE_PARTS: { [key in keyof typeof EVehiclePart]?: TechStateVehicleParts } = {
    ENGINE: EVehiclePart.ENGINE,
    TRANSMISSION: EVehiclePart.TRANSMISSION,
    BRAKING_SYSTEM: EVehiclePart.BRAKING_SYSTEM,
    TRANSFER_CASE: EVehiclePart.TRANSFER_CASE,
    STEERING: EVehiclePart.STEERING,
    DIFFERENTIAL: EVehiclePart.DIFFERENTIAL,
    CHASSIS: EVehiclePart.CHASSIS,
    CLUTCH: EVehiclePart.CLUTCH,
    BATTERY: EVehiclePart.BATTERY,
    AIR_CONDITIONER: EVehiclePart.AIR_CONDITIONER,
    OTHER: EVehiclePart.OTHER,
  };

  public static readonly LVL1_OPTS: Record<TechStateVehicleParts, VehicleAnomalyLvl1[]> = {
    [EVehiclePart.AIR_CONDITIONER]: [
      EAirConditionerAnomalyType.WITHOUT_FUNCTION,
      EAirConditionerAnomalyType.VENTILATION_NOISES,
      EAirConditionerAnomalyType.INSUFFICIENT_COOLING_POWER,
      VehicleTechStateMap.OTHER_ANOMALY,
    ],
    [EVehiclePart.BATTERY]: [
      EBatteryAnomalyType.DEFECT,
      EBatteryAnomalyType.BATTERY_INDICATOR_LIGHTS_ON,
      EBatteryAnomalyType.DEEP_DISCHARGE,
      EBatteryAnomalyType.MISSING,
      EBatteryAnomalyType.ANOMALIES_IN_ATTACHMENTS,
      VehicleTechStateMap.OTHER_ANOMALY,
    ],
    [EVehiclePart.BRAKING_SYSTEM]: [
      EBrakeSystemAnomalyType.NO_BRAKE_PRESSURE,
      EBrakeSystemAnomalyType.BRAKE_INDICATOR_LIGHTS_ON,
      EBrakeSystemAnomalyType.BRAKE_NEAR_WORN_OUT,
      EBrakeSystemAnomalyType.BRAKE_WORN_OUT,
      EBrakeSystemAnomalyType.BRAKE_RUSTY,
      EBrakeSystemAnomalyType.ABS_INDICATOR_LIGHTS_ON,
      EBrakeSystemAnomalyType.HANDBRAKE_NOT_WORKING,
      EBrakeSystemAnomalyType.BRAKE_FLUID_ABNORMAL,
      VehicleTechStateMap.OTHER_ANOMALY,
    ],
    [EVehiclePart.CHASSIS]: [
      EChassisAnomalyType.SUSPENSION_SPRING_BROKEN,
      EChassisAnomalyType.ESP_INDICATOR_LIGHTS_ON,
      EChassisAnomalyType.AIR_SUSPENSION_WITHOUT_FUNCTION,
      EChassisAnomalyType.UNEQUAL_CHASSIS_LEVEL,
      EChassisAnomalyType.VEHICLE_LOWERED,
      VehicleTechStateMap.OTHER_ANOMALY,
    ],
    [EVehiclePart.CLUTCH]: [
      EClutchAnomalyType.CLUTCH_NEAR_WORN,
      EClutchAnomalyType.COUPLING_DEFECTIVE,
      EClutchAnomalyType.STRIKING_NOISE,
      EClutchAnomalyType.CLUTCH_PEDAL_ANOMALIES,
      VehicleTechStateMap.OTHER_ANOMALY,
    ],
    [EVehiclePart.DIFFERENTIAL]: [EDifferentialAnomalyType.LEAKAGE, EDifferentialAnomalyType.STRIKING_SOUNDS, VehicleTechStateMap.OTHER_ANOMALY],
    [EVehiclePart.ENGINE]: [
      EEngineAnomalyType.STRIKING_NOISE,
      EEngineAnomalyType.OUT_OF_ROUND_RUN,
      EEngineAnomalyType.DOES_NOT_RUN_BATTERY_OK,
      EEngineAnomalyType.VISUAL_WARNINGS,
      EEngineAnomalyType.OIL_ANOMALY,
      EEngineAnomalyType.OIL_LEAK,
      EEngineAnomalyType.COOLING_WATER_ANOMALY,
      EEngineAnomalyType.EXHAUST_SYSTEM_ANOMALY,
      EEngineAnomalyType.STARTER_ANOMALY,
      EEngineAnomalyType.MISFIRE,
      EEngineAnomalyType.DUAL_MASS_ANOMALY,
      VehicleTechStateMap.OTHER_ANOMALY,
    ],
    [EVehiclePart.STEERING]: [
      ESteeringAnomalyType.STEERING_INDICATOR_LIGHTS_ON,
      ESteeringAnomalyType.AT_POWER_STEERING,
      ESteeringAnomalyType.NOISE_WHEN_USING_STEERING_WHEEL,
      ESteeringAnomalyType.STEERING_GEAR_LEAKING,
      ESteeringAnomalyType.STIFF,
      VehicleTechStateMap.OTHER_ANOMALY,
    ],
    [EVehiclePart.TRANSFER_CASE]: [
      ETransferCaseAnomalyType.STRIKING_SOUNDS,
      ETransferCaseAnomalyType.PROFILE_DEVIATION_ON_WHEELS,
      VehicleTechStateMap.OTHER_ANOMALY,
    ],
    [EVehiclePart.TRANSMISSION]: [
      ETransmissionAnomalyType.GEAR_STICKS,
      ETransmissionAnomalyType.GEARBOX_DAMAGE,
      ETransmissionAnomalyType.OIL_WET,
      ETransmissionAnomalyType.GEAR_NOT_ENGAGEABLE,
      ETransmissionAnomalyType.SHIFTING_DIFFICULT,
      ETransmissionAnomalyType.RUNNING_NOISE,
      VehicleTechStateMap.OTHER_ANOMALY,
    ],
    [EVehiclePart.OTHER]: [],
  };

  public static readonly LVL2_OPTS: Record<TechStateVehicleParts, Partial<Record<VehicleAnomalyLvl1, VehicleAnomalyLvl2[]>>> = {
    [EVehiclePart.AIR_CONDITIONER]: {
      [EAirConditionerAnomalyType.WITHOUT_FUNCTION]: [],
      [EAirConditionerAnomalyType.VENTILATION_NOISES]: [
        EAirConditionerNoiseAnomalySpecification.SQUEAK,
        EAirConditionerNoiseAnomalySpecification.NOISE,
        EAirConditionerNoiseAnomalySpecification.HUM,
      ],
      [EAirConditionerAnomalyType.INSUFFICIENT_COOLING_POWER]: [],
      [VehicleTechStateMap.OTHER_ANOMALY]: [],
    },
    [EVehiclePart.BATTERY]: {
      [EBatteryAnomalyType.DEFECT]: [],
      [EBatteryAnomalyType.BATTERY_INDICATOR_LIGHTS_ON]: [],
      [EBatteryAnomalyType.DEEP_DISCHARGE]: [],
      [EBatteryAnomalyType.MISSING]: [],
      [EBatteryAnomalyType.ANOMALIES_IN_ATTACHMENTS]: [
        EBatteryAttachmentsAnomaly.LOOSE,
        EBatteryAttachmentsAnomaly.INADEQUATE,
        EBatteryAttachmentsAnomaly.FAULTY,
      ],
      [VehicleTechStateMap.OTHER_ANOMALY]: [],
    },
    [EVehiclePart.BRAKING_SYSTEM]: {
      [EBrakeSystemAnomalyType.NO_BRAKE_PRESSURE]: [],
      [EBrakeSystemAnomalyType.BRAKE_INDICATOR_LIGHTS_ON]: [],
      [EBrakeSystemAnomalyType.BRAKE_NEAR_WORN_OUT]: [ERearFrontAnomalySpecification.REAR, ERearFrontAnomalySpecification.FRONT],
      [EBrakeSystemAnomalyType.BRAKE_WORN_OUT]: [ERearFrontAnomalySpecification.REAR, ERearFrontAnomalySpecification.FRONT],
      [EBrakeSystemAnomalyType.BRAKE_RUSTY]: [ERearFrontAnomalySpecification.REAR, ERearFrontAnomalySpecification.FRONT],
      [EBrakeSystemAnomalyType.ABS_INDICATOR_LIGHTS_ON]: [],
      [EBrakeSystemAnomalyType.HANDBRAKE_NOT_WORKING]: [],
      [EBrakeSystemAnomalyType.BRAKE_FLUID_ABNORMAL]: [
        EBrakeFluidAnomalySpecification.BELOW_MINIMUM,
        EBrakeFluidAnomalySpecification.BIN_EMPTY,
        EBrakeFluidAnomalySpecification.FLOATING_LOSS,
        EBrakeFluidAnomalySpecification.DRIPPING_LOSS,
      ],
      [VehicleTechStateMap.OTHER_ANOMALY]: [],
    },
    [EVehiclePart.CHASSIS]: {
      [EChassisAnomalyType.SUSPENSION_SPRING_BROKEN]: [ERearFrontAnomalySpecification.REAR, ERearFrontAnomalySpecification.FRONT],
      [EChassisAnomalyType.ESP_INDICATOR_LIGHTS_ON]: [],
      [EChassisAnomalyType.AIR_SUSPENSION_WITHOUT_FUNCTION]: [],
      [EChassisAnomalyType.UNEQUAL_CHASSIS_LEVEL]: [],
      [EChassisAnomalyType.VEHICLE_LOWERED]: [],
      [VehicleTechStateMap.OTHER_ANOMALY]: [],
    },
    [EVehiclePart.CLUTCH]: {
      [EClutchAnomalyType.CLUTCH_NEAR_WORN]: [],
      [EClutchAnomalyType.COUPLING_DEFECTIVE]: [],
      [EClutchAnomalyType.STRIKING_NOISE as VehicleAnomalyLvl1]: [
        EClutchNoiseAnomalySpecification.SQUEAK,
        EClutchNoiseAnomalySpecification.GRINDING,
        EClutchNoiseAnomalySpecification.RATTLING,
        EClutchNoiseAnomalySpecification.VIBRATING,
        EClutchNoiseAnomalySpecification.CLATTERING,
        EClutchNoiseAnomalySpecification.CREAKING,
      ],
      [EClutchAnomalyType.CLUTCH_PEDAL_ANOMALIES]: [EClutchPedalAnomalySpecification.COMES_EARLY, EClutchPedalAnomalySpecification.COMES_LATE],
      [VehicleTechStateMap.OTHER_ANOMALY]: [],
    },
    [EVehiclePart.DIFFERENTIAL]: {
      [EDifferentialAnomalyType.LEAKAGE]: [],
      [EDifferentialAnomalyType.STRIKING_SOUNDS as VehicleAnomalyLvl1]: [],
      [VehicleTechStateMap.OTHER_ANOMALY]: [],
    },
    [EVehiclePart.ENGINE]: {
      [EEngineAnomalyType.STRIKING_NOISE as VehicleAnomalyLvl1]: [
        EEngineNoiseAnomalySpecification.WHISTLING,
        EEngineNoiseAnomalySpecification.CRACKING,
        EEngineNoiseAnomalySpecification.CLATTERING,
        EEngineNoiseAnomalySpecification.BEATING,
        EEngineNoiseAnomalySpecification.RATTLING,
        EEngineNoiseAnomalySpecification.VIBRATING,
        EEngineNoiseAnomalySpecification.GRINDING,
        EEngineNoiseAnomalySpecification.SQUEAKING,
      ],
      [EEngineAnomalyType.OUT_OF_ROUND_RUN]: [],
      [EEngineAnomalyType.DOES_NOT_RUN_BATTERY_OK]: [],
      [EEngineAnomalyType.VISUAL_WARNINGS]: [
        EControlLightsAnomalySpecification.CHECK_ENGINE,
        EControlLightsAnomalySpecification.DIESEL_PARTICULATE_FILTER,
        EControlLightsAnomalySpecification.PREHEATING,
        EControlLightsAnomalySpecification.EPC,
      ],
      [EEngineAnomalyType.OIL_ANOMALY]: [
        EOilAnomalySpecification.BELOW_MINIMUM,
        EOilAnomalySpecification.BIN_EMPTY,
        EOilAnomalySpecification.OIL_LOSS_NOT_VISIBLE,
      ],
      [EEngineAnomalyType.OIL_LEAK]: [
        EOilLeakAnomalySpecification.AT_TURBO_CHARGER,
        EOilLeakAnomalySpecification.UNDER_CHASSIS,
        EOilLeakAnomalySpecification.ON_VALVE_COVER,
        EOilLeakAnomalySpecification.ON_CYLINDER_HEAD,
        EOilLeakAnomalySpecification.ON_VALVE_COVER_GASKET,
      ],
      [EEngineAnomalyType.COOLING_WATER_ANOMALY]: [
        ECoolingWaterAnomalySpecification.BELOW_MINIMUM,
        ECoolingWaterAnomalySpecification.BIN_EMPTY,
        ECoolingWaterAnomalySpecification.FLOATING_LOSS,
        ECoolingWaterAnomalySpecification.DRIPPING_LOSS,
        ECoolingWaterAnomalySpecification.OIL_FILM_MUDDY,
      ],
      [EEngineAnomalyType.EXHAUST_SYSTEM_ANOMALY]: [
        EExhaustAnomalySpecification.LEAKY,
        EExhaustAnomalySpecification.RECIRCULATION_DEFECT,
        EExhaustAnomalySpecification.LOSS_NOT_VISIBLE,
        EExhaustAnomalySpecification.DISTINCTIVE_ODOR,
        EExhaustAnomalySpecification.SURFACE_CORRODED,
      ],
      [EEngineAnomalyType.STARTER_ANOMALY]: [EStarterAnomalySpecification.DEFECT, EStarterAnomalySpecification.TURNS_BUT_ENGINE_WONT_START],
      [EEngineAnomalyType.MISFIRE]: [],
      [EEngineAnomalyType.DUAL_MASS_ANOMALY]: [EDualMassAnomalySpecification.CLATTERING, EDualMassAnomalySpecification.DEFECT],
      [VehicleTechStateMap.OTHER_ANOMALY]: [],
    },
    [EVehiclePart.STEERING]: {
      [ESteeringAnomalyType.STEERING_INDICATOR_LIGHTS_ON]: [],
      [ESteeringAnomalyType.AT_POWER_STEERING]: [EPowerSteeringAnomalySpecification.WITHOUT_FUNCTION, EPowerSteeringAnomalySpecification.LIMITED_FUNCTION],
      [ESteeringAnomalyType.NOISE_WHEN_USING_STEERING_WHEEL]: [
        ESteeringWheelNoiseAnomalySpecification.CRACKING,
        ESteeringWheelNoiseAnomalySpecification.CRUNCH,
        ESteeringWheelNoiseAnomalySpecification.CREAKING,
        ESteeringWheelNoiseAnomalySpecification.GRINDING,
        ESteeringWheelNoiseAnomalySpecification.RESILIENCE,
        ESteeringWheelNoiseAnomalySpecification.POWER_STEERING_PUMP_NOISE,
      ],
      [ESteeringAnomalyType.STEERING_GEAR_LEAKING]: [],
      [ESteeringAnomalyType.STIFF]: [],
      [VehicleTechStateMap.OTHER_ANOMALY]: [],
    },
    [EVehiclePart.TRANSFER_CASE]: {
      [ETransferCaseAnomalyType.STRIKING_SOUNDS as VehicleAnomalyLvl1]: [],
      [ETransferCaseAnomalyType.PROFILE_DEVIATION_ON_WHEELS]: [],
      [VehicleTechStateMap.OTHER_ANOMALY]: [],
    },
    [EVehiclePart.TRANSMISSION]: {
      [ETransmissionAnomalyType.GEAR_STICKS]: [],
      [ETransmissionAnomalyType.GEARBOX_DAMAGE]: [],
      [ETransmissionAnomalyType.OIL_WET]: [],
      [ETransmissionAnomalyType.GEAR_NOT_ENGAGEABLE]: [],
      [ETransmissionAnomalyType.SHIFTING_DIFFICULT]: [],
      [ETransmissionAnomalyType.RUNNING_NOISE]: [
        ETransmissionNoiseAnomalySpecification.WHISTLING,
        ETransmissionNoiseAnomalySpecification.RATTLING,
        ETransmissionNoiseAnomalySpecification.CRACKING,
        ETransmissionNoiseAnomalySpecification.GRINDING,
      ],
      [VehicleTechStateMap.OTHER_ANOMALY]: [],
    },
    [EVehiclePart.OTHER]: {},
  };
}
