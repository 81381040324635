<div class="badges-and-bid-value-container">
  <ng-container *ngIf="auction | isAuctionRunning; else auctionClosedState">
    <div class="badges">
      <app-auction-fee-discount-tag [auction]="auction" />
      <app-price-target-info [auction]="auction"></app-price-target-info>
      <app-cross-border-discount-badge [amount]="auction.buyerCrossBorderProcessingAmount"></app-cross-border-discount-badge>
    </div>
    <app-highest-bid-value [auction]="auction"></app-highest-bid-value>
  </ng-container>
</div>
<app-auction-vat [auction]="auction"></app-auction-vat>

<ng-template #auctionClosedState>
  <app-price-target-info [auction]="auction"></app-price-target-info>
</ng-template>
