import { Component, Input } from '@angular/core';
import { EReportType } from '@cos/partials/general-info/vehicle-info';
import { CommonModule } from '@angular/common';
@Component({
  selector: 'app-service-badge',
  templateUrl: './service-badge.component.html',
  styleUrls: ['./service-badge.component.scss'],
  standalone: true,
  imports: [CommonModule],
})
export class ServiceBadgeComponent {
  @Input()
  public service: 'battery' | 'cos-check' | 'cos-check-plus' | EReportType;

  @Input()
  public clickable = true;
}
