import { Component, Input } from '@angular/core';
import { IBuyerAuctionView, IPrebookedService } from '@caronsale/cos-models';
import { InstantPurchaseService } from '@cosCoreFeatures/auction-detail/buyer/instant-purchase-service/instant-purchase.service';
import { BuyerAuctionService } from '@cosCoreFeatures/auction-detail/common/auction-service/buyer-auction.service';

@Component({
  selector: 'app-instant-purchase-button',
  templateUrl: './instant-purchase-button.component.html',
  styleUrls: ['./instant-purchase-button.component.scss'],
})
export class InstantPurchaseButtonComponent {
  @Input()
  public auction: IBuyerAuctionView;

  @Input()
  public currentPrebookedServices: IPrebookedService[];

  public constructor(
    //
    private buyerAuctionService: BuyerAuctionService,
    private instantPurchaseService: InstantPurchaseService,
  ) {}

  public isInstantPurchaseActive(): boolean {
    return this.buyerAuctionService.isInstantPurchaseActive(this.auction);
  }

  public makeInstantPurchase(): void {
    this.instantPurchaseService.makeInstantPurchase(this.auction);
  }
}
