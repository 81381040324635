import { ChangeDetectionStrategy, Component, TemplateRef, effect, inject, input, signal } from '@angular/core';
import { NgFor, NgIf, NgOptimizedImage, NgTemplateOutlet } from '@angular/common';
import { SlideData } from 'photoswipe';
import { EnzoComponentsModule } from '@caronsale/enzo-angular';
import { FullscreenGalleryService } from '@cosCoreServices/fullscreen-gallery/fullscreen-gallery.service';
import { ImageGalleryThumbnailsComponent } from './image-gallery-thumbnails/image-gallery-thumbnails.component';

export interface IGalleryImage {
  url: string;
  urlRef?: string;
  overlayText?: string[];
  overlayTemplate?: TemplateRef<unknown>;
  imageDescription?: string;
}

@Component({
  standalone: true,
  selector: 'app-image-gallery',
  templateUrl: './image-gallery.component.html',
  styleUrls: ['./image-gallery.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgIf, NgFor, NgTemplateOutlet, ImageGalleryThumbnailsComponent, EnzoComponentsModule, NgOptimizedImage],
})
export class ImageGalleryComponent {
  public galleryImages = input.required<IGalleryImage[]>();
  public selectedIndex = signal<number>(0);
  private fullscreenGalleryService = inject(FullscreenGalleryService);

  public constructor() {
    effect(
      () => {
        if (this.galleryImages()) {
          this.selectedIndex.set(0);
        }
      },
      { allowSignalWrites: true },
    );
  }

  public openFullscreenGallery(selectedImage: HTMLImageElement) {
    const imagesDataSource: SlideData[] = this.galleryImages().map(galleryImage => ({
      src: galleryImage.url,
      overlayText: galleryImage.overlayText || null,
    }));
    const photoswipe = this.fullscreenGalleryService.open(this.selectedIndex(), imagesDataSource, selectedImage);
    photoswipe.on('close', () => this.selectedIndex.set(photoswipe.currIndex));
  }

  public increaseSelectedIndex() {
    this.selectedIndex.set((this.selectedIndex() + 1) % this.galleryImages().length);
  }

  public decreaseSelectedIndex() {
    this.selectedIndex.set((this.selectedIndex() - 1 + this.galleryImages().length) % this.galleryImages().length);
  }
}
