import { Component, EventEmitter, Input, Output } from '@angular/core';

import { CosPickupService } from '@cosCoreServices/cos-pickup/cos-pickup.service';

import { IVehicle } from '@caronsale/cos-vehicle-models';

@Component({
  selector: 'app-pickup-confirmation-by-scan',
  templateUrl: './pickup-confirmation-by-scan.component.html',
})
export class PickupConfirmationByScanComponent {
  @Input()
  public uuid: string;

  @Output()
  public confirmationError: EventEmitter<any> = new EventEmitter();

  public vehicle: IVehicle;
  public confirmed: boolean;
  public confirming: boolean;

  public constructor(private pickupService: CosPickupService) {}

  public async confirmPickup(): Promise<void> {
    try {
      this.confirming = true;
      const response = await this.pickupService.confirmByUUID(this.uuid);

      this.vehicle = response.associatedVehicle;
      this.confirmed = true;
    } catch (errorResponse: any) {
      this.confirmationError.emit({
        status: errorResponse.status,
        ...errorResponse.error,
      });
    } finally {
      this.confirming = false;
    }
  }
}
