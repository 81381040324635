import { IBuyNowPayLaterAmount, IBuyNowPayLaterBillingDataItem } from '@caronsale/cos-models';

export enum EBuyNowPayLaterBillieDeclineReason {
  RISK_POLICY = 'risk_policy',
  DEBTOR_NOT_IDENTIFIED = 'debtor_not_identified',
  DEBTOR_ADDRESS = 'debtor_address',
  RISK_SCORING_FAILED = 'risk_scoring_failed',
  DEBTOR_LIMIT_EXCEEDED = 'debtor_limit_exceeded',
}

export enum EBuyNowPayLaterBillieDeclineReasonErrors {
  UNKNOWN_ERROR = 'unknown_error',
  VALIDATION_ERROR = 'validation_error',
  SESSION_REJECTED = 'session_rejected',
}

export enum EBuyNowPayLaterBillieOrderStatus {
  AUTHORIZED = 'authorized',
  DECLINED = 'declined',
  PRE_WAITING = 'pre_waiting',
  WAITING = 'waiting',
}

export interface IBuyNowPayLaterBillieWidgetResponse {
  amount: IBuyNowPayLaterAmount;
  comment: string;
  duration: number;
  delivery_address: {
    street: string;
    city: string;
    postal_code: string;
    country: string;
    house_number: string;
  };
  debtor_company: {
    established_customer: false;
    address_city: string;
    address_country: string;
    address_postal_code: string;
    address_street: string;
    address_house_number: string;
    name: string;
    legal_form: string;
  };
  debtor_person: {
    first_name: string;
    last_name: string;
    phone_number: string;
    email: string;
  };
  line_items: IBuyNowPayLaterBillingDataItem[];
  payment_method: string;
  decline_reason: EBuyNowPayLaterBillieDeclineReason | EBuyNowPayLaterBillieDeclineReasonErrors;
  state: EBuyNowPayLaterBillieOrderStatus;
}
