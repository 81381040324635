import { MissingTranslationHandler, MissingTranslationHandlerParams } from '@ngx-translate/core';

interface IDictionary {
  [key: string]: string;
}

// a function that maps a string to a string or dictionary
type LookupFunction = (string) => IDictionary | string | null;

/**
 * Missing Translation Handler to avoid using html tags in translated strings
 * can combine translation keys:
 * - by concatenating numbered parts with <br/> tags,
 *   e.g. if called for a missing 'message' by joining all translations for 'message1', 'message2',... with <br/>s
 *
 * - or by filling a template given in the translation params, where curly braces are placeholders with translate keys
 *   e.g. if called for a missing 'dialog.message' when there is a
 *        { messageTemplate: '<ul><li>{first-key}</li><li>{second-key}</li>/ul>' } in the parameters it looks up
 *        dialog.first-key and dialog.second-key and fills the template with the translated values
 *        (dialog.first-key can also be missing and is substituted by combining dialog.first-key1, dialog.first-key2,...)
 */
export class CosMissingTranslationHandler implements MissingTranslationHandler {
  private buildLookupFunctionFor(dictionary: IDictionary): LookupFunction {
    // return a function that gets a path into the dictionary and returns the dictionary's content at that point
    return pathString => pathString.split('.').reduce((remainingDictionary, pathPart) => remainingDictionary?.[pathPart], dictionary);
  }

  private translatePlaceholdersAndFillTemplate(
    placeholderNames: string[],
    templateString: string,
    baseKey: string,
    params: MissingTranslationHandlerParams,
  ): string {
    // placeholder names are translation keys. Translate them all.
    // calling the service with an array of keys does not work: the translations are mixed up among the array elements
    // so we have to call the service with single keys only.
    // and we have to use the async method to allow a key to be handled by our missing translation handler
    const placeholderTranslations = placeholderNames.map(name => params.translateService.instant(baseKey + '.' + name, params.interpolateParams));
    return templateString.replace(
      /{(.*?)}/g,
      // replacer function: first parameter is the entire match (including curly braces),
      //                    second parameter is the contents of the capturing group (placeholder name)
      (_, name) => placeholderTranslations[placeholderNames.indexOf(name)],
    );
  }

  private concatenatePartialKeys(baseKeyTranslations: IDictionary, baseKey: string, lastPart: string, params: MissingTranslationHandlerParams): string | null {
    const partialKeysRegex = '^' + lastPart.replaceAll('?', '\\?') + '\\d+$';
    if (baseKeyTranslations && typeof baseKeyTranslations === 'object') {
      // look for siblings with digits at the end
      const partialMessageKeys = Object.keys(baseKeyTranslations) // siblings of the "lastPart"
        .filter(key => key.match(partialKeysRegex)) // find e.g. message1, message2, message3, ...
        .sort() // if you need more than 9, all must have two digits (start with 01)
        .map(key => baseKey + '.' + key); // full-length names

      if (partialMessageKeys.length > 0) {
        // translate all partial keys
        const partialMessageKeyTranslations = params.translateService.instant(partialMessageKeys, params.interpolateParams);

        // join all translations with <br/>s
        return partialMessageKeys.map(key => partialMessageKeyTranslations[key]).join('<br/>');
      }
    }
    return null;
  }

  public handle(params: MissingTranslationHandlerParams): string {
    const keyParts = params.key.split('.');
    if (keyParts.length === 1) {
      // no dot at all
      return params.key;
    }
    const lastPart = keyParts.pop();
    const baseKey = keyParts.join('.');

    const currentLangTranslations = params.translateService.translations[params.translateService.currentLang];
    const defaultLangTranslations = params.translateService.translations[params.translateService.defaultLang];

    // lookup the base name of the key
    const currentLangBaseKeyTranslations = currentLangTranslations ? this.buildLookupFunctionFor(currentLangTranslations)(baseKey) : null;
    const defaultLangBaseKeyTranslations = defaultLangTranslations ? this.buildLookupFunctionFor(defaultLangTranslations)(baseKey) : null;

    // look up siblings with digits and concatenate the parts with <br>
    const partialKeysLookupResult =
      this.concatenatePartialKeys(<IDictionary>currentLangBaseKeyTranslations, baseKey, lastPart, params) ||
      this.concatenatePartialKeys(<IDictionary>defaultLangBaseKeyTranslations, baseKey, lastPart, params);
    if (partialKeysLookupResult) {
      return partialKeysLookupResult;
    }

    // look for a template
    const templateString = params.interpolateParams?.[lastPart + 'Template'];
    if (templateString) {
      // extract all placeholder names by a non-greedy match of everything inside a pair of curly braces
      const placeholderNames = Array.from(templateString.matchAll(/{(.*?)}/g), match => match[1]);
      if (placeholderNames.length > 0) {
        return this.translatePlaceholdersAndFillTemplate(placeholderNames, templateString, baseKey, params);
      }
      return templateString; // np placeholders => return the raw template string
    }

    // no numeric siblings, no template => no translation available
    return params.key;
  }
}
