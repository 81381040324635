import { AfterViewInit, Directive, ElementRef, Input, NgZone } from '@angular/core';

import { SwiperContainer } from 'swiper/element';
import { SwiperOptions } from 'swiper/types/swiper-options';
import { register } from 'swiper/element/bundle';
register();

@Directive({
  selector: '[appSwiper]',
  standalone: true,
})
export class SwiperDirective implements AfterViewInit {
  @Input() public config?: SwiperOptions;
  public readonly swiperContainer: SwiperContainer;

  public constructor(
    private elementRef: ElementRef<SwiperContainer>,
    private ngZone: NgZone,
  ) {
    this.swiperContainer = this.elementRef.nativeElement;
  }

  public ngAfterViewInit() {
    Object.assign(this.swiperContainer, this.config);
    this.ngZone.runOutsideAngular(() => this.swiperContainer.initialize());
  }
}
