import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CopyOnClickComponent } from './copy-on-click.component';

@NgModule({
  imports: [CommonModule],
  declarations: [CopyOnClickComponent],
  exports: [CopyOnClickComponent],
})
export class CopyOnClickModule {}
