<div class="notification-dialog-item-root" [class]="rootClass" (click)="onNotificationClick()">
  <div class="delete-button" matTooltip="{{ 'notification.delete-button-tooltip' | translate }}" (click)="onDeleteButtonClick($event)">
    <fa-icon icon="times"></fa-icon>
  </div>

  <div class="icon-wrapper" *ngIf="guaranteeNotification; else mainImage">
    <img src="/assets/icons/vehicle/exclamation-circle.svg" />
  </div>

  <ng-template #mainImage>
    <img class="main-image" [src]="notification.imageUrl | urlThumbnail: 70 : 50" />
  </ng-template>

  <div class="text-container">
    <div class="notification-title">{{ notification.title }}</div>
    <div class="notification-text">{{ notification.text }}</div>
    <div class="notification-created-at">{{ notification.createdAt | timeSinceNotificationPipe }}</div>
  </div>
  <div class="notification-status-indicator" [class]="notificationStatusClasses"></div>
</div>
