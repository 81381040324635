import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BaseModule } from '@cosCoreComponentsGeneral/base.module';

import { HighlightsComponent } from './highlights.component';

@NgModule({
  declarations: [HighlightsComponent],
  exports: [HighlightsComponent],
  imports: [CommonModule, BaseModule],
})
export class HighlightsModule {}
