import { Injectable } from '@angular/core';
import { Observable, map, shareReplay } from 'rxjs';

import { FilterUtils, FormatUtils, IAuctionFilter, IVehicleFilter } from '@caronsale/cos-models';
import { IVehicleMake } from '@caronsale/cos-vehicle-models';
import { CosCoreClient } from '@cosCoreServices/core-client/cos-core-client.service';
import isEqual from 'lodash.isequal';
import { Params, Router } from '@angular/router';
import { removeEmptyValuesFromObject } from '@cosCoreUtils/type-script-utils';
import { GoogleAnalyticsService } from '@cosCoreServices/google-analytics/google-analytics.service';
import { EBuyerAuctionTabId } from '@cosCoreFeatures/auction-detail/common/auction-service/buyer-auction.service';
import { QUICK_SEARCH_MAX_LENGTH } from './quick-search/quick-search.component';

enum TopRankMakes {
  Audi = '60',
  Bmw = '130',
  Ford = '285',
  MercedesBenz = '570',
  Volkswagen = '905',
}

export const NUM_TOP_RANK_MAKES = Object.keys(TopRankMakes).length;

export const BASE_AUCTION_FILTER: IAuctionFilter = {
  ...FilterUtils.createAuctionFilter(),
  limit: 20,
  offset: 0,
};

const AUCTION_OVERVIEW_URL = 'salesman/auctions';
const AUCTION_ROOM_URL = 'salesman/auctions/room/{auctionRoomUuid}';
const AUCTION_DETAIL_URL = 'salesman/auctions/';
const AUCTION_UUID_PLACEHOLDER = '{auctionUuid}';
const RECOMMENDATION_ID_PLACEHOLDER = '{recommendationId}';

@Injectable({
  providedIn: 'root',
})
export class BuyerAuctionSearchService {
  public makes$: Observable<IVehicleMake[]>;

  public constructor(
    private cosCoreClient: CosCoreClient,
    private googleAnalyticsService: GoogleAnalyticsService,
    private router: Router,
  ) {
    this.makes$ = this.cosCoreClient.getMakeOptions().pipe(
      map(makes => this.reorderMakes(makes)),
      shareReplay(1),
    );
  }

  public reorderMakes(makes: IVehicleMake[]): IVehicleMake[] {
    const newMakes = Object.values(TopRankMakes).map(internalRef => makes.find(make => make.internalReference === internalRef));
    const oldMakes = makes.filter(make => !Object.values(TopRankMakes).includes(make.internalReference as TopRankMakes));

    return [...newMakes, ...oldMakes];
  }

  public getAuctionOverviewUrlWithAuctionFilterParams(auctionFilter: IAuctionFilter, tabId: EBuyerAuctionTabId): string {
    const auctionFilterQueryParams = this.getQueryParamsForAuctionFilter(this.removeEmptyValuesFromFilter(auctionFilter));

    return `${AUCTION_OVERVIEW_URL}?tabId=${tabId}` + (auctionFilterQueryParams ? `&${auctionFilterQueryParams}` : '');
  }

  public getAuctionRoomUrlWithAuctionFilterParams(auctionFilter: IAuctionFilter, auctionRoomUuid: string): string {
    const auctionFilterQueryParams = this.getQueryParamsForAuctionFilter(auctionFilter);

    return AUCTION_ROOM_URL.replace('{auctionRoomUuid}', auctionRoomUuid) + (auctionFilterQueryParams ? `?${auctionFilterQueryParams}` : '');
  }

  private getAuctionDetailsUrlWithAuctionFilterParams(auctionUuid: string, auctionFilter: IAuctionFilter, tabId: EBuyerAuctionTabId): string {
    const auctionFilterQueryParams = this.getQueryParamsForAuctionFilter(auctionFilter);
    const auctionDetailsUrl = this.getAuctionDetailsUrlForUuid(auctionUuid);

    let url = `${auctionDetailsUrl}?tabId=${tabId}` + (auctionFilterQueryParams ? `&${auctionFilterQueryParams}` : '');

    if (tabId === EBuyerAuctionTabId.RECOMMENDED_AUCTIONS) {
      url += `&recommendationId=${RECOMMENDATION_ID_PLACEHOLDER}`;
    }

    return url;
  }

  public getAuctionDetailsUrlTemplateWithAuctionFilterParams(auctionFilter: IAuctionFilter, tabId: EBuyerAuctionTabId): string {
    return this.getAuctionDetailsUrlWithAuctionFilterParams(AUCTION_UUID_PLACEHOLDER, auctionFilter, tabId);
  }

  public replaceAuctionDetailsUrlTemplateWithUuid(auctionUuid: string, url: string, recommendationId?: string): string {
    if (!url) {
      return this.getAuctionDetailsUrlForUuid(auctionUuid);
    }

    url = url.replace(AUCTION_UUID_PLACEHOLDER, auctionUuid);

    if (recommendationId) {
      url = url.replace(RECOMMENDATION_ID_PLACEHOLDER, recommendationId);
    }

    return url;
  }

  private getAuctionDetailsUrlForUuid(uuid: string): string {
    return AUCTION_DETAIL_URL + uuid;
  }

  public showAuctionDetailsPage(auctionUuid: string, auctionDetailsUrl: string, event?: Event): void {
    const eventName = 'auction_detail-open-modal';
    const eventCategory = 'auction_detail';
    const eventAction = 'open';
    const eventLabel = 'modal';
    const eventValue = auctionUuid;
    const trackingUUID = auctionUuid;

    this.googleAnalyticsService.eventEmitter(eventName, eventCategory, eventAction, eventLabel, eventValue, trackingUUID);

    if ((event as MouseEvent)?.ctrlKey || (event as MouseEvent)?.metaKey) {
      window.open(auctionDetailsUrl, '_blank');
    } else {
      this.router.navigateByUrl(auctionDetailsUrl);
    }
  }

  private getQueryParamsForAuctionFilter(auctionFilter: IAuctionFilter = {}): string {
    const MARKER_STRING = 'END_OF_PATH';
    const queryParams = FormatUtils.formatLinkToAuctionFilterResults(
      removeEmptyValuesFromObject({
        ...auctionFilter,
        vehicleSearchQuery: removeEmptyValuesFromObject(auctionFilter?.vehicleSearchQuery),
      }),
      'invalid',
      MARKER_STRING,
    );
    return queryParams.split(MARKER_STRING + '?')[1];
  }

  public areAuctionFiltersEqual(first: IAuctionFilter, second: IAuctionFilter) {
    return isEqual(this.removeEmptyValuesFromFilter(first), this.removeEmptyValuesFromFilter(second));
  }

  public removeEmptyValuesFromFilter(auctionFilter: IAuctionFilter) {
    if (!auctionFilter) {
      return null;
    }
    const vehicleSearchQuery: IVehicleFilter = {
      ...(auctionFilter.vehicleSearchQuery || {}),
      ...(auctionFilter.vehicleSearchQuery?.makeAndModelPairs?.length
        ? { makeAndModelPairs: auctionFilter.vehicleSearchQuery.makeAndModelPairs.filter(val => val && val.make) }
        : {}),
    };
    return removeEmptyValuesFromObject({
      ...auctionFilter,
      vehicleSearchQuery: removeEmptyValuesFromObject(vehicleSearchQuery),
      distance: removeEmptyValuesFromObject(auctionFilter.distance || {}),
      sellerAccounts: removeEmptyValuesFromObject(auctionFilter.sellerAccounts || {}),
      onlyForRegularBuyers: removeEmptyValuesFromObject(auctionFilter.onlyForRegularBuyers || {}),
    });
  }

  public getTabIdFromQueryParams(queryParams: Params): EBuyerAuctionTabId {
    return (Number(queryParams['tabId']) as unknown as EBuyerAuctionTabId) || EBuyerAuctionTabId.RUNNING_AUCTIONS;
  }

  public getAuctionFilterFromQueryParams(queryParams: Params): IAuctionFilter {
    const vehicleSearchQuery = this.getAuctionFilterValueFromParams(queryParams, 'vehicleSearchQuery');
    const onlyForRegularBuyers = this.getAuctionFilterValueFromParams(queryParams, 'onlyForRegularBuyers');
    const isMinAskReached = this.getAuctionFilterValueFromParams(queryParams, 'isMinAskReached');
    const sellerAccounts = this.getAuctionFilterValueFromParams(queryParams, 'sellerAccounts');
    const externalIds = this.getAuctionFilterValueFromParams(queryParams, 'externalIds');
    const distance = this.getAuctionFilterValueFromParams(queryParams, 'distance');
    const includeCountries = this.getAuctionFilterValueFromParams(queryParams, 'includeCountries');
    const locationZipCodeQuery = this.getAuctionFilterValueFromParams(queryParams, 'locationZipCodeQuery');
    const hasExpressPickup = this.getAuctionFilterValueFromParams(queryParams, 'hasExpressPickup');
    const limit = this.getAuctionFilterValueFromParams(queryParams, 'limit');
    const offset = this.getAuctionFilterValueFromParams(queryParams, 'offset');
    const currentPriceFrom = this.getAuctionFilterValueFromParams(queryParams, 'currentPriceFrom');
    const currentPriceTo = this.getAuctionFilterValueFromParams(queryParams, 'currentPriceTo');
    const isVATReportable = this.getAuctionFilterValueFromParams(queryParams, 'isVATReportable');
    const auctionRoomUuid = this.getAuctionFilterValueFromParams(queryParams, 'auctionRoomUuid');
    const search = this.getAuctionFilterValueFromParams(queryParams, 'search')?.slice(0, QUICK_SEARCH_MAX_LENGTH);
    const advertisementTypes = this.getAuctionFilterValueFromParams(queryParams, 'advertisementTypes');
    const hasDiscounts = this.getAuctionFilterValueFromParams(queryParams, 'hasDiscounts');

    return {
      vehicleSearchQuery,
      onlyForRegularBuyers,
      isMinAskReached,
      sellerAccounts,
      externalIds,
      distance,
      includeCountries,
      locationZipCodeQuery,
      hasExpressPickup,
      search,
      limit,
      offset,
      currentPriceFrom,
      currentPriceTo,
      isVATReportable,
      auctionRoomUuid,
      advertisementTypes,
      hasDiscounts,
    };
  }

  private getAuctionFilterValueFromParams<T extends keyof IAuctionFilter>(params: Params, selector: T) {
    let filter: IAuctionFilter[T];

    if (params[selector] === undefined) {
      return { ...BASE_AUCTION_FILTER }[selector];
    }

    try {
      filter = JSON.parse(params[selector]);
    } catch (error) {
      console.error('Error parsing auction filter', error);
      filter = { ...BASE_AUCTION_FILTER }[selector];
    }

    return filter;
  }

  public isAuctionFilterInQueryParams(queryParams: Params): boolean {
    return queryParams['offset'] !== undefined;
  }

  public isAuctionFilterEmpty(auctionFilter: IAuctionFilter): boolean {
    return !auctionFilter || Object.keys(this.removeEmptyValuesFromFilter(auctionFilter)).length === 0;
  }

  public addDefaultLimitAndOffset(auctionFilter: IAuctionFilter): IAuctionFilter {
    return {
      ...auctionFilter,
      limit: auctionFilter?.limit || BASE_AUCTION_FILTER.limit,
      offset: auctionFilter?.offset || BASE_AUCTION_FILTER.offset,
    };
  }

  public resetLimitAndOffset(auctionFilter: IAuctionFilter): IAuctionFilter {
    return {
      ...auctionFilter,
      offset: BASE_AUCTION_FILTER.offset,
      limit: BASE_AUCTION_FILTER.limit,
    };
  }

  public removeLimitAndOffset(auctionFilter: IAuctionFilter): IAuctionFilter {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { limit, offset, ...rest } = auctionFilter;
    return rest;
  }

  public isChangingPage(auctionFilter: IAuctionFilter, newAuctionFilter: IAuctionFilter): boolean {
    if (!auctionFilter || !newAuctionFilter) {
      return false;
    }
    return auctionFilter.offset !== newAuctionFilter.offset || auctionFilter.limit !== newAuctionFilter.limit;
  }
}
