import { Injectable } from '@angular/core';
import { NativeDateAdapter } from '@angular/material/core';
import { NgxMatNativeDateAdapter } from '@angular-material-components/datetime-picker';

@Injectable()
export class CustomDateAdapter extends NativeDateAdapter {
  public override getFirstDayOfWeek(): number {
    return 1;
  }
}

@Injectable()
export class NgxCustomDateAdapter extends NgxMatNativeDateAdapter {
  public override getFirstDayOfWeek(): number {
    return 1;
  }
}
