import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { BidsAmountComponent } from './bids-amount.component';
import { BaseModule } from '@cosCoreComponentsGeneral/base.module';
import { EnzoComponentsModule } from '@caronsale/enzo-angular';
import { ExpandablePanelModule } from '@cos/partials/expandable-panel/expandable-panel.module';
import { BiddersListModule } from '@cosCoreFeatures/auction-detail/buyer/bidders-list/bidders-list.module';

@NgModule({
  declarations: [BidsAmountComponent],
  exports: [BidsAmountComponent],
  imports: [CommonModule, BaseModule, EnzoComponentsModule, ExpandablePanelModule, BiddersListModule],
})
export class BidsAmountModule {}
